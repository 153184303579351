import React, { useState } from 'react';
import { StatusIndicatorStatus, useCustomTranslation } from '@holberg/ui-kit';
import { showConfirmationModal } from 'components/PropertiesContainer/helpers';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import { PropertiesProvider } from './usePropertiesContext';

export const PropertiesProviderContext: React.FC = observer(({ children }) => {
  const { t } = useCustomTranslation();

  const studyDetailsStore = useStore(StoreType.StudyDetails);
  const findingPropertiesStore = useStore(StoreType.FindingProperties);

  const [activeCodingId, setActiveCodingId] = useState<number | undefined>(
    undefined
  );

  const closeCurrentPropertyPanel = (action: () => void) => {
    if (findingPropertiesStore.saveStatus !== StatusIndicatorStatus.Saved) {
      showConfirmationModal(
        action,
        t(
          'You may loose some of the data by closing properties panel, proceed anyway?'
        )
      );
    } else {
      action();
    }
  };

  return (
    <PropertiesProvider
      value={{
        activeCodingId,
        onFindingClick: (coding) =>
          closeCurrentPropertyPanel(async () => {
            if (coding) {
              await studyDetailsStore.updateUserSettingsConfig({
                reportCollapsed: true
              });

              if (activeCodingId && activeCodingId === coding.eventCodingId) {
                setActiveCodingId(undefined);
              } else {
                setActiveCodingId(coding.eventCodingId);
              }
            } else {
              setActiveCodingId(undefined);
            }
          }),
        onCloseProperties: () => {
          closeCurrentPropertyPanel(() => setActiveCodingId(undefined));
        }
      }}
    >
      {children}
    </PropertiesProvider>
  );
});
