import React, { FC, useCallback, useReducer } from 'react';

import { Payload, Provider, State } from './NameSelectionContext';

enum ActionType {
  OPEN_MODAL,
  CLOSE_MODAL
}

const initialState: State = {
  isOpen: false
};

const reducer = (
  state: State,
  action: {
    type: ActionType;
    payload?: Payload;
  }
): State => {
  switch (action.type) {
    case ActionType.OPEN_MODAL:
      return {
        isOpen: true,
        ...action.payload!
      };
    case ActionType.CLOSE_MODAL:
      return { isOpen: false };
    default:
      return state;
  }
};

export const NameSelectionProvider: FC = ({ children }) => {
  const [state, dispatchState] = useReducer(reducer, initialState);

  const onOpen = useCallback(
    (payload: Payload) =>
      dispatchState({
        type: ActionType.OPEN_MODAL,
        payload
      }),
    []
  );

  const onClose = useCallback(
    () =>
      dispatchState({
        type: ActionType.CLOSE_MODAL
      }),
    []
  );

  return (
    <Provider
      value={{
        state,
        onOpen,
        onClose
      }}
    >
      {children}
    </Provider>
  );
};
