import { deserialize, object, serializable } from 'serializr';

import { Age } from './Age.entity';

export class PatientAges {
  @serializable(object(Age))
  chronologicalAge: Age = new Age();

  @serializable(object(Age))
  correctedAge: Age = new Age();

  @serializable(object(Age))
  gestationalAge: Age = new Age();

  @serializable(object(Age))
  postmenstrualAge: Age = new Age();

  static deserialize(json: Object | string) {
    return deserialize(PatientAges, json);
  }
}
