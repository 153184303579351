import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Checkbox,
  IconType,
  IndexBadge,
  rebuildTooltips,
  TextBadge,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { CommonHeaderProps } from 'components/CategoryHeader/CategoryHeader';
import { FindingsWarning } from 'components/FindingsWarning';
import { EventCodingStatus } from 'entities/DescriptionStatus.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { EventTreeSettingsType } from 'enums/EventTreeSettingsType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHasFocus } from 'hooks/useHasFocus';
import { createShortcutCombination } from 'services/keyboardShortcuts/helpers';
import {
  shortcutsBaseKeys,
  shortcutsBaseTitles
} from 'services/keyboardShortcuts/shortcutsBaseKeys';

import styles from './FindingHeader.module.scss';

interface Props extends CommonHeaderProps {
  itemIndex?: string;
  isSelected?: boolean;
  className?: string;
  headModel?: string;
  isMoveUpHidden?: boolean;
  isMoveDownHidden?: boolean;
  isMoveUpDisabled?: boolean;
  isToBeDefined?: boolean;
  isMoveDownDisabled?: boolean;
  isCheckboxHidden?: boolean;
  isCheckboxDisabled?: boolean;
  isActionsAvailable?: boolean;
  isHighlighted?: boolean;
  isMoveToAvailable?: boolean;
  isLinkDisabled?: boolean;
  isSimultaneous?: boolean;
  isLinked?: boolean;
  isFocused?: boolean;
  onToggle: () => void;
  onRemoveItem?: () => void;
  onMoveTo?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onClassify?: () => void;
  onLinkClick?: () => void;
  onUndoSimultaneous?: () => void;
  onDrag: () => void;
  codingStatus?: EventCodingStatus;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  index: number;
  eventCodingId: number;
  readOnly: boolean;
  studyId: number;
  parentFolderId: number;
  hasScreenshot: boolean;
  onScreenshotClick: () => void;
}

export const FindingHeader: FC<Props> = ({
  arrow,
  label,
  itemIndex,
  className,
  headModel,
  isMoveUpHidden = false,
  isToBeDefined = false,
  isMoveDownHidden = false,
  isMoveUpDisabled = false,
  isMoveDownDisabled = false,
  isLinkDisabled = true,
  isCheckboxHidden = false,
  isHighlighted = false,
  isCheckboxDisabled = false,
  isActionsAvailable = true,
  isMoveToAvailable,
  isSimultaneous,
  isLinked,
  isFocused,
  hasChildren,
  isSelected = false,
  codingStatus,
  onToggle,
  onDrag,
  onRemoveItem,
  onMoveUp,
  onMoveDown,
  onMoveTo,
  onUndoSimultaneous,
  onClick,
  onLinkClick,
  onClassify,
  isActive,
  eventCodingId,
  readOnly,
  studyId,
  parentFolderId,
  index,
  hasScreenshot,
  onScreenshotClick
}) => {
  const { t } = useCustomTranslation();
  const [hasFocus, setFocused] = useState(isFocused);
  const focusHandler = useHasFocus();
  const findingsStore = useStore(StoreType.Findings);

  const ref = useRef<HTMLDivElement>(null);

  const onBlur = useCallback(() => {
    setFocused(false);
    focusHandler.onBlur();
  }, [focusHandler]);

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: DraggableTypes.finding,
      canDrag: () =>
        !findingsStore.selectionState.selectedExamples.length &&
        (findingsStore.selectionState.selectedFindings.length
          ? !readOnly &&
            findingsStore.selectionState.selectedFindings.some(
              (finding) => finding.item.eventCodingId === eventCodingId
            )
          : !readOnly),
      end: (_item, monitor) => {
        if (!monitor.didDrop()) {
          findingsStore.selectionState.discardFindingsSelections();
        }
      },
      item: {
        id: eventCodingId,
        label: label,
        parentFolder: parentFolderId,
        index: index
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging()
        };
      }
    }),
    [label, readOnly, index]
  );

  useEffect(() => {
    dragPreview(getEmptyImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // collapse all selected findings while dragging
    if (isDragging) {
      if (!findingsStore.selectionState.selectedFindings.length) {
        onDrag();
        if (isActive)
          findingsStore.eventTreeState.updateEventTreeSettingsConfig({
            studyId: studyId,
            entityId: eventCodingId,
            settingKey: EventTreeSettingsType.FindingsState,
            expanded: false
          });
      } else {
        for (const findingId of findingsStore.selectionState
          .selectedFindingsIds) {
          findingsStore.eventTreeState.updateEventTreeSettingsConfig({
            studyId: studyId,
            entityId: findingId,
            settingKey: EventTreeSettingsType.FindingsState,
            expanded: false
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus();
      setFocused(true);
    } else if (!isFocused) {
      onBlur();
    }
    // eslint-disable-next-line
  }, [isFocused]);

  useEffect(() => {
    rebuildTooltips();
  }, [
    isCheckboxDisabled,
    isActionsAvailable,
    isSimultaneous,
    isMoveToAvailable,
    isMoveUpHidden,
    isMoveDownHidden
  ]);

  useHotkeys(
    createShortcutCombination({
      baseKey: shortcutsBaseKeys.OPTION,
      key: 'up'
    }),
    (event) => {
      event.preventDefault();

      focusHandler.hasFocus && isActionsAvailable && onMoveUp?.();
    },
    {},
    [focusHandler.hasFocus, isActionsAvailable, onMoveUp]
  );
  useHotkeys(
    createShortcutCombination({
      baseKey: shortcutsBaseKeys.OPTION,
      key: 'down'
    }),
    (event) => {
      event.preventDefault();

      focusHandler.hasFocus && isActionsAvailable && onMoveDown?.();
    },
    {},
    [focusHandler.hasFocus, isActionsAvailable, onMoveDown]
  );
  useHotkeys(
    `${createShortcutCombination({
      baseKey: shortcutsBaseKeys.BACKSPACE
    })}, ${createShortcutCombination({
      baseKey: shortcutsBaseKeys.DELETE
    })}`,

    (event) => {
      event.preventDefault();
      focusHandler.hasFocus && isActionsAvailable && onRemoveItem?.();
    },
    {},
    [focusHandler.hasFocus, isActionsAvailable, onRemoveItem]
  );

  const renderCheckbox = useCallback(
    () => (
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={isSelected}
          className={cn(styles.checkbox, {
            [styles.hidden]: isCheckboxHidden
          })}
          disabled={isCheckboxDisabled}
          onChange={onToggle}
          tabIndex={-1}
        />
      </div>
    ),
    [isCheckboxDisabled, isCheckboxHidden, isSelected, onToggle]
  );

  useEffect(() => {
    if (isHighlighted) {
      ref.current?.blur();
    }
  }, [isHighlighted]);

  return (
    <div
      className={cn(
        styles['finding-header'],
        className,
        'finding-header-identifier',
        {
          [styles['is-selected']]: isSelected || isHighlighted,
          'focus-visible': hasFocus
        }
      )}
      tabIndex={0}
      data-testid='finding-header'
      onClick={onClick}
      onFocus={focusHandler.onFocus}
      onBlur={onBlur}
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <div className={cn(styles.container, styles['main-container'])}>
        {isCheckboxDisabled ? (
          <Tooltip
            contentClassName={styles['checkbox-tooltip']}
            offset={{ bottom: 25 }}
            data={[
              {
                mainTooltip: t("Can't select both findings & examples")
              }
            ]}
          >
            {renderCheckbox()}
          </Tooltip>
        ) : (
          renderCheckbox()
        )}
        <span>
          {/* <Tooltip
            contentClassName={styles['arrow-tooltip']}
            offset={{ bottom: 5, right: 45 }}
            data={[
              {
                mainTooltip: (
                  <span>
                    {t('Expand category')}
                    <TextBadge title='C' />
                  </span>
                )
              }
              {
                mainTooltip: (
                  <span>
                    {t('Press')}
                    <TextBadge title={t('SHIFT')} />
                    +
                    <TextBadge title={t('C')} />
                    {t('to expand all children as well')}
                  </span>
                )
              }
            ]}
          > */}
          {arrow}
          {/* </Tooltip> */}
        </span>
        <div className={styles.headModel}>
          {headModel && !isToBeDefined ? (
            <div
              dangerouslySetInnerHTML={{
                __html: headModel
              }}
            />
          ) : (
            <span></span>
          )}
        </div>
        {itemIndex && (
          <IndexBadge className={styles.badge}>{itemIndex}</IndexBadge>
        )}
        <div className={styles['label-container']}>
          <span
            ref={drag}
            data-testid='finding-draggable-area'
            className={cn(styles.label, !hasChildren && styles.disabled)}
          >
            {label}
          </span>
          {isLinked && (
            <Tooltip
              contentClassName={styles['arrow-tooltip']}
              data={[
                {
                  mainTooltip: t('Click to open top example')
                }
              ]}
            >
              <Button
                data-testid='finding-link-button'
                size={ButtonSize.Small}
                disabled={isLinkDisabled}
                theme={ButtonTheme.SecondaryTransparent}
                className={cn(styles['link-button'])}
                icon={IconType.Link}
                onClick={(e) => {
                  e.stopPropagation();
                  onLinkClick && onLinkClick();
                }}
                tabIndex={-1}
              />
            </Tooltip>
          )}
          {hasScreenshot && (
            <Tooltip
              contentClassName={styles['tooltip-content']}
              data={[
                {
                  mainTooltip: <span>{t('View the first screenshot')}</span>
                }
              ]}
            >
              <Button
                theme={ButtonTheme.SecondaryTransparent}
                icon={IconType.Eeg}
                onClick={(e) => {
                  e.stopPropagation();
                  onScreenshotClick();
                }}
                className={cn(styles['link-button'])}
              />
            </Tooltip>
          )}
          <FindingsWarning
            status={codingStatus}
            isActive={isActive && hasChildren}
          />
        </div>
        <div className={styles.actions}>
          {isActionsAvailable && (
            <>
              {!isMoveUpHidden && (
                <Tooltip
                  data={[
                    {
                      mainTooltip: (
                        <span>
                          {t('Move up')}
                          <TextBadge
                            title={shortcutsBaseTitles.OPTION}
                            className={styles['text-badge']}
                          />
                          +
                          <TextBadge
                            title={t('↑')}
                            className={styles['text-badge']}
                          />
                        </span>
                      )
                    }
                  ]}
                >
                  <Button
                    data-testid='move-up'
                    size={ButtonSize.Small}
                    disabled={isMoveUpDisabled}
                    theme={ButtonTheme.SecondaryTransparent}
                    className={cn(styles.button)}
                    icon={IconType.Arrow}
                    onClick={(e) => {
                      e.stopPropagation();
                      onMoveUp && onMoveUp();
                    }}
                    tabIndex={-1}
                  />
                </Tooltip>
              )}
              {!isMoveDownHidden && (
                <Tooltip
                  data={[
                    {
                      mainTooltip: (
                        <span>
                          {t('Move down')}
                          <TextBadge
                            title={shortcutsBaseTitles.OPTION}
                            className={styles['text-badge']}
                          />
                          +
                          <TextBadge
                            title={t('↓')}
                            className={styles['text-badge']}
                          />
                        </span>
                      )
                    }
                  ]}
                >
                  <Button
                    data-testid='move-down'
                    size={ButtonSize.Small}
                    disabled={isMoveDownDisabled}
                    theme={ButtonTheme.SecondaryTransparent}
                    className={cn(styles.button, styles['move-down'])}
                    icon={IconType.Arrow}
                    onClick={(e) => {
                      e.stopPropagation();
                      onMoveDown && onMoveDown();
                    }}
                    tabIndex={-1}
                  />
                </Tooltip>
              )}
              {isSimultaneous && (
                <Tooltip
                  data={[
                    {
                      mainTooltip: (
                        <span>
                          {t('Undo simultaneous')}
                          <TextBadge title='S' />
                        </span>
                      )
                    }
                  ]}
                >
                  <Button
                    data-testid='undo-simultaneous'
                    size={ButtonSize.Small}
                    theme={ButtonTheme.SecondaryTransparent}
                    className={styles.button}
                    icon={IconType.UndoSimultaneous}
                    onClick={(e) => {
                      e.stopPropagation();
                      onUndoSimultaneous && onUndoSimultaneous();
                    }}
                    tabIndex={-1}
                  />
                </Tooltip>
              )}
              <Tooltip
                data={[
                  {
                    mainTooltip: (
                      <span>
                        {t(isToBeDefined ? 'Classify' : 'Reclassify')}
                        {/* <TextBadge
                          title={t('C')}
                          className={styles['text-badge']}
                        /> */}
                      </span>
                    )
                  }
                ]}
              >
                <Button
                  data-testid='classify'
                  size={ButtonSize.Small}
                  theme={ButtonTheme.SecondaryTransparent}
                  className={styles.button}
                  icon={IconType.Classify}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClassify && onClassify();
                  }}
                  tabIndex={-1}
                />
              </Tooltip>
              <Tooltip
                data={[
                  {
                    mainTooltip: (
                      <span>
                        {t('Remove')}
                        <TextBadge
                          title={shortcutsBaseTitles.DELETE}
                          className={styles['text-badge']}
                        />
                      </span>
                    )
                  }
                ]}
              >
                <Button
                  data-testid='remove'
                  size={ButtonSize.Small}
                  theme={ButtonTheme.CautionTransparent}
                  className={styles.button}
                  icon={IconType.Close}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveItem && onRemoveItem();
                  }}
                  tabIndex={-1}
                />
              </Tooltip>
            </>
          )}
          {isMoveToAvailable && (
            <Tooltip
              data={[
                {
                  mainTooltip: t('Move finding')
                }
              ]}
            >
              <Button
                size={ButtonSize.Small}
                icon={IconType.Subdirectory}
                onClick={(e) => {
                  e.stopPropagation();
                  onMoveTo && onMoveTo();
                }}
                title={t('Move to')}
                theme={ButtonTheme.Highlighted}
                className={cn(styles.button, styles['move-button'])}
                tabIndex={-1}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
