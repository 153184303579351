import {
  addSeconds,
  differenceInDays,
  format,
  formatDuration,
  getMilliseconds,
  intervalToDuration,
  parse,
  setMilliseconds
} from 'date-fns';

import { getLocale } from './browserHelpers';

const dateFormatWrapper = (
  formatCb: (date: Date) => string,
  date?: Date,
  placeholder?: string
) => {
  if (date && date instanceof Date && !isNaN(date.getTime())) {
    try {
      return formatCb(date);
    } catch (e) {
      return placeholder;
    }
  }
  return placeholder;
};

export const formatDate = (
  date?: Date,
  dateFormat: string = 'yyyy-MM-dd',
  placeholder?: string
): string | undefined =>
  dateFormatWrapper(
    (validDate) => format(validDate, dateFormat),
    date,
    placeholder
  );

export const formatLocaleDate = (
  date?: Date,
  placeholder?: string,
  options?: Intl.DateTimeFormatOptions
): string | undefined =>
  dateFormatWrapper(
    (validDate) => validDate.toLocaleDateString(getLocale(), options),
    date,
    placeholder
  );

export const formatLocaleDateTime = (
  date?: Date,
  placeholder?: string,
  options?: Intl.DateTimeFormatOptions
): string | undefined =>
  dateFormatWrapper(
    (validDate) => validDate.toLocaleString(getLocale(), options),
    date,
    placeholder
  );

export const formatLocaleDayNumber = (
  firstCompareDate: Date,
  date?: Date,
  placeholder?: string,
  options?: Intl.DateTimeFormatOptions
): string | undefined =>
  dateFormatWrapper(
    (validDate) =>
      `Day ${
        differenceInDays(validDate, firstCompareDate) + 1
      } ${validDate.toLocaleTimeString(getLocale(), options)}`,
    date,
    placeholder
  );

export const formatLocaleTime = (
  date?: Date,
  placeholder?: string,
  options?: Intl.DateTimeFormatOptions
): string | undefined =>
  dateFormatWrapper(
    (validDate) => validDate.toLocaleTimeString(getLocale(), options),
    date,
    placeholder
  );

export const parseDate = (
  payload?: string,
  dateFormat: string = 'yyyy-MM-dd'
): Date | null => {
  if (payload) {
    try {
      const parsed = parse(payload, dateFormat, new Date());
      const time = parsed.getTime();

      if (isNaN(time)) {
        return null;
      }

      return parsed;
    } catch (e) {
      return null;
    }
  }

  return null;
};

export const roundToNearestSecond = (date?: Date): Date | null => {
  if (date) {
    try {
      let roundedDate = new Date(date);
      const milliseconds = getMilliseconds(date);
      const maxMillisecondsAmount = 500;

      if (milliseconds > maxMillisecondsAmount) {
        roundedDate = addSeconds(roundedDate, 1);
        roundedDate = setMilliseconds(roundedDate, 0);
      } else {
        roundedDate = setMilliseconds(roundedDate, 0);
      }

      return roundedDate;
    } catch (e) {
      return null;
    }
  }

  return null;
};

export const excludeTimezoneOffset = (date: Date): Date =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000);

const DATE_PIECES = ['year', 'month', 'day'] as const;
const DATE_TIME_PIECES = [...DATE_PIECES, 'hour', 'minute'] as const;
const DATE_TIME_PIECES_REPLACEMENTS = {
  year: 'y',
  month: 'M',
  day: 'd',
  week: 'w',
  hour12: 'h',
  hour24: 'H',
  minute: 'm',
  second: 's'
};

export const dateDifference = (
  startDate: Date,
  endDate: Date,
  getDiffinShortUnits: boolean = false
) => {
  const duration = intervalToDuration({
    start: startDate,
    end: endDate
  });
  if (getDiffinShortUnits) {
    return (
      (duration.years
        ? `${duration.years}${DATE_TIME_PIECES_REPLACEMENTS.year} `
        : '') +
      (duration.months
        ? `${duration.months}${DATE_TIME_PIECES_REPLACEMENTS.month} `
        : '') +
      (duration.weeks
        ? `${duration.weeks}${DATE_TIME_PIECES_REPLACEMENTS.week} `
        : '') +
      (duration.days
        ? `${duration.days}${DATE_TIME_PIECES_REPLACEMENTS.day} `
        : '') +
      (duration.hours
        ? `${duration.hours}${DATE_TIME_PIECES_REPLACEMENTS.hour12} `
        : '') +
      (duration.minutes
        ? `${duration.minutes}${DATE_TIME_PIECES_REPLACEMENTS.minute} `
        : '') +
      (duration.seconds
        ? `${duration.seconds}${DATE_TIME_PIECES_REPLACEMENTS.second} `
        : '')
    ).trim();
  } else {
    return formatDuration(duration, {
      delimiter: ' '
    });
  }
};

const formatDatePiece = (name: typeof DATE_TIME_PIECES[number], date: Date) => {
  const formattedDate = formatLocaleDateTime(date, undefined, {
    [name]: 'numeric'
  });
  return name === 'hour' ? formattedDate?.slice(0, 2) : formattedDate;
};

const is24Format = () => {
  const hours = 23;
  const date = new Date(2021, 8, 19, hours, 14);

  return (
    formatLocaleDateTime(date, undefined, { hour: 'numeric' })?.slice(0, 2) ===
    String(hours)
  );
};

const createFormat = (
  datePieces: Readonly<Array<typeof DATE_TIME_PIECES[number]>>,
  formattedDate: string,
  date: Date
) => {
  const hourPiece = is24Format() ? 'hour24' : 'hour12';

  return datePieces.reduce((currentFormat, datePiece) => {
    const formattedDatePiece = String(
      Number(formatDatePiece(datePiece, date)!)
    );
    const piece = datePiece === 'hour' ? hourPiece : datePiece;
    const datePieceReplacement = DATE_TIME_PIECES_REPLACEMENTS[piece];

    return currentFormat.replace(
      formattedDatePiece,
      datePieceReplacement.repeat(formattedDatePiece.length)
    );
  }, formattedDate);
};

const getTimeDivider = () => {
  const hours = 23;
  const minutes = 14;

  const date = new Date(2021, 10, 22, hours, minutes);
  const formattedDate = formatLocaleDateTime(date, undefined, {
    hour: 'numeric',
    minute: 'numeric'
  });

  const hoursIndex = formattedDate!.indexOf(String(hours));
  const minutesIndex = formattedDate!.indexOf(String(minutes));

  const divider = formattedDate?.slice(
    hoursIndex + String(hours).length,
    minutesIndex
  );

  return divider;
};

export const getDateFormat = () => {
  const year = 2021;
  const monthIndex = 10;
  const day = 22;

  const date = new Date(year, monthIndex, day);
  const formattedDate = formatLocaleDate(date, undefined, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  });

  if (!formattedDate) {
    return;
  }

  return createFormat(DATE_PIECES, formattedDate, date);
};

export const getDateTimeFormat = () => {
  const year = 2021;
  const monthIndex = 10;
  const day = 22;
  const hours = 23;
  const minutes = 14;

  const date = new Date(year, monthIndex, day, hours, minutes);
  const formattedDate = formatLocaleDateTime(date, undefined, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });

  if (!formattedDate) {
    return;
  }

  let format = createFormat(DATE_TIME_PIECES, formattedDate, date);
  const timeDivider = getTimeDivider();

  // Replace exotic time dividers with columns
  if (
    timeDivider?.search(
      new RegExp(Object.values(DATE_TIME_PIECES_REPLACEMENTS).join('|'))
    ) !== -1
  ) {
    const startIndex = formattedDate.indexOf(String(hours)) + 2;
    const endIndex = formattedDate.indexOf(String(minutes));
    format = `${format.slice(0, startIndex)}:${format.slice(endIndex)}`;
  }

  return is24Format() ? format : format.replace(/PM|pm|p.m.|P.M./, 'a');
};

export const getDateMask = (format: string) =>
  Object.values(DATE_TIME_PIECES_REPLACEMENTS).reduce(
    (tempMask, value) => tempMask.replace(new RegExp(value, 'g'), '9'),
    format
  );

export const getDateTimeMask = (format: string) => {
  const mask = getDateMask(format);

  return mask.replace('a', 'aM');
};

export const getPadTimeFormat = (date: Date, withTime?: boolean) =>
  date.toLocaleString('en-US', {
    hour12: true,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: withTime ? '2-digit' : undefined,
    minute: withTime ? '2-digit' : undefined
  });

export const getCurrentTimeInSeconds = () => {
  return Math.floor(new Date().getTime() / 1000);
};
