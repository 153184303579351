import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { ApiError } from 'entities/ApiError.entity';
import { StoreType } from 'enums/StoreType.enum';
import { ApiRetryState } from 'services/API/ApiRetryManager';
import { Env } from 'services/Env';
import { stores } from 'stores';
import { AUTH_TOKEN_KEY } from 'utils/constants';

export const requestSuccessInterceptor = (config: AxiosRequestConfig) => {
  const token =
    sessionStorage.getItem(AUTH_TOKEN_KEY) ||
    localStorage.getItem(AUTH_TOKEN_KEY);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

export const responseSuccessInterceptor = (response: AxiosResponse) => {
  return response;
};

export const responseErrorInterceptor = (error?: AxiosError) => {
  if (error?.response?.status === 401) {
    stores[StoreType.Auth].unauthorize();
    stores[StoreType.Messages].setUnauthorizeError(
      ApiError.deserializeFromCatch(error)
    );
  }

  throw error;
};

export abstract class ApiBase {
  protected readonly client: AxiosInstance;

  constructor(prefix: string = '') {
    const client = axios.create({
      baseURL: Env.BASE_URL + prefix,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Timezone-Offset': new Date().getTimezoneOffset()
      }
    });

    client.interceptors.request.use(requestSuccessInterceptor);

    client.interceptors.response.use(
      responseSuccessInterceptor,
      responseErrorInterceptor
    );
    this.client = client;
  }

  createRetryState<T>(error: AxiosError): ApiRetryState<T> | undefined {
    if (error?.config) {
      return new ApiRetryState(this.client, error.config);
    }

    return undefined;
  }
}
