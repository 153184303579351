import { deserialize, list, object, serializable } from 'serializr';

import { EventPropertyCode } from './EventPropertyCode.entity';
import { EventPropertyType } from './EventPropertyType.entity';

export class EventPropertyTypeCategorical {
  @serializable(object(EventPropertyType))
  propertyType: EventPropertyType = new EventPropertyType();

  @serializable(list(object(EventPropertyCode)))
  propertyCodes: EventPropertyCode[] = [];

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyTypeCategorical, json);
  }
}
