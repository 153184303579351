import { deserialize, serializable } from 'serializr';

import { EventPropertyCodingsNumberDTO } from './EventPropertyCodingsNumberDTO.entity';

export class EventPropertyCodingsInteger extends EventPropertyCodingsNumberDTO {
  @serializable
  panelId: number = 0;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCodingsInteger, json);
  }
}
