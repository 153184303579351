import { SensorMeta, SensorState } from 'stores/head-model';
import { isIE } from 'utils/browserHelpers';

import { extractSensorId } from '../Sensor/utils';

export type SvgDragSelectElement =
  | SVGCircleElement
  | SVGEllipseElement
  | SVGImageElement
  | SVGLineElement
  | SVGPathElement
  | SVGPolygonElement
  | SVGPolylineElement
  | SVGRectElement
  | SVGTextElement
  | SVGUseElement;

export const svgDragSelectElementTypes = [
  SVGCircleElement,
  SVGEllipseElement,
  SVGImageElement,
  SVGLineElement,
  SVGPathElement,
  SVGPolygonElement,
  SVGPolylineElement,
  SVGRectElement,
  SVGTextElement,
  SVGUseElement
];

const DEFAULT_FONT_SIZE = 2;

export const getScaledOffset = (
  textElement: SVGTextElement,
  fontSize: number
) => {
  const { x, y, width, height } = textElement.getBBox();
  const scaleWidthIndex = width / DEFAULT_FONT_SIZE;
  const scaledWidth = fontSize * scaleWidthIndex;
  const scaleHeightIndex = height / DEFAULT_FONT_SIZE;
  const scaledHeight = fontSize * scaleHeightIndex;

  const xOffset = x - scaledWidth / 2 + width / 2;
  const yOffset = y + scaledHeight / 2 - height / 2;

  return {
    xOffset,
    yOffset
  };
};

const createPoint = (svg: SVGSVGElement, x: number, y: number) => {
  const point = svg.createSVGPoint();
  point.x = x;
  point.y = y;

  return point;
};

export const getFontSize = (
  svg: SVGSVGElement,
  pathElements: SVGGeometryElement[]
): number => {
  for (let fontSize = 9; fontSize > 1; fontSize--) {
    const isAvailableFontSize = pathElements.every((pathElement) => {
      const textElement = pathElement.nextElementSibling as SVGTextElement;

      if (!textElement) return false;

      const { width, height } = textElement.getBBox();
      const { xOffset, yOffset } = getScaledOffset(textElement, fontSize);

      const point1 = createPoint(svg, xOffset, yOffset);
      const point2 = createPoint(svg, xOffset + width, yOffset);
      const point3 = createPoint(svg, xOffset + width, yOffset + height);
      const point4 = createPoint(svg, xOffset, yOffset + height);

      const isInFill =
        pathElement.isPointInFill(point1) &&
        pathElement.isPointInFill(point2) &&
        pathElement.isPointInFill(point3) &&
        pathElement.isPointInFill(point4);

      return isInFill;
    });

    if (isAvailableFontSize) {
      return fontSize;
    }
  }

  return 1;
};

const collectElements = (
  into: SvgDragSelectElement[],
  svg: SVGSVGElement,
  ancestor: SVGElement,
  filter: (element: SvgDragSelectElement) => boolean
) => {
  for (
    let element = ancestor.firstElementChild;
    element;
    element = element.nextElementSibling
  ) {
    if (element instanceof SVGGElement) {
      collectElements(into, svg, element, filter);
      continue;
    }
    for (const elementType of svgDragSelectElementTypes) {
      if (element instanceof elementType && filter(element)) {
        into.push(element);
      }
    }
  }
  return into;
};

export const isInRange = (x: number, min: number, max: number) =>
  min <= x && x <= max;

const intersects = (areaInSvgCoordinate: SVGRect, bbox: SVGRect) => {
  const left = areaInSvgCoordinate.x;
  const right = left + areaInSvgCoordinate.width;
  const top = areaInSvgCoordinate.y;
  const bottom = top + areaInSvgCoordinate.height;
  return (
    (isInRange(bbox.x, left, right) ||
      isInRange(bbox.x + bbox.width, left, right) ||
      isInRange(left, bbox.x, bbox.x + bbox.width)) &&
    (isInRange(bbox.y, top, bottom) ||
      isInRange(bbox.y + bbox.height, top, bottom) ||
      isInRange(top, bbox.y, bbox.y + bbox.height))
  );
};

export const getIntersections = (
  svg: SVGSVGElement,
  referenceElement: SVGElement | null,
  areaInSvgCoordinate: SVGRect,
  areaInInitialSvgCoordinate: SVGRect
) => {
  const intersections = svg.getIntersectionList
    ? Array.prototype.slice.call(
        svg.getIntersectionList(areaInInitialSvgCoordinate, referenceElement!)
      )
    : collectElements([], svg, referenceElement || svg, (element) =>
        intersects(areaInSvgCoordinate, element.getBBox())
      );

  return intersections.filter((element: SVGGeometryElement) => {
    if (!(element instanceof SVGPathElement)) {
      return false;
    }

    if (isIE() || !element.isPointInFill) {
      return true;
    }

    const point = svg.createSVGPoint();
    point.x = areaInSvgCoordinate.x;
    point.y = areaInSvgCoordinate.y;

    if (element.isPointInFill(point)) {
      return true;
    }
    return false;
  });
};

export const getId = (element: SvgDragSelectElement): string =>
  element.parentElement?.attributes['data-id']?.value || '';

export const createSvgRect = (
  svg: SVGSVGElement,
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => {
  const svgRect = svg.createSVGRect();
  svgRect.x = Math.min(x1, x2);
  svgRect.y = Math.min(y1, y2);
  svgRect.width = Math.abs(x1 - x2);
  svgRect.height = Math.abs(y1 - y2);
  return svgRect;
};

export const transformSvgRect = (
  svg: SVGSVGElement,
  matrix: DOMMatrix | null,
  rect: SVGRect
) => {
  if (!matrix) {
    return rect;
  }
  const point = svg.createSVGPoint();
  point.x = rect.x;
  point.y = rect.y;
  const p1 = point.matrixTransform(matrix);
  point.x += rect.width;
  point.y += rect.height;
  const p2 = point.matrixTransform(matrix);
  return createSvgRect(svg, p1.x, p1.y, p2.x, p2.y);
};

export const getRegionsCodings = (
  sensorsFields: {
    [x: number]: SensorState;
  },
  sensorsMeta: Map<string, SensorMeta>
): { [x: string]: boolean } => {
  const selectedSensorsMeta = Object.entries(sensorsFields)
    .filter(([, state]) => state !== SensorState.Empty)
    .map(([sensorFieldName]) =>
      sensorsMeta?.get(extractSensorId(sensorFieldName))
    )
    .filter((sensorMeta) => !!sensorMeta);

  const selectedRegions = new Set<string>();
  selectedSensorsMeta.forEach((meta) =>
    meta?.regions.slice().forEach((regionId) => selectedRegions.add(regionId))
  );

  return Array.from(selectedRegions.values()).reduce((acc, regionId) => {
    return {
      ...acc,
      [regionId]: true
    };
  }, {});
};
