export const isMacOS = navigator.platform.toUpperCase().indexOf('MAC') !== -1;

export const shortcutsBaseKeys = Object.freeze({
  CMD: isMacOS ? 'command' : 'ctrl',
  OPTION: isMacOS ? 'Option' : 'Alt',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  SHIFT: 'shift'
});

export const shortcutsBaseTitles = Object.freeze({
  CMD: isMacOS ? 'cmd' : 'ctrl',
  OPTION: isMacOS ? 'option' : 'alt',
  DELETE: 'delete',
  SPACE: 'space',
  ESC: 'esc'
});
