import { Event } from 'entities/Event.entity';
import { EventCode } from 'entities/EventCode.entity';

export const generateSettingsConfigKey = (
  childId: EventCode['eventCodeId'],
  parentId?: number
): string => (parentId ? `${parentId}-${childId}` : String(childId));

export const groupExamples = (
  selectedExamples: Event[]
): Map<Event['eventCodingId'], Event[]> =>
  selectedExamples.reduce(
    (acc: Map<Event['eventCodingId'], Event[]>, example) => {
      if (!acc.get(example.eventCodingId)) {
        acc.set(example.eventCodingId, []);
      }
      acc.get(example.eventCodingId)!.push(example);
      return acc;
    },
    new Map()
  );
