import { StatusBadgeTheme } from '@holberg/ui-kit';

export enum StudyStatus {
  Completed = 'completed',
  InProgress = 'inProgress'
}

export const StudyStatusLabel = Object.freeze({
  [StudyStatus.Completed]: 'Completed',
  [StudyStatus.InProgress]: 'In progress'
});

export const StudyStatusTheme = Object.freeze({
  [StudyStatus.Completed]: StatusBadgeTheme.Green,
  [StudyStatus.InProgress]: StatusBadgeTheme.Blue
});
