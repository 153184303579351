import { action, computed, makeObservable, observable } from 'mobx';

export class ApiLoadingState {
  @observable
  private requestsStatus: Map<string, boolean> = new Map();

  constructor() {
    makeObservable<ApiLoadingState, 'requestsStatus'>(this);
  }

  @action
  reset() {
    this.requestsStatus = new Map();
  }

  @action
  setRequestLoadingStatus(identifier: string, status: boolean) {
    this.requestsStatus.set(identifier, status);
  }

  @computed
  get isAnyLoading(): boolean {
    const values = [...this.requestsStatus.values()];

    return values.find(Boolean) || false;
  }
}
