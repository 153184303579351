import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  useFormContext,
  useWatch
} from 'react-hook-form/dist/index.ie11';
import { components, OptionProps } from 'react-select';
import {
  Checkbox,
  ErrorMessage,
  Icon,
  InputContainer,
  Select,
  SelectOption,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { withBilateral } from 'components/PropertyTabsPanelForm/withBilateral';
import { withRequiredProperty } from 'components/PropertyTabsPanelForm/withRequiredProperty';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { EventPropertyTypeNonCategorical } from 'entities/EventPropertyTypeNonCategorical.entity';
import { FindingPropertiesEventTypes } from 'enums/FindingPropertiesEventTypes.enum';
import { HDataType } from 'enums/HDataType.enum';
import { PropertyPanelIconsId } from 'enums/PropertyPanelIconsId.enum';
import { PropertyPanelOperator } from 'enums/PropertyPanelOperatorEnum.enum';
import { useHandlePropertiesAutoSave } from 'hooks/useHandlePropertiesAutoSave';
import { useHasFocus } from 'hooks/useHasFocus';
import { usePrevious } from 'hooks/usePrevious';
import compose from 'lodash.flowright';
import { FindingPropertiesPublisher } from 'services/FindingPropertiesPublisher';
import {
  EventPropertyCodingsData,
  NumberPropertyCoding
} from 'stores/finding-properties';

import { PropertyFormInput } from './PropertyFormInput';
import { PropertyFormTimeInput } from './PropertyFormTimeInput';

import styles from './PropertyPanelInput.module.scss';

const Option: React.FC<OptionProps<{ label: string; value: string }, false>> = (
  props
) => (
  <components.Option {...props}>
    <SelectOption label={props.label} className={styles.option} />
  </components.Option>
);

interface LabelProps {
  text: string;
  iconId: EventPropertyType['iconId'];
  isDisabled?: boolean;
}

const Label: React.FC<LabelProps> = ({ text, iconId, isDisabled }) => (
  <span className={cn(styles.label, isDisabled && styles.disabled)}>
    {PropertyPanelIconsId[iconId] && (
      <Icon iconType={PropertyPanelIconsId[iconId]} className={styles.icon} />
    )}
    <span className={styles.text}>{text}</span>
  </span>
);

interface Props {
  data: EventPropertyTypeNonCategorical;
  disabled?: boolean;
  disabledByBilateral?: boolean;
  className?: string;
  panelId: EventPropertyPanel['eventPropertyPanelId'];
}

export const PropertyPanelInput: React.FC<Props> = compose(
  withBilateral<Props>(({ data }) => data.propertyType),
  withRequiredProperty<Props>(({ data }) => data.propertyType)
)(({ data, disabled, disabledByBilateral, className, panelId }) => {
  const [hasTimeFullFormat, setTimeFullFormat] = useState(false);
  const { t } = useCustomTranslation();
  const { control, errors, clearErrors, setValue } = useFormContext<{
    eventPropertyCodings: EventPropertyCodingsData;
  }>();
  const actionDisabled = disabled || disabledByBilateral;

  const { eventHandler } = useHandlePropertiesAutoSave(panelId);

  useEffect(() => {
    if (actionDisabled) {
      clearErrors();
      setTimeFullFormat(false);
    }
    // eslint-disable-next-line
  }, [actionDisabled]);

  const value1Focus = useHasFocus();
  const value2Focus = useHasFocus();

  const toggleTimeFullFormat = useCallback(() => {
    setTimeFullFormat((prevState) => !prevState);
  }, []);

  const accessor = useMemo(
    () =>
      data.propertyType.dataTypeId === HDataType.Integer
        ? `eventPropertyCodings.integer.${data.propertyType.eventPropertyTypeId}`
        : `eventPropertyCodings.decimal.${data.propertyType.eventPropertyTypeId}`,
    [data.propertyType.dataTypeId, data.propertyType.eventPropertyTypeId]
  );

  useEffect(() => {
    const notScoredObserver = {
      event: {
        eventType: FindingPropertiesEventTypes.NotScoredSelect,
        panelId
      },
      update: () => {
        setValue(accessor, {
          operatorId: data.defaultOperatorId,
          value1: null,
          value2: null
        });
      }
    };
    FindingPropertiesPublisher.subscribe(notScoredObserver);

    return () => FindingPropertiesPublisher.unsubscribe(notScoredObserver);
    // eslint-disable-next-line
  }, []);

  const watchedValues = useWatch<NumberPropertyCoding>({
    control,
    name: accessor
  });

  const previousOperator = usePrevious(watchedValues?.operatorId);

  useEffect(() => {
    if (previousOperator && previousOperator !== watchedValues?.operatorId) {
      eventHandler();
    }
    // eslint-disable-next-line
  }, [previousOperator, watchedValues?.operatorId]);

  const options = useMemo(
    () =>
      data.operators.reduce<
        {
          label: string;
          value: string;
        }[]
      >(
        (acc, operator) => [
          ...acc,
          {
            label: operator.translatedName.eitherValue,
            value: String(operator.operatorId)
          }
        ],
        []
      ),
    [data.operators]
  );

  const hasRange = useMemo(
    () =>
      Number(watchedValues?.operatorId || data.defaultOperatorId) ===
      PropertyPanelOperator.Between,
    [watchedValues, data.defaultOperatorId]
  );

  const error = (data.propertyType.dataTypeId === HDataType.Integer
    ? errors.eventPropertyCodings?.integer?.[
        data.propertyType.eventPropertyTypeId
      ]
    : errors.eventPropertyCodings?.decimal?.[
        data.propertyType.eventPropertyTypeId
      ]) as FieldError | undefined;

  const isInputBlurred = !value1Focus.hasFocus && !value2Focus.hasFocus;

  return (
    <Controller
      render={(field: ControllerRenderProps<NumberPropertyCoding>) => (
        <div
          className={cn(
            styles['panel-input'],
            actionDisabled && styles.disabled,
            className
          )}
        >
          <InputContainer
            label={
              <Label
                text={data.propertyType.translatedName.eitherValue}
                iconId={data.propertyType.iconId}
                isDisabled={actionDisabled}
              />
            }
            labelColSize={12}
            inputColSize={12}
            className={styles.wrap}
            colClassName={styles.col}
          >
            <div
              className={cn(
                styles.container,
                (hasTimeFullFormat || hasRange) && styles['column-direction']
              )}
            >
              <Select
                value={options.find(
                  (option) =>
                    Number(option.value) ===
                    Number(field.value?.operatorId || data.defaultOperatorId)
                )}
                onChange={(option) => {
                  field.onChange({
                    ...field.value,
                    operatorId: Number(option?.value),
                    value2:
                      Number(option?.value) !== PropertyPanelOperator.Between
                        ? null
                        : field.value.value2
                  });
                }}
                options={options}
                className={styles.select}
                styles={{
                  singleValue: () => ({
                    color: actionDisabled ? styles.N300 : styles.N800
                  }),
                  control: () => ({
                    borderColor: styles.N200
                  }),
                  menuList: () => ({
                    maxWidth: '100%'
                  })
                }}
                components={{ Option }}
                isDisabled={actionDisabled}
              />
              <div>
                <div
                  className={cn(
                    styles['input-container'],
                    hasTimeFullFormat && styles['column-direction']
                  )}
                >
                  {data.propertyType.isTimeRelated ? (
                    <PropertyFormTimeInput
                      field={field}
                      value1Focus={value1Focus}
                      value2Focus={value2Focus}
                      onBlur={eventHandler}
                      hasTimeFullFormat={hasTimeFullFormat}
                      error={error?.message}
                      hasRange={hasRange}
                      disabled={actionDisabled}
                    />
                  ) : (
                    <PropertyFormInput
                      field={field}
                      value1Focus={value1Focus}
                      value2Focus={value2Focus}
                      onBlur={eventHandler}
                      error={error?.message}
                      hasRange={hasRange}
                      disabled={actionDisabled}
                    />
                  )}
                </div>
                {!!(
                  error?.message &&
                  field.value?.value1 &&
                  field.value?.value2 &&
                  isInputBlurred
                ) && <ErrorMessage error={t(error.message)} />}
              </div>
            </div>
            {data.propertyType.isTimeRelated && (
              <Checkbox
                label={t('Use hh:mm:ss')}
                checked={hasTimeFullFormat}
                onChange={toggleTimeFullFormat}
                className={styles.checkbox}
                disabled={actionDisabled}
              />
            )}
          </InputContainer>
        </div>
      )}
      rules={{
        validate: (field: NumberPropertyCoding) => {
          if (
            field.operatorId === PropertyPanelOperator.Between &&
            ((field.value1 && !field.value2) || (field.value2 && !field.value1))
          ) {
            return 'Field is required';
          }

          if (
            field.operatorId === PropertyPanelOperator.Between &&
            Number(field.value1) > Number(field.value2)
          ) {
            return 'Second value should be larger than first value';
          }

          return true;
        }
      }}
      defaultValue={{
        operatorId: data.defaultOperatorId,
        value1: null,
        value2: null
      }}
      name={accessor}
      control={control}
    />
  );
});
