import { ApiError } from 'entities/ApiError.entity';
import { Phrase } from 'entities/Phrase.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { action, makeObservable, observable } from 'mobx';
import { RootApi } from 'services/API/Root/RootApi';
import { i18n } from 'services/I18n';

export class I18nStore {
  translation?: Record<string, Phrase>;

  @observable
  loadingTranslation: boolean = false;

  @observable
  loadingTranslationError?: ApiError | UnknownError;

  constructor() {
    makeObservable(this);
  }

  @action
  async loadTranslation() {
    if (this.translation) {
      return;
    }

    this.loadingTranslation = true;
    this.loadingTranslationError = undefined;
    try {
      const { data } = await RootApi.loadTranslation();
      const phrasesMap = data.reduce<Record<string, Phrase>>((acc, item) => {
        acc[item.phrase] = Phrase.deserialize(item);
        return acc;
      }, {});

      this.translation = phrasesMap;

      i18n.addResourceBundle('en', 'translation', phrasesMap);
    } catch (e) {
      this.loadingTranslationError = ApiError.deserializeFromCatch(e);
    } finally {
      this.loadingTranslation = false;
    }
  }
}
