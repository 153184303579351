import { RTUConfig } from 'entities/RTUConfig.entity';
import { ASYNC_STORAGE_KEY_PREFIX } from 'services/AsyncStorage';

export const REAL_TIME_UPDATE_CONFIG = 'REAL_TIME_UPDATE_CONFIG';

export class SessionStorage {
  private static prefixKey(key: string): string {
    return `${ASYNC_STORAGE_KEY_PREFIX}${key}`;
  }

  static async setItem(key: string, value: object): Promise<void> {
    await sessionStorage.setItem(
      SessionStorage.prefixKey(key),
      JSON.stringify(value)
    );
  }

  static async removeItem(key: string): Promise<void> {
    await sessionStorage.removeItem(SessionStorage.prefixKey(key));
  }

  static async getItem<T>(key: string) {
    const value = await sessionStorage.getItem(SessionStorage.prefixKey(key));

    return value ? (JSON.parse(value) as T) : null;
  }

  static async getRTUConfig(): Promise<RTUConfig | null> {
    const config = await SessionStorage.getItem<RTUConfig>(
      REAL_TIME_UPDATE_CONFIG
    );

    return config || null;
  }

  static async updateRTUConfig(config: RTUConfig): Promise<void> {
    await SessionStorage.setItem(REAL_TIME_UPDATE_CONFIG, config);
  }

  static async removeRTUConfig(): Promise<void> {
    await SessionStorage.removeItem(REAL_TIME_UPDATE_CONFIG);
  }

  static reset() {
    sessionStorage.clear();
  }
}
