import { StoreType } from 'enums/StoreType.enum';

import { useStore } from './store';

export const useCurrentSessionHasLock = (patientId: number) => {
  const patientLockDetailsStore = useStore(StoreType.PatientLockDetails);
  const patientLockDetails = patientLockDetailsStore.patientLockDetails.get(
    patientId
  );

  //if current user session has edit mode activated
  const currentSessionHasLock = patientLockDetails?.sessionHasLock || false;

  //user cannot activate edit mode, if isPatientLocked is true
  //isPatientLocked is true only when edit mode for this patient is activated in another session.
  const isPatientLocked =
    !currentSessionHasLock && patientLockDetails?.isLocked;

  return { currentSessionHasLock, isPatientLocked };
};
