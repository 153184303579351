import React from 'react';
import { useContext } from 'react';
import { EventCoding } from 'entities/EventCoding.entity';

const propertiesContext = React.createContext<{
  onFindingClick: (coding?: EventCoding) => void;
  onCloseProperties: () => void;
  activeCodingId: number | undefined;
}>({
  activeCodingId: undefined,
  onFindingClick: () => {},
  onCloseProperties: () => {}
});

export const PropertiesProvider = propertiesContext.Provider;

export const usePropertiesContext = () => useContext(propertiesContext);
