import React from 'react';
import { TextBadge, TextBadgeTheme, Tooltip } from '@holberg/ui-kit';

import styles from './TooltipTemplate.module.scss';

interface TooltipTemplateProps {
  tooltipData: { title: string; value: string }[][];
  badgeContent?: { badgeLabel: string; badgeSubLabel: string };
  delayTime?: number;
  message?: string | null;
}

export const TooltipTemplate: React.FC<TooltipTemplateProps> = ({
  tooltipData,
  badgeContent,
  children,
  delayTime = 500,
  message = null
}) => {
  return (
    <Tooltip
      offset={{ bottom: -5 }}
      contentClassName={styles['tooltip-content']}
      delayTime={delayTime}
      data={[
        {
          mainTooltip: (
            <div className={styles['template-container']}>
              {message && (
                <div className={styles['tooltip-message']}>{message}</div>
              )}
              {tooltipData.map((item, index) => {
                const rows = item.map((each, index) => {
                  return (
                    <div key={`row-${index}`} className={styles['row']}>
                      <span>{each.title}</span>
                      <span>{each.value}</span>
                    </div>
                  );
                });
                return (
                  <div
                    key={`division-${index}`}
                    className={styles['rows-divider']}
                  >
                    {rows}
                  </div>
                );
              })}
              {badgeContent && (
                <div className={styles['badge-div']}>
                  <TextBadge
                    title={badgeContent.badgeLabel}
                    theme={TextBadgeTheme.Grey}
                    className={styles['badge']}
                  />
                  <span>{badgeContent.badgeSubLabel}</span>
                </div>
              )}
            </div>
          )
        }
      ]}
    >
      {children}
    </Tooltip>
  );
};
