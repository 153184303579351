import { StatusBadgeTheme } from '@holberg/ui-kit';

export enum StudyBadgeStatus {
  New = 'new',
  InProgress = 'in_progress',
  Signed = 'signed',
  Archived = 'archived',
  Temporal = 'temporal'
}

export const studyBadgeTheme = Object.freeze<
  Record<StudyBadgeStatus, StatusBadgeTheme>
>({
  [StudyBadgeStatus.New]: StatusBadgeTheme.Red,
  [StudyBadgeStatus.InProgress]: StatusBadgeTheme.Blue,
  [StudyBadgeStatus.Signed]: StatusBadgeTheme.Green,
  [StudyBadgeStatus.Archived]: StatusBadgeTheme.GreyBlue,
  [StudyBadgeStatus.Temporal]: StatusBadgeTheme.GreyBlue
});

export const studyBadgeTitles = Object.freeze<Record<StudyBadgeStatus, string>>(
  {
    [StudyBadgeStatus.New]: 'New',
    [StudyBadgeStatus.InProgress]: 'In progress',
    [StudyBadgeStatus.Signed]: 'Signed',
    [StudyBadgeStatus.Archived]: 'Archived',
    [StudyBadgeStatus.Temporal]: 'Temporal'
  }
);
