import { deserialize, list, primitive, serializable } from 'serializr';

import { Event } from './Event.entity';

export class EventCodingCreateDTO {
  @serializable(list(primitive()))
  eventIds: Array<Event['eventId']> = [];

  @serializable
  eventCodeId: number = 0;

  @serializable
  parentEventCodingId?: number;

  static deserialize(json: Object | string) {
    return deserialize(EventCodingCreateDTO, json);
  }
}
