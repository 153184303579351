export enum DescriptionPropertyTypes {
  Alertness = 1,
  EEGType = 2,
  IndicationForEEG = 3,
  LevelOfCare = 4,
  Priority = 5,
  WorkingOrOffHours = 6,
  ChangeSinceLastEEG = 7,
  DiagnosticSignificance = 8,
  Etiology = 9
}
