import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { StoreType } from 'enums/StoreType.enum';

import { useStore } from './store';

export const useGetPanelById = (
  panelId: EventPropertyPanel['eventPropertyPanelId']
) => {
  const { id } = useParams<{ id: string }>();
  const descriptionDetailsStore = useStore(StoreType.Descriptions);
  const propertiesStore = useStore(StoreType.FindingProperties);

  return useMemo(() => {
    const descriptionDetails = descriptionDetailsStore.descriptionById(
      parseInt(id)
    )!;

    return propertiesStore.getPanel(descriptionDetails.ageConstraints, panelId);
    // eslint-disable-next-line
  }, [id, panelId]);
};
