import { EventsTreeSubitem } from '@holberg/ui-kit';
import { deserialize, list, object, serializable } from 'serializr';

import { EventCode } from './EventCode.entity';
import { EventCoding } from './EventCoding.entity';
import { ShoppingCartItem } from './ShoppingCartItem';

export class ShoppingCart {
  @serializable
  eventCodingId: EventCoding['eventCodingId'] = 0;

  @serializable
  parentEventCodingId?: EventCoding['eventCodingId'] | null;

  @serializable
  eventCodeId: EventCode['eventCodeId'] = 0;

  @serializable(list(object(ShoppingCartItem)))
  shoppingCartItems: ShoppingCartItem[] = [];

  get shoppingCartNodes(): EventsTreeSubitem[] {
    return this.shoppingCartItems
      .map((item, index) => item.getNodes(index))
      .flat();
  }

  static deserialize(json: Object | string) {
    return deserialize(ShoppingCart, json);
  }

  static deserializeAsMap(
    list: ShoppingCart[]
  ): Map<EventCoding['eventCodingId'], ShoppingCart> {
    return list.reduce((acc, shoppingCart) => {
      acc.set(
        shoppingCart.eventCodingId,
        ShoppingCart.deserialize(shoppingCart)
      );

      return acc;
    }, new Map());
  }
}
