import { FocusEvent, useCallback, useState } from 'react';

export const useHasFocus = () => {
  const [hasFocus, setHasFocus] = useState(false);

  const onFocus = useCallback(
    (event: FocusEvent) => {
      if (event.target.classList.contains('focus-visible')) {
        try {
          setHasFocus(true);
        } catch (e) {}
      }
    },
    [setHasFocus]
  );

  const onBlur = useCallback(() => {
    setHasFocus(false);
  }, [setHasFocus]);

  return { hasFocus, onFocus, onBlur };
};
