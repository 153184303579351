import {
  EventsTreeBlockItem,
  EventsTreeBlockType,
  EventsTreeSubitem,
  EventsTreeSubitemTheme
} from '@holberg/ui-kit';
import { Event } from 'entities/Event.entity';
import { EventCode } from 'entities/EventCode.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { Report } from 'entities/Report.entity';
import { ShoppingCart } from 'entities/ShoppingCart';
import { EventCodesTreeItem } from 'stores/findings';

type EventCodeId = EventCode['eventCodeId'];
type EventCodingCodeId = EventCoding['eventCodeId'];
type EventCodeCondition = (
  treeItem: EventCodesTreeItem,
  parentEventCoding?: EventCoding
) => boolean;

export class ReportEventTreeBuilder {
  readonly paths: Record<EventCodingCodeId, EventCoding[]>;

  constructor(
    public readonly eventCodings: EventCoding[],
    public readonly eventCodes: Map<EventCodeId, EventCode>,
    public readonly eventCodesTree: EventCodesTreeItem[],
    public readonly reportDetails: Report,
    public readonly shoppingCart?: ReturnType<
      typeof ShoppingCart.deserializeAsMap
    >,
    public readonly events?: Map<EventCoding['eventCodingId'], Event[]>
  ) {
    this.paths = this.makeEventCodingsPaths(this.eventCodings, this.eventCodes);
  }

  private makePathToRoot(
    eventCodes: Map<number, EventCode>,
    currentEventCodeId: EventCodeId,
    path: EventCodeId[] = []
  ): EventCodeId[] {
    const eventCode = eventCodes.get(currentEventCodeId);
    if (!eventCode) {
      return path;
    }

    path.push(eventCode.eventCodeId);
    if (eventCode.parentId) {
      return this.makePathToRoot(eventCodes, eventCode.parentId, [...path]);
    }
    return path;
  }

  private makeEventCodingsPaths(
    eventCodings: EventCoding[],
    eventCodes: Map<number, EventCode>
  ): Record<EventCodingCodeId, EventCoding[]> {
    return eventCodings.reduce<Record<EventCodingCodeId, EventCoding[]>>(
      (acc, eventCoding) => {
        const eventCode = eventCodes.get(eventCoding.eventCodeId);
        if (!eventCode) {
          return acc;
        }

        const path = this.makePathToRoot(eventCodes, eventCode.eventCodeId);
        path.forEach((eventCodeId) => {
          if (!acc[eventCodeId]) {
            acc[eventCodeId] = [];
          }
          acc[eventCodeId].push(eventCoding);
        });
        return acc;
      },
      {}
    );
  }

  isNestedHiddenCode: EventCodeCondition = ({ item }) => {
    return (
      !item.isFolder &&
      !item.showInFindingTreeView &&
      item.isDependentOnEventCoding
    );
  };

  isNonNestedFolder: EventCodeCondition = ({ item }) => {
    return item.isFolder && !item.isDependentOnEventCoding;
  };

  isNestedFolder: EventCodeCondition = ({ item }) => {
    return item.isFolder && item.isDependentOnEventCoding;
  };

  isInThePathOfNonTBD: EventCodeCondition = ({ item }) => {
    const eventCodeInPathCodings = this.paths[item.eventCodeId];
    const isInThePathOfNonTBD =
      eventCodeInPathCodings.filter((eventCoding) => {
        const eventCode = this.eventCodes.get(eventCoding.eventCodeId);

        return (
          eventCode && !eventCode.isToBeDefinedNode && !eventCoding.parentId
        );
      }).length > 0;

    return isInThePathOfNonTBD;
  };

  parentCodingHasNestedCodings = (parentEventCoding: EventCoding): boolean => {
    const allNestedEventCodings = this.eventCodings.filter(
      (coding) => coding.parentId === parentEventCoding.eventCodingId
    );

    if (allNestedEventCodings.length) {
      return true;
    }

    return false;
  };

  private getSubItems(eventCoding: EventCoding) {
    const propertiesNodes = this.shoppingCart?.get(eventCoding.eventCodingId)
      ?.shoppingCartNodes;

    const events = eventCoding.showTimestampInReport
      ? this.events
          ?.get(eventCoding.eventCodingId)
          ?.map((event) =>
            event.formatStartDateTime(
              this.reportDetails.reportStartDateTime,
              this.reportDetails.reportStopDateTime
            )
          )
          .join(', ')
      : undefined;
    const eventsNode: EventsTreeSubitem | undefined = !!events
      ? {
          text: `(Occurring at ${events})`,
          theme: EventsTreeSubitemTheme.Secondary
        }
      : undefined;

    if (eventsNode) {
      return propertiesNodes ? [...propertiesNodes, eventsNode] : [eventsNode];
    }

    return propertiesNodes;
  }

  buildTreeItem = (
    items: EventCodesTreeItem[],
    parentEventCoding?: EventCoding
  ): EventsTreeBlockItem[] => {
    const aggregatedItems = items
      .filter((treeItem) => this.paths[treeItem.item.eventCodeId])
      .reduce<[EventCodesTreeItem, EventCoding?][]>((acc, treeItem) => {
        /*
         * Skip folders that have TBD findings as parent on the non-nested level,
         * even if there is a non-TBD on nested level
         * Otherwise, include code if it's in the path with non-TBD parent coding
         */
        if (this.isNonNestedFolder(treeItem)) {
          if (this.isInThePathOfNonTBD(treeItem)) {
            acc.push([treeItem, parentEventCoding]);
          }

          return acc;
        }

        /*
         * Include code if it is a nested hidden code but also has parent coding
         * and parent coding has nested codings in this subtree
         */
        if (this.isNestedHiddenCode(treeItem) && parentEventCoding) {
          if (this.parentCodingHasNestedCodings(parentEventCoding)) {
            acc.push([treeItem, parentEventCoding]);
          }

          return acc;
        }

        /*
         * Include folder if it is a nested hidden code but also has parent coding
         * and parent coding has nested codings in this subtree
         */
        if (this.isNestedFolder(treeItem) && parentEventCoding) {
          if (this.parentCodingHasNestedCodings(parentEventCoding)) {
            acc.push([treeItem, parentEventCoding]);
          }

          return acc;
        }

        /*
         * Multiply codes with codings
         * but only include nested codings that are having the current parent as it parent
         */
        this.eventCodings.forEach((eventCoding) => {
          if (eventCoding.eventCodeId === treeItem.item.eventCodeId) {
            if (parentEventCoding) {
              if (parentEventCoding.eventCodingId === eventCoding.parentId) {
                acc.push([treeItem, eventCoding]);
              }
            } else {
              acc.push([treeItem, eventCoding]);
            }
          }
        });

        return acc;
      }, []);

    //reorder findings as per findings in events tree
    aggregatedItems.sort((a, b) => {
      const aSortOrder = a[1]?.sortOrder ? a[1].sortOrder : Infinity;
      const bSortOrder = b[1]?.sortOrder ? b[1].sortOrder : Infinity;
      return aSortOrder - bSortOrder;
    });

    let sortIndexNumber = 0;
    let lastSortOrder = -1;

    const showInFindingTreeViewCount = aggregatedItems.filter(
      ([treeItem, _eventCoding]) => {
        return treeItem.item.showInFindingTreeView;
      }
    ).length;

    return aggregatedItems.map(([treeItem, eventCoding]) => {
      const type = !treeItem.item.showInFindingTreeView
        ? EventsTreeBlockType.Hidden
        : treeItem.item.isFolder &&
          treeItem.item.eventCodeId !== eventCoding?.eventCodeId
        ? EventsTreeBlockType.Folder
        : EventsTreeBlockType.Finding;

      if (
        treeItem.item.showInFindingTreeView &&
        lastSortOrder !== eventCoding?.sortOrder
      ) {
        sortIndexNumber++;
        if (eventCoding?.sortOrder) lastSortOrder = eventCoding?.sortOrder;
      }

      const sortingNumber = treeItem.item.getSortingNumber(sortIndexNumber);
      const title =
        type === EventsTreeBlockType.Finding
          ? showInFindingTreeViewCount > 1
            ? `${sortingNumber ? sortingNumber + '.' : ''} ${
                treeItem.item.translatedLongName.eitherValue
              }`
            : treeItem.item.translatedLongName.eitherValue
          : treeItem.item.translatedName.eitherValue;

      const children = this.buildTreeItem(treeItem.children, eventCoding);

      /** If it's a folder which can have non-TBD findings with the same event code,
       * then we search for these findings
       * and inserts them as a children */
      if (treeItem.item.isFolder && !treeItem.item.isToBeDefinedNode) {
        const eventCodeCodings = this.eventCodings.filter(
          (eventCoding) => eventCoding.eventCodeId === treeItem.item.eventCodeId
        );

        eventCodeCodings.forEach((eventCodeCoding) =>
          children.push({
            id: eventCodeCoding.eventCodingId,
            type: EventsTreeBlockType.Finding,
            title: treeItem.item.translatedLongName.eitherValue,
            children: this.buildTreeItem(treeItem.children, eventCodeCoding),
            subItems: this.getSubItems(eventCodeCoding)
          })
        );
      }

      const subItems =
        type === EventsTreeBlockType.Finding && eventCoding
          ? this.getSubItems(eventCoding)
          : undefined;

      const id: number =
        type === EventsTreeBlockType.Finding && eventCoding
          ? eventCoding.eventCodingId
          : treeItem.item.eventCodeId;

      return {
        id: id,
        type,
        title,
        children,
        subItems
      };
    });
  };

  buildFullTree(): EventsTreeBlockItem[] {
    return this.buildTreeItem(this.eventCodesTree);
  }
}
