import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class Operator {
  @serializable
  operatorId: number = 0;

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  translatedLongName: InformationModel = new InformationModel();

  static deserialize(json: Object | string) {
    return deserialize(Operator, json);
  }
}
