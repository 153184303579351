import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { action, makeObservable, observable } from 'mobx';
import { CommonPropertyType } from 'stores/property-type-codes';

const notFoundCategoricalPropertyCodings = {};

export class CategoricalPropertyCodingState {
  @observable
  categoricalPropertyCodings: Map<
    number,
    ReturnType<typeof CategoricalPropertyCoding.deserializeAsMap>
  > = new Map();

  constructor() {
    makeObservable(this);
  }

  @action
  reset() {
    this.categoricalPropertyCodings = new Map();
  }

  categoricalPropertyCodingsById(
    entityId: string
  ): ReturnType<typeof CategoricalPropertyCoding.deserializeAsMap> {
    return (
      this.categoricalPropertyCodings.get(parseInt(entityId)) ||
      notFoundCategoricalPropertyCodings
    );
  }

  @action
  addCategoricalPropertyCodings(
    entityId: number,
    data: CategoricalPropertyCoding[]
  ) {
    this.categoricalPropertyCodings.set(
      entityId,
      CategoricalPropertyCoding.deserializeAsMap(data)
    );
  }

  @action
  addCategoricalPropertyCoding({
    entityId,
    propertyTypeId,
    data
  }: {
    entityId: number;
    propertyTypeId: CommonPropertyType;
    data: CategoricalPropertyCoding;
  }) {
    const entityCodings = this.categoricalPropertyCodings.get(entityId);

    if (entityCodings) {
      if (!entityCodings[propertyTypeId]) {
        entityCodings[propertyTypeId] = [];
      }

      entityCodings[propertyTypeId].push(data);
    } else {
      this.addCategoricalPropertyCodings(entityId, [data]);
    }
  }

  @action
  updateCategoricalPropertyCoding({
    entityId,
    propertyCodeId,
    propertyTypeId,
    data
  }: {
    entityId: number;
    propertyCodeId: number;
    propertyTypeId: CommonPropertyType;
    data: CategoricalPropertyCoding;
  }) {
    const entityCodings = this.categoricalPropertyCodings.get(entityId);

    if (entityCodings) {
      const targetItemIndex = (entityCodings[propertyTypeId] || []).findIndex(
        (coding) => coding.propertyCode?.propertyCodeId === propertyCodeId
      );

      if (targetItemIndex >= 0) {
        entityCodings[propertyTypeId][targetItemIndex] = data;
      }
    }
  }

  @action
  deleteCategoricalPropertyCoding({
    entityId,
    propertyCodeId,
    propertyTypeId
  }: {
    entityId: number;
    propertyCodeId: number;
    propertyTypeId: CommonPropertyType;
  }) {
    const entityCodings = this.categoricalPropertyCodings.get(entityId);

    if (entityCodings) {
      entityCodings[propertyTypeId] = entityCodings[propertyTypeId].filter(
        (coding) => coding.propertyCode?.propertyCodeId !== propertyCodeId
      );
    }
  }
}
