import { HDataType } from 'enums/HDataType.enum';
import { TemplateId } from 'enums/TemplateId.enum';
import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class EventPropertyType {
  @serializable
  eventPropertyTypeId: number = 0;

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  translatedReportName: InformationModel = new InformationModel();

  @serializable
  ageConstraintId: number = 0;

  @serializable
  sortOrder: number = 0;

  @serializable
  dataTypeId: HDataType = HDataType.String;

  @serializable
  isTimeRelated: boolean = false;

  @serializable
  isMultiselect: boolean = false;

  @serializable
  isLocationRelated: boolean = false;

  @serializable
  requiresBilateralLocationCoding: boolean = false;

  @serializable
  decidesIfAbnormalIfSometimesAbnormal: boolean = false;

  @serializable(object(InformationModel))
  definition: InformationModel = new InformationModel();

  @serializable
  iconId: number = 0;

  @serializable
  requiredEventPropertyTypeInCodingId: number | null = null;

  @serializable
  templateId: TemplateId = TemplateId.Categorical;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyType, json);
  }
}
