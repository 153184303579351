import { FilterCriteria } from 'entities/FilterCriteria.entity';
import { SortCriteriaState } from 'entities/SortCriteria.entity';
import { TableConfig } from 'entities/TableConfig.entity';
import { UserSettingsConfig } from 'entities/UserSettingsConfig';
import { GenericListPageType } from 'enums/GenericListPageType.enum';
import { EventTreeSettingsConfig } from 'stores/findings/EventTreeState';

export const ASYNC_STORAGE_KEY_PREFIX = 'HOLBERG_STORAGE_';
export const SORT_CONFIG = 'SORT_CONFIG';
export const FILTER_CONFIG = 'FILTER_CONFIG';
export const TABLE_CONFIG = 'TABLE_CONFIG';
export const USER_SETTINGS_CONFIG = 'USER_SETTINGS_CONFIG';
export const EVENT_TREE_SETTINGS_CONFIG = 'EVENT_TREE_SETTINGS_CONFIG';

export class AsyncStorage {
  private static prefixKey(key: string): string {
    return `${ASYNC_STORAGE_KEY_PREFIX}${key}`;
  }

  static async setItem(key: string, value: object): Promise<void> {
    await localStorage.setItem(
      AsyncStorage.prefixKey(key),
      JSON.stringify(value)
    );
  }

  static async removeItem(key: string): Promise<void> {
    await localStorage.removeItem(AsyncStorage.prefixKey(key));
  }

  static async getItem<T>(key: string) {
    const value = await localStorage.getItem(AsyncStorage.prefixKey(key));

    return value ? (JSON.parse(value) as T) : null;
  }

  static getKeyForPage(pageType: GenericListPageType, key: string) {
    return `${pageType.toUpperCase()}_${key}`;
  }

  static async getGenericListConfig(
    pageType: GenericListPageType
  ): Promise<ReturnType<typeof TableConfig.deserializeAsMap> | null> {
    const config = await AsyncStorage.getItem<TableConfig[]>(
      AsyncStorage.getKeyForPage(pageType, TABLE_CONFIG)
    );

    const result = config ? TableConfig.deserializeAsMap(config) : null;

    return result && Object.values(result).length === 0 ? null : result;
  }

  static async updateGenericListConfig(
    tableConfig: ReturnType<typeof TableConfig.deserializeAsMap>,
    pageType: GenericListPageType
  ): Promise<void> {
    await AsyncStorage.setItem(
      AsyncStorage.getKeyForPage(pageType, TABLE_CONFIG),
      Object.values(tableConfig)
    );
  }

  static async getSortConfig(
    pageType: GenericListPageType
  ): Promise<SortCriteriaState | null> {
    const config = await AsyncStorage.getItem<SortCriteriaState[]>(
      AsyncStorage.getKeyForPage(pageType, SORT_CONFIG)
    );

    if (config) {
      return SortCriteriaState.deserialize({ sortCriteria: config });
    }

    return null;
  }

  static async getFilterConfig(
    pageType: GenericListPageType
  ): Promise<FilterCriteria | null> {
    return await AsyncStorage.getItem<FilterCriteria>(
      AsyncStorage.getKeyForPage(pageType, FILTER_CONFIG)
    );
  }

  static async updateSortConfig(
    sortCriteriaState: SortCriteriaState,
    pageType: GenericListPageType
  ): Promise<void> {
    await AsyncStorage.setItem(
      AsyncStorage.getKeyForPage(pageType, SORT_CONFIG),
      sortCriteriaState.asList
    );
  }

  static async updateFilterConfig(
    pageType: GenericListPageType,
    filterCriteriaState: FilterCriteria
  ): Promise<void> {
    await AsyncStorage.setItem(
      AsyncStorage.getKeyForPage(pageType, FILTER_CONFIG),
      filterCriteriaState
    );
  }

  static async removeFilterConfig(
    pageType: GenericListPageType
  ): Promise<void> {
    await AsyncStorage.removeItem(
      AsyncStorage.getKeyForPage(pageType, FILTER_CONFIG)
    );
  }

  static async updateUserSettingsConfig(
    config: ReturnType<typeof UserSettingsConfig.deserialize>
  ): Promise<void> {
    await AsyncStorage.setItem(USER_SETTINGS_CONFIG, config);
  }

  static async getUserSettingsConfig(): Promise<ReturnType<
    typeof UserSettingsConfig.deserialize
  > | null> {
    const config = await AsyncStorage.getItem<
      ReturnType<typeof UserSettingsConfig.deserialize>
    >(USER_SETTINGS_CONFIG);

    return config || null;
  }

  static async getEventTreeSettingsConfig(): Promise<EventTreeSettingsConfig | null> {
    const config = await AsyncStorage.getItem<EventTreeSettingsConfig>(
      EVENT_TREE_SETTINGS_CONFIG
    );

    return config || null;
  }

  static async updateEventTreeSettingsConfig(
    config: EventTreeSettingsConfig
  ): Promise<void> {
    await AsyncStorage.setItem(EVENT_TREE_SETTINGS_CONFIG, config);
  }

  static reset() {
    localStorage.clear();
  }
}
