import React, { useMemo } from 'react';
import {
  HandlerProps,
  ReflexContainer,
  ReflexElement,
  ReflexSplitter
} from 'react-reflex';
import { useParams } from 'react-router-dom';
import { useReportData } from 'components/ReportSection/useReportData';
import { useWindowWidth } from 'hooks/useWindowWidth';
import {
  LG_SCREEN_MIN_WIDTH,
  REPORT_MIN_WIDTH,
  STUDY_TABS_CONTAINER_MIN_WIDTH
} from 'utils/constants';

import { ReportSection } from '../ReportSection';
import { StudyTabsSection } from '../StudyTabsSection';

import 'react-reflex/styles.css';
import styles from './ResizeContainer.module.scss';

export const REPORT_CONTAINER = 'report-container';

export const dividerStyles = {
  height: 'auto',
  border: 'none',
  backgroundColor: 'transparent',
  transitionDuration: '.2s',
  width: '4px'
};

interface ResizeConfig {
  size?: number;
  collapsed?: boolean;
}

interface Props {
  config: ResizeConfig;
  onStopResize: (e: HandlerProps) => void;
}

export const ResizeContainer: React.FC<Props> = ({ config, onStopResize }) => {
  const { id } = useParams<{ id: string }>();
  const { windowWidth } = useWindowWidth();
  const hideReportSection = useMemo(
    () => windowWidth < LG_SCREEN_MIN_WIDTH || config.collapsed,
    [windowWidth, config]
  );

  const reportData = useReportData(parseInt(id));
  return (
    <ReflexContainer
      orientation='vertical'
      windowResizeAware
      className={styles['reflex-container']}
    >
      <ReflexElement
        minSize={STUDY_TABS_CONTAINER_MIN_WIDTH}
        style={{ overflow: 'hidden' }}
      >
        <StudyTabsSection />
      </ReflexElement>
      {!hideReportSection && (
        <ReflexSplitter style={dividerStyles} data-testid='splitter' />
      )}
      {!hideReportSection && (
        <ReflexElement
          flex={config.size}
          minSize={REPORT_MIN_WIDTH}
          data-testid={REPORT_CONTAINER}
          onStopResize={onStopResize}
          className={styles['report-section']}
        >
          <ReportSection reportData={reportData} />
        </ReflexElement>
      )}
    </ReflexContainer>
  );
};
