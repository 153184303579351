import { EventCode } from 'entities/EventCode.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { EventCodesTreeItem } from 'stores/findings';
import { notEmpty } from 'utils/predicates';

export const sortFindings = (
  { eventCoding: currentCoding, item: currentCode }: EventCodesTreeItem,
  { eventCoding: nextCoding, item: nextCode }: EventCodesTreeItem
): number => {
  const currentCodeOrder = currentCode.sortOrder;
  const nextCodeOrder = nextCode.sortOrder;

  const currentCodingOrder = currentCoding ? currentCoding.sortOrder : null;
  const nextCodingOrder = nextCoding ? nextCoding.sortOrder : null;

  const codesOrder = currentCodeOrder - nextCodeOrder;

  if (!notEmpty(currentCodingOrder) && !notEmpty(nextCodingOrder)) {
    return codesOrder;
  }

  if (!notEmpty(currentCodingOrder)) {
    return 1;
  }

  if (!notEmpty(nextCodingOrder)) {
    return -1;
  }

  const codingsOrder = currentCodingOrder! - nextCodingOrder!;

  if (codingsOrder === 0) {
    return codesOrder;
  }

  return codingsOrder;
};

export const filterUnmatchedFindings = (
  { eventCoding }: EventCodesTreeItem,
  parentCodingId?: number
) => eventCoding?.parentId === parentCodingId;

export const useAggregateFindings = ({
  activeDescriptionId,
  data,
  parentCodingId,
  nestedFoldersSubTree
}: {
  data: EventCodesTreeItem;
  activeDescriptionId?: number;
  parentCodingId?: number;
  nestedFoldersSubTree: EventCodesTreeItem[];
}) => {
  const parentEventCode = EventCode.deserialize({
    ...data.item,
    isFolder: false,
    isExpandableNodeFindingTreeView: false
  });

  const findingsStore = useStore(StoreType.Findings);

  const findingEventCodes = data.children.filter(({ item }) => !item.isFolder);

  const availableFindings = findingEventCodes.reduce<EventCodesTreeItem[]>(
    (acc, code) => {
      const codings = findingsStore
        .eventCodingsForEventCode(activeDescriptionId, code.item.eventCodeId)
        .map((eventCoding) => ({
          ...code,
          eventCoding
        }));

      acc.push(...codings);
      return acc;
    },
    []
  );

  const parentEventCodeCodings = findingsStore.eventCodingsForEventCode(
    activeDescriptionId,
    data.item.eventCodeId
  );

  const toBeDefinedFindings: EventCodesTreeItem[] = parentEventCodeCodings.map(
    (eventCoding) => ({
      item: parentEventCode,
      children: nestedFoldersSubTree,
      eventCoding
    })
  );

  const totalFindings = [...availableFindings, ...toBeDefinedFindings].sort(
    sortFindings
  );

  return !data.isNested
    ? totalFindings
    : totalFindings.filter((item) =>
        filterUnmatchedFindings(item, parentCodingId)
      );
};
