import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Controller,
  useFormContext,
  useWatch
} from 'react-hook-form/dist/index.ie11';
import {
  ErrorTitles,
  SelectOptionType,
  TreeItem,
  TreeSelect,
  useCustomTranslation
} from '@holberg/ui-kit';
import { debounce } from 'debounce';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { propertyTypeStoreAccessor } from 'enums/PropertyType.enum';
import { useStore } from 'hooks/store';
import { useHandleAutoSave } from 'hooks/useHandleAutoSave';
import { usePropertyTypesReload } from 'hooks/usePropertyTypesReload';
import { useSelectSearchInput } from 'hooks/useSelectSearchInput';
import { observer } from 'mobx-react-lite';

import { FormSelectProps } from '../FormSelectRow/FormSelectRow';

import styles from '../FormRowSection/FormRowSection.module.scss';

export const FormTreeSelectRow: React.FC<FormSelectProps> = observer(
  ({
    hasNoteField,
    ageConstraints = 'all',
    isOptionSelectable,
    removeRow,
    selectedPropertyTypeCodes,
    fieldArrayItemAccessor,
    defaultValue,
    readOnly,
    propertyType,
    categoricalPropertyType,
    selectStyles,
    placeholder,
    onSelectSaveAction = () => {},
    includeCode
  }) => {
    const { t } = useCustomTranslation();

    const prevInputValue = useRef(defaultValue?.value?.value);

    const formattedDefaultValue = defaultValue?.value
      ? {
          ...defaultValue.value,
          label: `${
            includeCode !== false && defaultValue.value?.code
              ? `${defaultValue.value.code} `
              : ''
          }${defaultValue.value?.label}`
        }
      : null;

    const [childIdsLoading, setChildIdsLoading] = useState<
      Array<string | number>
    >([]);

    const storeAccessor = propertyTypeStoreAccessor[categoricalPropertyType];

    const propertyTypesCodesStore = useStore(storeAccessor);

    const stateAccessor = useMemo(
      () => ({
        ageConstraint: ageConstraints,
        propertyType
      }),
      [propertyType, ageConstraints]
    );

    const { isPropertyTypeRetrying, handleReload } = usePropertyTypesReload(
      storeAccessor,
      stateAccessor
    );

    const { control, setValue } = useFormContext<{
      patientDetails: PatientDetails;
      patientCodings: Record<number, CategoricalPropertyCoding['formShape'][]>;
    }>();

    const freeTextAccessor = useMemo(
      () => `${fieldArrayItemAccessor}.freeText`,
      [fieldArrayItemAccessor]
    );

    const selectAccessor = useMemo(() => `${fieldArrayItemAccessor}.value`, [
      fieldArrayItemAccessor
    ]);

    const freeTextValue = useWatch({
      control,
      name: freeTextAccessor
    });

    const selectValue = useWatch<SelectOptionType | null>({
      control,
      name: selectAccessor
    });

    useEffect(() => {
      setValue(selectAccessor, formattedDefaultValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
      onInputChange,
      inputHasValue,
      setInputHasValue,
      highlightedItemId,
      setHighlightedItemId,
      isInputChanged
    } = useSelectSearchInput(
      debounce((value: string) => {
        propertyTypesCodesStore.searchPropertyTypesCodes({
          propertyType,
          query: value,
          ageConstraint: ageConstraints
        });
      }, 200)
    );

    const propertyTypesError = propertyTypesCodesStore.getPropertyTypesCodesError(
      stateAccessor
    );

    const isClearable = !!selectValue ? !hasNoteField || !freeTextValue : false;
    const isLoading = propertyTypesCodesStore.propertyTypeCodesLoading;

    const previewPath = propertyTypesCodesStore.getPreviewPathsId({
      ageConstraint: stateAccessor.ageConstraint,
      propertyType: stateAccessor.propertyType
    });

    const options = useMemo(
      () =>
        (inputHasValue
          ? propertyTypesCodesStore.getSearchTreeData(
              stateAccessor,
              selectedPropertyTypeCodes,
              includeCode,
              isOptionSelectable
            )
          : propertyTypesCodesStore.getTreeData(
              stateAccessor,
              selectedPropertyTypeCodes,
              includeCode,
              isOptionSelectable
            )) || [],
      // eslint-disable-next-line
      [
        propertyTypesCodesStore,
        stateAccessor,
        selectedPropertyTypeCodes,
        inputHasValue,
        previewPath,
        isLoading
      ]
    );

    const { eventHandler } = useHandleAutoSave<
      {
        patientDetails: PatientDetails;
        patientCodings: Record<number, CategoricalPropertyCoding[]>;
      },
      SelectOptionType,
      { currentValue?: Partial<SelectOptionType> | null }
    >({
      saveHandler: (value, config) => {
        const newPropertyCodeId = value?.value;
        const oldPropertyCodeId = config.currentValue?.value;

        onSelectSaveAction(newPropertyCodeId, oldPropertyCodeId);
      }
    });

    const changeHandler = eventHandler(selectAccessor, {
      currentValue: selectValue
    });

    const deleteHandler = eventHandler(undefined, {
      currentValue: selectValue
    });

    const loadPropertyTypesCodesByParentId = useCallback(
      async ({ id }, expanded) => {
        if (expanded) {
          setChildIdsLoading([...childIdsLoading, id]);
          await propertyTypesCodesStore.loadPropertyTypesCodesByParentId({
            propertyType,
            parentId: id,
            ageConstraint: ageConstraints
          });
          setChildIdsLoading(
            childIdsLoading.filter((childId) => childId !== id)
          );
        }
      },
      [ageConstraints, childIdsLoading, propertyType, propertyTypesCodesStore]
    );

    const resetPreviewPathsId = useCallback(() => {
      propertyTypesCodesStore.resetPreviewPathsId(stateAccessor);
    }, [propertyTypesCodesStore, stateAccessor]);

    const clearError = useCallback(() => {
      propertyTypesCodesStore.clearError(stateAccessor);
    }, [propertyTypesCodesStore, stateAccessor]);

    const handleBlur = useCallback(
      (onBlur: () => void, value?: string) => {
        propertyTypesCodesStore.resetSearchResults(stateAccessor);
        setHighlightedItemId(undefined);
        isInputChanged &&
          prevInputValue.current === value &&
          resetPreviewPathsId();
        prevInputValue.current = value;
        propertyTypesError && clearError();
        onBlur();

        if (!value && removeRow) {
          removeRow();
        }
      },
      [
        clearError,
        isInputChanged,
        propertyTypesError,
        propertyTypesCodesStore,
        resetPreviewPathsId,
        setHighlightedItemId,
        stateAccessor,
        removeRow
      ]
    );

    return (
      <Controller
        control={control}
        name={selectAccessor}
        defaultValue={formattedDefaultValue}
        render={({ onChange, onBlur, ...rest }) => {
          return (
            <TreeSelect<{ label: string; value: string }>
              {...rest}
              readOnly={readOnly}
              isLoading={isLoading || isPropertyTypeRetrying}
              isClearable={isClearable}
              treeClassName={styles.tree}
              readOnlyValue={rest.value?.label}
              highlightedItemId={highlightedItemId}
              errorMessage={propertyTypesError && ErrorTitles.Load}
              childIdsLoading={childIdsLoading}
              onItemStateChange={loadPropertyTypesCodesByParentId}
              openMenuOnFocus
              onChange={(event, action) => {
                onChange(event, action);
                if (action.action === 'clear') {
                  removeRow && removeRow();
                  deleteHandler();
                  resetPreviewPathsId();
                } else {
                  changeHandler();
                }
              }}
              onBlur={() => {
                handleBlur(onBlur, rest.value?.value);
              }}
              onActionLabelClick={async (item: TreeItem) => {
                await propertyTypesCodesStore.setShowInTree(
                  {
                    ageConstraint: ageConstraints,
                    propertyType
                  },
                  item
                );
                setHighlightedItemId(parseInt(item.id as string));
                setInputHasValue(false);
              }}
              onInputChange={onInputChange}
              onMenuOpen={handleReload}
              items={propertyTypesError ? [] : options}
              placeholder={t(placeholder || 'Enter value here')}
              styles={{
                menu: () => ({
                  minWidth: '40vw',
                  ...selectStyles
                })
              }}
            />
          );
        }}
      />
    );
  }
);
