import { useCallback, useMemo } from 'react';
import { TABLE_CELL_MIN_WIDTH } from 'utils/constants';

export const useResizeAction = (
  updateAction: (key: string, value: number) => void
) => {
  const resizeHistory = useMemo(() => ({}), []);

  const onResize = useCallback(
    (sizes: Record<string, number>) => {
      const foundTarget = Object.entries(sizes).find(([key, value]) => {
        const maybeValue = resizeHistory[key];
        if (maybeValue) {
          const correctedValue =
            maybeValue <= TABLE_CELL_MIN_WIDTH
              ? TABLE_CELL_MIN_WIDTH
              : maybeValue;
          return correctedValue !== value;
        }
        return true;
      });

      if (foundTarget) {
        const [targetKey, value] = foundTarget;
        const newCorrectedValue =
          value <= TABLE_CELL_MIN_WIDTH ? TABLE_CELL_MIN_WIDTH : value;
        updateAction(targetKey, newCorrectedValue);
        resizeHistory[targetKey] = newCorrectedValue;
      }
    },
    [resizeHistory, updateAction]
  );

  return { onResize };
};
