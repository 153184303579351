import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class Age {
  @serializable
  ageInDays: number = 0;

  @serializable
  ageInMonths: number = 0;

  @serializable(object(InformationModel))
  displayText: InformationModel = new InformationModel();

  static deserialize(json: Object | string) {
    return deserialize(Age, json);
  }
}
