import { createRef } from 'react';
import { processNodes, Transform } from 'react-html-parser';
import convertInlineStyle from 'react-html-parser/lib/utils/inlineStyleToObject';
import { DomNode, Transformer, TransformerDecorator } from '@holberg/ui-kit';
import cn from 'classnames';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { LocationType } from 'enums/LocationType.enum';

import { Sensor } from './Sensor';
import { SVG } from './SVG';

import styles from './HeadModel.module.scss';

export class SVGTransformer extends TransformerDecorator {
  constructor(
    protected transformer: Transformer,
    private locationType: LocationType,
    private panelId: EventPropertyPanel['eventPropertyPanelId']
  ) {
    super(transformer);
  }

  sensorsRef = createRef<SVGGElement>();

  selectedClass?: string;
  selectedMaximumClass?: string;

  transform(node: DomNode, index: number, transformParam?: Transform) {
    const transform: Transform = transformParam || this.transform.bind(this);

    if (node.type === 'tag' && node.name === 'svg') {
      return (
        <SVG
          key={index}
          node={node}
          transform={transform}
          sensors={this.sensorsRef}
          locationType={this.locationType}
          panelId={this.panelId}
        />
      );
    }

    if (
      node.type === 'tag' &&
      node.name === 'text' &&
      node.parent.name === 'g'
    ) {
      const { class: className, ...props } = node.attribs;
      return (
        <text
          key={index}
          className={cn(className, styles['svg-text'])}
          {...props}
        >
          {processNodes(node.children, transform)}
        </text>
      );
    }

    if (node.type === 'tag' && node.name === 'text') {
      const { class: className, ...props } = node.attribs;
      return (
        <text
          key={index}
          className={cn(className, styles['svg-text'])}
          {...props}
        >
          {processNodes(node.children, transform)}
        </text>
      );
    }

    if (
      node.type === 'tag' &&
      node.name === 'g' &&
      node.attribs.id &&
      node.attribs.id.includes('sensorlayer')
    ) {
      const { class: className, ...props } = node.attribs;
      this.selectedClass = node.attribs['data-selectedclass'];
      this.selectedMaximumClass = node.attribs['data-selectedclassmaximum'];
      const clipPath = props['clip-path'];
      delete props['clip-path'];
      return (
        <g
          key={index}
          ref={this.sensorsRef}
          className={className}
          clipPath={clipPath}
          {...props}
        >
          {processNodes(node.children, transform)}
        </g>
      );
    }

    if (
      node.type === 'tag' &&
      node.name === 'g' &&
      !!node.attribs['data-name']
    ) {
      return (
        <Sensor
          key={index}
          node={node}
          transform={transform}
          locationType={this.locationType}
          selectedClassName={this.selectedClass}
          selectedMaximumClassName={this.selectedMaximumClass}
        />
      );
    }

    if (
      node.type === 'tag' &&
      node.name === 'ellipse' &&
      node.parent.name === 'g'
    ) {
      const { class: className, style, ...props } = node.attribs;
      return (
        <ellipse
          key={index}
          className={cn(className, styles.ellipse)}
          style={convertInlineStyle(style)}
          {...props}
        />
      );
    }

    return super.transform(node, index, transform);
  }
}
