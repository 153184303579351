import { differenceInDays } from 'date-fns';
import { computed } from 'mobx';
import { deserialize, serializable } from 'serializr';

export class LicensingStatus {
  @serializable
  isLicensed: boolean = false;

  @serializable
  clientName: string | null = null;

  @serializable
  licenseStatus: string | null = null;

  @serializable
  licenseStatusCode: number = 0;

  @serializable
  licenseExpiryUnixTimestamp: number = 0;

  @computed
  get timeLeftForExpiry() {
    return differenceInDays(
      new Date(this.licenseExpiryUnixTimestamp * 1000),
      new Date()
    );
  }

  static deserialize(json: Object | string) {
    return deserialize(LicensingStatus, json);
  }
}
