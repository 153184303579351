import { useParams } from 'react-router-dom';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { StoreType } from 'enums/StoreType.enum';
import { EventPropertyCodingsData } from 'stores/finding-properties';

import { useStore } from './store';
import { useHandleAutoSave } from './useHandleAutoSave';

export const useHandlePropertiesAutoSave = (
  panelId: EventPropertyPanel['eventPropertyPanelId']
) => {
  const { id: descriptionId } = useParams<{ id: string }>();
  const { activeCodingId } = usePropertiesContext();
  const findingProperties = useStore(StoreType.FindingProperties);
  const descriptionDetailsStore = useStore(StoreType.Descriptions);
  const descriptionDetails = descriptionDetailsStore.descriptionById(
    Number(descriptionId)
  );
  const panel =
    descriptionDetails &&
    findingProperties.getPanel(descriptionDetails.ageConstraints, panelId)!;

  const { eventHandler } = useHandleAutoSave<
    {
      eventPropertyCodings: EventPropertyCodingsData;
    },
    {
      eventPropertyCodings: EventPropertyCodingsData;
    },
    undefined
  >({
    saveHandler: (value) =>
      activeCodingId &&
      panel &&
      findingProperties.updatePropertyCodings({
        studyId: Number(descriptionId),
        descriptionId: Number(descriptionId),
        eventCodingId: activeCodingId,
        panel,
        data: value.eventPropertyCodings
      })
  });

  return { eventHandler: eventHandler() };
};
