import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ButtonTheme,
  EventsTreeSubitem,
  IconType,
  ImageSlider,
  Modal,
  ShoppingCart
} from '@holberg/ui-kit';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useCurrentSessionHasLock } from 'hooks/useCurrentSessionHasLock';
import { observer } from 'mobx-react-lite';

import styles from './ScreenshotSlider.module.scss';

export const ScreenshotSlider = observer(() => {
  const { id } = useParams<{ id: string }>();
  const activeDescriptionId = parseInt(id);
  const findingsStore = useStore(StoreType.Findings);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const descriptionDetails = descriptionsStore.descriptionById(
    activeDescriptionId
  )!;
  const [slides, setSlides] = useState<
    { slideHeader: JSX.Element; slideImage: string; slideIdentifier: number }[]
  >([]);
  const [openWithSlideIndex, setOpenWithSlideIndex] = useState<number>(-1);

  const { currentSessionHasLock } = useCurrentSessionHasLock(
    descriptionDetails.patientId
  );

  useEffect(() => {
    if (findingsStore.openEventScreenshot !== null) {
      buildSlidesData(findingsStore.openEventScreenshot);
    }
    return () => setSlides([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findingsStore.openEventScreenshot]);

  const screenshotHeader = (
    findingLabel: string | undefined,
    shoppingCartNodes: EventsTreeSubitem[] | undefined,
    exampleDate: string | undefined,
    exampleFreeText: string | undefined
  ) => {
    return (
      <div className={styles['screenshot-header']}>
        <div>
          <p>{findingLabel}</p>
          {shoppingCartNodes && <ShoppingCart nodes={shoppingCartNodes} />}
        </div>
        <p>
          Example {exampleDate} {exampleFreeText}
        </p>
      </div>
    );
  };

  const onModalClose = () => {
    findingsStore.openScreenshotModal(null);
  };

  const onDelete = (screenshotId: number) => {
    findingsStore.deleteScreenshot(screenshotId);
    findingsStore.openScreenshotModal(null);
  };

  const buildSlidesData = (screenshotEventId: number) => {
    const slidesData = findingsStore
      .getScreenshotsUnderEventcoding(screenshotEventId, activeDescriptionId)
      .map((screenshot, index) => {
        const {
          findingLabel,
          shoppingCartNodes,
          exampleDate,
          exampleFreeText
        } = findingsStore.getScreenshotHeaderData(
          screenshot.eventId,
          activeDescriptionId
        );
        if (screenshot.eventId === screenshotEventId) {
          setOpenWithSlideIndex(index);
        }
        return {
          slideHeader: screenshotHeader(
            findingLabel,
            shoppingCartNodes,
            exampleDate,
            exampleFreeText
          ),
          slideImage: screenshot.screenshot,
          slideIdentifier: screenshot.screenshotId
        };
      });
    setSlides(slidesData);
  };

  return (
    <Modal
      visible={findingsStore.openEventScreenshot !== null}
      handleVisible={onModalClose}
      contentClassName={styles['screenshot-modal']}
      headerClassName={styles['screenshot-modal-header']}
    >
      {slides.length === 0 ? (
        <p>Screenshot not available</p>
      ) : (
        <ImageSlider
          contentClassName={styles['custom-slider']}
          openWithSlideIndex={openWithSlideIndex}
          slides={slides}
          slideActions={
            currentSessionHasLock && !descriptionDetails.isCompleted
              ? [
                  {
                    buttonName: 'Delete',
                    onButtonClick: onDelete,
                    icon: IconType.Trash,
                    buttonTheme: ButtonTheme.Primary
                  },
                  {
                    buttonName: 'Close',
                    onButtonClick: onModalClose
                  }
                ]
              : [
                  {
                    buttonName: 'Close',
                    onButtonClick: onModalClose
                  }
                ]
          }
        />
      )}
    </Modal>
  );
});
