import { createContext, useContext } from 'react';

export type Payload = {
  title: string;
  submitButtonTitle: string;
  cancelButtonTitle?: string;
  modalContent?: string;
  onSubmit: (arg?: any) => void;
};

export type State =
  | ({
      isOpen: true;
    } & Payload)
  | {
      isOpen: false;
      modalContent?: never;
      title?: never;
      onSubmit?: never;
      submitButtonTitle?: never;
      cancelButtonTitle?: never;
    };

export const FindingsConfirmationContext = createContext<{
  state: State;
  onOpen: (payload: Payload) => void;
  onClose: () => Promise<unknown>;
} | null>(null);

export const Provider = FindingsConfirmationContext.Provider;

export const useConfirmationContext = () =>
  useContext(FindingsConfirmationContext);
