import { PropertyCodeTagType } from 'enums/PropertyCodeTagType.enum';
import { makeObservable } from 'mobx';
import { deserialize, list, primitive, serializable } from 'serializr';

export class PropertyCodeExtraInfo {
  @serializable
  propertyTypeId: number = 0;

  @serializable
  propertyCodeId: number = 0;

  @serializable(list(primitive()))
  tags: string[] = [];

  constructor() {
    makeObservable(this);
  }

  static deserialize(json: Object | string) {
    return deserialize(PropertyCodeExtraInfo, json);
  }

  static deserializeAsList(
    list: PropertyCodeExtraInfo[]
  ): PropertyCodeExtraInfo[] {
    return list.map(PropertyCodeExtraInfo.deserialize);
  }

  static deserializeAsMap(
    list: PropertyCodeExtraInfo[]
  ): Map<
    PropertyCodeExtraInfo['propertyCodeId'],
    Map<PropertyCodeTagType, boolean>
  > {
    return list.reduce((acc, propertyCode) => {
      const tagsMap = new Map();
      for (const tag of propertyCode.tags) {
        tagsMap.set(tag, true);
      }

      acc.set(propertyCode.propertyCodeId, tagsMap);
      return acc;
    }, new Map());
  }
}
