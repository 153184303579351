import { custom, deserialize, list, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

type EventCodeCompositeKeyParams = {
  parentEventCodingId: EventCodeStatus['parentEventCodingId'];
  eventCodeId: EventCodingStatus['eventCodingId'];
};

type EventCodingCompositeKeyParams = {
  eventCodingId: EventCodingStatus['eventCodingId'];
};

export const polymorphicListToMap = (
  prototype: typeof EventCodingStatus | typeof EventCodeStatus
) => (jsonValue: Object[]) => {
  const list = jsonValue.map((item) => prototype.deserialize(item));

  return list.reduce((acc, item) => {
    acc.set(item.compositeKey, item);

    return acc;
  }, new Map<string, InstanceType<typeof prototype>>());
};

export const mapToList = <K, V>(sourcePropertyValue: Map<K, V>) => [
  ...sourcePropertyValue.values()
];

export class StatusMessage {
  @serializable(object(InformationModel))
  message: InformationModel = new InformationModel();

  @serializable
  isSelfError: boolean = false;

  static deserialize(json: Object | string) {
    return deserialize(StatusMessage, json);
  }
}

export abstract class EventStatus {
  @serializable
  eventCodingStatusCodeId: number = 1;

  @serializable(list(object(StatusMessage)))
  errorMessages: StatusMessage[] = [];

  abstract get compositeKey(): string;

  get hasErrors(): boolean {
    return this.eventCodingStatusCodeId === 0;
  }

  getFullMessage(isOnlySelfErrors: boolean): string {
    let errorMessages = this.errorMessages;

    if (isOnlySelfErrors) {
      errorMessages = errorMessages.filter(
        (errorMessage) => errorMessage.isSelfError
      );
    }

    return errorMessages
      .map((statusMessage) => statusMessage.message.eitherValue)
      .join('\n');
  }
}

export class EventCodingStatus extends EventStatus {
  @serializable
  eventCodingId: number = 0;

  get compositeKey(): string {
    return EventCodingStatus.makeCompositeKey({
      eventCodingId: this.eventCodingId
    });
  }

  static makeCompositeKey({
    eventCodingId
  }: EventCodingCompositeKeyParams): string {
    return String(eventCodingId);
  }

  static deserialize(json: Object | string) {
    return deserialize(EventCodingStatus, json);
  }
}

export class EventCodeStatus extends EventStatus {
  @serializable
  eventCodeId: number = 0;

  @serializable
  parentEventCodingId?: number | null = null;

  get compositeKey(): string {
    return EventCodeStatus.makeCompositeKey({
      eventCodeId: this.eventCodeId,
      parentEventCodingId: this.parentEventCodingId
    });
  }

  static makeCompositeKey({
    eventCodeId,
    parentEventCodingId
  }: EventCodeCompositeKeyParams): string {
    if (typeof parentEventCodingId === 'number') {
      return `${eventCodeId}_${parentEventCodingId}`;
    }

    return String(eventCodeId);
  }

  static deserialize(json: Object | string) {
    return deserialize(EventCodeStatus, json);
  }
}

export class DescriptionStatus {
  @serializable(custom(mapToList, polymorphicListToMap(EventCodingStatus)))
  eventCodingStatus: Map<string, EventCodingStatus> = new Map<
    string,
    EventCodingStatus
  >();

  @serializable(custom(mapToList, polymorphicListToMap(EventCodeStatus)))
  eventCodeStatus: Map<string, EventCodeStatus> = new Map<
    string,
    EventCodeStatus
  >();

  getEventCodeStatus(keyParams: EventCodeCompositeKeyParams) {
    const compositeKey = EventCodeStatus.makeCompositeKey(keyParams);

    return this.eventCodeStatus.get(compositeKey);
  }

  getEventCodingStatus(keyParams: EventCodingCompositeKeyParams) {
    const compositeKey = EventCodingStatus.makeCompositeKey(keyParams);

    return this.eventCodingStatus.get(compositeKey);
  }

  get hasErrors(): boolean {
    const hasCodingErrors = this.eventCodingStatus.size > 0;
    const hasCodesErrors = this.eventCodeStatus.size > 0;

    return hasCodesErrors || hasCodingErrors;
  }

  static deserialize(json: Object | string) {
    return deserialize(DescriptionStatus, json);
  }
}
