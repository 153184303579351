import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

export const useSetPage = (defaultPage: number) => {
  const { replace } = useHistory();

  const [pageNumber, setPageNumber] = useQueryParam('page', StringParam);

  useEffect(() => {
    !pageNumber &&
      replace({
        search: new URLSearchParams({
          page: String(defaultPage)
        }).toString()
      });
  }, [pageNumber, replace, defaultPage]);

  const setPrevPage = useCallback(() => {
    pageNumber && setPageNumber(String(parseInt(pageNumber) - 1));
  }, [pageNumber, setPageNumber]);

  const setNextPage = useCallback(() => {
    pageNumber && setPageNumber(String(parseInt(pageNumber) + 1));
  }, [pageNumber, setPageNumber]);

  const setDefaultPage = useCallback(() => {
    defaultPage && setPageNumber(String(defaultPage));
  }, [defaultPage, setPageNumber]);

  return {
    pageNumber,
    setPrevPage,
    setNextPage,
    setDefaultPage
  };
};
