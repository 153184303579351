import React from 'react';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconType,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import { REPORT_SECTION } from 'components/ReportSection/ReportSection';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

export const CopyReportActions: React.FC<{
  onCopyClick?: () => void;
}> = observer(({ onCopyClick }) => {
  const { t } = useCustomTranslation();

  const descriptionsStore = useStore(StoreType.Descriptions);

  const reportPreviewSectionsSelected =
    descriptionsStore.selectedReportPreviewSections.length > 0;

  const onCopyReport = async () => {
    onCopyClick && (await onCopyClick());
    if (reportPreviewSectionsSelected) {
      //To copy only selected sections of report
      descriptionsStore.copyReportContentToClipboard(
        descriptionsStore.selectedReportPreviewSections
      );
      return;
    }

    //To copy full report
    const allSectionIds: string[] = [];
    document
      .querySelectorAll(`#${REPORT_SECTION} .copy-to-clipboard-section`)
      .forEach((ele) => allSectionIds.push(ele.getAttribute('id') || ''));
    descriptionsStore.copyReportContentToClipboard(allSectionIds);
  };

  return (
    <>
      {reportPreviewSectionsSelected && (
        <Button
          icon={IconType.Close}
          size={ButtonSize.Small}
          onClick={() => descriptionsStore.clearClipboardCopySelections()}
          title={t('Discard selection')}
          theme={ButtonTheme.SecondaryTransparent}
        />
      )}
      <Tooltip
        data={[
          {
            mainTooltip: (
              <span>
                {reportPreviewSectionsSelected
                  ? t('Copy selected sections content to clipboard')
                  : t('Copy report content to clipboard')}
              </span>
            )
          }
        ]}
      >
        <Button
          icon={IconType.Copy}
          size={ButtonSize.Regular}
          theme={ButtonTheme.Highlighted}
          title={
            reportPreviewSectionsSelected
              ? t('Copy selection')
              : t('Copy report')
          }
          onClick={onCopyReport}
        />
      </Tooltip>
    </>
  );
});
