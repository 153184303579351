import React, { FC, useCallback } from 'react';
import { BaseState, Detector } from 'react-detect-offline';
import {
  MessageSnackBar,
  MessageSnackBarTheme,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { Env } from 'services/Env';

import styles from './SnackBar.module.scss';

interface Props {
  className?: string;
}

interface SnackErrorProps {
  online: boolean;
  className?: string;
  isReaderAdminConnected: boolean | undefined;
}

const POLLING_SETTINGS = {
  enabled: true,
  url: `${Env.BASE_URL}/health`,
  interval: Env.CHECK_NETWORK_INTERVAL || 10000,
  timeout: Env.CHECK_NETWORK_TIMEOUT || 5000
};

export const NO_CONNECTION_TEXT = 'No network connection detected';
export const READER_NOT_CONNECTED = 'Reader not connected';

export const SnackError: FC<SnackErrorProps> = ({
  online,
  className,
  isReaderAdminConnected
}) => {
  const { t } = useCustomTranslation();

  const isVisible = !online || !isReaderAdminConnected;

  return (
    <div className={cn(styles['snack-bar'], { [styles.visible]: isVisible })}>
      <MessageSnackBar
        title={
          !online
            ? t(NO_CONNECTION_TEXT)
            : !isReaderAdminConnected
            ? t(READER_NOT_CONNECTED)
            : ''
        }
        theme={MessageSnackBarTheme.DarkBlue}
        hidden={online && isReaderAdminConnected}
        className={cn(styles['message'], className)}
      />
    </div>
  );
};

export const SnackBar: FC<Props> = ({ className }) => {
  const messagesStore = useStore(StoreType.Messages);
  const rtuStore = useStore(StoreType.RealTimeUpdates);
  const isReaderAdminConnected =
    rtuStore.realTimeUpdatesConfig.isReaderAdminConnected;

  const handleConnection = useCallback(
    (isReachable) => messagesStore.setConnectionIssues(!isReachable),
    [messagesStore]
  );

  const renderSnackErrorComponent = useCallback(
    ({ online }: BaseState) => (
      <SnackError
        online={online}
        className={className}
        isReaderAdminConnected={isReaderAdminConnected}
      />
    ),
    [className, isReaderAdminConnected]
  );

  return (
    <Detector
      render={renderSnackErrorComponent}
      polling={POLLING_SETTINGS}
      onChange={handleConnection}
    />
  );
};
