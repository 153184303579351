import React, { useState } from 'react';
import {
  Button,
  IconType,
  Popup,
  TableColumn,
  useCustomTranslation
} from '@holberg/ui-kit';
import { Condition } from 'entities/Condition.entity';
import { GenericColumnMetaInformation } from 'entities/GenericColumnMetaInformation.entity';
import { GenericListItem } from 'entities/GenericListItem.entity';
import { TableConfig } from 'entities/TableConfig.entity';
import { observer } from 'mobx-react-lite';

import { ColumnsSettings } from '../ColumnsSettings';
import { FiltersSettings } from '../FiltersSettings';

import styles from './TableSettings.module.scss';

interface Props<T extends object> {
  filterCriteria?: Condition[];
  onUpdateFilters: (conditions: Condition[]) => void;
  columnMetaInfo: Record<keyof GenericListItem, GenericColumnMetaInformation>;
  tableConfig: Record<keyof GenericListItem, TableConfig>;
  columns: Array<TableColumn<T>>;
  onUpdateTableConfig: (
    column: keyof T,
    key: keyof TableConfig,
    value: TableConfig[keyof TableConfig]
  ) => void;
  onUpdateConfigValues: (
    data: Array<TableColumn<T>>,
    key: keyof TableConfig
  ) => void;
  disabled?: boolean;
}

export const TableSettings: <T extends object>(
  p: Props<T>
) => React.ReactElement<Props<T>> = observer(
  ({
    columns,
    filterCriteria,
    columnMetaInfo,
    onUpdateTableConfig,
    onUpdateFilters,
    tableConfig,
    disabled,
    onUpdateConfigValues
  }) => {
    const { t } = useCustomTranslation();
    const [isSettingsVisible, setSettingsVisible] = useState<boolean>(false);
    const [isFiltersVisible, setFiltersVisible] = useState<boolean>(false);
    const filterIcon = filterCriteria?.length
      ? IconType.FilterActive
      : IconType.Filter;

    return (
      <div className={styles['table-settings']}>
        <Button
          id='filters-toggle'
          title={t('Filter')}
          icon={filterIcon}
          disabled={disabled}
          className={styles['cog-button']}
          isIconActive={!!filterCriteria?.length}
          onClick={() => setFiltersVisible((val) => !val)}
        />
        <Button
          id='columns-toggle'
          title={t('Columns')}
          icon={IconType.Cog}
          className={styles['cog-button']}
          disabled={disabled}
          onClick={() => {
            setSettingsVisible((val) => !val);
          }}
        />
        <Popup
          visible={isSettingsVisible && !disabled}
          className={styles['cog-popup']}
          exceptQuery='#columns-toggle, #columns-toggle *'
          onOutsideClick={() => {
            setSettingsVisible(false);
          }}
        >
          <ColumnsSettings
            columns={columns}
            onUpdateConfigValues={onUpdateConfigValues}
            onUpdateTableConfig={onUpdateTableConfig}
          />
        </Popup>
        <Popup
          visible={isFiltersVisible && !disabled}
          className={styles['filter-popup']}
          exceptQuery='#filters-toggle, #filters-toggle *, .rc-time-picker-panel, .rc-time-picker-panel *'
          onOutsideClick={() => {
            setFiltersVisible(false);
          }}
        >
          <FiltersSettings
            tableConfig={tableConfig}
            isFiltersVisible={isFiltersVisible}
            filterCriteria={filterCriteria}
            columnMetaInfo={columnMetaInfo}
            onUpdateFilters={onUpdateFilters}
          />
        </Popup>
      </div>
    );
  }
);
