import React, { useCallback, useMemo, useRef } from 'react';
import { useCustomTranslation } from '@holberg/ui-kit';
import { Example } from 'components/Example';
import { ExampleHeader } from 'components/ExampleHeader';
import {
  ConfirmationTitle,
  useConfirmationContext
} from 'components/FindingsConfirmationModal';
import { useNameSelectionContext } from 'components/NameSelectionModal/NameSelectionContext';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { Event } from 'entities/Event.entity';
import { EventCode } from 'entities/EventCode.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { Study } from 'entities/Study.entity';
import { EventTreeSettingsType } from 'enums/EventTreeSettingsType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import { Folder } from '../FindingFolder';

import styles from 'components/ExamplesList/ExamplesList.module.scss';

interface Props {
  events: Event[];
  studyId: Study['studyId'];
  descriptionId: number;
  entityId: number;
  readOnly?: boolean;
  isCheckboxHidden?: boolean;
  activeEventCodeId?: number;
  currentEventCodeId: EventCode['eventCodeId'];
  thisListIsUnderTbdFinding?: boolean;
}

export const ExamplesList: React.FC<Props> = observer(
  ({
    events,
    studyId,
    descriptionId,
    entityId,
    readOnly = false,
    isCheckboxHidden,
    activeEventCodeId,
    currentEventCodeId,
    thisListIsUnderTbdFinding = false
  }) => {
    const { t } = useCustomTranslation();
    const {
      activeCodingId,
      onFindingClick,
      onCloseProperties
    } = usePropertiesContext();
    const hasActiveCoding = activeCodingId !== undefined;
    const lastCheckedExample = useRef<number>(-1);

    const findingsStore = useStore(StoreType.Findings);
    const reportsStore = useStore(StoreType.PatientReports);
    const reportDetails = reportsStore.patientReports.get(descriptionId)!;
    const rtuStore = useStore(StoreType.RealTimeUpdates);
    const nameSelectionContext = useNameSelectionContext();
    const confirmationContext = useConfirmationContext();

    const formattedDateOfEvent = useCallback(
      (event: Event) => {
        return event.formatStartDateTime(
          reportDetails?.reportStartDateTime,
          reportDetails?.reportStopDateTime
        );
      },
      [reportDetails?.reportStartDateTime, reportDetails?.reportStopDateTime]
    );

    const headerProps = useMemo(
      () => ({
        label: t('Examples'),
        hasChildren: !!events.length
      }),
      [events.length, t]
    );

    const examplesState = findingsStore.eventTreeState.getEventTreeEntityStateById(
      studyId,
      EventTreeSettingsType.ExamplesState
    );

    const isActive = examplesState ? examplesState[entityId] : false;

    const onToggleFolder = useCallback(() => {
      findingsStore.eventTreeState.updateEventTreeSettingsConfig({
        studyId,
        entityId,
        settingKey: EventTreeSettingsType.ExamplesState,
        expanded: !isActive
      });
    }, [findingsStore.eventTreeState, studyId, entityId, isActive]);

    const onDoubleClick = (studyId: number, exampleId: Event['eventId']) => {
      rtuStore.activateEvent(studyId, descriptionId, exampleId);
    };

    const createEventCoding = useCallback(
      async (eventCodeId: number, exampleId: Event['eventId']) => {
        const eventCodings: EventCoding[] = await findingsStore.createEventCoding(
          {
            studyId,
            data: {
              eventIds: [exampleId],
              eventCodeId
            },
            descriptionId,
            identifier: `example-classify-${exampleId}`
          }
        );

        const activeEventCoding = eventCodings.find(
          (eventCoding) => eventCoding.eventCodeId === eventCodeId
        );

        !!eventCodings.length &&
          !findingsStore.isToBeDefined(eventCodeId) &&
          onFindingClick(activeEventCoding);
      },
      [descriptionId, findingsStore, onFindingClick, studyId]
    );

    const onClassify = useCallback(
      (exampleId: Event['eventId']) => {
        nameSelectionContext?.onOpen({
          activeEventCodeId,
          title: t('Reclassify'),
          disabledEventCodeIds: [currentEventCodeId],
          onSelect: (eventCodeId) => {
            if (
              findingsStore.hasActiveOnlyOneEventCoding(
                descriptionId,
                eventCodeId
              )
            ) {
              confirmationContext?.onOpen({
                title: t(ConfirmationTitle.Add_Second_OnlyOneEventCoding),
                submitButtonTitle: t('Yes, sure'),
                onSubmit: () => createEventCoding(eventCodeId, exampleId)
              });
            } else {
              createEventCoding(eventCodeId, exampleId);
            }

            nameSelectionContext.onClose();
          }
        });
      },
      [
        activeEventCodeId,
        confirmationContext,
        createEventCoding,
        currentEventCodeId,
        descriptionId,
        findingsStore,
        nameSelectionContext,
        t
      ]
    );

    if (!events?.length) {
      return null;
    }

    const onDeletingExample = (eventId: number) => {
      confirmationContext?.onOpen({
        title: ConfirmationTitle.Delete_Example,
        submitButtonTitle: 'Yes, delete',
        onSubmit: () => findingsStore.deleteEEGMarker(descriptionId, [eventId])
      });
    };

    return (
      <Folder
        isActive={isActive}
        onToggleFolder={onToggleFolder}
        headerProps={headerProps}
        className={styles['examples-list']}
        ladderClassName={styles.ladder}
        header={ExampleHeader}
      >
        {events.map((event, index) => {
          const exampleState = findingsStore.selectionState.getExampleState(
            event
          );

          return (
            <Example
              screenshot={findingsStore.getScreenshot(event.eventId)}
              onCaptureScreenshot={(studyId: number, eventId: number) => {
                findingsStore.captureScreenshot(studyId, eventId);
              }}
              onDelete={onDeletingExample}
              index={index}
              readOnly={readOnly}
              onDoubleClick={onDoubleClick}
              isSelected={exampleState.selected}
              isCheckboxHidden={isCheckboxHidden || exampleState.checkboxHidden}
              isCheckboxDisabled={exampleState.checkboxDisabled}
              isActionsAvailable={exampleState.actionsAvailable && !readOnly}
              isActive={
                rtuStore.realTimeUpdatesConfig.activeEventId === event.eventId
              }
              horizontalAlignment
              onSelect={(e) => {
                if (hasActiveCoding) {
                  onCloseProperties();
                }
                if (
                  e.nativeEvent.shiftKey &&
                  lastCheckedExample.current !== -1
                ) {
                  const selectedExamples = events.slice(
                    Math.min(index, lastCheckedExample.current),
                    Math.max(index, lastCheckedExample.current) + 1
                  );
                  findingsStore.selectionState.selectMultipleExamples(
                    selectedExamples
                  );
                } else {
                  findingsStore.selectionState.toggleExample(event);
                }
                lastCheckedExample.current = index;
              }}
              onUnclassify={() => {
                findingsStore.deleteEvents(descriptionId, [event.eventId]);
              }}
              onClassify={onClassify}
              eventDate={formattedDateOfEvent(event) || ''}
              eventDetails={event}
              thisEventIsUnderTbdFinding={thisListIsUnderTbdFinding}
              key={event.eventId}
            />
          );
        })}
      </Folder>
    );
  }
);
