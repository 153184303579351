import React from 'react';
import { useDragLayer } from 'react-dnd';
import { Icon, IconType } from '@holberg/ui-kit';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';

import styles from './CustomDragLayer.module.scss';

export const CustomDragLayer: React.FC = () => {
  const findingsStore = useStore(StoreType.Findings);
  const patientReportsStore = useStore(StoreType.PatientReports);
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  if (!isDragging) {
    return null;
  }

  const recordingDragPreview = () => {
    const selectedRecordingsCount =
      patientReportsStore.selectedUnreportedRecordings.size +
      patientReportsStore.selectedReportedRecordings.size;
    return (
      <div
        data-testid='recording-drag-layer'
        className={styles['dragged-item']}
      >
        <span>{item.start}</span>
        <Icon iconType={IconType.Arrow} className={styles['arrow-icon']} />
        <span>{item?.totalTime}</span>
        {selectedRecordingsCount > 1 && (
          <>
            <div className={styles['dragged-items-count']}>
              {selectedRecordingsCount}
            </div>
            <div className={styles['dragged-items-showcase']}></div>
          </>
        )}
      </div>
    );
  };

  function renderDragLayer() {
    switch (itemType) {
      case DraggableTypes.example:
        return (
          <div
            className={styles['dragged-item']}
            data-testid='example-drag-layer'
          >
            <span>{item.eventDate}</span>
            {findingsStore.selectionState.selectedExamples.length > 1 && (
              <>
                <div className={styles['dragged-items-count']}>
                  {findingsStore.selectionState.selectedExamples.length}
                </div>
                <div className={styles['dragged-items-showcase']}></div>
              </>
            )}
          </div>
        );
      case DraggableTypes.finding:
        return (
          <div
            className={styles['dragged-item']}
            data-testid='finding-drag-layer'
          >
            <span>{item.label}</span>
            {findingsStore.selectionState.selectedFindings.length > 1 && (
              <>
                <div className={styles['dragged-items-count']}>
                  {findingsStore.selectionState.selectedFindings.length}
                </div>
                <div className={styles['dragged-items-showcase']}></div>
              </>
            )}
          </div>
        );
      case DraggableTypes.columns:
        return (
          <div
            data-testid='column-drag-layer'
            className={styles['dragged-column']}
          >
            {item.title}
          </div>
        );
      case DraggableTypes.recording:
        return recordingDragPreview();
      default:
        return <></>;
    }
  }

  return (
    <div
      style={{
        zIndex: 99,
        left: 0,
        pointerEvents: 'none',
        position: 'fixed',
        top: 0,
        transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`
      }}
    >
      {renderDragLayer()}
    </div>
  );
};
