import { SortDirection } from 'enums/SortDirection.enum';
import { computed, makeObservable, observable } from 'mobx';
import { deserialize, list, object, serializable } from 'serializr';

export class SortCriteria {
  @observable
  @serializable
  sortOrder: number = 0;

  @observable
  @serializable
  columnName: string = '';

  @observable
  @serializable
  sortDirection: SortDirection = SortDirection.Ascending;

  static deserialize(json: Object | string) {
    return deserialize(SortCriteria, json);
  }

  static deserializeAsList(list: SortCriteria[]): SortCriteria[] {
    return list.map(SortCriteria.deserialize);
  }
}

export class SortCriteriaState {
  @observable
  @serializable(list(object(SortCriteria)))
  private sortCriteria: SortCriteria[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get asList() {
    return this.sortCriteria;
  }

  @computed
  get asMap() {
    return this.sortCriteria.reduce<Record<string, SortCriteria>>(
      (acc, criteria) => {
        acc[criteria.columnName] = criteria;
        return acc;
      },
      {}
    );
  }

  setCriteria(criteria: SortCriteria) {
    this.sortCriteria = observable([criteria]);
  }

  clearCriteria() {
    this.sortCriteria = observable([]);
  }

  static deserialize(json: Object | string) {
    return deserialize(SortCriteriaState, json);
  }
}
