import { HDataType } from 'enums/HDataType.enum';
import { LocationType } from 'enums/LocationType.enum';
import { TemplateId } from 'enums/TemplateId.enum';
import { deserialize, list, object, serializable } from 'serializr';

import { EventPropertyCode } from './EventPropertyCode.entity';
import { EventPropertyType } from './EventPropertyType.entity';
import { EventPropertyTypeCategorical } from './EventPropertyTypeCategorical.entity';
import { EventPropertyTypeNonCategorical } from './EventPropertyTypeNonCategorical.entity';
import { EventPropertyTypeOther } from './EventPropertyTypeOther.entity';
import { InformationModel } from './InformationModel.entity';

export class EventPropertyPanel {
  @serializable
  eventPropertyPanelId: number = 0;

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  @serializable
  ageConstraint: number = 0;

  @serializable
  sortOrder: number = 0;

  @serializable
  isActive: boolean = false;

  @serializable
  requiredEventCodeId: number | null = null;

  @serializable(object(InformationModel))
  definition: InformationModel = new InformationModel();

  @serializable(list(object(EventPropertyTypeCategorical)))
  categoricalPropertyTypes: EventPropertyTypeCategorical[] = [];

  @serializable(list(object(EventPropertyTypeNonCategorical)))
  nonCategoricalPropertyTypes: EventPropertyTypeNonCategorical[] = [];

  @serializable(list(object(EventPropertyTypeOther)))
  otherPropertyTypes: EventPropertyTypeOther[] = [];

  @serializable
  isDisabled?: boolean = false;

  get stringPropertyTypes() {
    return this.otherPropertyTypes.filter(
      (value) => value.propertyType.dataTypeId === HDataType.String
    );
  }

  get headModelPropertyType() {
    return this.otherPropertyTypes.find(
      (value) =>
        value.propertyType.dataTypeId === HDataType.LocationOnset ||
        value.propertyType.dataTypeId === HDataType.LocationOnsetPropagation
    );
  }

  get regionsPropertyType() {
    return this.categoricalPropertyTypes.find(
      (value) => value.propertyType.templateId === TemplateId.TagList
    );
  }

  get headModelLocationType() {
    switch (true) {
      case this.headModelPropertyType?.propertyType.dataTypeId ===
        HDataType.LocationOnset:
        return LocationType.Onset;
      case this.headModelPropertyType?.propertyType.dataTypeId ===
        HDataType.LocationOnsetPropagation:
        return LocationType.Propagation;
      default:
        return undefined;
    }
  }

  get diffuseProperty():
    | {
        propertyType: EventPropertyType;
        propertyCode: EventPropertyCode;
      }
    | undefined {
    for (const categorical of this.categoricalPropertyTypes) {
      const diffuseCode = categorical.propertyCodes.find(
        (code) => code.selectAllSensors
      );

      if (diffuseCode) {
        return {
          propertyType: categorical.propertyType,
          propertyCode: diffuseCode
        };
      }
    }

    return;
  }

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyPanel, json);
  }

  static deserializeAsList(list: EventPropertyPanel[]): EventPropertyPanel[] {
    return list.map(EventPropertyPanel.deserialize);
  }

  static deserializeAsMap(
    list: EventPropertyPanel[]
  ): Map<EventPropertyPanel['eventPropertyPanelId'], EventPropertyPanel> {
    return list.reduce((acc, eventCode) => {
      acc.set(
        eventCode.eventPropertyPanelId,
        EventPropertyPanel.deserialize(eventCode)
      );
      return acc;
    }, new Map());
  }
}
