import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form/dist/index.ie11';
import { Checkbox, RadioButton } from '@holberg/ui-kit';
import cn from 'classnames';
import { withBilateral } from 'components/PropertyTabsPanelForm/withBilateral';
import { withRequiredProperty } from 'components/PropertyTabsPanelForm/withRequiredProperty';
import { EventPropertyCode } from 'entities/EventPropertyCode.entity';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyPanelFocused } from 'entities/EventPropertyPanelFocused.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { FindingPropertiesEventTypes } from 'enums/FindingPropertiesEventTypes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHandlePropertiesAutoSave } from 'hooks/useHandlePropertiesAutoSave';
import { usePrevious } from 'hooks/usePrevious';
import compose from 'lodash.flowright';
import { observer } from 'mobx-react-lite';
import { FindingPropertiesPublisher } from 'services/FindingPropertiesPublisher';
import { EventPropertyCodingsData } from 'stores/finding-properties';

import styles from './CategoricalPropertyType.module.scss';

interface Props {
  propertyType: EventPropertyType;
  propertyCodes: EventPropertyCode[];
  showTitle?: boolean;
  disabled?: boolean;
  disabledByBilateral?: boolean;
  panelId: EventPropertyPanel['eventPropertyPanelId'];
  focusedProperty: EventPropertyPanelFocused;
}

export const CategoricalPropertyType: FC<Props> = compose(
  withBilateral<Props>(({ propertyType }) => propertyType),
  withRequiredProperty<Props>(({ propertyType }) => propertyType)
)(
  observer(
    ({
      propertyType,
      propertyCodes,
      showTitle,
      disabled,
      disabledByBilateral,
      panelId,
      focusedProperty
    }) => {
      const { control, register, setValue, getValues } = useFormContext<{
        eventPropertyCodings: EventPropertyCodingsData;
      }>();

      const { eventHandler } = useHandlePropertiesAutoSave(panelId);
      const findingPropertiesStore = useStore(StoreType.FindingProperties);

      const activeFocusProperty = findingPropertiesStore.focusedProperty;
      const actionDisabled = disabled || disabledByBilateral;

      const prevDisabled = usePrevious(actionDisabled);
      const currentFocusedItem = useRef<HTMLDivElement | null>(null);

      const notScored = useMemo(
        () => propertyCodes.find((code) => code.isNotScoredProperty),
        [propertyCodes]
      );

      const onDiscardCheckboxes = useCallback(
        (propertyCodes: EventPropertyCode[]) => {
          propertyCodes.forEach((code) => {
            if (!code.isExclusiveInMultiSelect) {
              setValue(
                `eventPropertyCodings.categorical.${code.eventPropertyTypeId}.${code.eventPropertyCodeId}`,
                false
              );
            }
          });
        },
        [setValue]
      );

      const onResetRadio = useCallback(() => {
        setValue(
          `eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}.radio`,
          `${notScored?.eventPropertyCodeId}` || ''
        );
      }, [setValue, propertyType.eventPropertyTypeId, notScored]);

      useEffect(() => {
        if (!prevDisabled && actionDisabled) {
          onDiscardCheckboxes(propertyCodes);
          onResetRadio();
        }
        // eslint-disable-next-line
      }, [actionDisabled, prevDisabled]);

      useEffect(() => {
        const diffuse = propertyCodes.find((code) => code.selectAllSensors);

        if (diffuse) {
          const observer = {
            event: {
              panelId,
              eventType: FindingPropertiesEventTypes.SensorsDeselect
            },
            update: () => {
              setValue(
                `eventPropertyCodings.categorical.${diffuse.eventPropertyTypeId}.${diffuse.eventPropertyCodeId}`,
                false
              );
            }
          };

          FindingPropertiesPublisher.subscribe(observer);

          return () => {
            FindingPropertiesPublisher.unsubscribe(observer);
          };
        }

        return;
        // eslint-disable-next-line
      }, []);

      useEffect(() => {
        const isFirstRender =
          Object.keys(findingPropertiesStore?.propertyIndexStructure || {})
            .length > 0;

        findingPropertiesStore.setPropertyIndexStructure({
          ...findingPropertiesStore.propertyIndexStructure,
          [String(focusedProperty.tabPositionNumber)]: {
            ...findingPropertiesStore.propertyIndexStructure?.[
              String(focusedProperty.tabPositionNumber)
            ],
            [String(focusedProperty.panelPositionNumber)]: {
              ...findingPropertiesStore.propertyIndexStructure?.[
                String(focusedProperty.tabPositionNumber)
              ]?.[String(focusedProperty.panelPositionNumber)],
              [String(
                focusedProperty.groupPositionNumber
              )]: propertyCodes.length
            }
          }
        } as Array<Array<Array<number>>>);

        if (isFirstRender) {
          findingPropertiesStore.setFocusedProperty({
            tabPositionNumber: 0,
            panelPositionNumber: 0,
            groupPositionNumber: findingPropertiesStore.getFirstGroupIndex(
              0,
              0
            ),
            itemPositionNumber: 0
          });
        }
        // eslint-disable-next-line
      }, []);

      useEffect(() => {
        if (currentFocusedItem.current) {
          findingPropertiesStore.setCurrentFocusedItem(
            currentFocusedItem.current
          );
        }
      });

      return (
        <div
          className={styles.container}
          key={propertyType.eventPropertyTypeId}
        >
          {showTitle && (
            <p className={styles.title}>
              {propertyType.translatedName.eitherValue}
            </p>
          )}

          {propertyCodes
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((propertyCode, id) => {
              const { eventPropertyTypeId } = propertyType;
              const { eventPropertyCodeId } = propertyCode;

              const isFocused =
                activeFocusProperty?.tabPositionNumber ===
                  focusedProperty.tabPositionNumber &&
                activeFocusProperty?.panelPositionNumber ===
                  focusedProperty.panelPositionNumber &&
                activeFocusProperty?.groupPositionNumber ===
                  focusedProperty.groupPositionNumber &&
                activeFocusProperty?.itemPositionNumber === id;

              return (
                <div
                  className={styles['categorical-container']}
                  key={propertyCode.eventPropertyCodeId}
                  ref={isFocused ? currentFocusedItem : null}
                >
                  {propertyType.isMultiselect &&
                  !propertyCode.isExclusiveInMultiSelect ? (
                    <Controller
                      render={({ value, onChange, ...rest }) => (
                        <Checkbox
                          {...rest}
                          onChange={() => {
                            const categoricalValues = getValues()
                              .eventPropertyCodings.categorical[
                              eventPropertyTypeId
                            ];
                            const updatedCategoricalValues = {
                              ...categoricalValues,
                              [propertyCode.eventPropertyCodeId]: !value
                            };
                            onChange(!value);

                            if (
                              notScored &&
                              !Object.values(updatedCategoricalValues).some(
                                (value) => !!value
                              )
                            ) {
                              setValue(
                                `eventPropertyCodings.categorical.${eventPropertyTypeId}.radio`,
                                `${notScored.eventPropertyCodeId}`
                              );
                            } else {
                              setValue(
                                `eventPropertyCodings.categorical.${eventPropertyTypeId}.radio`,
                                ''
                              );
                            }

                            if (propertyCode.selectAllSensors && !value) {
                              FindingPropertiesPublisher.publish({
                                eventType:
                                  FindingPropertiesEventTypes.DiffuseSelect,
                                panelId
                              });
                            }

                            eventHandler();
                          }}
                          data-testid='categorical-checkbox'
                          label={propertyCode.translatedName.eitherValue}
                          className={cn(
                            styles.label,
                            !actionDisabled && styles['label-active'],
                            isFocused && 'currentKeyFocusedItem'
                          )}
                          isFocused={isFocused}
                          checkmarkClassName={styles.checkmark}
                          checked={value}
                          disabled={actionDisabled}
                        />
                      )}
                      defaultValue={false}
                      control={control}
                      name={`eventPropertyCodings.categorical.${eventPropertyTypeId}.${eventPropertyCodeId}`}
                    />
                  ) : (
                    <RadioButton
                      ref={register}
                      disabled={actionDisabled}
                      label={propertyCode.translatedName.eitherValue}
                      className={cn(
                        styles.label,
                        isFocused && 'currentKeyFocusedItem'
                      )}
                      value={eventPropertyCodeId}
                      onClick={() => {
                        if (
                          propertyCode.isExclusiveInMultiSelect &&
                          propertyType.isMultiselect
                        ) {
                          onDiscardCheckboxes(propertyCodes);
                        }

                        if (propertyCode.isNotScoredProperty) {
                          FindingPropertiesPublisher.publish({
                            eventType:
                              FindingPropertiesEventTypes.NotScoredSelect,
                            panelId
                          });
                        }
                        eventHandler();
                      }}
                      isFocused={isFocused}
                      data-testid='categorical-radio'
                      name={`eventPropertyCodings.categorical.${eventPropertyTypeId}.radio`}
                    />
                  )}
                </div>
              );
            })}
        </div>
      );
    }
  )
);
