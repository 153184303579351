import { ErrorTheme, GeneralError, IconType } from '@holberg/ui-kit';

import styles from './ErrorScreen.module.scss';

interface Props {
  errorText: string;
}

export const ErrorScreen: React.FC<Props> = ({ errorText }) => (
  <div className={styles['error-screen-wrapper']}>
    <GeneralError
      icon={IconType.Broken}
      title={errorText}
      theme={ErrorTheme.Primary}
      className={styles['error-screen']}
      containerClassName={styles.container}
    />
  </div>
);
