import { SystemMessageTheme } from '@holberg/ui-kit';
import { ApiError } from 'entities/ApiError.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { action, makeObservable, observable } from 'mobx';

type SimpleError = {
  identifier: string;
  error: ApiError | UnknownError;
};

export class MessagesStore {
  @observable unauthorizeError?: ApiError | UnknownError;
  @observable hasConnectionIssues: boolean = false;
  @observable generalError?: ApiError | UnknownError;
  @observable message?: { title: string; theme: SystemMessageTheme };
  private simpleErrors = observable.array<SimpleError>([]);
  private errorsInterval: NodeJS.Timeout | null = null;
  private infoMsgTimeout: NodeJS.Timeout | null = null;
  private intervalTimeout: number;

  constructor(interval = 5000) {
    makeObservable<
      MessagesStore,
      | 'startErrorMsgInterval'
      | 'restartErrorMsgInterval'
      | 'pushError'
      | 'setMsgError'
      | 'startInfoMsgTimeout'
    >(this);
    this.intervalTimeout = interval;
  }

  private clearErrorsInterval() {
    if (this.errorsInterval) {
      clearInterval(this.errorsInterval);
      this.errorsInterval = null;
    }
  }

  private clearInfoMsgTimeout() {
    if (this.infoMsgTimeout) {
      clearInterval(this.infoMsgTimeout);
      this.infoMsgTimeout = null;
    }
  }

  @action setConnectionIssues(status: boolean) {
    this.hasConnectionIssues = status;
  }

  @action setUnauthorizeError(error: ApiError | UnknownError) {
    this.unauthorizeError = error;
  }

  @action setGeneralError(error: ApiError | UnknownError) {
    if (!this.generalError) {
      this.generalError = error;
    }
  }

  @action clearUnauthorizeError() {
    this.unauthorizeError = undefined;
  }

  @action private clearErrors() {
    this.simpleErrors.clear();
    this.clearErrorsInterval();
    this.message = undefined;
  }

  @action private clearInfoMsg() {
    this.clearInfoMsgTimeout();
    this.message = undefined;
  }

  @action clearSystemMessages() {
    this.clearErrors();
    this.clearInfoMsg();
  }

  @action clearPageErrors() {
    this.clearSystemMessages();
    this.generalError = undefined;
  }

  @action addMsgError(identifier: string, error: ApiError | UnknownError) {
    this.pushError(identifier, error);

    !this.errorsInterval &&
      !this.infoMsgTimeout &&
      error.fullMessage !== 'Unknown Network Error' &&
      this.startErrorMsgInterval();
  }

  @action private pushError(
    identifier: string,
    error: ApiError | UnknownError
  ) {
    const previousMsgError = this.simpleErrors.find(
      (simpleError) => simpleError.identifier === identifier
    );

    this.simpleErrors.push({
      identifier,
      error
    });

    if (previousMsgError) {
      this.removeError(identifier);
    }
  }

  @action private startErrorMsgInterval() {
    this.message = {
      title: this.simpleErrors[0].error.fullMessage,
      theme: SystemMessageTheme.Error
    };

    this.errorsInterval = setInterval(
      () => this.setMsgError(),
      this.intervalTimeout
    );
  }

  @action private restartErrorMsgInterval() {
    this.clearErrorsInterval();
    this.startErrorMsgInterval();
  }

  @action private setMsgError() {
    if (this.simpleErrors.length === 1) {
      this.clearErrorsInterval();
    } else {
      this.simpleErrors.shift();
      this.message = {
        title: this.simpleErrors[0].error.fullMessage,
        theme: SystemMessageTheme.Error
      };
    }
  }

  @action addMessage(title: string, theme: SystemMessageTheme) {
    this.message = {
      title,
      theme
    };
    if (theme !== SystemMessageTheme.Critical) this.startInfoMsgTimeout();
    this.simpleErrors.length > 1 && this.simpleErrors.shift();
  }

  @action private startInfoMsgTimeout() {
    this.clearErrorsInterval();

    this.infoMsgTimeout = setTimeout(() => {
      this.removeMessage();
    }, this.intervalTimeout);
  }

  @action removeMessage() {
    if (this.message?.theme !== SystemMessageTheme.Error) {
      this.clearInfoMsg();

      this.simpleErrors.length && this.startErrorMsgInterval();
    } else {
      this.simpleErrors.shift();

      this.simpleErrors.length
        ? this.restartErrorMsgInterval()
        : this.clearErrors();
    }
  }

  @action removeError(identifier: string) {
    const correspondingError = this.simpleErrors.find(
      (simpleError) => simpleError.identifier === identifier
    );

    if (!correspondingError) {
      return;
    }

    if (this.simpleErrors[0]?.identifier === identifier) {
      this.message?.theme !== SystemMessageTheme.Error
        ? this.simpleErrors.shift()
        : this.removeMessage();
    } else {
      this.simpleErrors.remove(correspondingError);
    }
  }
}
