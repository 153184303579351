import { useEffect, useRef } from 'react';

export const useAutofocus = <T extends HTMLElement>(dependency?: any) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    ref.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, dependency]);

  return ref;
};
