import { SiteType } from 'enums/SiteType.enum';
import { computed } from 'mobx';
import { deserialize, serializable } from 'serializr';

export class Site {
  @serializable
  isDeleted: boolean = false;

  @serializable
  siteName: string = '';

  @serializable
  institutionName: string = '';

  @serializable
  siteType: SiteType = SiteType.Any;

  @serializable
  customerLogo: string = '';

  @serializable
  unitName: string = '';

  @serializable
  address: string = '';

  @serializable
  phoneNumber: string = '';

  @computed
  get hospitalDetails() {
    return `${this.address}, ${this.phoneNumber}`;
  }

  static deserialize(json: Object | string) {
    return deserialize(Site, json);
  }
}
