import { isFuture } from 'date-fns';

import { parseDate } from './dateHelpers';

export const isInteger = (value: string): boolean => {
  const parsed = parseFloat(value);
  return (
    typeof parsed === 'number' &&
    isFinite(parsed) &&
    Math.floor(parsed) === parsed
  );
};

export const isFloat = (value: string): boolean => {
  const parsed = parseFloat(value);
  return !!(parsed % 1);
};

export const hasLength = (
  value: string,
  length: number,
  exact: boolean = true
): boolean => {
  if (exact) {
    return value.length === length;
  } else {
    return value.length >= length;
  }
};

export const isValidDate = (
  value: string,
  dateFormat: string = 'yyyy-MM-dd'
): boolean => {
  if (value === undefined || value === '') {
    return false;
  }

  return !!parseDate(value, dateFormat);
};

export const isFutureDate = (date: Date): boolean | null => {
  try {
    return isFuture(date);
  } catch (e) {
    return null;
  }
};

export const isValidNotFutureDate = (
  data: string,
  dateFormat: string = 'yyyy-MM-dd'
): boolean => {
  const isValid = isValidDate(data, dateFormat);

  if (!isValid) {
    return false;
  }

  const date = parseDate(data, dateFormat);
  const isFuture = date && isFutureDate(date);

  if (!isFuture) {
    return true;
  }

  return false;
};
