import { EventsTreeSubitem, EventsTreeSubitemTheme } from '@holberg/ui-kit';
import { HDataType } from 'enums/HDataType.enum';
import { ShoppingCartEventPropertyType } from 'enums/ShoppingCartEventPropertyType';
import { ShoppingCartItemType } from 'enums/ShoppingCartItemType';
import {
  deserialize,
  getDefaultModelSchema,
  list,
  object,
  serializable
} from 'serializr';
import { SensorState } from 'stores/head-model';

import { ShoppingCartComponent, ShoppingCartValue } from './ShoppingCartValue';

const NORMAL_SENSORS_COUNT = 4;

export class ShoppingCartItem extends ShoppingCartComponent {
  @serializable
  itemId: number = 0;

  @serializable
  type: ShoppingCartItemType = ShoppingCartItemType.Panel;

  @serializable(object(ShoppingCartValue))
  shoppingCartValue: ShoppingCartValue | null = null;

  shoppingCartItems: ShoppingCartItem[] = [];

  private parentNode: null | ShoppingCartItem = null;

  constructor() {
    super();

    getDefaultModelSchema(ShoppingCartItem)!.props['shoppingCartItems'] = list(
      object(ShoppingCartItem, {
        afterDeserialize: (cb, err, item: ShoppingCartItem) => {
          item.parentNode = this;

          cb(err, item);
        }
      })
    );
  }

  get groupedSensors(): null | {
    [SensorState.Max]: ShoppingCartValue[];
    [SensorState.Normal]: ShoppingCartValue[];
  } {
    if (this.type !== ShoppingCartItemType.Type) {
      return null;
    }

    const childrenDataType = this.shoppingCartItems[0]?.shoppingCartValue
      ?.valueDataType;
    if (
      childrenDataType !== HDataType.LocationOnset &&
      childrenDataType !== HDataType.LocationOnsetPropagation
    ) {
      return null;
    }

    return this.shoppingCartItems.reduce<{
      [SensorState.Max]: ShoppingCartValue[];
      [SensorState.Normal]: ShoppingCartValue[];
    }>(
      (group, item) => ({
        [SensorState.Max]: item.shoppingCartValue?.values[1].primaryValue
          ? [...group[SensorState.Max], item.shoppingCartValue]
          : group[SensorState.Max],
        [SensorState.Normal]:
          item.shoppingCartValue &&
          !item.shoppingCartValue.values[1].primaryValue
            ? [...group[SensorState.Normal], item.shoppingCartValue]
            : group[SensorState.Normal]
      }),
      {
        [SensorState.Max]: [],
        [SensorState.Normal]: []
      }
    );
  }

  private getItemNodes(): EventsTreeSubitem[] {
    if (this.type === ShoppingCartItemType.Type && this.groupedSensors) {
      const isVisible =
        this.groupedSensors[SensorState.Max].length ||
        this.groupedSensors[SensorState.Normal].length <= NORMAL_SENSORS_COUNT;

      if (!isVisible) {
        return [];
      }

      const maxSensors = this.groupedSensors[SensorState.Max].length
        ? `Maximum ${this.groupedSensors[SensorState.Max]
            .map((sensor) => sensor.values[0].primaryValue)
            .join(', ')}`
        : undefined;

      const normalSensors =
        this.groupedSensors[SensorState.Normal].length &&
        this.groupedSensors[SensorState.Normal].length <= NORMAL_SENSORS_COUNT
          ? this.groupedSensors[SensorState.Normal]
              .map((sensor) => sensor.values[0].primaryValue)
              .join(', ')
          : undefined;

      return [
        ...(this.shoppingCartValue?.getNodes() || []),
        {
          text: `(${maxSensors || ''}${
            (maxSensors && normalSensors && ' in addition ') || ''
          }${normalSensors || ''})`
        }
      ];
    }

    if (!this.shoppingCartValue) {
      return [];
    }

    switch (this.shoppingCartValue.valueDataType) {
      case HDataType.Categorical:
      case HDataType.Decimal:
      case HDataType.Integer: {
        return this.shoppingCartValue.getNodes();
      }
      case HDataType.String: {
        if (this.type === ShoppingCartItemType.Value) {
          return [
            {
              text: this.shoppingCartValue.values[0].eitherValue,
              theme: EventsTreeSubitemTheme.Italic
            }
          ];
        }

        return this.shoppingCartValue.getNodes();
      }
      case HDataType.LocationOnset:
      case HDataType.LocationOnsetPropagation:
        return [];
      default:
        return this.shoppingCartValue.values.map((value) => ({
          text: value.eitherValue
        }));
    }
  }

  private getDividerNode(index: number): EventsTreeSubitem | undefined {
    switch (this.type) {
      case ShoppingCartItemType.Tab:
        return { text: '. ' };
      case ShoppingCartItemType.Panel:
        return index > 0 ? { text: '; ' } : undefined;
      case ShoppingCartItemType.Type:
        if (
          this.itemId === ShoppingCartEventPropertyType.Laterality ||
          this.itemId === ShoppingCartEventPropertyType.Region ||
          this.itemId ===
            ShoppingCartEventPropertyType.LocationOnsetHeadModel ||
          this.itemId === ShoppingCartEventPropertyType.LocationHeadModel ||
          this.itemId ===
            ShoppingCartEventPropertyType.LocationPropagationHeadModel
        ) {
          return { text: ' ' };
        }
        return index > 0 ? { text: '; ' } : undefined;
      case ShoppingCartItemType.Value:
        if (
          this.shoppingCartValue?.valueDataType === HDataType.LocationOnset ||
          this.shoppingCartValue?.valueDataType ===
            HDataType.LocationOnsetPropagation
        ) {
          return;
        }
        const parentNodeItemid = this.parentNode?.itemId;
        if (
          parentNodeItemid === ShoppingCartEventPropertyType.Laterality ||
          parentNodeItemid === ShoppingCartEventPropertyType.Region ||
          parentNodeItemid ===
            ShoppingCartEventPropertyType.LocationOnsetHeadModel ||
          parentNodeItemid ===
            ShoppingCartEventPropertyType.LocationHeadModel ||
          parentNodeItemid ===
            ShoppingCartEventPropertyType.LocationPropagationHeadModel
        ) {
          return index > 0 ? { text: ' ' } : undefined;
        }
        return index > 0 ? { text: '; ' } : undefined;
      default:
        return;
    }
  }

  getNodes(index = 0): EventsTreeSubitem[] {
    const childrenNodes = this.shoppingCartItems
      .map((item, index) => item.getNodes(index))
      .flat();

    const itemNodes = this.getItemNodes();
    const dividerNode =
      itemNodes.length || childrenNodes.length
        ? this.getDividerNode(Number(index))
        : null;

    const nodes = [...itemNodes, ...childrenNodes];

    if (dividerNode) {
      this.type === ShoppingCartItemType.Tab
        ? nodes.push(dividerNode)
        : nodes.unshift(dividerNode);
    }

    return nodes;
  }

  static deserialize(json: Object | string) {
    return deserialize(ShoppingCartItem, json);
  }

  static deserializeAsList(list: ShoppingCartItem[]): ShoppingCartItem[] {
    return list.map(ShoppingCartItem.deserialize);
  }
}
