export enum TemplateId {
  Categorical = 1,
  DateTime = 2,
  Decimal = 3,
  Integer = 4,
  String = 5,
  HeadModel = 6,
  TagList = 7,
  LateralityList = 8
}
