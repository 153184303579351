import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { debounce } from 'debounce';
import get from 'lodash.get';

export const useHandleAutoSave = <
  TFormValues,
  TFieldType,
  TConfig,
  TSaveHandler extends Function = (value: TFieldType, config: TConfig) => void
>({
  saveHandler
}: {
  saveHandler: TSaveHandler;
}) => {
  const { getValues, errors } = useFormContext<TFormValues>();

  const eventHandler = (valueAccessor?: string, config?: TConfig) =>
    /** We use debounce for 2 reasons:
     * 1. Fix react-hook-form issue with errors (errors object has incorrect values right after validation - it has the previous values)
     * 2. To reduce requests number
     */
    debounce(() => {
      const hasError = valueAccessor
        ? !!get(errors, valueAccessor)
        : !!Object.keys(errors).length;

      if (hasError) {
        return;
      }

      const value = (valueAccessor
        ? getValues(valueAccessor)
        : getValues()) as TFieldType;

      saveHandler(value, config);
    }, 250);

  return {
    eventHandler
  };
};
