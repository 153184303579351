import { PropertyType } from 'enums/PropertyType.enum';
import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class GenericPropertyType {
  @serializable
  propertyTypeId: number = 0;

  @serializable
  name: string = '';

  @serializable
  ageConstraintId: number = 0;

  @serializable
  sortOrder: number = 0;

  @serializable
  isMultiSelect: boolean = false;

  @serializable
  supportsAutoPopulation: boolean = false;

  @serializable
  propertyType: PropertyType = PropertyType.Patient;

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  static deserialize(json: Object | string) {
    return deserialize(GenericPropertyType, json);
  }

  static deserializeAsList(list: GenericPropertyType[]): GenericPropertyType[] {
    return list.map(GenericPropertyType.deserialize);
  }

  static deserializeAsMap(
    list: GenericPropertyType[]
  ): Map<GenericPropertyType['propertyTypeId'], GenericPropertyType> {
    return list.reduce((acc, propertyType) => {
      acc.set(
        propertyType.propertyTypeId,
        GenericPropertyType.deserialize(propertyType)
      );

      return acc;
    }, new Map());
  }
}
