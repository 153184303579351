import { createContext, useContext } from 'react';
import { EventCode } from 'entities/EventCode.entity';

export type Payload = {
  activeEventCodeId?: EventCode['eventCodeId'];
  disabledEventCodeIds?: Array<EventCode['eventCodeId']>;
  title: string;
  onSelect: (eventCodeId: EventCode['eventCodeId']) => void;
};

export type State =
  | ({
      isOpen: true;
    } & Payload)
  | {
      isOpen: false;
      title?: never;
      onSelect?: never;
      activeEventCodeId?: never;
      disabledEventCodeIds?: never;
    };

export const NameSelectionContext = createContext<{
  state: State;
  onOpen: (payload: Payload) => void;
  onClose: () => void;
} | null>(null);

export const Provider = NameSelectionContext.Provider;

export const useNameSelectionContext = () => useContext(NameSelectionContext);
