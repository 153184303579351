export enum Routes {
  Root = '/',
  Login = '/login',
  ExternalLogin = '/external-login',
  // Studies = '/studies',
  Patients = '/patients',
  // PatientsDetails = '/patients/:id',
  PatientOverview = '/patients/:id',
  Study = '/studies/:id',
  StudyFindings = '/studies/:id/findings',
  StudyDetails = '/studies/:id/study-details',
  StudyConclusion = '/studies/:id/conclusion',
  PatientProfile = '/patients/:id/profile',
  PatientHistory = '/patients/:id/history'
}
