import { ConditionType } from 'enums/ConditionType.enum';
import { HDataType } from 'enums/HDataType.enum';
import { makeObservable, observable } from 'mobx';
import { deserialize, serializable } from 'serializr';
import { isInteger, isValidDate } from 'utils/validators';

import { isFloat } from '../utils/validators';

export class Condition {
  @observable
  @serializable
  columnName: string = '';

  @observable
  @serializable
  dataType: HDataType = HDataType.String;

  @observable
  @serializable
  value: string = '';

  @observable
  @serializable
  conditionType: ConditionType = ConditionType.Equals;

  constructor() {
    makeObservable(this);
  }

  get isValid() {
    switch (this.dataType) {
      case HDataType.String:
        return this.value !== undefined && this.value !== '';

      case HDataType.Decimal:
        return isFloat(this.value);

      case HDataType.Integer:
        return isInteger(this.value);

      case HDataType.DateTime:
        return isValidDate(this.value, 'yyyy-MM-dd HH:mm');

      case HDataType.Date:
        return isValidDate(this.value);

      case HDataType.Boolean:
        return this.value !== undefined && typeof this.value === 'boolean';

      default:
        return false;
    }
  }

  static deserialize(json: Object | string) {
    return deserialize(Condition, json);
  }

  static deserializeAsList(list: Condition[]): Condition[] {
    return list.map(Condition.deserialize);
  }
}
