import { useHotkeys } from 'react-hotkeys-hook';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { createShortcutCombination } from 'services/keyboardShortcuts/helpers';
import { shortcutsBaseKeys } from 'services/keyboardShortcuts/shortcutsBaseKeys';

export const useHotKeysPushToPath = (key: 1 | 2 | 3, path: string) => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  return useHotkeys(
    createShortcutCombination({
      baseKey: shortcutsBaseKeys.CMD,
      key
    }),
    (event) => {
      event.preventDefault();

      push(generatePath(path, { id }));
    }
  );
};
