import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { useParams } from 'react-router-dom';
import {
  FormInput,
  InputContainer,
  rebuildTooltips,
  useCustomTranslation
} from '@holberg/ui-kit';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { PatientPropertyTypes } from 'enums/PatientPropertyType.enum';
import { PropertyType } from 'enums/PropertyType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHandleAutoSave } from 'hooks/useHandleAutoSave';
import { observer } from 'mobx-react-lite';
import { CommonPropertyType } from 'stores/property-type-codes';

import { FormRowSection } from '../FormRowSection';
import { FormTreeSelectRow } from '../FormTreeSelectRow';

import styles from './StudyReferralInfoForm.module.scss';

interface Props {
  readOnly: boolean;
}

export const StudyReferralInfoForm: React.FC<Props> = observer(
  ({ readOnly }) => {
    const { id: descriptionId } = useParams<{ id: string }>();
    const { t } = useCustomTranslation();
    const { register } = useFormContext<{
      patientDetails: PatientDetails;
      studyCodings: Record<number, CategoricalPropertyCoding[]>;
      patientCodings: Record<number, CategoricalPropertyCoding[]>;
    }>();

    const patientDetailsStore = useStore(StoreType.PatientDetails);
    const descriptionsStore = useStore(StoreType.Descriptions);
    const descriptionDetails = descriptionsStore.descriptionById(
      Number(descriptionId)
    )!;
    const patientId = String(descriptionDetails?.patientId);
    const patientPropertyTypesStore = useStore(StoreType.PatientPropertyTypes);
    const descriptionPropertyTypesStore = useStore(
      StoreType.DescriptionPropertyTypes
    );

    const descriptionPropertyTypes = descriptionPropertyTypesStore.getPropertyTypes(
      descriptionDetails?.ageConstraints
    );
    const patientPropertyTypes = patientPropertyTypesStore.getPropertyTypes(
      descriptionDetails?.ageConstraints
    );

    useEffect(() => {
      rebuildTooltips();
    }, []);

    const onPatientSelectSaveAction = useCallback<
      (
        propertyType: CommonPropertyType,
        newPropertyCodeId?: string,
        oldPropertyCodeId?: string
      ) => void
    >(
      (propertyType, newPropertyCodeId, oldPropertyCodeId) => {
        patientDetailsStore.updatePropertyCodings({
          patientId,
          newPropertyCodeId,
          propertyTypeId: propertyType,
          oldPropertyCodeId,
          descriptionId: descriptionDetails.descriptionId
        });
      },
      [patientDetailsStore, patientId, descriptionDetails.descriptionId]
    );

    const onPatientNoteSaveAction = useCallback<
      (
        propertyType: CommonPropertyType,
        freeText: string,
        currentPropertyCodeId: string
      ) => void
    >(
      (propertyType, freeText, currentPropertyCodeId) => {
        patientDetailsStore.updatePropertyCodingsNote({
          patientId,
          currentPropertyCodeId,
          propertyTypeId: propertyType,
          freeText,
          descriptionId: descriptionDetails.descriptionId
        });
      },
      [patientDetailsStore, patientId, descriptionDetails.descriptionId]
    );

    const onDescriptionSelectSaveAction = useCallback<
      (
        propertyType: CommonPropertyType,
        newPropertyCodeId?: string,
        oldPropertyCodeId?: string
      ) => void
    >(
      (propertyType, newPropertyCodeId, oldPropertyCodeId) => {
        descriptionsStore.updatePropertyCodings({
          newPropertyCodeId,
          propertyTypeId: propertyType,
          oldPropertyCodeId,
          descriptionId: descriptionDetails.descriptionId
        });
      },
      [descriptionDetails.descriptionId, descriptionsStore]
    );

    const onDescriptionNoteSaveAction = useCallback<
      (
        propertyType: CommonPropertyType,
        freeText: string,
        currentPropertyCodeId: string
      ) => void
    >(
      (propertyType, freeText, currentPropertyCodeId) => {
        descriptionsStore.updatePropertyCodingsNote({
          currentPropertyCodeId,
          propertyTypeId: propertyType,
          freeText,
          descriptionId: descriptionDetails.descriptionId
        });
      },
      [descriptionDetails.descriptionId, descriptionsStore]
    );

    const { eventHandler } = useHandleAutoSave<
      {
        patientDetails: PatientDetails;
        patientCodings: Record<number, CategoricalPropertyCoding[]>;
      },
      string,
      { propertyName: string }
    >({
      saveHandler: (value, config) => {
        patientDetailsStore.updatePatientDetails(
          value,
          patientId,
          config.propertyName
        );
      }
    });

    return (
      <>
        <InputContainer required label={t('Indication for EEG')}>
          <FormRowSection
            readOnly={readOnly}
            component={FormTreeSelectRow}
            includeCode={false}
            formAccessorName='studyCodings'
            onSelectSaveAction={onDescriptionSelectSaveAction}
            onNoteSaveAction={onDescriptionNoteSaveAction}
            categoricalPropertyType={PropertyType.Description}
            propertyType={DescriptionPropertyTypes.IndicationForEEG}
            addButtonTitle={t('Add indication')}
            isMultiSelect={
              descriptionPropertyTypes.get(
                DescriptionPropertyTypes.IndicationForEEG
              )?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('Patient history')}>
          <div className={styles['input-container']}>
            <FormInput
              multiline
              ref={register}
              readOnly={readOnly}
              onBlur={eventHandler('patientDetails.patientHistory', {
                propertyName: 'patientHistory'
              })}
              name='patientDetails.patientHistory'
              placeholder={t('Enter history here')}
            />
          </div>
        </InputContainer>
        <InputContainer label={t('Medication at referral')}>
          <FormRowSection
            readOnly={readOnly}
            component={FormTreeSelectRow}
            formAccessorName='patientCodings'
            onSelectSaveAction={onPatientSelectSaveAction}
            onNoteSaveAction={onPatientNoteSaveAction}
            propertyType={PatientPropertyTypes.Medication}
            addButtonTitle={t('Add medication')}
            isMultiSelect={
              patientPropertyTypes.get(PatientPropertyTypes.Medication)
                ?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('Clinical diagnosis at referral')}>
          <FormRowSection
            readOnly={readOnly}
            component={FormTreeSelectRow}
            formAccessorName='patientCodings'
            onSelectSaveAction={onPatientSelectSaveAction}
            onNoteSaveAction={onPatientNoteSaveAction}
            propertyType={PatientPropertyTypes.ClinicalDiagnosis}
            addButtonTitle={t('Add diagnosis')}
            isMultiSelect={
              patientPropertyTypes.get(PatientPropertyTypes.ClinicalDiagnosis)
                ?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('Episode frequency')}>
          <FormRowSection
            readOnly={readOnly}
            formAccessorName='patientCodings'
            onSelectSaveAction={onPatientSelectSaveAction}
            onNoteSaveAction={onPatientNoteSaveAction}
            propertyType={PatientPropertyTypes.EpisodeFrequency}
            addButtonTitle={t('Add episode frequency')}
            isMultiSelect={
              patientPropertyTypes.get(PatientPropertyTypes.EpisodeFrequency)
                ?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('Last episode')}>
          <FormRowSection
            readOnly={readOnly}
            formAccessorName='patientCodings'
            onSelectSaveAction={onPatientSelectSaveAction}
            onNoteSaveAction={onPatientNoteSaveAction}
            propertyType={PatientPropertyTypes.LastEpisode}
            addButtonTitle={t('Add last episode')}
            isMultiSelect={
              patientPropertyTypes.get(PatientPropertyTypes.LastEpisode)
                ?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('Brain MRI/CT')}>
          <FormRowSection
            readOnly={readOnly}
            formAccessorName='patientCodings'
            onSelectSaveAction={onPatientSelectSaveAction}
            onNoteSaveAction={onPatientNoteSaveAction}
            propertyType={PatientPropertyTypes.BrainMRI}
            addButtonTitle={t('Add brain MRI')}
            isMultiSelect={
              patientPropertyTypes.get(PatientPropertyTypes.BrainMRI)
                ?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer
          label={t('Functional neuroimaging')}
          className={styles['form-row']}
        >
          <FormRowSection
            readOnly={readOnly}
            formAccessorName='patientCodings'
            onSelectSaveAction={onPatientSelectSaveAction}
            onNoteSaveAction={onPatientNoteSaveAction}
            propertyType={PatientPropertyTypes.FunctionalNeuroimaging}
            addButtonTitle={t('Add neuroimaging')}
            isMultiSelect={
              patientPropertyTypes.get(
                PatientPropertyTypes.FunctionalNeuroimaging
              )?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('Scalp defects')}>
          <div className={styles['input-container']}>
            <FormInput
              multiline
              ref={register}
              readOnly={readOnly}
              onBlur={eventHandler('patientDetails.scalpDefects', {
                propertyName: 'scalpDefects'
              })}
              name='patientDetails.scalpDefects'
              placeholder={t('Enter value')}
            />
          </div>
        </InputContainer>
      </>
    );
  }
);
