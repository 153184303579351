import React, { useEffect, useRef } from 'react';
import {
  FormInput,
  InputContainer,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import { GestationalAgeRow } from '../GestationalAgeRow';

import styles from './NeonatalInfoForm.module.scss';

interface Props {
  onPropertyBlur?: (accessor: string, propertyName: string) => void;
  patientId?: string;
  descriptionId?: number;
  readOnly?: boolean;
}

export const NeonatalInfoForm: React.FC<Props> = observer(
  ({ onPropertyBlur = () => {}, descriptionId, readOnly = false }) => {
    const { t } = useCustomTranslation();
    const descriptionDetailsStore = useStore(StoreType.Descriptions);
    const patientDetailsStore = useStore(StoreType.PatientDetails);
    const isFirstRenderDone = useRef(false);

    const descriptionDetails = descriptionDetailsStore.descriptionById(
      descriptionId
    );
    let patientDetails: PatientDetails | undefined;

    if (descriptionDetails) {
      patientDetails = patientDetailsStore.patientById(
        descriptionDetails!.patientId.toString()
      );
    }

    useEffect(() => {
      if (descriptionId && isFirstRenderDone.current) {
        descriptionDetailsStore.loadDescriptionDetails(descriptionId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      patientDetails?.formattedCorrectedAge,
      patientDetails?.formattedPostMenstrualAge
    ]);

    useEffect(() => {
      isFirstRenderDone.current = true;
    }, []);

    return (
      <>
        <InputContainer label={t('Gestational age')}>
          <div
            data-testid='unit-row'
            className={cn(styles['unit-row'], readOnly && styles['read-only'])}
          >
            <GestationalAgeRow
              readOnly={readOnly}
              onPropertyBlur={onPropertyBlur}
            />
          </div>
        </InputContainer>
        <InputContainer label={t('Corrected age')}>
          <div className={styles['input-container']}>
            <FormInput
              readOnly
              value={descriptionDetails?.correctedAgeAtStudyTime}
            />
          </div>
        </InputContainer>
        <InputContainer label={t('Postmenstrual age')}>
          <div className={styles['input-container']}>
            <FormInput
              readOnly
              value={descriptionDetails?.postMenstrualAgeAtStudyTime}
            />
          </div>
        </InputContainer>
      </>
    );
  }
);
