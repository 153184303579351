import { deserialize, list, object, serializable } from 'serializr';

import { EventPropertyType } from './EventPropertyType.entity';
import { Operator } from './Operator.entity';

export class EventPropertyTypeNonCategorical {
  @serializable(object(EventPropertyType))
  propertyType: EventPropertyType = new EventPropertyType();

  @serializable(list(object(Operator)))
  operators: Operator[] = [];

  @serializable
  defaultOperatorId: number = 0;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyTypeNonCategorical, json);
  }
}
