import { ScrollDirectionType } from 'enums/ScrollDirection.enum';

interface ScrollConfig {
  direction: ScrollDirectionType;
  distance?: number;
  step?: number;
}

export const onTabsScroll = (
  { direction, distance = 100, step = 15 }: ScrollConfig,
  element: HTMLDivElement | null
) => {
  if (element) {
    let scrollAmount = 0;
    let animationId: number;

    const slide = () => {
      if (direction === ScrollDirectionType.Left) {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;

      if (scrollAmount <= distance) {
        animationId = requestAnimationFrame(slide);
      } else {
        cancelAnimationFrame(animationId);
      }
    };

    requestAnimationFrame(slide);
  }
};
