import { matchPath, useLocation } from 'react-router-dom';
import { Routes } from 'enums/Routes.enum';

export const usePageMatch = (route: Routes) => {
  const { pathname } = useLocation<{ pathname: string }>();

  return matchPath(pathname, {
    path: route,
    exact: false,
    strict: false
  });
};
