import { useDebouncedValue } from '@holberg/ui-kit';

import { usePrevious } from './usePrevious';

export const useDelayedLoading = (currentLoading: boolean, delay: number) => {
  const debouncedLoading = useDebouncedValue(currentLoading, delay);

  const previousLoading = usePrevious(currentLoading);

  if (previousLoading && !currentLoading) {
    return currentLoading;
  }

  return debouncedLoading;
};
