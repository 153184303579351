import React, { FC, useCallback, useReducer } from 'react';

import { Payload, Provider, State } from './FindingsConfirmationContext';

enum ActionType {
  OPEN_MODAL,
  CLOSE_MODAL
}

const initialState: State = {
  isOpen: false
};

const reducer = (
  state: State,
  action: {
    type: ActionType;
    payload?: Payload;
  }
): State => {
  switch (action.type) {
    case ActionType.OPEN_MODAL:
      return {
        isOpen: true,
        ...action.payload!
      };
    case ActionType.CLOSE_MODAL:
      return { isOpen: false };
    default:
      return state;
  }
};

interface Props {
  skipWarnings?: boolean;
}

export const FindingsConfirmationProvider: FC<Props> = ({
  children,
  skipWarnings
}) => {
  const [state, dispatchState] = useReducer(reducer, initialState);

  const onOpen = useCallback(
    (payload: Payload) => {
      if (!skipWarnings) {
        dispatchState({
          type: ActionType.OPEN_MODAL,
          payload
        });
      } else {
        payload.onSubmit();
      }
    },
    [skipWarnings]
  );

  const onClose = useCallback(
    () =>
      new Promise((resolve) =>
        resolve(
          dispatchState({
            type: ActionType.CLOSE_MODAL
          })
        )
      ),
    []
  );

  return (
    <Provider
      value={{
        state,
        onOpen,
        onClose
      }}
    >
      {children}
    </Provider>
  );
};
