import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { FindingPropertiesEventTypes } from 'enums/FindingPropertiesEventTypes.enum';

interface PropertiesEvent {
  panelId: EventPropertyPanel['eventPropertyPanelId'];
  eventType: FindingPropertiesEventTypes;
}

interface Observer {
  event: PropertiesEvent;
  update(): void;
}

interface Observable {
  subscribe(observer: Observer): void;
  unsubscribe(observer: Observer): void;
  publish(event: PropertiesEvent): void;
}

class FindingPropertiesObservable implements Observable {
  private observers: Map<
    EventPropertyPanel['eventPropertyPanelId'],
    Set<Observer>
  > = new Map();

  subscribe(observer: Observer) {
    if (!this.observers.has(observer.event.panelId)) {
      this.observers.set(observer.event.panelId, new Set());
    }
    this.observers.get(observer.event.panelId)!.add(observer);
  }

  unsubscribe(observer: Observer) {
    this.observers.get(observer.event.panelId)?.delete(observer);
  }

  publish(event: PropertiesEvent) {
    const observersSet = this.observers.get(event.panelId);

    if (!observersSet) {
      return;
    }

    for (const observer of observersSet) {
      if (event.eventType === observer.event.eventType) {
        observer.update();
      }
    }
  }
}

export const FindingPropertiesPublisher = new FindingPropertiesObservable();
