import { useEffect, useState } from 'react';

import { usePrevious } from './usePrevious';

export const useSetLoadingOnMount = (isLoading: boolean) => {
  const [isLoadingOnMount, setLoadingOnMount] = useState(true);
  const previousIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (!isLoading && previousIsLoading) {
      setLoadingOnMount(false);
    }
  }, [isLoading, previousIsLoading]);

  return {
    isLoadingOnMount
  };
};
