import { computed, makeObservable, observable } from 'mobx';
import { deserialize, serializable } from 'serializr';

export class InformationModel<
  P extends string | number | boolean = string,
  S = P
> {
  @observable
  @serializable
  primaryValue?: P;

  @observable
  @serializable
  secondaryValue?: S;

  constructor() {
    makeObservable(this);
  }

  @computed
  get eitherValue() {
    return String(this.primaryValue || this.secondaryValue || '');
  }

  static deserialize<P extends string | number | boolean = string, S = P>(
    json: Object | string
  ) {
    return deserialize<InformationModel<P, S>>(InformationModel, json);
  }
}
