import React, { useMemo } from 'react';
import Masonry from 'react-masonry-css';
import { useParams } from 'react-router-dom';
import { PropertyTabsPanel } from 'components/PropertyTabsPanel';
import { EventPropertyPanelFocused } from 'entities/EventPropertyPanelFocused.entity';
import { Tab } from 'entities/Tab';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { usePanelDisabled } from 'hooks/usePanelDisabled';
import { observer } from 'mobx-react-lite';
import { MASONRY_BREAKPOINT_LG, MASONRY_BREAKPOINT_SM } from 'utils/constants';

import styles from './PropertiesTab.module.scss';

interface Props {
  data: Tab;
  focusedProperty: EventPropertyPanelFocused;
  containerWidth?: number;
}

export const tabTestId = 'properties-tab';
export const columnClassName = 'column';

const PropertiesTabInner: React.FC<{
  data: Tab;
  breakpointCols: number;
  focusedProperty: EventPropertyPanelFocused;
}> = observer(({ data, breakpointCols, focusedProperty }) => {
  const { id } = useParams<{ id: string }>();

  const findingPropertiesStore = useStore(StoreType.FindingProperties);
  const descriptionDetailsStore = useStore(StoreType.Descriptions);
  const descriptionDetails = descriptionDetailsStore.descriptionById(
    parseInt(id)
  )!;
  const ageConstraint = descriptionDetails.ageConstraints;

  const panels = data.panelIds
    .map((panelId) => {
      const panel = findingPropertiesStore.getPanel(ageConstraint, panelId);

      if (panel) panel.isDisabled = usePanelDisabled(panel);

      return panel;
    })
    .sort((prev, next) => Number(prev?.sortOrder) - Number(next?.sortOrder))
    .sort((prev, next) => Number(prev?.isDisabled) - Number(next?.isDisabled));

  return (
    <div className={styles['properties-tab']} data-testid={tabTestId}>
      <h2 className={styles.title}>{data.translatedName.eitherValue}</h2>
      <Masonry
        breakpointCols={breakpointCols}
        className={styles.masonry}
        columnClassName={styles[columnClassName]}
      >
        {panels.map(
          (panel, id) =>
            panel && (
              <PropertyTabsPanel
                key={panel.eventPropertyPanelId}
                panel={panel}
                ageConstraint={ageConstraint}
                focusedProperty={
                  panel.isDisabled
                    ? {}
                    : {
                        ...focusedProperty,
                        panelPositionNumber: id
                      }
                }
              />
            )
        )}
      </Masonry>
    </div>
  );
});

export const PropertiesTab: React.FC<Props> = ({
  data,
  focusedProperty,
  containerWidth
}) => {
  const breakpointCols = useMemo(() => {
    if (containerWidth) {
      switch (true) {
        case containerWidth < MASONRY_BREAKPOINT_SM:
          return 1;
        case containerWidth >= MASONRY_BREAKPOINT_SM &&
          containerWidth! < MASONRY_BREAKPOINT_LG:
          return 2;
        case containerWidth >= MASONRY_BREAKPOINT_LG:
          return 3;
        default:
          return 1;
      }
    } else {
      return 1;
    }
  }, [containerWidth]);

  return (
    <PropertiesTabInner
      breakpointCols={breakpointCols}
      data={data}
      focusedProperty={focusedProperty}
    />
  );
};
