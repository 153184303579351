import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import {
  IconType,
  Modal,
  ShoppingCart,
  useCustomTranslation
} from '@holberg/ui-kit';
import classNames from 'classnames';
import { EventsTree } from 'components/EventsTree';
import { reorderEventTreeData } from 'components/EventsTree/helpers';
import { ExamplesList } from 'components/ExamplesList';
import {
  ConfirmationTitle,
  useConfirmationContext
} from 'components/FindingsConfirmationModal';
import { useNameSelectionContext } from 'components/NameSelectionModal/NameSelectionContext';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { debounce } from 'debounce';
import { Description } from 'entities/Description.entity';
import { Event } from 'entities/Event.entity';
import { EventCode } from 'entities/EventCode.entity';
import { Study } from 'entities/Study.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { EventTreeSettingsType } from 'enums/EventTreeSettingsType.enum';
import { PropertyCodeTagType } from 'enums/PropertyCodeTagType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { EventCodesTreeItem } from 'stores/findings';

import { Folder } from '../FindingFolder';
import { FindingHeader } from '../FindingHeader';
import { FindingReorderSpace } from './FindingReorderSpace';

import findingsStyles from '../Findings/Findings.module.scss';
import styles from './Finding.module.scss';

interface Props {
  itemIndex?: string;
  headerClassName?: string;
  parentCodingId?: number;
  data: EventCodesTreeItem;
  findingsList?: EventCodesTreeItem[];
  reorderItems?: (getItemIndex: (index: number) => number) => void;
  isMoveUpDisabled?: boolean;
  isMoveDownDisabled?: boolean;
  readOnly: boolean;
  isMoveUpHidden?: boolean;
  isMoveDownHidden?: boolean;
  activeDescriptionId: Description['descriptionId'];
  studyId: Study['studyId'];
  isSimultaneous?: boolean;
  index: number;
}

export const Finding: React.FC<Props> = observer(
  ({
    data,
    findingsList = [],
    itemIndex,
    headerClassName,
    readOnly,
    isMoveUpDisabled,
    isMoveDownDisabled,
    isMoveUpHidden,
    isMoveDownHidden,
    activeDescriptionId,
    studyId,
    parentCodingId,
    isSimultaneous,
    index
  }) => {
    const {
      onFindingClick,
      activeCodingId,
      onCloseProperties
    } = usePropertiesContext();
    const { t } = useCustomTranslation();
    const { item, children, eventCoding } = data;
    const isToBeDefined = item.isToBeDefinedNode;

    const findingsStore = useStore(StoreType.Findings);
    const findingPropertiesStore = useStore(StoreType.FindingProperties);
    const headModelsStore = useStore(StoreType.HeadModel);
    const rtuStore = useStore(StoreType.RealTimeUpdates);
    const descriptionsStore = useStore(StoreType.Descriptions);

    const nameSelectionContext = useNameSelectionContext();
    const confirmationContext = useConfirmationContext();

    const [isAbnormalModalVisible, setIsAbnormalModalVisible] = useState(false);

    const handleVisible = useCallback(() => {
      setIsAbnormalModalVisible((prevState) => !prevState);
    }, []);

    const isReviewSessionActive =
      rtuStore.realTimeUpdatesConfig.isReaderAdminConnected;

    const events: Event[] = findingsStore.eventsForEventCoding(
      activeDescriptionId,
      eventCoding?.eventCodingId
    );

    const isLinked = !!events.length;

    const activeEventCodeId = useMemo(() => {
      const codeId = findingsStore.getVisibleParentEventCode(item.eventCodeId);
      return codeId === null ? undefined : codeId;
    }, [item.eventCodeId, findingsStore]);

    const reorderItems = useCallback(
      (getNewPositionIndex: (index: number) => number) => {
        const sortedList = reorderEventTreeData<EventCodesTreeItem>(
          findingsList,
          data,
          getNewPositionIndex
        ).map((item, index) => ({
          eventCodingId: item.eventCoding!.eventCodingId,
          sortOrder: index + 1
        }));
        findingsStore.updateEventCodingsSortOrder({
          data: sortedList,
          descriptionId: activeDescriptionId
        });
      },
      [activeDescriptionId, data, findingsList, findingsStore]
    );

    const moveUp = useCallback(() => {
      reorderItems((itemIndex: number) => itemIndex - 1);
    }, [reorderItems]);

    const moveDown = useCallback(() => {
      reorderItems((itemIndex: number) => itemIndex + 1);
    }, [reorderItems]);

    const deleteEventCodings = useCallback(() => {
      eventCoding &&
        findingsStore.deleteEventCodings(activeDescriptionId, [
          eventCoding!.eventCodingId
        ]);

      onCloseProperties();
    }, [eventCoding, activeDescriptionId, findingsStore, onCloseProperties]);

    const removeFinding = useCallback(() => {
      if (
        data.eventCoding?.isAbnormal &&
        findingsStore.abnormalEventCodings(activeDescriptionId).length === 1 &&
        descriptionsStore.hasCodeWithRequiredTag({
          descriptionId: activeDescriptionId,
          propertyTypeId: DescriptionPropertyTypes.DiagnosticSignificance,
          requiredTagType: PropertyCodeTagType.Abnormal
        })
      ) {
        handleVisible();
        return;
      }

      if (isLinked || !isToBeDefined) {
        confirmationContext?.onOpen({
          title: t(
            isToBeDefined
              ? ConfirmationTitle.Unclassify_Linked
              : ConfirmationTitle.Unclassify_Classified
          ),
          submitButtonTitle: t('Yes, unclassify'),
          onSubmit: deleteEventCodings
        });
      } else {
        deleteEventCodings();
      }
    }, [
      data.eventCoding?.isAbnormal,
      findingsStore,
      activeDescriptionId,
      descriptionsStore,
      isLinked,
      isToBeDefined,
      handleVisible,
      confirmationContext,
      t,
      deleteEventCodings
    ]);

    const updateEventCodings = useCallback(
      async (eventCodeId: EventCode['eventCodeId']) => {
        const eventCodings = await findingsStore.updateEventCodings({
          data: [
            {
              eventCodingId: eventCoding!.eventCodingId,
              parentEventCodingId: parentCodingId
            }
          ],
          newEventCodeId: eventCodeId,
          descriptionId: activeDescriptionId,
          identifier: `finding-classify-${eventCoding!.eventCodingId}`
        });
        const activeEventCoding = eventCodings.find(
          (eventCoding) => eventCoding.eventCodeId === eventCodeId
        );
        if (!findingsStore.isToBeDefined(eventCodeId)) {
          onFindingClick(activeEventCoding);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [activeDescriptionId, eventCoding, findingsStore, parentCodingId]
    );

    const onClassifyFinding = useCallback(
      async (eventCodeId: EventCode['eventCodeId']) => {
        if (
          findingsStore.hasActiveOnlyOneEventCoding(
            activeDescriptionId,
            eventCodeId
          )
        ) {
          await confirmationContext?.onClose();

          confirmationContext?.onOpen({
            title: t(ConfirmationTitle.Add_Second_OnlyOneEventCoding),
            submitButtonTitle: t('Yes, sure'),
            onSubmit: () => updateEventCodings(eventCodeId)
          });
        } else {
          updateEventCodings(eventCodeId);
        }

        nameSelectionContext?.onClose();
      },
      [
        activeDescriptionId,
        confirmationContext,
        findingsStore,
        nameSelectionContext,
        t,
        updateEventCodings
      ]
    );

    const onOpenNameSelectionMenu = () => {
      nameSelectionContext?.onOpen({
        activeEventCodeId:
          isToBeDefined && data.item.eventCodeId
            ? data.item.eventCodeId
            : activeEventCodeId,
        title: isToBeDefined ? t('Classify') : t('Reclassify'),
        disabledEventCodeIds: [data.item.eventCodeId],
        onSelect: (eventCodeId) => {
          if (!isToBeDefined) {
            confirmationContext?.onOpen({
              title: t(ConfirmationTitle.Reclassify),
              submitButtonTitle: t('Yes, reclassify'),
              onSubmit: () => onClassifyFinding(eventCodeId)
            });
          } else {
            onClassifyFinding(eventCodeId);
          }
        }
      });
    };

    const onUndoSimultaneous = useCallback(() => {
      findingsStore.undoSimultaneous(activeDescriptionId, [data.eventCoding!]);
    }, [activeDescriptionId, data.eventCoding, findingsStore]);

    const onExamplesMoveTo = async () => {
      await findingsStore.updateEvents({
        descriptionId: activeDescriptionId,
        eventIds: findingsStore.selectionState.draggedExample
          ? [findingsStore.selectionState.draggedExample.eventId]
          : findingsStore.selectionState.selectedExamples.map(
              (example) => example.eventId
            ),
        eventCodingId: eventCoding!.eventCodingId
      });
      findingsStore.eventTreeState.updateEventTreeSettingsConfig({
        studyId: studyId,
        entityId: eventCoding!.eventCodingId,
        settingKey: EventTreeSettingsType.FindingsState,
        expanded: true
      });
      findingsStore.selectionState.discardSelections();
    };

    const mergeEventCodings = async () => {
      await findingsStore.mergeEventCodings({
        descriptionId: activeDescriptionId,
        destinationEventCodingId: eventCoding!.eventCodingId,
        sourceEventCodingIds: findingsStore.selectionState.draggedFinding
          ? [findingsStore.selectionState.draggedFinding?.item.eventCodingId]
          : findingsStore.selectionState.selectedFindingsIds
      });
      findingsStore.selectionState.discardSelections();
    };

    const onFindingsMoveTo = () => {
      const hasClassifiedFinding = findingsStore.selectionState.draggedFinding
        ? !findingsStore.selectionState.draggedFinding?.isToBeDefined
        : findingsStore.selectionState.selectedFindings.some(
            (finding) => !finding.isToBeDefined
          );
      if (hasClassifiedFinding) {
        confirmationContext?.onOpen({
          title: t(ConfirmationTitle.Move),
          submitButtonTitle: t('Yes, move'),
          onSubmit: mergeEventCodings
        });
      } else {
        mergeEventCodings();
      }
    };

    const findingState = findingsStore.selectionState.getFindingState(
      eventCoding!
    );

    const hasCategories = !!children.find(
      (child) =>
        child.item.isFolder || child.item.isExpandableNodeFindingTreeView
    );

    const descriptionStatus = findingsStore.descriptionStatuses.get(
      activeDescriptionId
    );

    const codingStatus = descriptionStatus?.getEventCodingStatus({
      eventCodingId: data.eventCoding?.eventCodingId!
    });

    const entityId = eventCoding?.eventCodingId || item.eventCodeId;
    const findingsState = findingsStore.eventTreeState.getEventTreeEntityStateById(
      studyId,
      EventTreeSettingsType.FindingsState
    );
    const isActive = findingsState ? findingsState[entityId] : false;

    const [{ canDrop, isOver, draggedItem }, drop] = useDrop(
      () => ({
        accept: [DraggableTypes.example, DraggableTypes.finding],
        drop: (item: any, monitor) => {
          if (monitor.didDrop() || isSimultaneous) {
            return;
          }
          if (
            monitor.getItemType() === DraggableTypes.example &&
            item.eventCodingId !== eventCoding?.eventCodingId
          ) {
            //to stop dropping in same findings container
            onExamplesMoveTo();
          } else if (
            monitor.getItemType() === DraggableTypes.finding &&
            item.id !== eventCoding?.eventCodingId &&
            !findingsStore.selectionState.selectedFindings.some(
              (finding) =>
                finding.item.eventCodingId === eventCoding!.eventCodingId
            )
          ) {
            //to stop dropping in same or one of the selected findings container
            onFindingsMoveTo();
          } else {
            findingsStore.selectionState.discardSelections();
          }
        },
        collect: (monitor) => ({
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
          draggedItem: monitor.getItem()
        })
      }),
      [readOnly, isSimultaneous]
    );

    useEffect(() => {
      if (readOnly) {
        onCloseProperties();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readOnly]);

    useEffect(() => {
      //expand finding when hovered by dragged item after a second
      let expandTimeout: ReturnType<typeof setTimeout>;
      if (isOver) {
        expandTimeout = setTimeout(() => {
          if (
            isOver &&
            !isActive &&
            draggedItem.id !== eventCoding?.eventCodingId &&
            !findingsStore.selectionState.selectedFindingsIds.includes(
              eventCoding!.eventCodingId
            )
          ) {
            findingsStore.eventTreeState.updateEventTreeSettingsConfig({
              studyId: studyId,
              entityId: eventCoding!.eventCodingId,
              settingKey: EventTreeSettingsType.FindingsState,
              expanded: true
            });
          }
        }, 1100);
      }
      return () => {
        if (expandTimeout !== undefined) {
          clearTimeout(expandTimeout);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOver]);

    const headerProps: Omit<
      React.ComponentProps<typeof FindingHeader>,
      'arrow'
    > = {
      studyId: studyId,
      index: index,
      eventCodingId: data.eventCoding!.eventCodingId,
      parentFolderId: item.parentFolderId,
      readOnly: readOnly,
      isActive,
      isMoveUpDisabled,
      isSimultaneous,
      isMoveDownDisabled,
      onMoveUp: moveUp,
      onMoveDown: moveDown,
      itemIndex,
      isToBeDefined,
      isMoveUpHidden,
      isMoveDownHidden,
      className: headerClassName,
      onUndoSimultaneous,
      label: item.translatedLongName.eitherValue,
      codingStatus,
      hasChildren: !!children.length,
      onRemoveItem: debounce(removeFinding, 250, true),
      headModel: eventCoding?.eventCodingId
        ? headModelsStore.getHeadModels(eventCoding.eventCodingId, true).onset
            ?.headModelSvg
        : undefined,
      isHighlighted: activeCodingId === data.eventCoding?.eventCodingId,
      isSelected: findingState.selected,
      isCheckboxHidden: findingState.checkboxHidden || readOnly,
      isCheckboxDisabled: findingState.checkboxDisabled,
      isLinkDisabled: !isReviewSessionActive,
      isActionsAvailable: findingState.actionsAvailable && !readOnly,
      isMoveToAvailable:
        findingState.moveToAvailable &&
        !readOnly &&
        !(
          parentCodingId &&
          findingsStore.selectionState.getFindingState(
            findingsStore.eventCodings
              .get(activeDescriptionId)!
              .get(parentCodingId)!
          ).selected
        ),
      isLinked,
      isFocused:
        eventCoding &&
        findingsStore.focusedEventCodingId === eventCoding.eventCodingId,
      onToggle: () => {
        if (activeCodingId !== undefined) {
          onCloseProperties();
        }
        findingsStore.selectionState.toggleFinding(
          eventCoding!,
          item.parentFolderId,
          isSimultaneous,
          isToBeDefined,
          isLinked
        );
      },
      onDrag: () => {
        if (activeCodingId !== undefined) {
          onCloseProperties();
        }
        findingsStore.selectionState.setDraggedFinding(
          eventCoding!,
          item.parentFolderId,
          isSimultaneous,
          isToBeDefined,
          isLinked
        );
      },
      onClassify: onOpenNameSelectionMenu,
      onClick: () => {
        if (readOnly) {
          findingsStore.focusEventCodingById(eventCoding?.eventCodingId);
          return;
        }
        if (isToBeDefined) {
          onOpenNameSelectionMenu();
        } else if (
          eventCoding &&
          findingsStore.selectionState.selectionSize === 0
        ) {
          onFindingClick(eventCoding);
          findingPropertiesStore.setPropertyIndexStructure([]);
        }
      },
      onLinkClick: () => {
        if (!isActive) {
          onToggleFolder();
        }

        if (!isExampleFolderActive) {
          onToggleExamplesFolder();
        }

        rtuStore.activateEvent(
          events[0]?.studyId,
          activeDescriptionId,
          events[0]?.eventId
        );
      },
      onMoveTo: () => {
        if (findingsStore.selectionState.selectedExamples.length) {
          onExamplesMoveTo();
        } else {
          onFindingsMoveTo();
        }
      },
      hasScreenshot: events.some((event) =>
        findingsStore.getScreenshot(event.eventId)
      ),
      onScreenshotClick: () => {
        for (const event of events) {
          if (findingsStore.getScreenshot(event.eventId)) {
            findingsStore.openScreenshotModal(event.eventId);
            return;
          }
        }
      }
    };

    const examplesState = findingsStore.eventTreeState.getEventTreeEntityStateById(
      studyId,
      EventTreeSettingsType.ExamplesState
    );

    const isExampleFolderActive = examplesState
      ? examplesState[entityId]
      : false;

    const onToggleFolder = useCallback(() => {
      findingsStore.eventTreeState.updateEventTreeSettingsConfig({
        studyId,
        entityId,
        settingKey: EventTreeSettingsType.FindingsState,
        expanded: !isActive
      });
    }, [findingsStore.eventTreeState, studyId, entityId, isActive]);

    const onToggleExamplesFolder = useCallback(() => {
      findingsStore.eventTreeState.updateEventTreeSettingsConfig({
        studyId,
        entityId,
        settingKey: EventTreeSettingsType.ExamplesState,
        expanded: !isExampleFolderActive
      });
    }, [
      findingsStore.eventTreeState,
      studyId,
      entityId,
      isExampleFolderActive
    ]);

    const shoppingCartNodes =
      eventCoding &&
      findingsStore.shoppingCart
        .get(activeDescriptionId)
        ?.get(eventCoding?.eventCodingId)?.shoppingCartNodes;
    const hasContent =
      shoppingCartNodes?.length || events.length || hasCategories;

    const showFindingReorderSpace =
      (findingsStore.selectionState.selectedFindings.length === 1 ||
        findingsStore.selectionState.draggedFinding) &&
      draggedItem?.parentFolder === data.item.parentFolderId &&
      !(draggedItem?.index === index);

    return (
      <>
        {showFindingReorderSpace && index === 0 ? (
          //This component is shown on the top of first finding inside a category, when one of the findings inside same category is dragged.
          <FindingReorderSpace
            findingsList={findingsList}
            dropIndex={index}
            onReorder={reorderItems}
          />
        ) : (
          <></>
        )}
        <div
          ref={drop}
          className={classNames(
            styles.finding,
            isOver && canDrop && !isSimultaneous
              ? findingsStyles['on-dropover']
              : ''
          )}
        >
          <Folder<React.ComponentProps<typeof FindingHeader>>
            header={FindingHeader}
            headerProps={headerProps}
            onToggleFolder={onToggleFolder}
            toggleDisabled={!hasContent}
            contentClassName={styles.content}
            isActive={hasContent ? isActive : false}
          >
            <div>
              {shoppingCartNodes && (
                <ShoppingCart
                  nodes={shoppingCartNodes}
                  className={styles['shopping-cart']}
                />
              )}
              <ExamplesList
                events={events}
                studyId={studyId}
                readOnly={readOnly}
                activeEventCodeId={activeEventCodeId}
                currentEventCodeId={data.item.eventCodeId}
                descriptionId={activeDescriptionId}
                entityId={entityId}
                isCheckboxHidden={headerProps.isCheckboxHidden}
                thisListIsUnderTbdFinding={isToBeDefined}
              />
            </div>
            <EventsTree
              readOnly={readOnly}
              eventTreeData={children}
              parentCodingId={parentCodingId || eventCoding?.eventCodingId}
            />
          </Folder>
          <Modal
            visible={isAbnormalModalVisible}
            className={styles.modal}
            headerClassName={styles.header}
            iconClassName={styles.warning}
            handleVisible={handleVisible}
            icon={IconType.WarningFilled}
            title={t(
              "If you want to delete the last abnormal finding - please remove the 'Abnormal recording' selection from the  Diagnostic Significance section in the Conclusion tab"
            )}
          />
        </div>
        {showFindingReorderSpace && !(draggedItem?.index - 1 === index) ? (
          //This component is shown below each finding inside a category, when one of the findings inside same category is dragged.
          <FindingReorderSpace
            findingsList={findingsList}
            dropIndex={index + 1}
            onReorder={reorderItems}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
);
