import { useParams } from 'react-router-dom';
import { Modal, useCustomTranslation } from '@holberg/ui-kit';
import cn from 'classnames';
import { CopyReportActions } from 'components/CopyReportActions';
import { ReportSection } from 'components/ReportSection';
import { useReportData } from 'components/ReportSection/useReportData';
import { StudyStatus } from 'enums/StudyStatus.enum';

import styles from './ReportPreviewModal.module.scss';

export const REPORT_PREVIW_MODAL = 'report-preview-modal';
interface Props {
  visible: boolean;
  onVisibilityChange: () => void;
  descriptionStatus?: StudyStatus;
}

export const ReportPreviewModal: React.FC<Props> = ({
  visible,
  onVisibilityChange,
  descriptionStatus
}) => {
  const { t } = useCustomTranslation();
  const { id } = useParams<{ id: string }>();

  const reportData = useReportData(parseInt(id));

  return (
    <Modal
      className={cn(styles.modal, styles['report-preview-modal'])}
      contentClassName={styles.content}
      visible={visible}
      handleVisible={onVisibilityChange}
      title={t('Report preview')}
      data-testid={REPORT_PREVIW_MODAL}
    >
      <div className={styles.wrap}>
        <ReportSection reportData={reportData} />
      </div>
      {descriptionStatus === StudyStatus.Completed && (
        <div className={styles['report-copy-actions']}>
          <CopyReportActions />
        </div>
      )}
    </Modal>
  );
};
