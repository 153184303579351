import { LogicalOperatorType } from 'enums/LogicalOperatorType.enum';
import {
  createModelSchema,
  deserialize,
  list,
  object,
  primitive
} from 'serializr';

import { Condition } from './Condition.entity';

export class FilterCriteria {
  conditions: Condition[] = [];

  nestedConditions?: FilterCriteria[] = [];
  operator: LogicalOperatorType = LogicalOperatorType.And;

  static deserialize(json: Object | string) {
    return deserialize(FilterCriteria, json);
  }
}

createModelSchema(FilterCriteria, {
  conditions: list(object(Condition)),
  operator: primitive(),
  nestedConditions: list(object(Condition))
});
