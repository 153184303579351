import { shortcutsBaseKeys } from './shortcutsBaseKeys';

export type ShortcutsKeys =
  | 'a'
  | 'z'
  | 'f'
  | 'up'
  | 'down'
  | 'esc'
  | 'left'
  | 'right'
  | 'tab'
  | 'enter'
  | '\\'
  | ']'
  | '['
  | ';'
  | 1
  | 2
  | 3
  | '';

export const createShortcutCombination = ({
  baseKey,
  key = ''
}: {
  baseKey?: typeof shortcutsBaseKeys[keyof typeof shortcutsBaseKeys];
  key?: ShortcutsKeys;
}) => {
  if (baseKey && key) {
    return typeof key === 'number'
      ? `${baseKey}+${key}, ${baseKey}+num_${key}`
      : `${baseKey}+${key}`;
  }
  if (baseKey) {
    return baseKey;
  } else {
    return typeof key === 'number' ? `${key}, num_${key}` : `${key}`;
  }
};
