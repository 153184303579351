export enum HDataType {
  Categorical = 1,
  DateTime = 2,
  Decimal = 3,
  Integer = 4,
  String = 5,
  LocationOnset = 6,
  LocationOnsetPropagation = 7,
  Boolean = 8,
  Date = 9
}
