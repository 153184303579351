import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class EventCode {
  @serializable
  eventCodeId: number = 0;

  @serializable
  sortOrder: number = 0;

  @serializable
  isFolder: boolean = false;

  @serializable
  isExpandableNodeFindingTreeView: boolean = false;

  @serializable
  isExpandableNodeSelectionTreeView: boolean = false;

  @serializable
  parentId: number | null = null;

  @serializable
  nestedEventCodeId?: number;

  @serializable
  rootCodeId?: number;

  @serializable
  showInSelectionTreeView?: boolean;

  @serializable
  showInFindingTreeView?: boolean;

  @serializable
  isActive?: boolean;

  @serializable
  ageConstraintId: number = 0;

  @serializable
  abnormalityCodeId: number = 0;

  @serializable
  definesAbnormalityCodeId: number = 0;

  @serializable
  isSelectable: boolean = false;

  @serializable
  isDependentOnEventCoding: boolean = false;

  @serializable
  parentFolderId: number = 0;

  @serializable
  namingRuleRootCodeId?: number;

  @serializable
  onlyOneEventCoding: boolean = false;

  @serializable
  canHaveSimultaneousFindings?: boolean;

  @serializable(object(InformationModel))
  sortingFormat: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  definition: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  translatedLongName: InformationModel = new InformationModel();

  @serializable
  hasLocation: boolean = false;

  @serializable
  hasPropagation: boolean = false;

  @serializable
  isToBeDefinedNode: boolean = false;

  getSortingNumber(order: number) {
    return this.sortingFormat.eitherValue.replace('{0}', `${order}`);
  }

  static deserialize(json: Object | string) {
    return deserialize(EventCode, json);
  }

  static deserializeAsList(list: EventCode[]): EventCode[] {
    return list.map(EventCode.deserialize);
  }

  static deserializeAsMap(
    list: EventCode[]
  ): Map<EventCode['eventCodeId'], EventCode> {
    return list.reduce((acc, eventCode) => {
      acc.set(eventCode.eventCodeId, EventCode.deserialize(eventCode));

      return acc;
    }, new Map());
  }
}
