import React from 'react';
import { CommonHeaderProps } from 'components/CategoryHeader/CategoryHeader';

import styles from './ExampleHeader.module.scss';

export const ExampleHeader: React.FC<CommonHeaderProps> = ({
  arrow,
  label
}) => (
  <div className={styles['example-header']} data-testid='examples-header'>
    <span className={styles.arrow}>{arrow}</span>
    <span className={styles.label}>{label}</span>
  </div>
);
