import { EventCodesTreeItem } from 'stores/findings';

type EventCodesGroup = EventCodesTreeItem[];

export const isMoveUpDisabled = (
  itemIndex: number,
  propertyCodingsAmount: number
) => !propertyCodingsAmount || itemIndex === 0;

export const isMoveDownDisabled = (
  itemIndex: number,
  propertyCodingsAmount: number
) => !propertyCodingsAmount || itemIndex === propertyCodingsAmount - 1;

export const groupEventTreeDataByIsFolderProp = (
  eventTreeData: EventCodesTreeItem[]
) =>
  eventTreeData.reduce<[findings: EventCodesGroup, folders: EventCodesGroup]>(
    ([findings, folders], element) =>
      element.item.isFolder ||
      (!element.item.nestedEventCodeId &&
        element.item.isExpandableNodeFindingTreeView)
        ? [findings, [...folders, element]]
        : [[...findings, element], folders],
    [[], []]
  );

export const groupEventTreeDataByLevelOfNesting = (
  eventTreeData: EventCodesTreeItem[]
) =>
  eventTreeData.reduce<
    [deepestLevelFindings: EventCodesGroup, rootFolders: EventCodesGroup]
  >(
    ([nestedFindings, folders], element) => {
      if (!element.item.isFolder && element.item.isDependentOnEventCoding) {
        return [[...nestedFindings, element], folders];
      }

      return [nestedFindings, [...folders, element]];
    },
    [[], []]
  );

export const reorderEventTreeData = <T>(
  list: Array<T>,
  item: T,
  getNewPositionIndex: (index: number) => number
): Array<T> => {
  const itemIndex = list.indexOf(item);
  const result = Array.from(list);
  const [removed] = result.splice(itemIndex, 1);

  if (removed) {
    result.splice(getNewPositionIndex(itemIndex), 0, removed);
  }

  return result;
};

export const groupFindingsBySortOrder = (
  findings: EventCodesTreeItem[]
): Array<EventCodesTreeItem[]> =>
  Object.values(
    findings.reduce((acc: Record<string, EventCodesTreeItem[]>, treeItem) => {
      let key: string;

      if (treeItem.eventCoding?.sortOrder) {
        key = `coding-${treeItem.eventCoding.sortOrder}`;
      } else {
        key = `code-${treeItem.item.sortOrder}-${treeItem.eventCoding?.eventCodingId}`;
      }

      acc[key] = acc[key] || [];
      acc[key].push(treeItem);

      return acc;
    }, {})
  );
