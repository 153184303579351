import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonSize,
  ErrorTheme,
  ErrorTitles,
  GeneralError,
  IconType,
  LoadingOverlay,
  PropertiesTabElement,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import {
  getActivePanelIndex,
  getDimensions
} from 'components/PropertiesContainer/helpers';
import { PropertiesHeader } from 'components/PropertiesHeader';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { PropertiesTab } from 'components/PropertiesTab';
import { debounce } from 'debounce';
import { Tab } from 'entities/Tab';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { createShortcutCombination } from 'services/keyboardShortcuts/helpers';

import styles from './PropertiesContainer.module.scss';

interface Props {
  onCloseProperties?: () => void;
  dimensions?: {
    width: number;
  };
}

interface InnerProps extends Props {
  tabs: Tab[];
}

const CONTENT_INDENTATION = 40;

const PropertiesContainerInner: FC<InnerProps> = observer(
  ({ tabs, onCloseProperties, dimensions }) => {
    const headerRef = React.useRef<HTMLDivElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const panelsRefs = React.useRef<(HTMLDivElement | null)[]>([]);

    const findingPropertiesStore = useStore(StoreType.FindingProperties);
    const getFirstGroupIndex = findingPropertiesStore.getFirstGroupIndex;

    const [selectedTab, setSelectedTab] = useState<PropertiesTabElement>({
      title: '',
      id: 0,
      index: 0
    });

    const onSelect = useCallback(
      (selectedTab: PropertiesTabElement) => {
        panelsRefs.current[selectedTab.index]?.scrollIntoView({
          behavior: 'smooth'
        });

        findingPropertiesStore.setFocusedProperty({
          tabPositionNumber: selectedTab.index,
          panelPositionNumber: 0,
          groupPositionNumber: getFirstGroupIndex(selectedTab.index, 0),
          itemPositionNumber: 0
        });
      },
      [findingPropertiesStore, getFirstGroupIndex]
    );

    const onScroll = debounce(() => {
      if (headerRef.current && containerRef.current) {
        const { height: headerHeight } = getDimensions(headerRef.current);
        const scrollPosition =
          containerRef.current.scrollTop + headerHeight + CONTENT_INDENTATION;

        const activePanelIndex = getActivePanelIndex(
          panelsRefs.current,
          scrollPosition
        );

        if (activePanelIndex !== -1) {
          setSelectedTab({
            id: tabs[activePanelIndex].eventPropertyTabId,
            index: activePanelIndex,
            title: tabs[activePanelIndex].translatedName.eitherValue
          });
        }
      }
    }, 200);

    useEffect(() => {
      //autoscroll to current focused item in panel
      const currentFocusedItemInPropertyPanel =
        findingPropertiesStore.currentFocusedItemInPropertyPanel;
      if (currentFocusedItemInPropertyPanel) {
        const focusedItemPositions = currentFocusedItemInPropertyPanel.getBoundingClientRect();
        if (
          !(
            focusedItemPositions.top <
              containerRef.current!.getBoundingClientRect().bottom &&
            focusedItemPositions.bottom >=
              containerRef.current!.getBoundingClientRect().top
          )
        ) {
          currentFocusedItemInPropertyPanel.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [findingPropertiesStore.currentFocusedItemInPropertyPanel]);

    useEffect(() => {
      const containerElement = containerRef.current;
      onScroll();
      containerElement?.addEventListener('scroll', onScroll);
      return () => {
        containerElement?.removeEventListener('scroll', onScroll);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeTab = (direction: 'prev' | 'next') => {
      const newIndex =
        direction === 'prev'
          ? selectedTab.index === 0
            ? tabs.length - 1
            : selectedTab.index - 1
          : selectedTab.index === tabs.length - 1
          ? 0
          : selectedTab.index + 1;

      setSelectedTab({
        id: tabs[newIndex].eventPropertyTabId,
        index: newIndex,
        title: tabs[newIndex].translatedName.eitherValue
      });
    };

    useHotkeys(
      createShortcutCombination({ key: 'left' }),
      (event) => {
        event.preventDefault();

        handleChangeTab('prev');
      },
      {},
      [handleChangeTab]
    );

    useHotkeys(
      createShortcutCombination({ key: 'right' }),
      (event) => {
        event.preventDefault();

        handleChangeTab('next');
      },
      {},
      [handleChangeTab]
    );

    useHotkeys(
      createShortcutCombination({ key: 'enter' }),
      (event) => {
        event.preventDefault();

        if (
          selectedTab.index ===
          findingPropertiesStore.focusedProperty.tabPositionNumber
        ) {
          //when pressed enter on focused item in a panel
          const currentFocusedItem =
            findingPropertiesStore.currentFocusedItemInPropertyPanel;
          if (currentFocusedItem) {
            const input = currentFocusedItem.querySelector('input');
            if (input) {
              input.click();
            }
          }
          return;
        }

        panelsRefs.current[selectedTab.index]?.scrollIntoView({
          behavior: 'smooth'
        });

        findingPropertiesStore.setFocusedProperty({
          tabPositionNumber: selectedTab.index,
          panelPositionNumber: 0,
          groupPositionNumber: getFirstGroupIndex(selectedTab.index, 0),
          itemPositionNumber: 0
        });
      },
      {},
      [selectedTab.index, findingPropertiesStore.focusedProperty]
    );

    useHotkeys(
      createShortcutCombination({ key: 'down' }),
      (event) => {
        event.preventDefault();

        findingPropertiesStore.updateFocusedProperty('down');
      },
      {},
      [handleChangeTab]
    );

    useHotkeys(
      createShortcutCombination({ key: 'up' }),
      (event) => {
        event.preventDefault();

        findingPropertiesStore.updateFocusedProperty('up');
      },
      {},
      [handleChangeTab]
    );

    useHotkeys(
      createShortcutCombination({ key: 'tab' }),
      (event) => {
        event.preventDefault();

        findingPropertiesStore.updateFocusedProperty('tab');
      },
      {},
      [handleChangeTab, findingPropertiesStore.focusedProperty]
    );

    return (
      <>
        <div ref={headerRef} className={styles['properties-header']}>
          <PropertiesHeader
            onSelect={onSelect}
            data={tabs}
            selectedTab={selectedTab}
            onCloseProperties={onCloseProperties}
          />
        </div>
        <div
          ref={containerRef}
          data-testid='scroll-panels-container'
          className={styles['properties-tabs']}
          style={{
            height: `calc(100% - ${headerRef.current?.clientHeight}px)`
          }}
        >
          {tabs.map((tab, index) => (
            <div
              key={tab.eventPropertyTabId}
              className={styles.tab}
              ref={(ref) => (panelsRefs.current[index] = ref)}
            >
              <PropertiesTab
                data={tab}
                containerWidth={dimensions?.width}
                focusedProperty={{
                  tabPositionNumber: index
                }}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
);

export const PropertiesContainer: FC<Props> = observer((props) => {
  const { id } = useParams<{ id: string }>();

  const { t } = useCustomTranslation();
  const { activeCodingId, onCloseProperties } = usePropertiesContext();

  const findingPropertiesStore = useStore(StoreType.FindingProperties);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const findingsStore = useStore(StoreType.Findings);
  const headModelsStore = useStore(StoreType.HeadModel);
  const messagesStore = useStore(StoreType.Messages);

  const activeDescriptionId = parseInt(id);
  const descriptionDetails = descriptionsStore.descriptionById(
    activeDescriptionId
  )!;
  const eventCodeId =
    findingsStore.eventCodings.get(activeDescriptionId)?.get(activeCodingId!)
      ?.eventCodeId || -1;
  const eventCode = findingsStore.eventCodes.get(eventCodeId);
  const tabs = findingPropertiesStore.propertyTabs
    .get(descriptionDetails.ageConstraints)
    ?.get(eventCodeId);

  if (eventCodeId === -1) {
    onCloseProperties();
  }

  useEffect(() => {
    if (eventCodeId !== -1) {
      findingPropertiesStore.loadPropertiesData(
        eventCodeId,
        descriptionDetails.ageConstraints,
        false
      );
    }
    return () => {
      headModelsStore.clearErrors();
      messagesStore.clearSystemMessages();
      findingPropertiesStore.clearErrors();
    };
    // eslint-disable-next-line
  }, [eventCodeId, descriptionDetails.ageConstraints]);

  useEffect(() => {
    if (
      eventCodeId !== -1 &&
      !findingPropertiesStore.propertyPanelsError &&
      !findingPropertiesStore.propertyTabsError
    ) {
      findingPropertiesStore.loadPropertyCodings(
        activeDescriptionId,
        activeCodingId!
      );
    }
    // eslint-disable-next-line
  }, [eventCodeId, activeCodingId, activeDescriptionId]);

  const error =
    findingPropertiesStore.propertyCodingsError ||
    findingPropertiesStore.propertyPanelsError ||
    findingPropertiesStore.propertyTabsError ||
    headModelsStore.headModelError;

  useEffect(() => {
    if (
      activeCodingId &&
      eventCode?.hasLocation &&
      !error &&
      eventCodeId !== -1
    )
      headModelsStore.loadHeadModel(activeDescriptionId, activeCodingId);
  }, [
    eventCode?.hasLocation,
    activeCodingId,
    activeDescriptionId,
    headModelsStore,
    error,
    eventCodeId
  ]);

  return (
    <LoadingOverlay
      loading={
        findingPropertiesStore.propertyTabsLoading ||
        findingPropertiesStore.propertyCodingsLoading ||
        findingPropertiesStore.propertyPanelsLoading ||
        headModelsStore.headModelLoading
      }
    >
      {error ? (
        <div
          className={cn(
            styles['error-container'],
            props.onCloseProperties && styles['small-height']
          )}
        >
          {props.onCloseProperties && (
            <Button
              title={t('Findings tree')}
              size={ButtonSize.Small}
              icon={IconType.Arrow}
              iconClassName={styles.icon}
              onClick={props.onCloseProperties}
              data-testid='close-properties'
            />
          )}
          <GeneralError theme={ErrorTheme.Secondary} title={ErrorTitles.Load} />
        </div>
      ) : (
        tabs && (
          <PropertiesContainerInner
            key={activeCodingId}
            {...props}
            tabs={tabs
              .slice()
              .sort((tab1, tab2) => (tab1.sortOrder > tab2.sortOrder ? 1 : -1))}
          />
        )
      )}
    </LoadingOverlay>
  );
});
