import React, { useMemo, useState } from 'react';
import {
  Controller,
  useFormContext,
  useWatch
} from 'react-hook-form/dist/index.ie11';
import {
  Button,
  ButtonTheme,
  FormInput,
  FormInputTheme,
  IconType,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { useHandleAutoSave } from 'hooks/useHandleAutoSave';

import styles from './FormRow.module.scss';

export const FormRow: React.FC<{
  className?: string;
  readOnly?: boolean;
  onRemoveClick?: (currentPropertyCodeId?: string) => void;
  hasNoteField: boolean;
  defaultValue?: CategoricalPropertyCoding['formShape'];
  fieldArrayAccessor: string;
  onNoteSaveAction?: (freeText: string, currentPropertyCodeId: string) => void;
}> = ({
  children,
  readOnly,
  hasNoteField,
  defaultValue = {},
  className,
  onRemoveClick = () => {},
  fieldArrayAccessor,
  onNoteSaveAction = () => {}
}) => {
  const { t } = useCustomTranslation();
  const { control } = useFormContext<{
    patientDetails: PatientDetails;
    patientCodings: Record<number, CategoricalPropertyCoding['formShape'][]>;
  }>();

  const freeTextAccessor = useMemo(() => `${fieldArrayAccessor}.freeText`, [
    fieldArrayAccessor
  ]);

  const selectAccessor = useMemo(() => `${fieldArrayAccessor}.value`, [
    fieldArrayAccessor
  ]);

  const selectValue = useWatch<CategoricalPropertyCoding['selectOptionShape']>({
    control,
    name: selectAccessor
  });

  const { eventHandler } = useHandleAutoSave<
    {
      patientDetails: PatientDetails;
      patientCodings: Record<number, CategoricalPropertyCoding[]>;
    },
    string,
    CategoricalPropertyCoding['selectOptionShape']
  >({
    saveHandler: (value, config) => {
      if (config?.value) {
        onNoteSaveAction(value, config.value);
      }
    }
  });

  const freeTextValue = useWatch<string | undefined>({
    control,
    name: freeTextAccessor
  });

  const [isNoteVisible, setNoteVisible] = useState(!!defaultValue?.freeText);
  const [isAutoFocus, setIsAutoFocus] = useState(false);

  const blurHandler = eventHandler(freeTextAccessor, selectValue);

  return (
    <div className={cn(styles['form-row'], className)}>
      <div className={styles['main-row']}>
        <div className={styles['input-container']}>{children}</div>
        <span className={!hasNoteField || readOnly ? styles.hidden : ''}>
          {isNoteVisible ? (
            <Button
              title=''
              data-testid='remove-button'
              icon={IconType.Close}
              theme={ButtonTheme.SecondaryTransparent}
              className={styles.button}
              tabIndex={-1}
              iconClassName={styles.icon}
              onClick={() => {
                onRemoveClick(selectValue?.value);
              }}
            />
          ) : (
            <Button
              title=''
              data-testid='add-button'
              icon={IconType.AddComment}
              disabled={!selectValue}
              theme={ButtonTheme.SecondaryTransparent}
              className={styles.button}
              iconClassName={styles.icon}
              onClick={() => {
                setIsAutoFocus(true);
                setNoteVisible(true);
              }}
            />
          )}
        </span>
      </div>
      {isNoteVisible && hasNoteField && (
        <div className={styles['input-container']}>
          <Controller
            render={({ onBlur, ...props }) => {
              return (
                <FormInput
                  data-testid={freeTextAccessor}
                  {...props}
                  readOnly={readOnly}
                  theme={
                    readOnly ? FormInputTheme.Italic : FormInputTheme.Primary
                  }
                  disabled={!selectValue}
                  placeholder={t('Enter notes here')}
                  multiline
                  autoFocus={isAutoFocus}
                  onBlur={() => {
                    setIsAutoFocus(false);
                    onBlur();
                    blurHandler();
                    !freeTextValue && setNoteVisible(false);
                  }}
                />
              );
            }}
            control={control}
            name={freeTextAccessor}
            defaultValue={freeTextValue}
          />
        </div>
      )}
    </div>
  );
};
