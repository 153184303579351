import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconType,
  PropertiesTabElement,
  PropertiesTabs
} from '@holberg/ui-kit';
import cn from 'classnames';
import { onTabsScroll } from 'components/PropertiesHeader/helpers';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { debounce } from 'debounce';
import { Tab } from 'entities/Tab';
import { ScrollDirectionType } from 'enums/ScrollDirection.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { isIE } from 'utils/browserHelpers';

import styles from './PropertiesHeader.module.scss';

interface Props {
  data: Tab[];
  selectedTab: PropertiesTabElement;
  onCloseProperties?: () => void;
  onSelect: (selectedTab: PropertiesTabElement) => void;
}

export const PropertiesHeader: React.FC<Props> = ({
  data,
  onCloseProperties,
  selectedTab,
  onSelect
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tabsRefs = useRef<(HTMLSpanElement | null)[]>([]);

  const { id } = useParams<{ id: string }>();

  const findingsStore = useStore(StoreType.Findings);

  const activeDescriptionId = parseInt(id);
  const { activeCodingId } = usePropertiesContext();

  const activeEventCodeId = findingsStore.eventCodings
    .get(activeDescriptionId)!
    .get(activeCodingId!)!.eventCodeId;

  const activeEventCode = findingsStore.eventCodes.get(activeEventCodeId);

  const [isPrevVisible, setPrevVisible] = useState<boolean>(false);
  const [isNextVisible, setNextVisible] = useState<boolean>(true);

  const isContentOverflowed = containerRef.current
    ? containerRef.current.scrollWidth !== containerRef.current.clientWidth
    : false;

  const onScroll = debounce(() => {
    if (containerRef.current) {
      const isPrevArrowVisible = containerRef.current.scrollLeft > 20;
      const isNextArrowVisible =
        containerRef.current.scrollWidth -
          containerRef.current.scrollLeft -
          containerRef.current.offsetWidth !==
        0;

      setPrevVisible(isPrevArrowVisible);
      setNextVisible(isNextArrowVisible);
    }
  }, 100);

  useEffect(() => {
    if (containerRef.current?.scrollTo && tabsRefs.current[selectedTab.index]) {
      const scrollLeft =
        (tabsRefs.current[selectedTab.index]?.offsetLeft || 0) - 25;

      if (isIE()) {
        containerRef.current.scrollLeft = scrollLeft;
      } else {
        containerRef.current.scrollTo({
          top: 0,
          left: scrollLeft,
          behavior: 'smooth'
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab.title]);

  useEffect(() => {
    const containerElement = containerRef.current;

    containerElement?.addEventListener('scroll', onScroll);
    return () => {
      containerElement?.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['properties-header']}>
      {onCloseProperties && (
        <Button
          title='Findings tree'
          size={ButtonSize.Small}
          icon={IconType.Arrow}
          iconClassName={styles.icon}
          className={styles['close-button']}
          onClick={onCloseProperties}
          data-testid='close-properties'
        />
      )}
      <h1 className={styles.title}>
        {activeEventCode!.translatedLongName.eitherValue}
      </h1>
      <div className={styles['tabs-container']}>
        <PropertiesTabs
          className={styles.tabs}
          ref={containerRef}
          data={data.map((tab) => ({
            title: tab.translatedName.eitherValue,
            id: tab.eventPropertyTabId
          }))}
          tabsRefs={tabsRefs}
          selectedTabId={selectedTab.id}
          onSelect={onSelect}
        />
        {isPrevVisible && isContentOverflowed && (
          <Button
            size={ButtonSize.Small}
            data-testid='arrow-prev'
            icon={IconType.Arrow}
            theme={ButtonTheme.Secondary}
            className={cn(styles.arrow, styles['arrow-prev'])}
            onClick={() =>
              onTabsScroll(
                {
                  direction: ScrollDirectionType.Left
                },
                containerRef.current
              )
            }
          />
        )}
        {isNextVisible && isContentOverflowed && (
          <Button
            size={ButtonSize.Small}
            icon={IconType.Arrow}
            theme={ButtonTheme.Secondary}
            className={cn(styles.arrow, styles['arrow-next'])}
            onClick={() =>
              onTabsScroll(
                {
                  direction: ScrollDirectionType.Right
                },
                containerRef.current
              )
            }
          />
        )}
      </div>
    </div>
  );
};
