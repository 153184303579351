import { useFormContext, useWatch } from 'react-hook-form/dist/index.ie11';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { StoreType } from 'enums/StoreType.enum';
import { EventPropertyCodingsData } from 'stores/finding-properties';
import { SensorState } from 'stores/head-model';

import { useStore } from './store';

export const useSensorSelected = (panel: EventPropertyPanel): boolean => {
  const { control } = useFormContext<{
    eventPropertyCodings: EventPropertyCodingsData;
  }>();
  const headModelStore = useStore(StoreType.HeadModel);
  const { activeCodingId } = usePropertiesContext();

  const sensorsMetaInfo =
    activeCodingId && panel.headModelLocationType
      ? headModelStore.getSensorsMeta(
          activeCodingId,
          panel.headModelLocationType
        )
      : new Map();

  const watchableFields = Array.from(sensorsMetaInfo?.keys() || []).map(
    (sensorId) =>
      `eventPropertyCodings.headModel.${panel.headModelLocationType}.${sensorId}`
  );
  const headModelSensors = useWatch<{ [x: number]: SensorState }>({
    control,
    name: watchableFields
  });

  // If head model is not rendered yet and sensors is not initialized
  if (!Object.keys(headModelSensors).length) {
    return true;
  }

  if (!activeCodingId || !panel.headModelLocationType) {
    return false;
  }

  const selectedSensorIds = Object.entries(headModelSensors || {}).filter(
    ([, state]) => state !== SensorState.Empty
  );

  return !!selectedSensorIds.length;
};
