import { LocationType } from 'enums/LocationType.enum';
import { deserialize, serializable } from 'serializr';

import { EventPropertyType } from './EventPropertyType.entity';

export class EventPropertyCodingsSensorDTO {
  @serializable
  sensorId: number = 0;

  @serializable
  isMaximum: boolean = false;

  @serializable
  locationTypeId: LocationType = LocationType.Onset;

  @serializable
  propertyTypeId: EventPropertyType['eventPropertyTypeId'] = 0;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCodingsSensorDTO, json);
  }
}
