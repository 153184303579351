import { InformationModel } from './InformationModel.entity';

export class GenericListItem {
  constructor(json: object) {
    Object.entries(json).forEach(([key, value]) => {
      this[key] = InformationModel.deserialize<string | number>(value);
    });
  }
  [key: string]: InformationModel<string | number>;

  static deserializeAsList(list: GenericListItem[]): GenericListItem[] {
    return list.map((item) => new GenericListItem(item));
  }
}
