import { AxiosError } from 'axios';
import { deserialize, list, object, serializable } from 'serializr';

import { UnknownError } from './UnknownError.entity';

export interface CommonErrorShape {
  fullMessage: string;
}

export const fallbackErrorStub = Object.freeze({
  success: false,
  errors: [
    {
      propertyName: null,
      errors: [{ message: 'Unknown UI Error' }]
    }
  ]
});

interface FallbackError {
  success: boolean;
  errors: PropertyError[];
}

export const getFallbackError = (error?: AxiosError): FallbackError | null => {
  const statusCode =
    error?.code || error?.request?.status || error?.response?.status;

  if (!statusCode) {
    return null;
  }

  return {
    success: false,
    errors: [
      {
        errors: [
          {
            message: `Sorry, we couldn't process your request due to error${
              statusCode ? ` ${statusCode}` : ''
            }. Please contact your admin`
          }
        ]
      }
    ]
  };
};

class ErrorDetails {
  @serializable
  message: string = '';
}

class PropertyError {
  @serializable
  propertyName?: string;

  @serializable(list(object(ErrorDetails)))
  errors: ErrorDetails[] = [];
}

export class ApiError implements CommonErrorShape {
  @serializable
  success: boolean = false;

  @serializable(list(object(PropertyError)))
  errors: PropertyError[] = [];

  get fullMessage() {
    return this.errors
      .map((propertyError) => {
        const joinedMessages = propertyError.errors
          .map((errorDetails) => errorDetails.message)
          .join(', ');

        return propertyError.propertyName
          ? `${propertyError.propertyName}: ${joinedMessages}`
          : `${joinedMessages}`;
      })
      .join('; ');
  }

  static deserialize(json: Object | string) {
    return deserialize(ApiError, json);
  }

  static deserializeFromCatch(error?: AxiosError) {
    try {
      const data = error?.response?.data || getFallbackError(error) || {};
      const apiError: ApiError = ApiError.deserialize(data);
      if (apiError?.errors.length > 0) {
        return apiError;
      }
      return new UnknownError(error?.message || 'error: no details');
    } catch (e) {
      return new UnknownError(e);
    }
  }
}
