import { custom, deserialize, serializable } from 'serializr';

import {
  excludeTimezoneOffset,
  getDateFormat,
  parseDate
} from './../utils/dateHelpers';
export class PatientUpdateDTO {
  @serializable(
    custom(
      (sourcePropertyValue: string) => sourcePropertyValue,
      (jsonValue: string) => {
        return parseInt(jsonValue) || null;
      }
    )
  )
  gestationalAgeInWeeks?: number;

  @serializable(
    custom(
      (sourcePropertyValue: string) => sourcePropertyValue,
      (jsonValue: string) => {
        return parseInt(jsonValue) || null;
      }
    )
  )
  gestationalAgeInDaysExceedingWeeks?: number;

  @serializable(
    custom(
      (sourcePropertyValue: Date) => sourcePropertyValue,
      (jsonValue: string) => {
        const format = getDateFormat();
        const date = jsonValue ? parseDate(jsonValue, format) : null;

        return date && excludeTimezoneOffset(date);
      }
    )
  )
  dateOfDeath?: Date;

  @serializable
  internalPatientNotes?: string;

  @serializable
  patientHistory?: string;

  @serializable
  patientNotes?: string;

  @serializable
  scalpDefects?: string;

  static deserialize(json: Object | string) {
    return deserialize(PatientUpdateDTO, json);
  }
}
