import { deserialize, serializable } from 'serializr';

export class StudyUpdateDTO {
  @serializable
  internalStudyNotes?: string;

  static deserialize(json: Object | string) {
    return deserialize(StudyUpdateDTO, json);
  }
}
