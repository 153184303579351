import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { Env } from 'services/Env';

// @ts-ignore // ISSUE: https://github.com/i18next/react-i18next/issues/1112#issuecomment-642131092
i18n.use(initReactI18next).init({
  resources: {},
  lng: 'en',
  debug: Env.TRANSLATIONS_DEBUG,
  interpolation: {
    escapeValue: false
  },
  react: {
    bindI18nStore: 'added'
  }
});

export { i18n };
