import { PropertyPanelOperator } from 'enums/PropertyPanelOperatorEnum.enum';
import { deserialize, serializable } from 'serializr';

export class EventPropertyCodingsNumberDTO {
  @serializable
  propertyTypeId: number = 0;

  @serializable
  value1: number = 0;

  @serializable
  value2?: number | null = null;

  @serializable
  operatorId: PropertyPanelOperator = PropertyPanelOperator.Equals;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCodingsNumberDTO, json);
  }
}
