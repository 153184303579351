import { processNodes, Transform } from 'react-html-parser';
import { DomNode } from '@holberg/ui-kit';
import cn from 'classnames';
import { SensorState } from 'stores/head-model';

import styles from './Sensor.module.scss';

export const processChildren = ({
  children,
  transform,
  state,
  selectedClassName,
  selectedMaximumClassName
}: {
  children: DomNode[];
  transform: Transform;
  state: SensorState;
  selectedClassName?: string;
  selectedMaximumClassName?: string;
}) => {
  const parsedChildren = children
    .filter(
      (node) => node.type === 'tag' && !node.attribs.class?.includes('pattern')
    )
    .map((node) => ({
      ...node,
      attribs: {
        ...node.attribs,
        class: cn(
          node.attribs.class,
          styles.node,
          selectedClassName && {
            [selectedClassName]: state === SensorState.Normal
          },
          selectedMaximumClassName && {
            [selectedMaximumClassName]: state === SensorState.Max
          }
        )
      }
    }));

  return processNodes(parsedChildren, transform);
};

export const getDefaultState = (
  className?: string,
  selectedClassName?: string,
  selectedMaximumClassName?: string
) => {
  if (!className) {
    return SensorState.Empty;
  }

  if (
    selectedMaximumClassName &&
    className.includes(selectedMaximumClassName)
  ) {
    return SensorState.Max;
  }

  if (selectedClassName && className.includes(selectedClassName)) {
    return SensorState.Normal;
  }

  return SensorState.Empty;
};

export const extractSensorId = (sensorFieldName: string) => {
  const parsedName = sensorFieldName.split('.');
  return parsedName[parsedName.length - 1];
};
