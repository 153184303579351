import { computed, makeObservable } from 'mobx';
import { deserialize, serializable } from 'serializr';

export class Me {
  @serializable
  firstName: string = '';

  @serializable
  lastName: string = '';

  @serializable
  email: string = '';

  @serializable
  userId: number = -1;

  @serializable
  userRole: string = '';

  constructor() {
    makeObservable(this);
  }

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  static deserialize(json: Object | string) {
    return deserialize(Me, json);
  }
}
