import { deserialize, list, object, primitive, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class Tab {
  @serializable
  eventPropertyTabId: number = 0;

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  @serializable(list(primitive()))
  panelIds: number[] = [];

  @serializable
  sortOrder: number = 0;

  @serializable(object(InformationModel))
  definition: InformationModel = new InformationModel();

  @serializable
  isActive: boolean = false;

  static deserialize(json: Object | string) {
    return deserialize(Tab, json);
  }

  static deserializeAsList(list: Tab[]): Tab[] {
    return list.map(Tab.deserialize);
  }
}
