import React, { useCallback, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import {
  LoadingOverlay,
  PaginationControls,
  Table,
  TableColumn,
  TableLoading,
  TableMessages
} from '@holberg/ui-kit';
import cn from 'classnames';
import { withLayout } from 'components/Layout';
import { TableSettings } from 'components/TableSettings';
import { Condition } from 'entities/Condition.entity';
import { GenericListItem } from 'entities/GenericListItem.entity';
import { TableConfig } from 'entities/TableConfig.entity';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useAutofocus } from 'hooks/useAutofocus';
import { useResizeAction } from 'hooks/useResizeAction';
import { useSetLoadingOnMount } from 'hooks/useSetLoadingOnMount';
import { useSetPage } from 'hooks/useSetPage';
import { observer } from 'mobx-react-lite';

import styles from './PatientsPage.module.scss';

const defaultPage = 1;

export const PatientsPage: React.FC = withLayout(
  (hasConnectionIssues: boolean) =>
    hasConnectionIssues ? styles.snackWrapper : styles.wrapper
)(
  observer(() => {
    const { push } = useHistory();
    const patientStore = useStore(StoreType.Patient);
    const isLoading =
      patientStore.metadataLoading || patientStore.dataListLoading;
    const { isLoadingOnMount } = useSetLoadingOnMount(isLoading);
    const { pageNumber, setPrevPage, setNextPage, setDefaultPage } = useSetPage(
      defaultPage
    );
    const { onResize } = useResizeAction((key, value) =>
      patientStore.updateConfigPropValue(key, 'width', value)
    );

    const isTableLoading =
      isLoadingOnMount && !patientStore.dataListInitialLoaded;

    const tableRef = useAutofocus<HTMLDivElement>(isTableLoading);

    useEffect(() => {
      if (pageNumber && !isLoading) {
        patientStore.loadGenericList(pageNumber);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
      return () => {
        patientStore.resetInitialLoading();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isTableVisible = patientStore.columns.some(
      ({ isVisible }) => isVisible
    );

    const emptyStateMessage = !!patientStore.filterCriteria?.conditions.length
      ? TableMessages.NoMatchedData
      : TableMessages.NoData;

    const tableErrorMessage =
      patientStore.dataListError && TableMessages.LoadError;

    const updateFilters = useCallback(
      (conditions: Condition[]) => {
        patientStore.updateFilterCriteria(conditions);
      },
      [patientStore]
    );

    const updateConfigValues = useCallback(
      (data: TableColumn<GenericListItem>[], key: keyof TableConfig) => {
        patientStore.updateConfigValues(data, key);
      },
      [patientStore]
    );

    const updateTableConfig = useCallback(
      (column, key, value) => {
        patientStore.updateConfigPropValue(column, key, value);
      },
      [patientStore]
    );

    return (
      <div className={styles['patients-page']}>
        <LoadingOverlay
          loading={isLoading && patientStore.dataListInitialLoaded}
        >
          <div className={styles.content}>
            <TableSettings
              disabled={isLoading}
              onUpdateFilters={updateFilters}
              columns={patientStore.columns}
              tableConfig={patientStore.tableConfig}
              columnMetaInfo={patientStore.columnMetaInfo}
              filterCriteria={patientStore.filterCriteria?.conditions}
              onUpdateConfigValues={updateConfigValues}
              onUpdateTableConfig={updateTableConfig}
            />
            {isTableLoading ? (
              <TableLoading
                rowsAmount={15}
                columnsAmount={8}
                bodyClassName={cn(styles.table, styles['table-loading'])}
              />
            ) : (
              <Table
                className={styles['table-wrapper']}
                tableProps={{
                  tabIndex: 0,
                  ref: tableRef
                }}
                onWidthChange={onResize}
                bodyClassName={styles.table}
                columns={patientStore.columns}
                data={patientStore.dataList}
                emptyStateMessage={emptyStateMessage}
                errorMessage={tableErrorMessage}
                onRowClick={(item) => {
                  const id = item.patientId?.eitherValue;
                  if (id) {
                    push(generatePath(Routes.PatientOverview, { id }));
                  }
                }}
                onSortChange={(sortBy) => {
                  patientStore.updateSortCriteria(sortBy);
                }}
                footer={
                  <PaginationControls
                    className={styles.footer}
                    pageNumber={
                      patientStore.pagination.pagedMetadata.pageNumber
                    }
                    onNextPageClick={setNextPage}
                    onPrevPageClick={setPrevPage}
                    onSkipBackClick={setDefaultPage}
                    skipBackDisabled={
                      pageNumber === String(defaultPage) || !isTableVisible
                    }
                    nextPageDisabled={
                      !patientStore.pagination.pagedMetadata.nextPage ||
                      !isTableVisible
                    }
                    prevPageDisabled={
                      !patientStore.pagination.pagedMetadata.previousPage ||
                      !isTableVisible
                    }
                  />
                }
              />
            )}
          </div>
        </LoadingOverlay>
      </div>
    );
  })
);
