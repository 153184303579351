import React from 'react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { EventCodesTreeItem } from 'stores/findings';

import styles from './Finding.module.scss';

interface findingReorderProps {
  findingsList?: EventCodesTreeItem[];
  dropIndex: number;
  onReorder: (getNewPositionIndex: (index: number) => number) => void;
}

export const FindingReorderSpace: React.FC<findingReorderProps> = ({
  findingsList = [],
  dropIndex,
  onReorder
}) => {
  const findingsStore = useStore(StoreType.Findings);

  const [{ sortIsOver }, sortDrop] = useDrop(() => ({
    accept: DraggableTypes.finding,
    drop: (item: any, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      findingsStore.selectionState.discardFindingsSelections();
      const [removed] = findingsList.splice(item.index, 1);
      if (removed) {
        findingsList.splice(
          item.index < dropIndex ? dropIndex - 1 : dropIndex,
          0,
          removed
        );
      }
      onReorder((index) => index);
    },
    collect: (monitor) => ({
      sortIsOver: monitor.isOver({ shallow: true })
    })
  }));

  return (
    <div
      className={classNames(
        styles.findingReorderSpace,
        sortIsOver ? styles['findingReorderSpace-hover'] : ''
      )}
      ref={sortDrop}
      data-testid='reorder-space'
    >
      {sortIsOver && 'Drop here'}
    </div>
  );
};
