import React from 'react';
import { KeyValueRow } from '@holberg/ui-kit';
import cn from 'classnames';

import styles from './DetailsPageHeader.module.scss';

interface Props {
  className?: string;
  title?: string;
  actionButton?: JSX.Element;
  descriptors?: { title: string; value?: string | number }[];
}

export const DetailsPageHeader: React.FC<Props> = ({
  className,
  title,
  actionButton,
  children,
  descriptors = []
}) => {
  return (
    <div className={cn(styles['details-page-header'], className)}>
      <div className={styles.content}>
        <div>
          <div className={styles.header}>
            {title && <span className={styles.title}>{title}</span>}
            {children}
          </div>
          {descriptors.length > 0 && (
            <div className={styles['descriptors']} data-testid='descriptors'>
              {descriptors.map(
                ({ title, value }) =>
                  !!value && (
                    <KeyValueRow
                      key={title}
                      value={value}
                      title={title}
                      className={styles.row}
                      titleClassName={styles['row-title']}
                    />
                  )
              )}
            </div>
          )}
        </div>
        {actionButton}
      </div>
    </div>
  );
};
