import { computed, makeObservable } from 'mobx';
import { deserialize, serializable } from 'serializr';

export class Phrase {
  @serializable
  phrase: string = '';

  @serializable
  phraseId: number = 0;

  @serializable
  languageId: number = 0;

  @serializable
  translation: string = '';

  @serializable
  language?: string = '';

  @serializable
  defaultTranslation: string = '';

  constructor() {
    makeObservable(this);
  }

  @computed
  get displayValue() {
    return this.translation || this.defaultTranslation;
  }

  static deserialize(json: Object | string) {
    return deserialize(Phrase, json);
  }
}
