import { ApiError } from 'entities/ApiError.entity';
import { Site } from 'entities/Site.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { StoreType } from 'enums/StoreType.enum';
import { action, makeObservable, observable } from 'mobx';
import { RootApi } from 'services/API/Root/RootApi';
import { stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';

const notFoundSiteDetails = Site.deserialize({});

export class SiteStore implements BaseStore {
  @observable site!: Site;

  @observable siteLoading!: boolean;
  @observable siteError?: ApiError | UnknownError;

  constructor() {
    makeObservable(this);
    this.reset();
  }

  @action reset() {
    this.siteLoading = false;
    this.siteError = undefined;
  }

  @action
  async loadSite() {
    this.siteLoading = true;

    try {
      const { data } = await RootApi.loadSite();

      this.site = Site.deserialize(data);
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.siteLoading = false;
    }
  }

  get siteDetails(): Site {
    return this.site || notFoundSiteDetails;
  }
}
