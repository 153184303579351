import { deserialize, serializable } from 'serializr';

export class PropertyCodingCreateDTO {
  @serializable
  patientId: number = 0;

  @serializable
  propertyTypeId: number = 0;

  @serializable
  propertyCodeId?: number;

  @serializable
  freeText?: string;

  static deserialize(json: Object | string) {
    return deserialize(PropertyCodingCreateDTO, json);
  }
}
