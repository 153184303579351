export const AUTH_TOKEN_KEY = 'accessToken';
export const TABLE_CELL_MIN_WIDTH = 110;

export const TREE_DROPDOWN_MIN_WIDTH = 500;
export const TREE_DROPDOWN_RIGHT_INDENTATION = -70;

export const STUDY_TABS_CONTAINER_MIN_WIDTH = 750;
export const STUDY_TABS_CONTAINER_MIN_WIDTH_NO_EXAMPLES = 800;
export const FINDINGS_PROPERTIES_MIN_WIDTH = 600;
export const REPORT_MIN_WIDTH = 540;
export const REPORT_DEFAULT_FLEX_WIDTH = 0.44;

export const SM_SCREEN_MIN_WIDTH = 800;
export const SM_SCREEN_MAX_WIDTH = 1039;
export const MD_SCREEN_MIN_WIDTH = SM_SCREEN_MAX_WIDTH + 1;
export const MD_SCREEN_MAX_WIDTH = 1579;
export const LG_SCREEN_MIN_WIDTH = MD_SCREEN_MAX_WIDTH + 1;
export const LG_SCREEN_MAX_WIDTH = 1859;
export const XL_SCREEN_MIN_WIDTH = LG_SCREEN_MAX_WIDTH + 1;

export const MASONRY_BREAKPOINT_SM = 592;
export const MASONRY_BREAKPOINT_LG = 868;

export const REPORT_INFO_BOTTOM_VIEW_MAX_WIDTH = 1400;
