import { Event } from 'entities/Event.entity';
import { Screenshot } from 'entities/Screenshot.entity';
import queryString from 'query-string';

import { ApiBase } from '../ApiBase';

class ScreenshotApi extends ApiBase {
  getScreenshotsByRecordingIds(recordingIds: number[]) {
    const query = queryString.stringify(
      {
        recordingIds
      },
      { arrayFormat: 'comma' }
    );
    return this.client.get<Screenshot[]>(`/screenshots?${query}`);
  }

  createScreenshot(recordingId: number, eventId: Event['eventId']) {
    const query = queryString.stringify({ recordingId, eventId });
    return this.client.post<Screenshot>(`/screenshots?${query}`);
  }

  deleteScreenshot(screenshotId: Screenshot['screenshotId']) {
    const query = queryString.stringify({
      screenshotId
    });
    return this.client.delete<Screenshot>(`/screenshots?${query}`);
  }
}

const instance = new ScreenshotApi('/api/v1');

export { instance as ScreenshotApi };
