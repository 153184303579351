import env from '@beam-australia/react-env';

class Env {
  readonly BASE_URL: string;
  readonly TRANSLATIONS_DEBUG: boolean;
  readonly APP_TITLE: string;
  readonly CHECK_NETWORK_INTERVAL?: number;
  readonly CHECK_NETWORK_TIMEOUT?: number;

  constructor() {
    const isTestEnv = env('NODE_ENV') === 'test';
    const isTranslationDebugMode = env('TRANSLATIONS_DEBUG') === 'true';

    this.BASE_URL = env('BASE_URL');
    this.TRANSLATIONS_DEBUG =
      isTranslationDebugMode && !isTestEnv ? true : false;

    this.APP_TITLE = env('APP_TITLE');

    this.CHECK_NETWORK_INTERVAL = env('CHECK_NETWORK_INTERVAL')
      ? parseInt(env('CHECK_NETWORK_INTERVAL'))
      : undefined;
    this.CHECK_NETWORK_TIMEOUT = env('CHECK_NETWORK_TIMEOUT')
      ? parseInt(env('CHECK_NETWORK_TIMEOUT'))
      : undefined;
  }
}

const instance = Object.freeze(new Env());

export { instance as Env };
