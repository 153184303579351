const CONTENT_BOTTOM_INDENTATION = 30;

export const getDimensions = (element: HTMLDivElement) => {
  const { height } = element.getBoundingClientRect();
  const offsetTop = element.offsetTop;

  const offsetBottom = offsetTop + height + CONTENT_BOTTOM_INDENTATION;

  return {
    height,
    offsetTop,
    offsetBottom
  };
};

export const getActivePanelIndex = (
  elements: (HTMLDivElement | null)[],
  scrollPosition: number
) =>
  elements.findIndex((element) => {
    if (element) {
      const { offsetBottom, offsetTop } = getDimensions(element);

      return scrollPosition > offsetTop && scrollPosition < offsetBottom;
    }

    return -1;
  });

export const showConfirmationModal = (
  action: () => void,
  confirmTitle: string
) => {
  if (window.confirm(confirmTitle)) {
    action();
  }
};
