import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class CategoricalPropertyCode {
  @serializable
  propertyCodeId: number = 0;

  @serializable
  code: string = '';

  @serializable
  name: string = '';

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  @serializable
  rootCode: string = '';

  @serializable
  rootCodeId: number = 0;

  @serializable
  codeLevel: number = 0;

  @serializable
  parentCode: string = '';

  @serializable
  ageConstraintId: number = 0;

  @serializable
  isSelectable: boolean = false;

  @serializable
  isExclusiveInMultiSelect?: boolean;

  @serializable
  isDefault: boolean = false;

  @serializable
  hasChildren: boolean = false;

  @serializable
  isActive: boolean = false;

  @serializable
  parentId: number = 0;

  @serializable
  definition: string = '';

  @serializable
  propertyGroupId: number = 0;

  static deserialize(json: Object | string) {
    return deserialize(CategoricalPropertyCode, json);
  }
}
