import { TreeItem } from '@holberg/ui-kit';
import { ApiError } from 'entities/ApiError.entity';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PropertyTypeCode } from 'entities/PropertyTypeCode.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { action, computed, makeObservable, observable } from 'mobx';
import { ApiRetryState } from 'services/API/ApiRetryManager';
import { PropertyTypeCodesRules } from 'stores/property-type-codes/PropertyTypeCodesRules';
import { notEmpty } from 'utils/predicates';

import { CommonPropertyType } from './index';

export class PropertyTypeCodesState {
  @observable
  readonly key: CommonPropertyType;

  @observable
  propertyTypes: ReturnType<typeof PropertyTypeCode.deserializeAsMap>;

  @observable
  searchResultIds: number[] = [];

  @observable
  previewPathIds: number[] = [];

  @observable private error?: ApiError | UnknownError;

  @observable retryState?: ApiRetryState<PropertyTypeCode[]>;

  constructor(
    key: CommonPropertyType,
    propertyTypes: ReturnType<
      typeof PropertyTypeCode.deserializeAsMap
    > = new Map(),
    error?: ApiError | UnknownError,
    retryState?: ApiRetryState<PropertyTypeCode[]>
  ) {
    makeObservable<PropertyTypeCodesState, 'error'>(this);
    this.key = key;
    this.propertyTypes = observable(propertyTypes);
    this.error = error;
    this.retryState = retryState;
  }

  private makeTreeItem(
    propertyTypes: {
      list: PropertyTypeCode[];
      selected: PropertyTypeCode;
    },
    selectedItems: Array<CategoricalPropertyCoding['formShape']>,
    {
      addChildren = true,
      includeLabel = true
    }: { addChildren?: boolean; includeLabel?: boolean } = {},
    isSelectableRule?: (
      propertyCodeId: PropertyTypeCode['propertyCodeId']
    ) => boolean
  ): TreeItem {
    const { list, selected } = propertyTypes;
    const {
      code,
      hasChildren,
      propertyCodeId,
      translatedName,
      isSelectable,
      isExclusiveInMultiSelect
    } = selected;

    const children = addChildren
      ? list
          .filter((innerNode) => innerNode.parentId === propertyCodeId)
          .map((innerNode) =>
            this.makeTreeItem(
              { list, selected: innerNode },
              selectedItems,
              {
                addChildren,
                includeLabel
              },
              isSelectableRule
            )
          )
      : [];

    const matchIsSelectableExtraRule = isSelectableRule
      ? isSelectableRule(propertyCodeId)
      : true;

    return {
      label: includeLabel ? code : undefined,
      hasChildren,
      id: propertyCodeId,
      isMultiSelect: !isExclusiveInMultiSelect,
      subLabel: translatedName.eitherValue,
      isSelectable:
        isSelectable &&
        PropertyTypeCodesRules.isSelectable(selectedItems, {
          propertyCodeId,
          isMultiSelect: !isExclusiveInMultiSelect,
          translatedName: translatedName.secondaryValue
        }) &&
        matchIsSelectableExtraRule,
      actionLabel: 'Show in tree',
      children,
      expanded: this.previewPathIds.includes(propertyCodeId)
    };
  }

  @computed
  get dataError() {
    return this.error;
  }

  @action
  getTreeData(
    selectedItems: Array<CategoricalPropertyCoding['formShape']>,
    includeCode?: boolean,
    isSelectableRule?: (
      propertyCodeId: PropertyTypeCode['propertyCodeId']
    ) => boolean
  ): TreeItem[] {
    const nodes = [...this.propertyTypes.values()];

    return nodes
      .filter((node) => node.rootCodeId === null)
      .map((node) => {
        return this.makeTreeItem(
          { list: nodes, selected: node },
          selectedItems,
          { includeLabel: includeCode },
          isSelectableRule
        );
      });
  }

  @action
  getSearchTreeData(
    selectedItems: Array<CategoricalPropertyCoding['formShape']>,
    includeCode?: boolean,
    isSelectableRule?: (
      propertyCodeId: PropertyTypeCode['propertyCodeId']
    ) => boolean
  ): TreeItem[] {
    const searchPropertyTypes = this.searchResultIds
      .map((id) => this.propertyTypes.get(id))
      .filter(notEmpty);

    return searchPropertyTypes.map((propertyType) => {
      const propertyTypes = {
        list: searchPropertyTypes,
        selected: propertyType
      };

      const item = this.makeTreeItem(
        propertyTypes,
        selectedItems,
        {
          addChildren: false,
          includeLabel: includeCode
        },
        isSelectableRule
      );

      item.hasChildren = false;
      return item;
    });
  }

  @computed
  get values(): PropertyTypeCode[] {
    return [...this.propertyTypes.values()];
  }

  @computed
  get isRetrying(): boolean {
    return this.retryState?.isRetrying || false;
  }

  @action async retryOnce(isSearch?: boolean) {
    await this.retryState?.retryOnce();
    if (this.retryState?.result) {
      if (isSearch) {
        this.addSearchResults(this.retryState.result.data);
      } else {
        this.addValues(this.retryState.result.data);
      }
      this.error = undefined;
      this.retryState = undefined;
    }
  }

  @action
  addValues(values: PropertyTypeCode[]) {
    values.forEach((entity) => {
      const foundPropertyType = this.propertyTypes.get(entity.propertyCodeId);
      if (!foundPropertyType) {
        this.propertyTypes.set(
          entity.propertyCodeId,
          PropertyTypeCode.deserialize(entity)
        );
      } else {
        foundPropertyType.hasChildren =
          foundPropertyType.hasChildren || entity.hasChildren;
      }
    });
  }

  @action
  addSearchResults(values: PropertyTypeCode[]) {
    this.searchResultIds = values.map(({ propertyCodeId }) => propertyCodeId);
    this.addValues(values);
  }

  private makePath(childId: number, acc: number[] = []) {
    const childItem = this.propertyTypes.get(childId);
    if (childItem?.parentId) {
      acc.push(childItem.parentId);
      const parentItem = this.propertyTypes.get(childItem.parentId);
      parentItem && this.makePath(parentItem.propertyCodeId, acc);
    }
    return acc;
  }

  @action
  setShowInTree(values: PropertyTypeCode[], item: TreeItem) {
    this.addValues(values);

    const res = this.makePath(parseInt(item.id as string), []);
    this.previewPathIds = res;
  }

  @action resetSearchResults() {
    this.searchResultIds = [];
  }

  @action resetPreviewPathsId() {
    this.previewPathIds = [];
  }

  @action setPreviewPathIds(path: Array<PropertyTypeCode['propertyCodeId']>) {
    this.previewPathIds = path;
  }

  @action
  setError(
    error: ApiError | UnknownError,
    retryState?: ApiRetryState<PropertyTypeCode[]>
  ) {
    this.error = error;
    if (retryState) {
      this.retryState = retryState;
    }
  }

  @action
  clearError() {
    this.error = undefined;
    this.retryState = undefined;
  }
}
