import React, { ComponentType, FC } from 'react';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { useBilateralSelected } from 'hooks/useBilateralSelected';
import { useGetPanelById } from 'hooks/useGetPanelById';
import { observer } from 'mobx-react-lite';

/**
 * Bilateral means that sensors from the left and right sides are selected.
 *
 * Need to use attributes from SVG to check the side of the sensor.
 * e.g. Left sensors have attribute data-position-left="True", right sensors have attribute data-position-right="True"
 *
 * Property type is dependant on Bilateral rules if it has a property requiresBilateralLocationCoding.
 * In that case we wrap original component with watchable HOC and check selected sensors
 */
export const withBilateral = <
  P extends {
    disabled?: boolean;
    panelId: EventPropertyPanel['eventPropertyPanelId'];
  }
>(
  getPropertyType: (props: P) => EventPropertyType
) => (WrappedComponent: ComponentType<P>) => {
  const WatchedComponent: FC<P> = observer((props) => {
    const panel = useGetPanelById(props.panelId);

    const isEnabled = useBilateralSelected(panel!);

    return <WrappedComponent {...props} disabledByBilateral={!isEnabled} />;
  });

  const Wrapped: FC<P> = (props) => {
    const propertyType = getPropertyType(props);

    return !propertyType.requiresBilateralLocationCoding ? (
      <WrappedComponent {...props} />
    ) : (
      <WatchedComponent {...props} />
    );
  };

  return Wrapped;
};
