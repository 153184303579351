import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IconType, Input, TableColumn } from '@holberg/ui-kit';
import { CustomDragLayer } from 'components/CustomDragLayer/CustomDragLayer';
import { TableConfig } from 'entities/TableConfig.entity';

import { ColumnsList } from '../ColumnsList';

import styles from './ColumnsSettings.module.scss';

interface Props<T extends object> {
  columns: Array<TableColumn<T>>;
  onUpdateConfigValues: (
    data: Array<TableColumn<T>>,
    key: keyof TableConfig
  ) => void;
  onUpdateTableConfig: (
    column: keyof T,
    key: keyof TableConfig,
    value: TableConfig[keyof TableConfig]
  ) => void;
}

export const ColumnsSettings: <T extends object>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  columns,
  onUpdateTableConfig,
  onUpdateConfigValues
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredColumns = columns.filter(({ Header }) => {
    if (typeof Header === 'string') {
      return String(Header).toLowerCase().includes(searchValue.toLowerCase());
    }
    return false;
  });

  return (
    <>
      <div className={styles.search}>
        <Input
          cancelable
          value={searchValue}
          icon={IconType.Search}
          placeholder='Type to search...'
          onChange={({ target }) => setSearchValue(target.value)}
        />
      </div>

      <DndProvider backend={HTML5Backend}>
        <CustomDragLayer />
        <ColumnsList
          columns={filteredColumns}
          isDragDisabled={!!searchValue.length}
          onUpdateTableConfig={onUpdateTableConfig}
          onUpdateConfigValues={onUpdateConfigValues}
        />
      </DndProvider>
    </>
  );
};
