import { deserialize, list, primitive, serializable } from 'serializr';

export class RTUConfig {
  @serializable(list(primitive()))
  activeStudyIds?: number[] = [];

  @serializable
  activeEventId?: number;

  @serializable
  isUserInteractionsBlocked?: boolean = false;

  @serializable
  isHubConnected?: boolean = true;

  @serializable
  isReaderConnected?: boolean = true;

  @serializable
  isTerminated?: boolean = false;

  @serializable
  isReaderAdminConnected?: boolean = false;

  static deserialize(json: Object | string) {
    return deserialize(RTUConfig, json);
  }
}
