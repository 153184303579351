import React, { CSSProperties, Fragment, useEffect, useMemo } from 'react';
import {
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form/dist/index.ie11';
import { useParams } from 'react-router-dom';
import { Button, ButtonSize, IconType } from '@holberg/ui-kit';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { PropertyTypeCode } from 'entities/PropertyTypeCode.entity';
import { PropertyType } from 'enums/PropertyType.enum';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { usePageMatch } from 'hooks/usePageMatch';
import { observer } from 'mobx-react-lite';
import { CommonPropertyType } from 'stores/property-type-codes';
import { PropertyTypeCodesRules } from 'stores/property-type-codes/PropertyTypeCodesRules';

import { FormRow } from '../FormRow';
import { FormSelectRow } from '../FormSelectRow';
import { FormSelectProps } from '../FormSelectRow/FormSelectRow';

import styles from './FormRowSection.module.scss';

const selectCustomStyles: CSSProperties = {
  left: -170
};

interface Props {
  readOnly?: boolean;
  hasNoteField?: boolean;
  isMultiSelect?: boolean;
  component?: React.FC<FormSelectProps>;
  includeCode?: boolean;
  categoricalPropertyType?: PropertyType;
  propertyType: CommonPropertyType;
  addButtonTitle?: string;
  placeholder?: string;
  isOptionSelectable?: (
    propertyCodeId: PropertyTypeCode['propertyCodeId']
  ) => boolean;
  formAccessorName: string;
  onSelectSaveAction?: (
    propertyType: CommonPropertyType,
    newPropertyCodeId?: string,
    oldPropertyCodeId?: string
  ) => void;
  onNoteSaveAction?: (
    propertyType: CommonPropertyType,
    freeText: string,
    currentPropertyCodeId: string
  ) => void;
}

export const FormRowSection: React.FC<Props> = observer(
  ({
    component = FormSelectRow,
    categoricalPropertyType = PropertyType.Patient,
    readOnly = false,
    isOptionSelectable,
    hasNoteField = true,
    isMultiSelect = false,
    addButtonTitle,
    placeholder,
    propertyType,
    formAccessorName,
    onSelectSaveAction = () => {},
    onNoteSaveAction = () => {},
    includeCode
  }) => {
    const { id } = useParams<{ id: string }>();
    const isStudyPage = usePageMatch(Routes.Study);
    const patientDetailsStore = useStore(StoreType.PatientDetails);
    const descriptionDetailsStore = useStore(StoreType.Descriptions);

    const ageConstraints = isStudyPage
      ? descriptionDetailsStore.descriptionById(parseInt(id))!.ageConstraints
      : patientDetailsStore.patientById(id).ageConstraints;

    const { control } = useFormContext<{
      patientDetails: PatientDetails;
      patientCodings: Record<number, CategoricalPropertyCoding['formShape'][]>;
    }>();

    const name = useMemo(() => `${formAccessorName}[${propertyType}]`, [
      formAccessorName,
      propertyType
    ]);

    const { fields, append, remove } = useFieldArray<
      CategoricalPropertyCoding['formShape']
    >({
      control,
      name
    });

    const FormSelectComponent = component;

    const watchedFields = useWatch({
      control,
      name: fields.map((_field, index) => `${name}[${index}].value`)
    });

    const watchedFieldsData: typeof fields = Object.values(
      watchedFields
    ).map((valueField) => ({ value: valueField }));

    useEffect(() => {
      if (!fields.length) {
        append(
          {
            freeText: '',
            value: null
          },
          false
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields.length]);

    const hasSelectValues = watchedFieldsData.every(({ value }) => {
      return value?.value;
    });

    const selectStyles = isStudyPage ? selectCustomStyles : {};

    return (
      <div>
        {fields.map(({ value, freeText, id }, index) => {
          const fieldArrayItemAccessor = `${name}[${index}]`;

          return (
            <Fragment key={id}>
              <FormRow
                readOnly={readOnly}
                hasNoteField={hasNoteField}
                defaultValue={{ value, freeText }}
                onRemoveClick={(oldPropertyCodeId) => {
                  remove(index);
                  onSelectSaveAction(
                    propertyType,
                    undefined,
                    oldPropertyCodeId
                  );
                }}
                fieldArrayAccessor={fieldArrayItemAccessor}
                className={isMultiSelect ? styles.row : undefined}
                onNoteSaveAction={(freeText, currentPropertyCodeId) => {
                  onNoteSaveAction(
                    propertyType,
                    freeText,
                    currentPropertyCodeId
                  );
                }}
              >
                <FormSelectComponent
                  readOnly={readOnly}
                  isOptionSelectable={isOptionSelectable}
                  includeCode={includeCode}
                  hasNoteField={hasNoteField}
                  propertyType={propertyType}
                  selectStyles={selectStyles}
                  ageConstraints={ageConstraints}
                  removeRow={() => {
                    remove(index);
                  }}
                  placeholder={placeholder}
                  defaultValue={{ value, freeText }}
                  selectedPropertyTypeCodes={watchedFieldsData}
                  fieldArrayItemAccessor={fieldArrayItemAccessor}
                  categoricalPropertyType={categoricalPropertyType}
                  onSelectSaveAction={(
                    newPropertyCodeId,
                    oldPropertyCodeId
                  ) => {
                    onSelectSaveAction(
                      propertyType,
                      newPropertyCodeId,
                      oldPropertyCodeId
                    );
                  }}
                />
              </FormRow>
            </Fragment>
          );
        })}
        {isMultiSelect && !readOnly && (
          <Button
            onClick={() => {
              append({
                freeText: '',
                value: null
              });
            }}
            title={addButtonTitle}
            icon={IconType.Plus}
            size={ButtonSize.Small}
            disabled={
              !hasSelectValues ||
              !watchedFieldsData.length ||
              PropertyTypeCodesRules.isMultipleRowsDisabled(watchedFieldsData)
            }
          />
        )}
      </div>
    );
  }
);
