export enum StoreType {
  Auth = 'auth',
  Patient = 'patient',
  Study = 'study',
  Descriptions = 'descriptions',
  PatientDetails = 'patientDetails',
  PatientReports = 'patientReports',
  PatientLockDetails = 'patientLockDetails',
  StudyDetails = 'studyDetails',
  Studies = 'studies',
  I18n = 'i18n',
  PatientPropertyTypeCodes = 'patientPropertyTypeCodes',
  StudyPropertyTypeCodes = 'studyPropertyTypeCodes',
  DescriptionPropertyTypeCodes = 'descriptionPropertyTypeCodes',
  PatientPropertyTypes = 'patientPropertyTypes',
  StudyPropertyTypes = 'studyPropertyTypes',
  DescriptionPropertyTypes = 'descriptionPropertyTypes',
  Messages = 'messages',
  Findings = 'findings',
  FindingProperties = 'findingProperties',
  RealTimeUpdates = 'realTimeUpdates',
  HeadModel = 'headModel',
  Site = 'site'
}
