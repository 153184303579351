import { deserialize, serializable } from 'serializr';

export class PropertyCodingUpdateDTO {
  @serializable
  propertyCodeId?: number;

  @serializable
  freeText?: string;

  static deserialize(json: Object | string) {
    return deserialize(PropertyCodingUpdateDTO, json);
  }
}
