import { computed } from 'mobx';
import { date, deserialize, serializable } from 'serializr';
import { formatDate } from 'utils/dateHelpers';

export class EventCoding {
  @serializable
  eventCodingId: number = 0;

  @serializable
  eventCodeId: number = 0;

  @serializable
  parentId?: number;

  @serializable
  descriptionId: number = 0;

  @serializable
  sortOrder: number | null = null;

  @serializable
  isActive: boolean = false;

  @serializable
  isAbnormal: boolean = false;

  @serializable
  freeText?: string = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable
  showTimestampInReport: boolean = false;

  @computed
  get formattedDateOfEvent() {
    return formatDate(this.createdAt, 'HH:mm:ss dd-MM-yyyy', '');
  }

  static deserialize(json: Object | string) {
    return deserialize(EventCoding, json);
  }

  static deserializeAsList(list: EventCoding[]): EventCoding[] {
    return list.map(EventCoding.deserialize);
  }

  static deserializeAsMap(
    list: EventCoding[]
  ): Map<EventCoding['eventCodingId'], EventCoding> {
    return list.reduce((acc, eventCoding) => {
      acc.set(eventCoding.eventCodingId, EventCoding.deserialize(eventCoding));

      return acc;
    }, new Map());
  }
}
