import { LocationType } from 'enums/LocationType.enum';
import { deserialize, serializable } from 'serializr';

export class HeadModel {
  @serializable
  headModelSvg: string = '';

  @serializable
  locationTypeId: LocationType = LocationType.Onset;

  static deserialize(json: Object | string) {
    return deserialize(HeadModel, json);
  }
}
