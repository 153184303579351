import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

export interface ProtectedRouteProps extends RouteProps {
  redirectPath?: Routes;
  forAuthenticated?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(
  ({ redirectPath = Routes.Root, forAuthenticated = true, ...rest }) => {
    const authStore = useStore(StoreType.Auth);

    const shouldRedirect = forAuthenticated
      ? authStore.isAuthenticated
      : !authStore.isAuthenticated;

    return shouldRedirect ? (
      <Route {...rest} data-testid='route' />
    ) : (
      <Redirect to={redirectPath} />
    );
  }
);
