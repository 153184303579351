import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form/dist/index.ie11';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { EventPropertyTypeCategorical } from 'entities/EventPropertyTypeCategorical.entity';
import { EventPropertyCodingsData } from 'stores/finding-properties';

export const useRequiredPropertyTypeSelected = (
  requiredPropertyTypeId: EventPropertyType['requiredEventPropertyTypeInCodingId'],
  panel: EventPropertyPanel
): boolean => {
  const { control } = useFormContext<{
    eventPropertyCodings: EventPropertyCodingsData;
  }>();

  const categoricalPropertyType = useMemo<
    EventPropertyTypeCategorical | undefined
  >(
    () =>
      panel.categoricalPropertyTypes.find(
        (categorical) =>
          categorical.propertyType.eventPropertyTypeId ===
          requiredPropertyTypeId
      ),
    [panel, requiredPropertyTypeId]
  );

  const notScored = useMemo(
    () =>
      categoricalPropertyType?.propertyCodes.find(
        (code) => code.isNotScoredProperty
      ),
    [categoricalPropertyType]
  );

  const watchableFields = useMemo(
    () =>
      categoricalPropertyType
        ? [
            ...categoricalPropertyType.propertyCodes.map(
              (code) =>
                `eventPropertyCodings.categorical.${requiredPropertyTypeId}.${code.eventPropertyCodeId}`
            ),
            `eventPropertyCodings.categorical.${requiredPropertyTypeId}.radio`
          ]
        : [],
    [categoricalPropertyType, requiredPropertyTypeId]
  );

  const propertyTypeValues = useWatch<{
    [key: string]: boolean | string | undefined;
  }>({
    control,
    name: watchableFields
  });

  if (!propertyTypeValues || !categoricalPropertyType) {
    return false;
  }

  return notScored
    ? propertyTypeValues[
        `eventPropertyCodings.categorical.${requiredPropertyTypeId}.radio`
      ] !== `${notScored.eventPropertyCodeId}`
    : Object.values(propertyTypeValues).some(
        (value) => value !== false && value !== undefined
      );
};
