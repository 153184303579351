import { useEffect, useRef, useState } from 'react';
import { getCurrentTimeInSeconds } from 'utils/dateHelpers';

export const useActivityTracker = () => {
  const [trackerActive, setTrackerActive] = useState(false);
  const lastActiveTime = useRef<null | number>();

  function updateLastActiveTime() {
    lastActiveTime.current = getCurrentTimeInSeconds();
  }

  function startTracker() {
    setTrackerActive(true);
  }

  function stopTracker() {
    setTrackerActive(false);
  }

  function getLastActiveTime() {
    return lastActiveTime.current
      ? getCurrentTimeInSeconds() - lastActiveTime.current
      : 0;
  }

  useEffect(() => {
    if (trackerActive) {
      lastActiveTime.current = getCurrentTimeInSeconds();
      window.addEventListener('mousemove', updateLastActiveTime);
      window.addEventListener('keydown', updateLastActiveTime);
      window.addEventListener('scroll', updateLastActiveTime);
    }
    return () => {
      lastActiveTime.current = null;
      window.removeEventListener('mousemove', updateLastActiveTime);
      window.removeEventListener('keydown', updateLastActiveTime);
      window.removeEventListener('scroll', updateLastActiveTime);
    };
  }, [trackerActive]);

  return { startTracker, stopTracker, getLastActiveTime };
};
