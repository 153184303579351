import { useParams } from 'react-router-dom';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { StoreType } from 'enums/StoreType.enum';

import { useStore } from './store';

export const usePanelDisabled = (panel?: EventPropertyPanel) => {
  const findingsStore = useStore(StoreType.Findings);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const { id } = useParams<{ id: string }>();

  return panel?.requiredEventCodeId && panel.requiredEventCodeId !== 0
    ? !findingsStore.eventCodingsForEventCode(
        descriptionsStore.descriptionById(Number(id))?.descriptionId,
        panel.requiredEventCodeId
      ).length
    : false;
};
