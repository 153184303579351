import { StatusIndicatorStatus } from '@holberg/ui-kit';
import { ApiError } from 'entities/ApiError.entity';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { PatientUpdateDTO } from 'entities/PatientUpdateDTO.entity';
import { PropertyCodingCreateDTO } from 'entities/PropertyCodingCreateDTO.entity';
import { PropertyCodingUpdateDTO } from 'entities/PropertyCodingUpdateDTO.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { RealTimeUpdateReceiveMessages } from 'enums/RealTimeUpdateType.enum';
import { SaveOperationType } from 'enums/SaveOperationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, computed, makeObservable, observable } from 'mobx';
import { ApiLoadingState } from 'services/API/ApiLoadingState';
import { ApiRetryManager } from 'services/API/ApiRetryManager';
import { PatientApi } from 'services/API/Patient/PatientApi';
import {
  PatientListRTUData,
  PatientRTUData,
  RTUManager,
  StudyRTUData
} from 'services/RealTimeUpdatesManager';
import { stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';
import { CategoricalPropertyCodingState } from 'stores/categorical-property-coding/CategoricalPropertyCodingState';
import { CommonPropertyType } from 'stores/property-type-codes';

const notFoundPatientDetails = PatientDetails.deserialize({});

export class PatientDetailsStore implements BaseStore {
  @observable
  readonly categoricalPropertyCodingState: CategoricalPropertyCodingState = new CategoricalPropertyCodingState();

  @observable
  categoricalPropertyCodingsLoading!: boolean;

  @observable
  categoricalPropertyCodingsError?: ApiError | UnknownError;

  @observable
  readonly retryManager: ApiRetryManager = new ApiRetryManager();

  @observable
  readonly apiLoadingState: ApiLoadingState = new ApiLoadingState();

  @observable
  patientDetails!: Map<number, ReturnType<typeof PatientDetails.deserialize>>;

  @observable
  patientDetailsLoading!: boolean;

  constructor() {
    makeObservable(this);
    this.reset();

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.PatientUpdated,
        callback: (data) => this.updatePatient(data)
      },
      {
        message: RealTimeUpdateReceiveMessages.StudyUpdated,
        callback: (data) => this.updatePatient(data)
      }
    ]);

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.PatientListUpdated,
        callback: (data) => this.updatePatientRTU(data)
      }
    ]);
  }

  @action reset() {
    this.categoricalPropertyCodingsLoading = false;
    this.categoricalPropertyCodingsError = undefined;
    this.patientDetails = new Map();
    this.patientDetailsLoading = false;
    this.categoricalPropertyCodingState.reset();
    this.apiLoadingState.reset();
    this.retryManager.stopAll();
  }

  patientById(id: string): PatientDetails {
    return this.patientDetails.get(parseInt(id)) || notFoundPatientDetails;
  }

  propertyCodings(
    id: string
  ): ReturnType<typeof CategoricalPropertyCoding.deserializeAsMap> {
    return this.categoricalPropertyCodingState.categoricalPropertyCodingsById(
      id
    );
  }

  @action
  private updatePatient(data: PatientRTUData | StudyRTUData) {
    const patientId =
      (data as PatientRTUData).patientId || data.patient?.patientId;

    if (data.patient) {
      this.patientDetails.set(
        patientId!,
        PatientDetails.deserialize(data.patient)
      );
    }

    if (data.patientPropertyCodings) {
      this.categoricalPropertyCodingState.addCategoricalPropertyCodings(
        patientId!,
        data.patientPropertyCodings
      );
    }
  }

  @action
  private updatePatientRTU(data: PatientListRTUData) {
    for (const eachPatient of data.PatientListChanges) {
      this.updatePatient(eachPatient);
    }
  }

  @computed
  get hasGiveUpSaving(): boolean {
    return this.retryManager.hasGiveUp;
  }

  @computed
  get saveStatus(): StatusIndicatorStatus {
    if (this.apiLoadingState.isAnyLoading || this.retryManager.isRetrying) {
      return StatusIndicatorStatus.Loading;
    }

    if (this.retryManager.errors.length) {
      return StatusIndicatorStatus.Error;
    }

    return StatusIndicatorStatus.Saved;
  }

  @action
  retryAllRequests() {
    this.retryManager.retryAll();
  }

  @action
  clearRetryRequests() {
    this.apiLoadingState.reset();
    this.retryManager.stopAll();
  }

  @action
  clearPropertyCodingsError() {
    this.categoricalPropertyCodingsError = undefined;
  }

  @action
  clearAllRequests() {
    this.clearRetryRequests();
    this.clearPropertyCodingsError();
  }

  @action
  async loadPatientCategoricalPropertyCodings(patientId: number) {
    this.categoricalPropertyCodingsLoading = true;
    this.categoricalPropertyCodingsError = undefined;
    try {
      const { data } = await PatientApi.loadCategoricalPropertyCodings(
        patientId
      );

      this.categoricalPropertyCodingState.addCategoricalPropertyCodings(
        patientId,
        data
      );
    } catch (e) {
      this.categoricalPropertyCodingsError = ApiError.deserializeFromCatch(e);
    } finally {
      this.categoricalPropertyCodingsLoading = false;
    }
  }

  @action
  async loadPatientDetails(patientId: PatientDetails['patientId']) {
    this.patientDetailsLoading = true;
    this.categoricalPropertyCodingsError = undefined;
    try {
      const { data } = await PatientApi.loadPatientDetails(patientId);

      await this.loadPatientCategoricalPropertyCodings(patientId);
      this.patientDetails.set(patientId, PatientDetails.deserialize(data));
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.patientDetailsLoading = false;
    }
  }

  @action
  async updatePatientDetails(
    value: string | null,
    patientId: string,
    propertyName: string
  ) {
    const saveIdentifier = `${patientId}_${propertyName}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = PatientUpdateDTO.deserialize({
      [propertyName]: value
    });

    try {
      const { data } = await PatientApi.updatePatientDetails(
        parseInt(patientId),
        updateDto
      );

      const updatePatient = PatientDetails.deserialize(data);
      this.patientDetails.set(updatePatient.patientId, updatePatient);
      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = PatientApi.createRetryState<PatientDetails>(e);
      retryState?.addEventListener('success', () => {
        if (retryState.result) {
          const { data } = retryState.result;
          const updatePatient = PatientDetails.deserialize(data);
          this.patientDetails.set(updatePatient.patientId, updatePatient);
        }

        stores[StoreType.Messages].removeError(saveIdentifier);
      });
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async createPropertyCodings(
    patientId: string,
    newPropertyCodeId: string,
    propertyTypeId: CommonPropertyType,
    descriptionId?: number
  ) {
    const saveIdentifier = `${SaveOperationType.SAVE}_${patientId}_${propertyTypeId}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const createDto = PropertyCodingCreateDTO.deserialize({
      propertyCodeId: parseInt(newPropertyCodeId),
      patientId: parseInt(patientId),
      propertyTypeId
    });

    try {
      const { data } = await PatientApi.createCategoricalPropertyCoding(
        parseInt(patientId),
        createDto,
        descriptionId ? descriptionId : undefined
      );

      this.categoricalPropertyCodingState.addCategoricalPropertyCoding({
        entityId: parseInt(patientId),
        propertyTypeId,
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = PatientApi.createRetryState<CategoricalPropertyCoding>(
        e
      );

      retryState?.addEventListener('success', () => {
        if (retryState.result) {
          this.categoricalPropertyCodingState.addCategoricalPropertyCoding({
            entityId: parseInt(patientId),
            propertyTypeId,
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }

        stores[StoreType.Messages].removeError(saveIdentifier);
      });

      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async updatePropertyCodings({
    patientId,
    newPropertyCodeId,
    propertyTypeId,
    oldPropertyCodeId,
    descriptionId
  }: {
    patientId: string;
    newPropertyCodeId?: string;
    propertyTypeId: CommonPropertyType;
    oldPropertyCodeId?: string;
    descriptionId?: number;
  }) {
    if (!oldPropertyCodeId && !!newPropertyCodeId) {
      this.createPropertyCodings(
        patientId,
        newPropertyCodeId,
        propertyTypeId,
        descriptionId
      );
      return;
    }

    if (!!oldPropertyCodeId && !newPropertyCodeId) {
      this.deletePropertyCodings(patientId, oldPropertyCodeId, propertyTypeId);
      return;
    }

    if (!(oldPropertyCodeId && newPropertyCodeId)) {
      return;
    }

    const saveIdentifier = `${SaveOperationType.UPDATE}_${patientId}_${propertyTypeId}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = PropertyCodingUpdateDTO.deserialize({
      propertyCodeId: parseInt(newPropertyCodeId)
    });

    try {
      const { data } = await PatientApi.updateCategoricalPropertyCoding({
        patientId: parseInt(patientId),
        dto: updateDto,
        propertyTypeId,
        currentPropertyCodeId: oldPropertyCodeId,
        descriptionId: descriptionId ? descriptionId : undefined
      });

      this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
        entityId: parseInt(patientId),
        propertyTypeId,
        propertyCodeId: parseInt(oldPropertyCodeId),
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = PatientApi.createRetryState<CategoricalPropertyCoding>(
        e
      );
      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        if (retryState.result) {
          this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
            entityId: parseInt(patientId),
            propertyTypeId,
            propertyCodeId: parseInt(oldPropertyCodeId),
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }
      });

      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async deletePropertyCodings(
    patientId: string,
    propertyCodeId: string,
    propertyTypeId: CommonPropertyType
  ) {
    const saveIdentifier = `${SaveOperationType.DELETE}_${patientId}_${propertyTypeId}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    try {
      await PatientApi.deleteCategoricalPropertyCoding(
        parseInt(patientId),
        propertyTypeId,
        propertyCodeId
      );

      this.categoricalPropertyCodingState.deleteCategoricalPropertyCoding({
        entityId: parseInt(patientId),
        propertyTypeId,
        propertyCodeId: parseInt(propertyCodeId)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = PatientApi.createRetryState(e);
      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        this.categoricalPropertyCodingState.deleteCategoricalPropertyCoding({
          entityId: parseInt(patientId),
          propertyTypeId,
          propertyCodeId: parseInt(propertyCodeId)
        });
      });
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async updatePropertyCodingsNote({
    patientId,
    currentPropertyCodeId,
    propertyTypeId,
    descriptionId,
    freeText
  }: {
    patientId: string;
    currentPropertyCodeId: string;
    propertyTypeId: CommonPropertyType;
    freeText: string;
    descriptionId?: number;
  }) {
    const saveIdentifier = `${SaveOperationType.UPDATE}_${patientId}_${propertyTypeId}_freeText`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = PropertyCodingUpdateDTO.deserialize({ freeText });

    try {
      const { data } = await PatientApi.updateCategoricalPropertyCoding({
        patientId: parseInt(patientId),
        dto: updateDto,
        propertyTypeId,
        currentPropertyCodeId,
        descriptionId: descriptionId ? descriptionId : undefined
      });

      this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
        entityId: parseInt(patientId),
        propertyTypeId,
        propertyCodeId: parseInt(currentPropertyCodeId),
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = PatientApi.createRetryState<CategoricalPropertyCoding>(
        e
      );
      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        if (retryState.result) {
          this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
            entityId: parseInt(patientId),
            propertyTypeId,
            propertyCodeId: parseInt(currentPropertyCodeId),
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }
      });
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }
}
