import React, { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form/dist/index.ie11';
import { Card, CardTheme } from '@holberg/ui-kit';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { PropertyTabsPanelForm } from 'components/PropertyTabsPanelForm';
import { ApiError } from 'entities/ApiError.entity';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyPanelFocused } from 'entities/EventPropertyPanelFocused.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { EventPropertyCodingsData } from 'stores/finding-properties';

import styles from './PropertyTabsPanel.module.scss';

interface Props {
  panel: EventPropertyPanel;
  ageConstraint: string;
  focusedProperty: EventPropertyPanelFocused;
}
interface FormProps {
  panel: EventPropertyPanel;
  eventPropertyCodings: EventPropertyCodingsData;
  focusedProperty: EventPropertyPanelFocused;
  error?: ApiError | UnknownError;
}

const PanelForm: React.FC<FormProps> = memo(
  ({ panel, eventPropertyCodings, focusedProperty, error }) => {
    const methods = useForm<{
      eventPropertyCodings: EventPropertyCodingsData;
    }>({
      defaultValues: { eventPropertyCodings },
      mode: 'onChange',
      reValidateMode: 'onChange',
      shouldUnregister: false
    });

    useEffect(() => {
      if (error) {
        methods.reset({ eventPropertyCodings });
      }

      // eslint-disable-next-line
  }, [eventPropertyCodings]);

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(() => {})}>
          <PropertyTabsPanelForm
            panel={panel}
            focusedProperty={focusedProperty}
          />
        </form>
      </FormProvider>
    );
  },
  (prevProps, nextProps) =>
    prevProps.error === nextProps.error &&
    JSON.stringify(prevProps.eventPropertyCodings) ===
      JSON.stringify(nextProps.eventPropertyCodings)
);

export const PropertyTabsPanel: React.FC<Props> = observer(
  ({ panel, ageConstraint, focusedProperty }) => {
    const { activeCodingId } = usePropertiesContext();
    const findingPropertiesStore = useStore(StoreType.FindingProperties);
    const eventPropertyCodings = findingPropertiesStore.getPropertyCodings(
      ageConstraint,
      activeCodingId!,
      panel?.eventPropertyPanelId
    );

    if (!panel) {
      return null;
    }

    return (
      <div data-testid='properties-panel'>
        <Card theme={CardTheme.Form} isDisabled={panel.isDisabled}>
          <div className={styles.panel}>
            <p className={styles.title}>{panel.translatedName.eitherValue}</p>
            {findingPropertiesStore.propertyCodings.get(activeCodingId!) &&
              eventPropertyCodings && (
                <PanelForm
                  panel={panel}
                  eventPropertyCodings={eventPropertyCodings}
                  error={findingPropertiesStore.propertyCodingsUpdateErrors.get(
                    panel.eventPropertyPanelId
                  )}
                  focusedProperty={focusedProperty}
                />
              )}
          </div>
        </Card>
      </div>
    );
  }
);
