import React, { FC } from 'react';
import {
  ActionBanner,
  ActionBannerTheme,
  useCustomTranslation
} from '@holberg/ui-kit';
import { HeadModel } from 'components/HeadModel';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { HDataType } from 'enums/HDataType.enum';
import { LocationType } from 'enums/LocationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import styles from './HeadModelPropertyType.module.scss';

interface Props {
  propertyType: EventPropertyType;
  panelId: EventPropertyPanel['eventPropertyPanelId'];
}

export const HeadModelPropertyType: FC<Props> = observer(
  ({ propertyType, panelId }) => {
    const { t } = useCustomTranslation();

    const headModelsStore = useStore(StoreType.HeadModel);
    const { activeCodingId } = usePropertiesContext();

    const headModels = headModelsStore.getHeadModels(activeCodingId!);
    const hasInvisibleSensors =
      !!activeCodingId &&
      !!headModelsStore.headModels.get(activeCodingId)?.sensorsNotForHeadModel;
    const headModel =
      propertyType.dataTypeId === HDataType.LocationOnset
        ? headModels.onset
        : propertyType.dataTypeId === HDataType.LocationOnsetPropagation
        ? headModels.propagation
        : undefined;

    if (!headModel) {
      return null;
    }

    return (
      <div className={styles.container} data-testid='head-model-property-type'>
        {hasInvisibleSensors && (
          <ActionBanner
            title={t('A number of sensors cannot be shown in the head model.')}
            theme={ActionBannerTheme.Warning}
            className={styles.warning}
          />
        )}
        <div className={styles.card}>
          <HeadModel
            data={headModel.headModelSvg}
            locationType={
              propertyType.dataTypeId === HDataType.LocationOnset
                ? LocationType.Onset
                : LocationType.Propagation
            }
            panelId={panelId}
          />
        </div>
      </div>
    );
  }
);
