import { isNullish } from '@holberg/ui-kit';
import { computed, makeObservable } from 'mobx';
import { date, deserialize, object, serializable } from 'serializr';
import { formatLocaleDate } from 'utils/dateHelpers';

import { InformationModel } from './InformationModel.entity';

export class PatientDetails {
  @serializable
  patientId: number = 0;

  @serializable
  insuranceNumber?: string;

  @serializable
  address?: string;

  @serializable
  postalCode?: string;

  @serializable
  postalCodeName?: string;

  @serializable
  phoneNumber?: string;

  @serializable
  site?: string;

  @serializable
  firstName: string = '';

  @serializable
  lastName: string = '';

  @serializable
  patientHistory?: string = '';

  @serializable
  patientNotes?: string = '';

  @serializable
  internalPatientNotes?: string = '';

  @serializable
  scalpDefects?: string = '';

  @serializable
  middleName?: string = '';

  @serializable
  ageConstraints: string = 'all';

  @serializable
  identityString: string = '';

  @serializable(date())
  dateOfBirth: Date = new Date();

  @serializable(date())
  dateOfDeath?: Date = undefined;

  @serializable(object(InformationModel))
  correctedAge: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  postMenstrualAge: InformationModel = new InformationModel();

  @serializable
  gestationalAgeInWeeks?: number;

  @serializable
  gestationalAgeInDaysExceedingWeeks?: number;

  @serializable(object(InformationModel))
  age: InformationModel = new InformationModel();

  constructor() {
    makeObservable(this);
  }

  @computed
  get formattedCorrectedAge() {
    return this.correctedAge?.eitherValue || '-';
  }

  @computed
  get formattedPostMenstrualAge() {
    return this.postMenstrualAge?.eitherValue || '-';
  }

  @computed
  get fullName(): string {
    if (isNullish(this.lastName)) {
      return '';
    }

    return `${this.lastName}, ${this.firstName} ${this.middleName}`;
  }

  @computed
  get formattedAge() {
    return this.age?.eitherValue;
  }

  @computed
  get gestationalAgeInDaysOption() {
    if (this.gestationalAgeInDaysExceedingWeeks) {
      return {
        value: String(this.gestationalAgeInDaysExceedingWeeks),
        label: `${this.gestationalAgeInDaysExceedingWeeks} days`
      };
    }
    return {
      value: undefined,
      label: ''
    };
  }

  @computed
  get gestationalAgeInWeeksOption() {
    if (this.gestationalAgeInWeeks) {
      return {
        value: String(this.gestationalAgeInWeeks),
        label: `${this.gestationalAgeInWeeks} weeks`
      };
    }
    return {
      value: undefined,
      label: ''
    };
  }

  @computed
  get formattedDateOfBirth() {
    return formatLocaleDate(this.dateOfBirth);
  }

  @computed
  get formattedDateOfDeath() {
    return formatLocaleDate(this.dateOfDeath, '', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  static deserialize(json: Object | string) {
    return deserialize(PatientDetails, json);
  }

  static deserializeAsList(list: PatientDetails[]): PatientDetails[] {
    return list.map(PatientDetails.deserialize);
  }
}
