import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadingOverlay } from '@holberg/ui-kit';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { ExternalLoginPage } from 'pages/ExternalLoginPage';
import { LoginPage } from 'pages/LoginPage';
import { PatientOverviewPage } from 'pages/PatientOverviewPage/PatientOverviewPage';
import { PatientsPage } from 'pages/PatientsPage';
import { StudyDetailsPage } from 'pages/StudyDetailsPage';

export const App: React.FC = observer(() => {
  const authStore = useStore(StoreType.Auth);
  const loadingStudyFromReader = authStore.loadingStudyFromReader;

  useEffect(() => {
    if (!authStore.licenseStatus.clientName) {
      //gets license info of server
      authStore.getLicenseStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingOverlay
      loading={loadingStudyFromReader && !authStore.authError}
      isSplashScreen
    >
      <Switch>
        <Route path={Routes.Root} exact>
          <Redirect to={Routes.Login} />
        </Route>

        <Route path={Routes.ExternalLogin} exact>
          <ExternalLoginPage />
        </Route>

        <ProtectedRoute
          path={Routes.Login}
          redirectPath={Routes.Patients}
          exact
          forAuthenticated={false}
        >
          <LoginPage />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Patients} exact>
          <PatientsPage />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.PatientOverview} exact>
          <PatientOverviewPage />
        </ProtectedRoute>

        <ProtectedRoute path={Routes.Study}>
          <StudyDetailsPage />
        </ProtectedRoute>
      </Switch>
    </LoadingOverlay>
  );
});
