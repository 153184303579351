import { Cell, SortingRule } from 'react-table';
import { TableColumn } from '@holberg/ui-kit';
import { AxiosError } from 'axios';
import { CustomCellAccessor } from 'components/CustomTableCell';
import { ApiError } from 'entities/ApiError.entity';
import { Condition } from 'entities/Condition.entity';
import { FilterCriteria } from 'entities/FilterCriteria.entity';
import { GenericColumnMetaInformation } from 'entities/GenericColumnMetaInformation.entity';
import { GenericListItem } from 'entities/GenericListItem.entity';
import { GenericListPagination } from 'entities/GenericListPagination.entity';
import { InformationModel } from 'entities/InformationModel.entity';
import { SortCriteria, SortCriteriaState } from 'entities/SortCriteria.entity';
import { TableConfig } from 'entities/TableConfig.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { GenericListPageType } from 'enums/GenericListPageType.enum';
import { HDataType } from 'enums/HDataType.enum';
import { SortDirection } from 'enums/SortDirection.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, computed, makeObservable, observable } from 'mobx';
import { GenericListApi } from 'services/API/GenericListApi';
import { PatientApi } from 'services/API/Patient/PatientApi';
import { AsyncStorage } from 'services/AsyncStorage';
import { stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';
import { TABLE_CELL_MIN_WIDTH } from 'utils/constants';
import { formatLocaleDate, formatLocaleDateTime } from 'utils/dateHelpers';

export const parseListItem = (
  item: InformationModel<string | number>,
  metaInfo: GenericColumnMetaInformation
) => {
  switch (metaInfo.dataType) {
    case HDataType.DateTime:
    case HDataType.Date:
      if (!item.secondaryValue) {
        return item.eitherValue;
      }

      const date = new Date(item.secondaryValue);

      return metaInfo.dataType === HDataType.DateTime
        ? formatLocaleDateTime(date, '', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          })
        : formatLocaleDate(date, '', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          });
    default:
      return item.eitherValue;
  }
};

export const tableValueAccessor = (
  propertyName: string | number,
  metaInfo: GenericColumnMetaInformation
) => (item: GenericListItem) => {
  const prop = item[propertyName];

  if (Array.isArray(prop)) {
    return (prop as InformationModel<string | number>[])
      .map((item) => parseListItem(item, metaInfo))
      .join(', ');
  }

  if (prop instanceof InformationModel) {
    return parseListItem(prop, metaInfo);
  }

  return prop;
};

export class GenericListPageStore implements BaseStore {
  constructor(pageType: GenericListPageType) {
    makeObservable<GenericListPageStore, 'updateGenericListConfig'>(this);
    this.pageType = pageType;
    const apiMapping = { [GenericListPageType.Patients]: PatientApi };
    this.genericApi = apiMapping[pageType];
    this.reset();
  }

  private readonly pageType: GenericListPageType;
  private readonly genericApi: GenericListApi;

  @observable tableConfig!: ReturnType<typeof TableConfig.deserializeAsMap>;
  @observable tableConfigLoading!: boolean;

  @observable
  columnMetaInfo!: ReturnType<
    typeof GenericColumnMetaInformation.deserializeAsMap
  >;
  @observable
  filterCriteria?: ReturnType<typeof FilterCriteria.deserialize>;
  @observable
  sortCriteria!: ReturnType<typeof SortCriteriaState.deserialize>;

  @observable metadataLoading!: boolean;
  @observable metadataError?: ApiError | UnknownError;

  @observable
  dataList!: ReturnType<typeof GenericListItem.deserializeAsList>;

  @observable dataListLoading!: boolean;
  @observable dataListInitialLoaded!: boolean;
  @observable dataListError?: ApiError | UnknownError;

  @observable
  pagination!: ReturnType<typeof GenericListPagination.deserialize>;

  @action reset() {
    this.tableConfig = TableConfig.deserializeAsMap([]);
    this.tableConfigLoading = false;
    this.columnMetaInfo = GenericColumnMetaInformation.deserializeAsMap([]);
    this.filterCriteria = undefined;
    this.sortCriteria = SortCriteriaState.deserialize({});
    this.metadataLoading = false;
    this.metadataError = undefined;
    this.dataList = [];
    this.dataListLoading = false;
    this.dataListInitialLoaded = false;
    this.dataListError = undefined;
    this.pagination = GenericListPagination.deserialize({});
  }

  @computed
  get columns(): TableColumn<GenericListItem>[] {
    return Object.values(this.tableConfig)
      .filter(({ propertyName }) => this.columnMetaInfo[propertyName])
      .map(
        ({ propertyName, order, isVisible, width = TABLE_CELL_MIN_WIDTH }) => {
          const metaInfo = this.columnMetaInfo[propertyName];
          const name = metaInfo.name;
          const minWidth = TABLE_CELL_MIN_WIDTH;
          const correctedWidth = width < minWidth ? minWidth : width;
          const isNotSortable = !metaInfo.isSortable;
          const isSorted = !!this.sortCriteria.asMap[propertyName];
          const isSortedDesc =
            this.sortCriteria.asMap[propertyName]?.sortDirection ===
            SortDirection.Descending;

          return {
            id: String(propertyName),
            Header: name.primaryValue,
            accessor: tableValueAccessor(propertyName, metaInfo),
            order,
            isVisible,
            width: correctedWidth,
            isSorted,
            isSortedDesc: isSorted && isSortedDesc,
            disableSortBy: isNotSortable,
            minWidth,
            Cell: (cell?: Cell) => CustomCellAccessor(propertyName, cell),
            sticky: undefined
          };
        }
      )
      .sort((currentMeta, nextMeta) => currentMeta?.order - nextMeta?.order);
  }

  private updateFilters = () => {
    if (this.filterCriteria) {
      AsyncStorage.updateFilterConfig(this.pageType, this.filterCriteria);
    } else {
      AsyncStorage.removeFilterConfig(this.pageType);
    }
  };

  private setDataListError = (e: AxiosError) => {
    if (!this.dataList.length) {
      this.dataListError = ApiError.deserializeFromCatch(e);
    } else {
      stores[StoreType.Messages].addMsgError(
        `dataList_${Date.now()}`,
        ApiError.deserializeFromCatch(e)
      );
    }
  };

  @action
  async setupConfig(data: GenericColumnMetaInformation[]) {
    try {
      const storageConfig = await AsyncStorage.getGenericListConfig(
        this.pageType
      );
      const apiConfig = TableConfig.deserializeAsMap(data);
      this.tableConfig = { ...apiConfig, ...storageConfig };
      await this.updateGenericListConfig();
    } catch (e) {
      const metadataError = ApiError.deserializeFromCatch(e);
      this.metadataError = metadataError;
      stores[StoreType.Messages].setGeneralError(metadataError);
    }
  }

  @action
  async loadGenericMetadata() {
    if (Object.keys(this.columnMetaInfo).length > 0) {
      return;
    }

    this.metadataLoading = true;
    this.metadataError = undefined;
    try {
      const { data } = await this.genericApi.loadGenericListMetadata();
      this.columnMetaInfo = GenericColumnMetaInformation.deserializeAsMap(
        data.columnsMetaInformation
      );

      Promise.all([
        AsyncStorage.getSortConfig(this.pageType),
        AsyncStorage.getFilterConfig(this.pageType)
      ]).then(([sortCriteriaState, filterCriteriaState]) => {
        this.filterCriteria = filterCriteriaState || undefined;

        this.sortCriteria =
          sortCriteriaState || SortCriteriaState.deserialize(data);

        AsyncStorage.updateSortConfig(this.sortCriteria, this.pageType);
        this.updateFilters();
      });

      await this.setupConfig(data.columnsMetaInformation);
    } catch (e) {
      const metadataError = ApiError.deserializeFromCatch(e);
      this.metadataError = metadataError;
      stores[StoreType.Messages].setGeneralError(metadataError);
    } finally {
      this.metadataLoading = false;
    }
  }

  @action
  async loadGenericList(pageNumber: string = '1') {
    const pageNumberIsValid = parseInt(pageNumber) > 0;

    if (!pageNumberIsValid || this.dataListLoading || this.metadataLoading) {
      return;
    }

    this.dataListLoading = true;
    this.dataListError = undefined;

    await this.loadGenericMetadata();

    if (!this.metadataError) {
      try {
        const { data } = await this.genericApi.loadGenericList(pageNumber, {
          sortCriteria: this.sortCriteria.asList,
          filterCriteria: this.filterCriteria
        });

        this.pagination = GenericListPagination.deserialize(data);
        this.dataList = GenericListItem.deserializeAsList(data.data);
        this.dataListInitialLoaded = true;
        stores[StoreType.Messages].clearPageErrors();
      } catch (e) {
        this.setDataListError(e);
      } finally {
        this.dataListLoading = false;
      }
    } else {
      this.dataListLoading = false;
    }
  }

  @action
  resetInitialLoading() {
    this.dataListInitialLoaded = false;
  }

  @action
  private async updateGenericListConfig() {
    this.tableConfigLoading = true;

    try {
      await AsyncStorage.updateGenericListConfig(
        this.tableConfig,
        this.pageType
      );
    } catch (e) {
      stores[StoreType.Messages].addMsgError(
        `listConfig_${Date.now()}`,
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.tableConfigLoading = false;
    }
  }

  @action
  updateConfigPropValue<T extends keyof TableConfig>(
    column: keyof GenericListItem,
    key: T,
    value: TableConfig[T]
  ) {
    if (this.columnMetaInfo[column]) {
      this.tableConfig[column][key] = value;
      this.updateGenericListConfig();
    }
  }

  @action
  updateConfigValues(data: TableColumn<GenericListItem>[], key: string) {
    for (const element of data) {
      const { id } = element;
      this.tableConfig[id][key] = element[key];
    }
    this.updateGenericListConfig();
  }

  @action
  updateSortCriteria(sortBy: Array<SortingRule<TableColumn<GenericListItem>>>) {
    const [sortItem] = sortBy;
    if (sortItem) {
      const sortOrder = this.tableConfig[sortItem.id]?.order;
      const sortCriteria = SortCriteria.deserialize({
        columnName: sortItem.id,
        sortDirection: sortItem.desc
          ? SortDirection.Descending
          : SortDirection.Ascending,
        sortOrder
      });

      this.sortCriteria.setCriteria(sortCriteria);
    } else {
      this.sortCriteria.clearCriteria();
    }
    AsyncStorage.updateSortConfig(this.sortCriteria, this.pageType);

    this.loadGenericList('1');
  }

  @action
  updateFilterCriteria(conditions: Condition[]) {
    if (conditions.length) {
      if (!this.filterCriteria) {
        this.filterCriteria = FilterCriteria.deserialize({});
      }

      this.filterCriteria.conditions = Condition.deserializeAsList(conditions);
    } else {
      this.filterCriteria = undefined;
    }

    this.updateFilters();
    this.loadGenericList('1');
  }
}
