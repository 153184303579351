import React, { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form/dist/index.ie11';
import { Transform } from 'react-html-parser';
import inlineStyleToObject from 'react-html-parser/lib/utils/inlineStyleToObject';
import { DomNode, Tooltip } from '@holberg/ui-kit';
import cn from 'classnames';
import { LocationType } from 'enums/LocationType.enum';
import { observer } from 'mobx-react-lite';

import { getDefaultState, processChildren } from './utils';

import styles from './Sensor.module.scss';

interface Props {
  node: DomNode;
  transform: Transform;
  locationType: LocationType;
  selectedClassName?: string;
  selectedMaximumClassName?: string;
}

export const Sensor: FC<Props> = observer(
  ({
    node,
    transform,
    locationType,
    selectedClassName,
    selectedMaximumClassName
  }) => {
    const { control } = useFormContext();

    const pathChildren = node.children.find(
      (children) => children.name === 'path'
    )!;
    const pathPatternChildren = node.children.find((children) =>
      children.attribs.class?.includes('pattern')
    );

    const { style, class: className, ...props } = node.attribs;
    const textChildren = node.children.find((children) =>
      children.attribs.class?.includes('sensortext')
    );
    const pathDAttribute = (pathChildren.attribs as {
      class: string;
      d: string;
    }).d;

    const sensorName = textChildren?.children?.[0]?.children?.[0]?.data;
    const defaultValue = useMemo(
      () =>
        getDefaultState(
          pathPatternChildren?.attribs.class,
          selectedClassName,
          selectedMaximumClassName
        ),
      [pathPatternChildren, selectedClassName, selectedMaximumClassName]
    );

    return (
      <Controller
        name={`eventPropertyCodings.headModel.${locationType}.${props['data-id']}`}
        control={control}
        defaultValue={defaultValue}
        render={({ value }) => (
          <Tooltip
            key={sensorName}
            delayTime={0}
            tooltipId='sensor-tooltip'
            data={[
              {
                mainTooltip: <span>{sensorName}</span>
              }
            ]}
          >
            <g
              className={cn(className, styles.g)}
              style={inlineStyleToObject(style)}
              {...props}
            >
              <path d={pathDAttribute} className={styles.path} />
              {processChildren({
                children: node.children,
                transform,
                state: value,
                selectedClassName,
                selectedMaximumClassName
              })}
            </g>
          </Tooltip>
        )}
      />
    );
  }
);
