import { deserialize, serializable } from 'serializr';

export class EventPropertyCodingsCategoricalDTO {
  @serializable
  propertyTypeId: number = 0;

  @serializable
  propertyCodeId: number = 0;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCodingsCategorical, json);
  }
}

export class EventPropertyCodingsCategorical extends EventPropertyCodingsCategoricalDTO {
  @serializable
  panelId: number = 0;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCodingsCategorical, json);
  }
}
