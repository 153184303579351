import { GenericListItem } from 'entities/GenericListItem.entity';
import { HDataType } from 'enums/HDataType.enum';
import { makeObservable, observable } from 'mobx';
import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class GenericColumnMetaInformation {
  @observable
  @serializable(object(InformationModel))
  name: InformationModel = new InformationModel();

  @observable
  @serializable
  dataType: HDataType = HDataType.String;

  @observable
  @serializable
  propertyName: keyof GenericListItem = 'patientId';

  @serializable
  order: number = 0;

  @observable
  @serializable
  isVisible: boolean = false;

  @observable
  @serializable
  isSortable: boolean = false;

  @observable
  @serializable
  isFrozen: boolean = false;

  @observable
  @serializable
  isSearchable: boolean = false;

  @observable
  @serializable
  isMultiSelect: boolean = false;

  @observable
  @serializable
  isCategorical: boolean = false;

  @observable
  @serializable
  isACollection: boolean = false;

  @observable
  @serializable
  width: number = 0;

  @observable
  @serializable
  endPoint?: string;

  constructor() {
    makeObservable(this);
  }

  static deserialize(json: Object | string) {
    return deserialize(GenericColumnMetaInformation, json);
  }

  static deserializeAsMap(
    list: GenericColumnMetaInformation[]
  ): Record<keyof GenericListItem, GenericColumnMetaInformation> {
    return list.reduce((acc, patient) => {
      return {
        ...acc,
        [patient.propertyName]: GenericColumnMetaInformation.deserialize(
          patient
        )
      };
    }, {} as Record<keyof GenericListItem, GenericColumnMetaInformation>);
  }

  static deserializeAsList(
    list: GenericColumnMetaInformation[]
  ): GenericColumnMetaInformation[] {
    return list.map(GenericColumnMetaInformation.deserialize);
  }
}
