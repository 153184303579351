export enum PatientPropertyTypes {
  BrainMRI = 1,
  ClinicalDiagnosis,
  DiagnosisCauseOfDeath,
  EpisodeFrequency,
  FunctionalNeuroimaging,
  Gender,
  Handedness,
  LastEpisode,
  Medication,
  MedicationAdministered,
  MedicationWithdrawal,
  PatientConsent
}
