import { computed, makeObservable } from 'mobx';
import { deserialize, list, object, primitive, serializable } from 'serializr';
import {
  dateDifference,
  formatLocaleDate,
  formatLocaleDateTime
} from 'utils/dateHelpers';

import { Age } from './Age.entity';
import { CategoricalPropertyCoding } from './CategoricalPropertyCoding.entity';
import { InformationModel } from './InformationModel.entity';

export class Study {
  @serializable
  studyId: number = 0;

  @serializable
  externalStudyId: string | null = null;

  @serializable
  patientId: number = 0;

  @serializable(object(InformationModel))
  studyStart: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  studyStop: InformationModel = new InformationModel();

  @serializable(list(primitive()))
  overlappingRecordingIds: number[] = [];

  @serializable
  isArchived?: boolean;

  @serializable
  isDeleted?: boolean;

  @serializable
  isOnline?: boolean;

  @serializable(object(Age))
  ageAtStudy: Age = new Age();

  @serializable(object(Age))
  correctedAge: Age = new Age();

  @serializable(object(Age))
  postmenstrualAgeAtStudy: Age = new Age();

  @serializable(object(InformationModel))
  studyLength: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  totalRecordingTime: InformationModel = new InformationModel();

  @serializable
  isStudyTimeEditedByUser: boolean = false;

  @serializable
  miniHeadModel: string = '';

  @serializable(list(object(CategoricalPropertyCoding)))
  propertyCodings: CategoricalPropertyCoding[] = [];

  constructor() {
    makeObservable(this);
  }

  private getDateFromInformationModel(informationModel?: InformationModel) {
    return informationModel?.secondaryValue
      ? new Date(informationModel.secondaryValue)
      : undefined;
  }

  @computed
  get totalTime() {
    if (!this.studyStop.eitherValue) return '';
    return dateDifference(
      new Date(this.studyStop.eitherValue),
      new Date(this.studyStart.eitherValue),
      true
    );
  }

  @computed
  get ageAtStudyTime() {
    return this.ageAtStudy.displayText.eitherValue || '-';
  }

  @computed
  get isNeonatal() {
    const maxAgeInlMonths = 12;

    return (
      Number.isInteger(this.ageAtStudy.ageInMonths) &&
      this.ageAtStudy.ageInMonths <= maxAgeInlMonths
    );
  }

  @computed
  get isOnGoing() {
    return !this.studyStop.eitherValue ? true : false;
  }

  get formattedStudyStart(): string {
    const date = this.getDateFromInformationModel(this.studyStart);

    return formatLocaleDate(date, '') as string;
  }

  get formattedStudyStartDateTime(): string {
    const date = this.getDateFromInformationModel(this.studyStart);

    return formatLocaleDateTime(date, '', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }) as string;
  }

  get formattedStudyStop(): string {
    const date = this.getDateFromInformationModel(this.studyStop);

    return formatLocaleDate(date, '') as string;
  }

  get formattedStudyStopDateTime(): string {
    const date = this.getDateFromInformationModel(this.studyStop);

    return formatLocaleDateTime(date, '', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }) as string;
  }

  static deserialize(json: Object | string) {
    return deserialize(Study, json);
  }

  static deserializeAsList(list: Study[]): Study[] {
    return list.map(Study.deserialize);
  }
}
