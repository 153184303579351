import React, { useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { SwitchRow, TableColumn } from '@holberg/ui-kit';
import { TableConfig } from 'entities/TableConfig.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';

import styles from './ColumnsList.module.scss';

type ExtractTableDataType<T> = T extends Props<infer R> ? R : T;

interface Props<T extends object> {
  isDragDisabled?: boolean;
  onUpdateConfigValues?: (
    data: Array<TableColumn<T>>,
    key: keyof TableConfig
  ) => void;
  columns: TableColumn<T>[];
  onUpdateTableConfig: (
    column: keyof T,
    key: keyof TableConfig,
    value: TableConfig[keyof TableConfig]
  ) => void;
}

export const ColumnsList: <T extends object>(
  p: Props<T>
) => React.ReactElement<Props<T>> = (props) => {
  const {
    columns,
    onUpdateTableConfig,
    onUpdateConfigValues,
    isDragDisabled
  } = props;

  type TableData = ExtractTableDataType<typeof props>;
  type TableColumnData = TableColumn<TableData>;

  const [draggableUnpinnedColumns, setDraggableUnpinnedColumns] = useState<
    TableColumnData[]
  >([]);

  useEffect(() => {
    setDraggableUnpinnedColumns(columns);
    // eslint-disable-next-line
  }, [columns]);

  const moveColumn = (dragIndex: number, destIndex: number) => {
    const items = [...draggableUnpinnedColumns];
    const [removedItem] = items.splice(dragIndex, 1);
    items.splice(destIndex, 0, removedItem);
    const result = items.map((item, index) => ({
      ...item,
      order: index
    }));
    setDraggableUnpinnedColumns(result);
    onUpdateConfigValues && onUpdateConfigValues(result, 'order');
  };

  return (
    <div className={styles['columns-list']}>
      {draggableUnpinnedColumns.map((column, index) => {
        const key =
          typeof column.accessor === 'function'
            ? column.id
            : (column.accessor as string);
        const title =
          typeof column.Header === 'string' ? (column.Header as string) : '';

        return (
          <EachColumnItem
            key={key}
            index={index}
            isDragDisabled={isDragDisabled}
            title={title}
            onUpdateTableConfig={onUpdateTableConfig}
            moveColumn={moveColumn}
            column={column}
          />
        );
      })}
    </div>
  );
};

interface columnProps<T extends object> {
  column: TableColumn<T>;
  isDragDisabled?: boolean;
  index: number;
  title: string;
  onUpdateTableConfig: (
    column: keyof T,
    key: keyof TableConfig,
    value: TableConfig[keyof TableConfig]
  ) => void;
  moveColumn?: (dragIndex: number, destIndex: number) => void;
}

export const EachColumnItem: <T extends object>(
  p: columnProps<T>
) => React.ReactElement<columnProps<T>> = ({
  column,
  isDragDisabled,
  index,
  title,
  onUpdateTableConfig,
  moveColumn
}) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const [isDown, setIsDown] = useState(false);

  const [{ isOver, handlerId }, drop] = useDrop(
    () => ({
      accept: DraggableTypes.columns,
      collect: (monitor) => ({
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver()
      }),
      drop: (item: { draggedItemIndex: number }, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        moveColumn && moveColumn(item.draggedItemIndex, index);
      },
      hover(item: { draggedItemIndex: number }, monitor: DropTargetMonitor) {
        if (!columnRef.current) {
          return;
        }
        const dragIndex = item.draggedItemIndex;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = columnRef.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
          setIsDown(true);
        } else if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
          setIsDown(false);
        }
      }
    }),
    [column]
  );

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: DraggableTypes.columns,
      canDrag: () => !isDragDisabled,
      item: {
        draggedItemIndex: index,
        title: title
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging()
        };
      }
    }),
    [column]
  );

  useEffect(() => {
    dragPreview(getEmptyImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  drag(drop(columnRef));

  return (
    <div
      ref={columnRef}
      data-testid='column-list-item'
      data-handler-id={handlerId}
      style={{
        transition: '0.15s ease-in',
        ...(isDragging && { opacity: 0 }),
        ...(isOver &&
          !isDragging && {
            padding: isDown ? '0 0 28px 0' : '28px 0 0 0'
          })
      }}
    >
      <SwitchRow
        title={title}
        pinned={!!column.sticky}
        checked={column.isVisible}
        onChange={(value) => {
          onUpdateTableConfig(column.id, 'isVisible', value);
        }}
      />
    </div>
  );
};
