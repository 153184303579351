import React from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router-dom';

interface Props {
  when: boolean;
  message: string;
}

export const PageLeavePrompt: React.FC<Props> = ({ when, message }) => {
  useBeforeunload(() => {
    return when ? message : undefined;
  });

  return <Prompt when={when} message={message} />;
};
