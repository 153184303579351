import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonSize,
  IconType,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { Condition } from 'entities/Condition.entity';
import { GenericColumnMetaInformation } from 'entities/GenericColumnMetaInformation.entity';
import { GenericListItem } from 'entities/GenericListItem.entity';
import { TableConfig } from 'entities/TableConfig.entity';
import { ConditionType } from 'enums/ConditionType.enum';
import { HDataType } from 'enums/HDataType.enum';
import { observer } from 'mobx-react-lite';

import { FilterRow } from '../FilterRow';
import { FiltersControls } from '../FiltersControls';

import styles from './FiltersSettings.module.scss';

const defaultCondition = {
  value: '',
  label: '',
  dataType: HDataType.String
};

export interface FilterOption {
  label: string;
  value: string;
  dataType?: HDataType;
}

interface Props {
  isFiltersVisible: boolean;
  onUpdateFilters: (conditions: Condition[]) => void;
  filterCriteria?: Condition[];
  tableConfig: Record<keyof GenericListItem, TableConfig>;
  columnMetaInfo: Record<keyof GenericListItem, GenericColumnMetaInformation>;
}

export const FiltersSettings: React.FC<Props> = observer(
  ({
    columnMetaInfo = {},
    tableConfig = {},
    filterCriteria = [],
    onUpdateFilters,
    isFiltersVisible
  }) => {
    const { t } = useCustomTranslation();
    const [filterConditions, setFilterConditions] = useState<Condition[]>(
      filterCriteria
    );

    useEffect(() => {
      const isConditionsLengthChanged =
        filterConditions.length !== filterCriteria?.length &&
        filterCriteria?.length;

      if (isConditionsLengthChanged) {
        setFilterConditions(filterCriteria);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterCriteria]);

    useEffect(() => {
      const isConditionsChanged = filterConditions !== filterCriteria;

      if (!isFiltersVisible && isConditionsChanged) {
        setFilterConditions(filterCriteria);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFiltersVisible]);

    const addNewCondition = () => {
      const newCondition = Condition.deserialize({});

      setFilterConditions((prevConditions) => [
        ...prevConditions,
        newCondition
      ]);
    };

    const removeCondition = (index: number) => {
      const conditions = [...filterConditions];

      conditions.splice(index, 1);

      setFilterConditions(conditions);
    };

    const changeConditionName = (rowIndex: number, option: FilterOption) => {
      const { value, dataType } = option || defaultCondition;
      const newFilterConditions = [...filterConditions];
      const conditionType =
        dataType === HDataType.String
          ? ConditionType.Contains
          : ConditionType.Equals;

      newFilterConditions[rowIndex] = Condition.deserialize({
        ...filterConditions[rowIndex],
        dataType,
        value: '',
        conditionType,
        columnName: value
      });

      setFilterConditions(newFilterConditions);
    };

    const changeConditionValue = (
      rowIndex: number,
      value: string | number = ''
    ) => {
      const newFilterConditions = [...filterConditions];

      newFilterConditions[rowIndex] = Condition.deserialize({
        ...filterConditions[rowIndex],
        value: String(value)
      });

      setFilterConditions(newFilterConditions);
    };

    const hasConditionNames = useMemo(
      () => filterConditions.every(({ columnName }) => columnName),
      [filterConditions]
    );

    const hasConditionValues = useMemo(() => {
      return filterConditions.every((condition) => condition.isValid);
    }, [filterConditions]);

    const isPopupFooterVisible = useMemo(
      () => !!filterCriteria?.length || !!filterConditions?.length,
      [filterCriteria, filterConditions]
    );

    const isFiltersApplied = useMemo(
      () =>
        !!filterCriteria?.length &&
        JSON.stringify(filterConditions) === JSON.stringify(filterCriteria),
      [filterConditions, filterCriteria]
    );

    const clearFilters = useCallback(() => {
      onUpdateFilters([]);
      setFilterConditions([]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={cn(styles.filter)}>
        <div className={styles.filters}>
          {filterConditions.map((condition, index) => (
            <FilterRow
              rowIndex={index}
              condition={condition}
              tableConfig={tableConfig}
              columnMetaInfo={columnMetaInfo}
              onRemoveCondition={removeCondition}
              filterConditions={filterConditions}
              key={`${condition.columnName}-${index}`}
              onChangeColumnName={changeConditionName}
              onChangeColumnValue={changeConditionValue}
            />
          ))}
          {!filterConditions?.length && (
            <>
              <span className={styles['filter-title']}>
                {t('You don’t have any filters yet.')}
              </span>
              <span className={styles['filter-title']}>
                {t('Click on the button below to add one')}
              </span>
            </>
          )}
          <Button
            disabled={!hasConditionNames}
            onClick={addNewCondition}
            title={t('Add a filter')}
            size={ButtonSize.Small}
            icon={IconType.Plus}
          />
        </div>
        {isPopupFooterVisible && (
          <FiltersControls
            onUpdateFilters={onUpdateFilters}
            onClearFilters={clearFilters}
            filterConditions={filterConditions}
            isFiltersApplied={isFiltersApplied}
            isConditionsValid={hasConditionNames && hasConditionValues}
          />
        )}
      </div>
    );
  }
);
