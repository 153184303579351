import { deserialize, list, object, serializable } from 'serializr';

import { EventPropertyCodingsCategoricalDTO } from './EventPropertyCodingsCategorical.entity';
import { EventPropertyCodingsNumberDTO } from './EventPropertyCodingsNumberDTO.entity';
import { EventPropertyCodingsSensorDTO } from './EventPropertyCodingsSensorDTO.entity';
import { EventPropertyCodingStringDTO } from './EventPropertyCodingString.entity';

export class EventPropertyCodingUpdateDTO {
  @serializable
  panelId: number = 0;

  @serializable(list(object(EventPropertyCodingsCategoricalDTO)))
  categoricalPropertyCodings: EventPropertyCodingsCategoricalDTO[] = [];

  @serializable(list(object(EventPropertyCodingStringDTO)))
  stringPropertyCodings: EventPropertyCodingStringDTO[] = [];

  @serializable(list(object(EventPropertyCodingsNumberDTO)))
  integerPropertyCodings: EventPropertyCodingsNumberDTO[] = [];

  @serializable(list(object(EventPropertyCodingsNumberDTO)))
  decimalPropertyCodings: EventPropertyCodingsNumberDTO[] = [];

  @serializable(list(object(EventPropertyCodingsSensorDTO)))
  locationSensorCodings: EventPropertyCodingsSensorDTO[] = [];

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCodingUpdateDTO, json);
  }

  static deserializeAsList(
    list: EventPropertyCodingUpdateDTO[]
  ): EventPropertyCodingUpdateDTO[] {
    return list.map(EventPropertyCodingUpdateDTO.deserialize);
  }
}
