import React, { useCallback, useMemo } from 'react';
import { EventPropertyCode } from 'entities/EventPropertyCode.entity';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyPanelFocused } from 'entities/EventPropertyPanelFocused.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { EventPropertyTypeCategorical } from 'entities/EventPropertyTypeCategorical.entity';
import { EventPropertyTypeNonCategorical } from 'entities/EventPropertyTypeNonCategorical.entity';
import { EventPropertyTypeString } from 'entities/EventPropertyTypeString.entity';
import { StoreType } from 'enums/StoreType.enum';
import { TemplateId } from 'enums/TemplateId.enum';
import { useStore } from 'hooks/store';

import { CategoricalPropertyType } from './CategoricalPropertyType';
import { HeadModelPropertyType } from './HeadModelPropertyType';
import { LateralityPropertyType } from './LateralityPropertyType';
import { PropertyPanelInput } from './PropertyPanelInput';
import { RegionPropertyType } from './RegionPropertyType';
import { StringPropertyType } from './StringPropertyType';

type Categorical =
  | EventPropertyTypeCategorical
  | EventPropertyTypeNonCategorical
  | EventPropertyTypeString;

const compare = (a: Categorical, b: Categorical) =>
  a.propertyType.sortOrder - b.propertyType.sortOrder;

interface Props {
  panel: EventPropertyPanel;
  focusedProperty: EventPropertyPanelFocused;
}

export const PropertyTabsPanelForm: React.FC<Props> = ({
  panel,
  focusedProperty
}) => {
  const findingPropertiesStore = useStore(StoreType.FindingProperties);

  const categoricalPropertyTypes = useMemo(
    () => panel.categoricalPropertyTypes.sort(compare),
    [panel]
  );

  const nonCategoricalPropertyTypes = useMemo(
    () => panel.nonCategoricalPropertyTypes.sort(compare),
    [panel]
  );

  const stringPropertyTypes = useMemo(
    () => panel.stringPropertyTypes.sort(compare),
    [panel]
  );

  const renderCategoricalProperty = useCallback(
    (
      propertyType: EventPropertyType,
      propertyCodes: EventPropertyCode[],
      focusedProperty: EventPropertyPanelFocused
    ) => {
      if (!propertyCodes.length) {
        return null;
      }
      switch (propertyType.templateId) {
        case TemplateId.TagList: {
          return (
            !!panel.headModelLocationType && (
              <RegionPropertyType
                key={propertyType.eventPropertyTypeId}
                propertyType={propertyType}
                propertyCodes={propertyCodes}
                locationType={panel.headModelLocationType}
                panelId={panel.eventPropertyPanelId}
              />
            )
          );
        }

        case TemplateId.LateralityList: {
          return (
            !!panel.headModelLocationType && (
              <LateralityPropertyType
                key={propertyType.eventPropertyTypeId}
                propertyType={propertyType}
                propertyCodes={propertyCodes}
                locationType={panel.headModelLocationType}
                panelId={panel.eventPropertyPanelId}
              />
            )
          );
        }

        default: {
          return (
            <CategoricalPropertyType
              key={propertyType.eventPropertyTypeId}
              propertyType={propertyType}
              propertyCodes={propertyCodes}
              showTitle={focusedProperty.groupPositionNumber! > 0}
              panelId={panel.eventPropertyPanelId}
              focusedProperty={focusedProperty}
            />
          );
        }
      }
    },
    [panel.eventPropertyPanelId, panel.headModelLocationType]
  );

  // when panel does not have items for navigation, but has Notes field set groupPositionNumber as -1
  if (categoricalPropertyTypes.length === 0 && stringPropertyTypes.length > 0) {
    findingPropertiesStore.setPropertyIndexStructure({
      ...findingPropertiesStore.propertyIndexStructure,
      [String(focusedProperty.tabPositionNumber)]: {
        ...findingPropertiesStore.propertyIndexStructure?.[
          String(focusedProperty.tabPositionNumber)
        ],
        [String(focusedProperty.panelPositionNumber)]: {
          '-1': 1
        }
      }
    } as Array<Array<Array<number>>>);
  }

  return (
    <>
      {panel.headModelPropertyType && (
        <HeadModelPropertyType
          propertyType={panel.headModelPropertyType.propertyType}
          panelId={panel.eventPropertyPanelId}
        />
      )}
      {categoricalPropertyTypes.map(({ propertyType, propertyCodes }, idx) =>
        renderCategoricalProperty(
          propertyType,
          propertyCodes,
          (focusedProperty = {
            ...focusedProperty,
            groupPositionNumber: idx
          })
        )
      )}

      {nonCategoricalPropertyTypes.map((item) => (
        <PropertyPanelInput
          data={item}
          key={item.propertyType.eventPropertyTypeId}
          panelId={panel.eventPropertyPanelId}
        />
      ))}

      {stringPropertyTypes.map(({ propertyType }) => (
        <StringPropertyType
          key={propertyType.eventPropertyTypeId}
          propertyType={propertyType}
          panelId={panel.eventPropertyPanelId}
          activeFocusedProperty={focusedProperty}
        />
      ))}
    </>
  );
};
