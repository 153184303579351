import { deserialize, serializable } from 'serializr';

export class StudyPropertyCodingCreateDTO {
  @serializable
  propertyTypeId: number = 0;

  @serializable
  propertyCodeId: number = 0;

  @serializable
  freeText?: string;

  static deserialize(json: Object | string) {
    return deserialize(StudyPropertyCodingCreateDTO, json);
  }
}
