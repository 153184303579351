import { EventPropertyCoding } from 'entities/EventPropertyCoding.entity';
import {
  EventPropertyCodingsCategorical,
  EventPropertyCodingsCategoricalDTO
} from 'entities/EventPropertyCodingsCategorical.entity';
import { EventPropertyCodingsDecimal } from 'entities/EventPropertyCodingsDecimal.entity';
import { EventPropertyCodingsInteger } from 'entities/EventPropertyCodingsInteger.entity';
import { EventPropertyCodingsNumberDTO } from 'entities/EventPropertyCodingsNumberDTO.entity';
import { EventPropertyCodingsSensorDTO } from 'entities/EventPropertyCodingsSensorDTO.entity';
import {
  EventPropertyCodingString,
  EventPropertyCodingStringDTO
} from 'entities/EventPropertyCodingString.entity';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { LocationType } from 'enums/LocationType.enum';
import { SensorState } from 'stores/head-model';

import { EventPropertyCodingsData } from './index';

export type SensorCodings = {
  sensorId: number;
  state: SensorState;
  locationType: LocationType;
};

export const filterByPanelId = (
  panelId: EventPropertyPanel['eventPropertyPanelId']
) => (
  item:
    | EventPropertyCodingsCategorical
    | EventPropertyCodingsDecimal
    | EventPropertyCodingsInteger
    | EventPropertyCodingString
) => item.panelId === panelId;

export const reduceNumberPropertyCoding = <
  T,
  K extends EventPropertyCodingsInteger | EventPropertyCodingsDecimal
>(
  acc: T,
  item: K
) => ({
  ...acc,
  [item.propertyTypeId]: {
    operatorId: item.operatorId,
    value1: item.value1,
    value2: item.value2
  }
});

export const getCategoricalCodingsDTO = (
  data: EventPropertyCodingsData['categorical']
) =>
  Object.entries(data).reduce(
    (
      acc: EventPropertyCodingsCategoricalDTO[],
      [propertyTypeId, propertyCodes]
    ) => {
      const values = { ...propertyCodes };
      if (values.radio) {
        values[values.radio] = true;
      }
      delete values.radio;

      const data = Object.entries(values)
        .filter(([_, value]) => !!value)
        .map(([propertyCodeId]) => ({
          propertyTypeId: Number(propertyTypeId),
          propertyCodeId: Number(propertyCodeId)
        }));

      return [...acc, ...data];
    },
    []
  );

export const getNumberCodingsDTO = (
  data:
    | EventPropertyCodingsData['decimal']
    | EventPropertyCodingsData['integer']
): EventPropertyCodingsNumberDTO[] =>
  Object.entries(data)
    .filter(
      ([_, propertyCode]) =>
        !(
          (propertyCode.value1 === null || propertyCode.value1 === undefined) &&
          (propertyCode.value2 === null || propertyCode.value2 === undefined)
        )
    )
    .map(([propertyTypeId, propertyCode]) => {
      return {
        propertyTypeId: Number(propertyTypeId),
        operatorId: propertyCode.operatorId,
        value1: Number(propertyCode.value1),
        value2:
          propertyCode.value2 === null ? null : Number(propertyCode.value2)
      };
    });

export const getStringCodingsDTO = (
  data: EventPropertyCodingsData['string']
): EventPropertyCodingStringDTO[] =>
  Object.entries(data).map(([propertyTypeId, freeText]) => ({
    propertyTypeId: Number(propertyTypeId),
    freetext: freeText
  }));

export const getSensorCodingsDTO = (
  data: EventPropertyCodingsData['headModel'],
  propertyTypeId: EventPropertyType['eventPropertyTypeId']
): EventPropertyCodingsSensorDTO[] =>
  Object.entries(data || {})
    .map(([locationType, entries]) =>
      Object.entries(entries)
        .filter(([, state]) => state !== SensorState.Empty)
        .map(([sensorId, state]) => ({
          sensorId: Number(sensorId),
          isMaximum: state === SensorState.Max,
          locationTypeId: Number(locationType) as LocationType,
          propertyTypeId
        }))
    )
    .flat();

export const createEmptyEventPropertyCoding = () =>
  EventPropertyCoding.deserialize({
    categorical: [],
    decimal: [],
    integer: [],
    string: []
  });

export const convertSensorsForm = (
  headModel: EventPropertyCodingsData['headModel']
) =>
  Object.entries(headModel || {})
    .map(([locationType, sensors]) =>
      Object.entries(sensors).map<SensorCodings>(([sensorId, state]) => ({
        sensorId: Number(sensorId),
        state,
        locationType: Number(locationType)
      }))
    )
    .flat();
