import React from 'react';
import { GenericListPageType } from 'enums/GenericListPageType.enum';
import { PropertyType } from 'enums/PropertyType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { configure } from 'mobx';
import { PropertyTypesStore } from 'stores/property-types';
import { RealTimeUpdatesStore } from 'stores/real-time-updates';
import { SiteStore } from 'stores/site';

import { AuthStore } from './auth';
import { DescriptionsStore } from './descriptions';
import { FindingPropertiesStore } from './finding-properties';
import { FindingsStore } from './findings';
import { GenericListPageStore } from './generic-list-page/GenericListPageStore';
import { HeadModelStore } from './head-model';
import { I18nStore } from './i18n';
import { MessagesStore } from './messages';
import { PatientDetailsStore } from './patient-details';
import { patientLockDetails } from './patient-lock';
import { PatientReportsStore } from './patient-reports';
import { PropertyTypeCodesStore } from './property-type-codes';
import { StudyDetailsStore } from './study-details';
// Need to configure mobx before first usage, otherwise it will not work in IE https://github.com/mobxjs/mobx/discussions/2607#discussioncomment-125998
configure({ useProxies: 'never', enforceActions: 'never' });

export const stores = Object.freeze({
  [StoreType.FindingProperties]: new FindingPropertiesStore(),
  [StoreType.Auth]: new AuthStore(),
  [StoreType.Patient]: new GenericListPageStore(GenericListPageType.Patients),
  [StoreType.Study]: new GenericListPageStore(GenericListPageType.Studies),
  [StoreType.Descriptions]: new DescriptionsStore(),
  [StoreType.PatientDetails]: new PatientDetailsStore(),
  [StoreType.StudyDetails]: new StudyDetailsStore(),
  [StoreType.I18n]: new I18nStore(),
  [StoreType.PatientPropertyTypeCodes]: new PropertyTypeCodesStore(
    PropertyType.Patient
  ),
  [StoreType.StudyPropertyTypeCodes]: new PropertyTypeCodesStore(
    PropertyType.Study
  ),
  [StoreType.DescriptionPropertyTypeCodes]: new PropertyTypeCodesStore(
    PropertyType.Description
  ),
  [StoreType.PatientPropertyTypes]: new PropertyTypesStore(
    PropertyType.Patient
  ),
  [StoreType.StudyPropertyTypes]: new PropertyTypesStore(PropertyType.Study),
  [StoreType.DescriptionPropertyTypes]: new PropertyTypesStore(
    PropertyType.Description
  ),
  [StoreType.Messages]: new MessagesStore(),
  [StoreType.Findings]: new FindingsStore(),
  [StoreType.RealTimeUpdates]: new RealTimeUpdatesStore(),
  [StoreType.HeadModel]: new HeadModelStore(),
  [StoreType.Site]: new SiteStore(),
  [StoreType.PatientReports]: new PatientReportsStore(),
  [StoreType.PatientLockDetails]: new patientLockDetails()
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;

export const resetStores = () => {
  for (const store of Object.values(stores)) {
    if ('reset' in store) {
      store.reset();
    }
  }
};
