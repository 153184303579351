import { range } from '@holberg/ui-kit';

export const [minDaysAmount, maxDaysAmount] = [1, 7];
export const [minWeeksAmount, maxWeeksAmount] = [20, 45];

export const gestationalAgeDaysAllowedCharts = range(
  maxDaysAmount,
  minDaysAmount
).map((daysAmount: number) => String(daysAmount));

export const gestationalAgeWeeksAllowedCharts = ['2', '3', '4'];
