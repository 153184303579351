import { deserialize, list, object, serializable } from 'serializr';

import { EventCoding } from './EventCoding.entity';
import { HeadModel } from './HeadModel.entity';

export class EventCodingHeadModel {
  @serializable
  eventCodingId: EventCoding['eventCodingId'] = 0;

  @serializable
  sensorsNotForHeadModel: number = 0;

  @serializable(list(object(HeadModel)))
  headModels: HeadModel[] = [];

  static deserialize(json: Object | string) {
    return deserialize(EventCodingHeadModel, json);
  }

  static deserializeAsList(list: EventCodingHeadModel[]) {
    return list.map(EventCodingHeadModel.deserialize);
  }

  static deserializeAsMap(list: EventCodingHeadModel[]) {
    return list.reduce((acc, headModel) => {
      acc.set(
        headModel.eventCodingId,
        EventCodingHeadModel.deserialize(headModel)
      );

      return acc;
    }, new Map());
  }
}
