import React, { useCallback } from 'react';
import {
  Button,
  ButtonTheme,
  Icon,
  IconType,
  useCustomTranslation
} from '@holberg/ui-kit';
import { Condition } from 'entities/Condition.entity';
import { observer } from 'mobx-react-lite';

import styles from './FiltersControls.module.scss';

interface Props {
  isFiltersApplied: boolean;
  isConditionsValid: boolean;
  filterConditions: Condition[];
  onClearFilters: () => void;
  onUpdateFilters: (filterConditions: Condition[]) => void;
}

export const FiltersControls: React.FC<Props> = observer(
  ({
    onClearFilters,
    onUpdateFilters,
    filterConditions,
    isConditionsValid,
    isFiltersApplied
  }) => {
    const { t } = useCustomTranslation();

    const handleUpdateFilters = useCallback(
      () => onUpdateFilters(filterConditions),
      [onUpdateFilters, filterConditions]
    );

    return (
      <div className={styles['filters-controls']}>
        <div className={styles.info}>
          <Icon iconType={IconType.Info} className={styles['info-icon']} />
          <span className={styles['info-message']}>
            {t('Fill in filter values to proceed')}
          </span>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            theme={ButtonTheme.Primary}
            onClick={handleUpdateFilters}
            title={t(isFiltersApplied ? 'Applied' : 'Apply')}
            disabled={!isConditionsValid || isFiltersApplied}
          />
          <Button
            title={t('Clear all')}
            className={styles.button}
            theme={ButtonTheme.SecondaryTransparent}
            onClick={onClearFilters}
          />
        </div>
      </div>
    );
  }
);
