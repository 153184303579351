export enum ConfirmationTitle {
  Move = 'The moved finding will no longer be available, it will be replaced by the destination finding. Do you agree to continue?',
  Unclassify_Linked = 'Unclassifying a linked finding will remove its examples. Do you agree to continue?',
  Unclassify_Classified = 'Unclassifying a classified finding will remove its properties. Do you agree to continue?',
  Reclassify = 'Reclassifying a classified finding will remove its properties. Do you agree to continue?',
  Delete_Example = 'This action will permanently delete the selected examples and corresponding markers in the EEG. Do you want to proceed?',
  Edit_Report = 'This action may import updates from the EEG software and modify the report content. This action is irreversible. The revised report will need to be completed again. Would you like to proceed?',
  Add_Second_OnlyOneEventCoding = 'Only one item is allowed for this type of finding. When you create additional items, they will be merged with the existing finding.',
  Removing_All_Recordings = 'Removing all recordings from an "In progress" report will delete the report.',
  Delete_Report = 'Deleting report will move recordings from this report to the unreported section.',
  Completed_Reports_Merge = 'Content may be lost or resynced to the EEG data when combining these reports - please review the new report before completion.',
  Merging_Reports = 'Content may be lost when combining these reports - please review the new report before completion.',
  Reopen_Report = 'This action will change the status of this report from “Completed” to “In progress” and all data will be resynced. This may permanently change the content of the report.'
}
