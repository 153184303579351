import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PropertyTypeCode } from 'entities/PropertyTypeCode.entity';
import memoizee from 'memoizee';

interface PropertyTypeOption {
  translatedName?: string | number;
  propertyCodeId?: PropertyTypeCode['propertyCodeId'];
  isMultiSelect?: boolean;
}

type BaseRule = (
  selectedItems: Array<CategoricalPropertyCoding['formShape']>
) => boolean;

type DerivedRule = BaseRule extends (selectedItems: infer U) => infer R
  ? (selectedItems: U, option: PropertyTypeOption) => R
  : never;

export abstract class PropertyTypeCodesRules {
  static isSelectable: DerivedRule = memoizee(
    (selectedItems, propertyTypeItem) => {
      if (!PropertyTypeCodesRules.hasSelectedItems(selectedItems)) {
        return true;
      }

      return [
        PropertyTypeCodesRules.notPreviouslySelected,
        PropertyTypeCodesRules.matchMultipleSelectRule
      ].every((rule) => rule(selectedItems, propertyTypeItem));
    }
  );

  static hasSelectedItems: BaseRule = (selectedItems) => {
    return selectedItems.some((item) => item.value?.value);
  };

  static notPreviouslySelected: DerivedRule = (
    selectedItems,
    { propertyCodeId }
  ) => {
    return !selectedItems.some(
      ({ value }) => value?.value === String(propertyCodeId)
    );
  };

  static matchMultipleSelectRule: DerivedRule = (
    selectedItems,
    { isMultiSelect }
  ) => {
    if (
      selectedItems.length === 1 ||
      !PropertyTypeCodesRules.isMultiSelectRuleApplied(selectedItems)
    ) {
      return true;
    }

    return !(
      PropertyTypeCodesRules.isMultiSelectSelected(selectedItems) &&
      !isMultiSelect
    );
  };

  static isMultiSelectRuleApplied: BaseRule = (selectedItems) => {
    return selectedItems.some(
      ({ value }) => value?.isMultiSelect !== undefined
    );
  };

  static isMultiSelectSelected: BaseRule = (selectedItems) => {
    return selectedItems.some(({ value }) => value?.isMultiSelect);
  };

  static isMultipleRowsDisabled: BaseRule = (selectedItems) => {
    return (
      PropertyTypeCodesRules.hasSelectedItems(selectedItems) &&
      !PropertyTypeCodesRules.isMultiSelectSelected(selectedItems)
    );
  };
}
