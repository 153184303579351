import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Controller,
  useFormContext,
  useWatch
} from 'react-hook-form/dist/index.ie11';
import { RegionTag, RegionTags, useCustomTranslation } from '@holberg/ui-kit';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { EventPropertyCode } from 'entities/EventPropertyCode.entity';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { FindingPropertiesEventTypes } from 'enums/FindingPropertiesEventTypes.enum';
import { LateralityIds } from 'enums/LateralityIds.enum';
import { LocationType } from 'enums/LocationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHandlePropertiesAutoSave } from 'hooks/useHandlePropertiesAutoSave';
import { observer } from 'mobx-react-lite';
import { FindingPropertiesPublisher } from 'services/FindingPropertiesPublisher';
import { EventPropertyCodingsData } from 'stores/finding-properties';
import { SensorMeta, SensorState } from 'stores/head-model';

import styles from './LateralityPropertyType.module.scss';

interface Props {
  locationType: LocationType;
  propertyCodes: EventPropertyCode[];
  propertyType: EventPropertyType;
  panelId: EventPropertyPanel['eventPropertyPanelId'];
}

export const LateralityPropertyType: FC<Props> = observer(
  ({ locationType, propertyCodes, propertyType, panelId }) => {
    const { t } = useCustomTranslation();

    const { eventHandler } = useHandlePropertiesAutoSave(panelId);

    const { activeCodingId } = usePropertiesContext();
    const headModelStore = useStore(StoreType.HeadModel);
    const allSensorsMeta = headModelStore.getSensorsMeta(
      activeCodingId!,
      locationType
    );

    const { getValues, setValue, control } = useFormContext<{
      eventPropertyCodings: EventPropertyCodingsData;
    }>();

    const watchedTags = useWatch<{ [key: string]: boolean }>({
      control,
      name: Object.values(LateralityIds).map(
        (id) =>
          `eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}.${id}`
      )
    });

    const lateralityTags = useMemo(
      () =>
        propertyCodes
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((code) => ({
            id: code.eventPropertyCodeId,
            title: code.translatedName.eitherValue
          })),
      [propertyCodes]
    );

    const onSetLaterality = useCallback(() => {
      const headModelSensors =
        getValues().eventPropertyCodings?.headModel?.[locationType] || {};

      const selectedSensorsMeta = Object.entries(headModelSensors)
        .filter(([, value]) => value !== SensorState.Empty)
        .map(([key]) => allSensorsMeta?.get(key))
        .filter((meta) => !!meta) as SensorMeta[];

      const isLeftSelected = selectedSensorsMeta.some((meta) => meta.left);
      const isRightSelected = selectedSensorsMeta.some((meta) => meta.right);
      const isMidlineSelected = selectedSensorsMeta.some(
        (meta) => meta.midline
      );
      const isBilateralSelected = isLeftSelected && isRightSelected;

      setValue(
        `eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}.${LateralityIds.Bilateral}`,
        isBilateralSelected
      );
      setValue(
        `eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}.${LateralityIds.Midline}`,
        isMidlineSelected
      );
      setValue(
        `eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}.${LateralityIds.Left}`,
        isBilateralSelected ? false : isLeftSelected
      );
      setValue(
        `eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}.${LateralityIds.Right}`,
        isBilateralSelected ? false : isRightSelected
      );

      eventHandler();
    }, [
      eventHandler,
      getValues,
      locationType,
      allSensorsMeta,
      setValue,
      propertyType.eventPropertyTypeId
    ]);

    useEffect(() => {
      const selectObserver = {
        event: {
          panelId,
          eventType: FindingPropertiesEventTypes.SensorsSelect
        },
        update: () => {
          onSetLaterality();
        }
      };
      const deselectObserver = {
        event: {
          panelId,
          eventType: FindingPropertiesEventTypes.SensorsDeselect
        },
        update: () => {
          onSetLaterality();
        }
      };

      FindingPropertiesPublisher.subscribe(deselectObserver);
      FindingPropertiesPublisher.subscribe(selectObserver);

      return () => {
        FindingPropertiesPublisher.unsubscribe(selectObserver);
        FindingPropertiesPublisher.unsubscribe(deselectObserver);
      };
      // eslint-disable-next-line
    }, [onSetLaterality]);

    return (
      <div className={styles.container} data-testid='laterality-property-type'>
        <RegionTags
          placeholder={t('Laterality will appear here once you select sensors')}
          placeholderVisible={
            !Object.values(watchedTags).some((tagValue) => !!tagValue)
          }
          tags={lateralityTags}
          renderTag={(tagProps) => (
            <Controller
              key={tagProps.id}
              name={`eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}.${tagProps.id}`}
              render={({ value }) => (
                <RegionTag {...tagProps} isHidden={!value} />
              )}
              defaultValue={false}
            />
          )}
        />
      </div>
    );
  }
);
