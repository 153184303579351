import { isEmptyMultiRowData } from '@holberg/ui-kit';
import { MultiRowDataType, ReportDataFacade } from '@holberg/ui-kit';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { PatientPropertyTypes } from 'enums/PatientPropertyType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { StudyPropertyTypes } from 'enums/StudyPropertyType.enum';
import { useStore } from 'hooks/store';
import { formatLocaleDate } from 'utils/dateHelpers';

const makeSingleRowValue = (
  title: string,
  value?: string | number,
  subValue?: string | number
): MultiRowDataType => {
  return {
    title,
    values: [
      {
        value,
        subValue
      }
    ]
  };
};

const getCoding = <
  T extends StudyPropertyTypes | PatientPropertyTypes | DescriptionPropertyTypes
>(
  codings: ReturnType<typeof CategoricalPropertyCoding.deserializeAsMap>
) => (title: string, key: T, includeCode?: boolean): MultiRowDataType => {
  const values = (codings[key] || []).map((item) => ({
    value:
      includeCode && item.propertyCode.code
        ? `${item.propertyCode.code} ${item.name}`
        : item.name,
    subValue: item.freeText
  }));

  return {
    title,
    values
  };
};

export const useReportData = (
  descriptionId: number,
  addScreenshotsData: boolean = false
): ReportDataFacade => {
  const studyDetailsStore = useStore(StoreType.StudyDetails);
  const patientDetailsStore = useStore(StoreType.PatientDetails);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const reportsStore = useStore(StoreType.PatientReports);
  const findingsStore = useStore(StoreType.Findings);
  const siteStore = useStore(StoreType.Site);

  const reportDetails = reportsStore.patientReports.get(descriptionId)!;
  const studyDetails = studyDetailsStore.studyById(descriptionId.toString())!;
  const activeDescription = descriptionsStore.descriptionById(descriptionId)!;
  const patientId = String(activeDescription?.patientId);
  const siteDetails = siteStore.siteDetails;
  const patientDetails = patientDetailsStore.patientById(patientId);

  const patientCodings = patientDetailsStore.propertyCodings(patientId);
  const descriptionCodings = descriptionsStore.propertyCodings(descriptionId);

  const getPatientCoding = getCoding<PatientPropertyTypes>(patientCodings);
  const getDescriptionCoding = getCoding<DescriptionPropertyTypes>(
    descriptionCodings
  );

  const patientData = {
    patientName: makeSingleRowValue('Name', patientDetails.fullName),
    patientId: makeSingleRowValue('Patient ID', patientDetails.identityString),
    dateOfBirth: makeSingleRowValue(
      'Date of birth',
      patientDetails.formattedDateOfBirth
    ),
    gender: getPatientCoding('Gender', PatientPropertyTypes.Gender)
  };
  const isPatientHidden = Object.values(patientData).every(isEmptyMultiRowData);

  const studyData = {
    studyStart: {
      title: 'Start',
      values: [
        {
          value: reportDetails?.reportStartDate
        }
      ]
    },
    studyStop: {
      title: 'Stop',
      values: [
        {
          value: reportDetails?.reportStopDate
        }
      ]
    },
    totalTimeSpan: {
      title: 'Total time span',
      values: [
        {
          value: reportDetails?.totalTime
        }
      ]
    },
    recordedTimeSpan: {
      title: 'Recorded time span',
      values: [
        {
          value: studyDetails?.totalRecordingTime.eitherValue
        }
      ]
    },
    indicationEeg: getDescriptionCoding(
      'Indication for EEG',
      DescriptionPropertyTypes.IndicationForEEG
    ),
    patientHistory: makeSingleRowValue(
      'Patient history',
      patientDetails.patientHistory
    ),
    eegType: getDescriptionCoding('EEG type', DescriptionPropertyTypes.EEGType)
  };
  const isStudyHidden = Object.values(studyData).every(isEmptyMultiRowData);

  const studyDetailsData = {
    diagnosisReferral: getPatientCoding(
      'Clinical diagnosis at referral',
      PatientPropertyTypes.ClinicalDiagnosis,
      true
    ),
    episodeFrequency: getPatientCoding(
      'Episode frequency',
      PatientPropertyTypes.EpisodeFrequency
    ),
    lastEpisode: getPatientCoding(
      'Last episode',
      PatientPropertyTypes.LastEpisode
    ),
    brainMRI: getPatientCoding('Brain MRI/CT', PatientPropertyTypes.BrainMRI),
    scalpDefects: makeSingleRowValue(
      'Scalp defects',
      patientDetails.scalpDefects
    ),
    alertness: getDescriptionCoding(
      'Alertness',
      DescriptionPropertyTypes.Alertness
    ),
    medicationReferral: getPatientCoding(
      'Medication at referral',
      PatientPropertyTypes.Medication
    ),
    medicationDuringStudy: getPatientCoding(
      'Medication during the study',
      PatientPropertyTypes.MedicationAdministered
    ),
    medicationWithdrawal: getPatientCoding(
      'Medication withdrawal',
      PatientPropertyTypes.MedicationWithdrawal
    ),

    latestMeal: makeSingleRowValue(
      'Latest meal',
      activeDescription?.formattedLatestMeal
    ),
    priority: getDescriptionCoding(
      'Priority',
      DescriptionPropertyTypes.Priority
    ),
    workingOffHours: getDescriptionCoding(
      'Working off-hours',
      DescriptionPropertyTypes.WorkingOrOffHours
    ),
    levelOfCare: getDescriptionCoding(
      'Level of care',
      DescriptionPropertyTypes.LevelOfCare
    ),
    studyNotes: makeSingleRowValue(
      'Study notes',
      activeDescription?.recordingNotes || ''
    ),
    functionalNeuroimaging: getPatientCoding(
      'Functional neuroimaging',
      PatientPropertyTypes.FunctionalNeuroimaging
    )
  };
  const isStudyDetailsHidden = Object.values(studyDetailsData).every(
    isEmptyMultiRowData
  );

  const eventsTreePayload = findingsStore.getReportEventsTree(
    descriptionId,
    reportDetails,
    addScreenshotsData
  );

  const modulatorsTreePayload = findingsStore.getReportModulatorsTree(
    descriptionId,
    reportDetails,
    addScreenshotsData
  );
  return {
    reportDate: {
      key: 'Reported date',
      value: formatLocaleDate(new Date()) || ''
    },
    studyDate: { key: 'Study date', value: reportDetails?.reportStartDate },
    studyId: {
      key: 'Report ID',
      value: activeDescription?.descriptionId || ''
    },
    revision: {
      key: 'Revision',
      value: activeDescription?.revision || ''
    },
    mainLogo: siteDetails.customerLogo,
    reportTitle: reportDetails?.reportStartDate,
    patientPersonalInfoTitle: 'Patient',
    studyInformationTitle: 'Study',
    studyDetailsTitle: 'Study details',
    conclusionTitle: 'Conclusion',
    modulatorsTitle: 'Modulators/Procedures',
    findingsTitle: 'Findings',

    /** Patient */
    ...patientData,
    isPatientHidden,
    gestationalAge: makeSingleRowValue(
      'Gestational age',
      `${patientDetails.gestationalAgeInWeeksOption?.label} ${patientDetails.gestationalAgeInDaysOption?.label}`
    ),
    correctedAge: makeSingleRowValue(
      'Corrected age',
      activeDescription?.correctedAgeAtStudyTime
    ),

    age: makeSingleRowValue('Age', patientDetails.formattedAge),
    handedness: getPatientCoding('Handedness', PatientPropertyTypes.Handedness),

    postmenstrualAge: makeSingleRowValue(
      'Postmenstrual age',
      activeDescription?.postMenstrualAgeAtStudyTime
    ),

    /** Study */
    ...studyData,
    isStudyHidden,

    /** Study details */
    ...studyDetailsData,
    isStudyDetailsHidden,

    /** Conclusion */
    diagnosticSignificance: getDescriptionCoding(
      'Diagnostic significance',
      DescriptionPropertyTypes.DiagnosticSignificance
    ),
    changeSinceLastEEG: getDescriptionCoding(
      'Change since last EEG',
      DescriptionPropertyTypes.ChangeSinceLastEEG
    ),
    etiology: getDescriptionCoding(
      'Etiology',
      DescriptionPropertyTypes.Etiology
    ),

    eegSummary: makeSingleRowValue(
      'EEG summary',
      activeDescription?.eegSummary || ''
    ),

    clinicalComments: makeSingleRowValue(
      'Clinical comments',
      activeDescription?.clinicalComment || ''
    ),
    headmodel: findingsStore.reportHeadModel,

    /** Findings */
    eventsTreePayload,
    isFindingsHidden: !eventsTreePayload.length,

    /** Modulators */
    modulatorsTreePayload,
    isModulatorsHidden: !modulatorsTreePayload.length,

    /** Screenshots */
    isScreenshotsHidden: !addScreenshotsData,
    screenshots: findingsStore.getPdfScreenshots()
  };
};
