import { deserialize, serializable } from 'serializr';

import { Event } from './Event.entity';

export class Screenshot {
  @serializable
  screenshotId: number = -1;

  @serializable
  recordingId: number = -1;

  @serializable
  eventId: number = -1;

  @serializable
  siteId: number = -1;

  @serializable
  freeText: string = '';

  @serializable
  screenshot: string = '';

  @serializable
  mimeType: string = '';

  @serializable
  name: string = '';

  static deserialize(json: Object | string) {
    return deserialize(Screenshot, json);
  }

  static deserializeAsMap(
    list: Screenshot[]
  ): Map<Event['eventId'], Screenshot> {
    return list.reduce((acc, screenshot) => {
      acc.set(screenshot.eventId, this.deserialize(screenshot));
      return acc;
    }, new Map());
  }
}
