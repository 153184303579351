import { deserialize, serializable } from 'serializr';

export class EventPropertyCodingStringDTO {
  @serializable
  propertyTypeId: number = 0;

  @serializable
  freetext: string = '';

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCodingString, json);
  }
}

export class EventPropertyCodingString extends EventPropertyCodingStringDTO {
  @serializable
  panelId: number = 0;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCodingString, json);
  }
}
