type GroupedRegions = {
  severalRegions: Set<string>;
  uniqueRegions: Set<string>;
};

export const groupRegions = (sensorRegions: string[][]) => {
  const groupedRegions = sensorRegions.reduce<GroupedRegions>(
    (acc, regions) => {
      return {
        severalRegions:
          regions.length > 1
            ? new Set([...acc.severalRegions, ...regions])
            : acc.severalRegions,
        uniqueRegions:
          regions.length === 1
            ? new Set([...acc.uniqueRegions, ...regions])
            : acc.uniqueRegions
      };
    },
    {
      severalRegions: new Set(),
      uniqueRegions: new Set()
    }
  );

  groupedRegions.uniqueRegions.forEach((uniqueRegionId) =>
    groupedRegions.severalRegions.delete(uniqueRegionId)
  );

  return groupedRegions;
};
