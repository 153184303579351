import React, { PropsWithChildren } from 'react';
import { Accordion } from '@holberg/ui-kit';
import { CommonHeaderProps } from 'components/CategoryHeader/CategoryHeader';

interface Props<T extends CommonHeaderProps> extends PropsWithChildren<{}> {
  className?: string;
  ladderClassName?: string;
  contentClassName?: string;
  header: React.FC<T>;
  headerProps: Omit<T, 'arrow'>;
  toggleDisabled?: boolean;
  isActive?: boolean;
  onToggleFolder: () => void;
  defaultActive?: boolean;
}

export const Folder = <T extends CommonHeaderProps>({
  header: HeaderComponent,
  headerProps,
  className,
  ladderClassName,
  contentClassName,
  children,
  isActive = false,
  onToggleFolder,
  toggleDisabled = false
}: Props<T>) => (
  <div className={className}>
    <Accordion
      hasLadder
      isActive={isActive}
      toggleDisabled={toggleDisabled}
      onArrowClick={(e) => {
        e.stopPropagation();
        onToggleFolder();
      }}
      ladderClassName={ladderClassName}
      contentClassName={contentClassName}
      renderHeader={(arrow) => {
        const props = { ...headerProps, arrow } as T;

        return <HeaderComponent {...props} />;
      }}
    >
      {children}
    </Accordion>
  </div>
);
