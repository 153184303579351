import { ApiError } from 'entities/ApiError.entity';
import { GenericPropertyType } from 'entities/GenericPropertyType.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { PropertyType, propertyTypeUrl } from 'enums/PropertyType.enum';
import { action, makeObservable, observable } from 'mobx';
import { ScoreApi } from 'services/API/Score/ScoreApi';
import { BaseStore } from 'stores/BaseStore';

export class PropertyTypesStore implements BaseStore {
  constructor(propertyType: PropertyType) {
    makeObservable(this);
    this.propertyTypeUrl = propertyTypeUrl[propertyType];
    this.reset();
  }

  private readonly propertyTypeUrl: string;

  @observable
  propertyTypes!: Map<
    string,
    Map<GenericPropertyType['propertyTypeId'], GenericPropertyType>
  >;
  @observable
  propertyTypesLoading!: boolean;

  @observable
  propertyTypesError?: ApiError | UnknownError;

  @action
  reset() {
    this.propertyTypes = new Map();
    this.propertyTypesError = undefined;
    this.propertyTypesLoading = false;
  }

  getPropertyTypes(
    ageConstraint: string = 'all'
  ): Map<GenericPropertyType['propertyTypeId'], GenericPropertyType> {
    return this.propertyTypes?.get(ageConstraint) || new Map();
  }

  @action
  async loadPropertyTypes(ageConstraint: string) {
    if (this.propertyTypes.get(ageConstraint)?.size) {
      return;
    }

    this.propertyTypesError = undefined;
    this.propertyTypesLoading = true;

    try {
      const { data } = await ScoreApi.loadPropertyTypes(this.propertyTypeUrl, {
        ageConstraint
      });

      this.propertyTypes.set(
        ageConstraint,
        GenericPropertyType.deserializeAsMap(data)
      );
    } catch (e) {
      this.propertyTypesError = ApiError.deserializeFromCatch(e);
    } finally {
      this.propertyTypesLoading = false;
    }
  }
}
