import { StatusIndicatorStatus } from '@holberg/ui-kit';

export const combineSaveStatus = (
  statuses: StatusIndicatorStatus[]
): StatusIndicatorStatus => {
  const hasNonRetryError = statuses.find(
    (status) => status === StatusIndicatorStatus.NonRetryError
  );

  if (hasNonRetryError) {
    return StatusIndicatorStatus.NonRetryError;
  }

  const hasError = statuses.find(
    (status) => status === StatusIndicatorStatus.Error
  );

  if (hasError) {
    return StatusIndicatorStatus.Error;
  }

  const hasLoading = statuses.find(
    (status) => status === StatusIndicatorStatus.Loading
  );

  if (hasLoading) {
    return StatusIndicatorStatus.Loading;
  }

  return StatusIndicatorStatus.Saved;
};
