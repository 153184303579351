import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { getRegionsCodings } from 'components/HeadModel/SVG/utils';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { StoreType } from 'enums/StoreType.enum';

import { useStore } from './store';
import { useGetPanelById } from './useGetPanelById';

export const useSetRegions = (
  panelId: EventPropertyPanel['eventPropertyPanelId']
) => {
  const { getValues, setValue } = useFormContext();
  const { activeCodingId } = usePropertiesContext();

  const headModelStore = useStore(StoreType.HeadModel);

  const panel = useGetPanelById(panelId);
  const locationType = panel?.headModelLocationType;

  const setRegions = useCallback(() => {
    if (!panel?.regionsPropertyType || !locationType || !activeCodingId) {
      return;
    }

    const sensorsFields =
      getValues().eventPropertyCodings.headModel?.[locationType] || {};

    const allSensorsMeta = headModelStore.getSensorsMeta(
      activeCodingId,
      locationType
    );

    const regionsData = allSensorsMeta
      ? getRegionsCodings(sensorsFields, allSensorsMeta)
      : {};

    setValue(
      `eventPropertyCodings.categorical.${panel?.regionsPropertyType?.propertyType.eventPropertyTypeId}`,
      regionsData
    );
  }, [
    panel?.regionsPropertyType,
    getValues,
    headModelStore,
    setValue,
    activeCodingId,
    locationType
  ]);

  return { setRegions };
};
