import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form/dist/index.ie11';
import {
  FormInput,
  InputContainer,
  useCustomTranslation
} from '@holberg/ui-kit';
import { NoteLabel } from 'components/NoteLabel';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyPanelFocused } from 'entities/EventPropertyPanelFocused.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { HDataType } from 'enums/HDataType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHandlePropertiesAutoSave } from 'hooks/useHandlePropertiesAutoSave';
import { observer } from 'mobx-react-lite';
import { EventPropertyCodingsData } from 'stores/finding-properties';

import styles from './StringPropertyType.module.scss';

interface Props {
  propertyType: EventPropertyType;
  panelId: EventPropertyPanel['eventPropertyPanelId'];
  activeFocusedProperty?: EventPropertyPanelFocused;
}

export const StringPropertyType: FC<Props> = observer(
  ({ propertyType, panelId, activeFocusedProperty }) => {
    const { t } = useCustomTranslation();
    const { control } = useFormContext<{
      eventPropertyCodings: EventPropertyCodingsData;
    }>();
    const { eventHandler } = useHandlePropertiesAutoSave(panelId);

    const findingPropertiesStore = useStore(StoreType.FindingProperties);

    const isFocused =
      activeFocusedProperty?.tabPositionNumber ===
        findingPropertiesStore.focusedProperty.tabPositionNumber &&
      activeFocusedProperty?.panelPositionNumber ===
        findingPropertiesStore.focusedProperty.panelPositionNumber &&
      findingPropertiesStore.focusedProperty.groupPositionNumber === -1;

    const handleNavigationButton = (key: string) => {
      findingPropertiesStore.updateFocusedProperty(
        key === 'Tab' ? 'tab' : key === 'ArrowDown' ? 'down' : 'up'
      );
    };

    return propertyType.dataTypeId === HDataType.String ? (
      <Controller
        render={(props) => (
          <InputContainer
            label={
              <NoteLabel
                text={propertyType.translatedName.eitherValue}
                iconId={propertyType.iconId}
              />
            }
            labelColSize={12}
            inputColSize={12}
            className={styles.container}
            colClassName={styles.col}
          >
            <FormInput
              {...props}
              onBlur={() => {
                props.onBlur();
                eventHandler();
              }}
              isFocused={isFocused}
              onNavigationButton={handleNavigationButton}
              autoComplete={isFocused ? 'off' : undefined}
              placeholder={t('Type notes here')}
            />
          </InputContainer>
        )}
        defaultValue=''
        control={control}
        name={`eventPropertyCodings.string[${propertyType.eventPropertyTypeId}]`}
      />
    ) : null;
  }
);
