import { useCallback } from 'react';
import { StoreType } from 'enums/StoreType.enum';
import { StateAccessor } from 'stores/property-type-codes';

import { useStore } from './store';

export const usePropertyTypesReload = (
  storeType:
    | StoreType.DescriptionPropertyTypeCodes
    | StoreType.StudyPropertyTypeCodes
    | StoreType.PatientPropertyTypeCodes,
  stateAccessor: StateAccessor
): {
  handleReload: (isSearch?: boolean) => void;
  isPropertyTypeRetrying: boolean;
} => {
  const propertyTypesStore = useStore(storeType);
  const propertyTypeError = propertyTypesStore.getPropertyTypesCodesError(
    stateAccessor
  );
  const isPropertyTypeRetrying = propertyTypesStore.getPropertyTypesCodesRetrying(
    stateAccessor
  );

  const handleReload = useCallback(
    (isSearch?: boolean) => {
      if (propertyTypeError) {
        propertyTypesStore.reloadPropertyTypesCodes(stateAccessor, isSearch);
      }
    },
    [propertyTypeError, propertyTypesStore, stateAccessor]
  );

  return {
    handleReload,
    isPropertyTypeRetrying: !!isPropertyTypeRetrying
  };
};
