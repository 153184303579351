import React, { FC, useMemo, useState } from 'react';
import {
  HTMLParser,
  Icon,
  IconType,
  ReactTransformer,
  TextBadge,
  Tooltip,
  TooltipManager,
  useCustomTranslation
} from '@holberg/ui-kit';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { LocationType } from 'enums/LocationType.enum';
import { shortcutsBaseTitles } from 'services/keyboardShortcuts/shortcutsBaseKeys';

import { SVGTransformer } from './SVGTransformer';

import styles from './HeadModel.module.scss';

interface Props {
  data: string;
  locationType: LocationType;
  panelId: EventPropertyPanel['eventPropertyPanelId'];
}

export const HeadModel: FC<Props> = ({ data, locationType, panelId }) => {
  const transformer = useMemo(() => {
    const reactTransformer = new ReactTransformer();
    return new SVGTransformer(reactTransformer, locationType, panelId);
  }, [locationType, panelId]);
  const { t } = useCustomTranslation();
  const [enlargeHeadModel, setEnlargeHeadModel] = useState<boolean>(false);

  return (
    <div
      className={enlargeHeadModel ? styles['maximized-head-model-overlay'] : ''}
    >
      <div className={styles.container} data-testid='head-model'>
        <HTMLParser html={data} transformer={transformer} />
        <TooltipManager
          tooltipId='sensor-tooltip'
          effect='float'
          delayShow={0}
        />
        <Tooltip
          tooltipId='sensor-tooltip'
          data={[
            {
              mainTooltip: (
                <>
                  <div className={styles.tip}>
                    <TextBadge title='Click' className={styles.control} />
                    {t('to set activity')}
                  </div>
                  <div className={styles.tip}>
                    <TextBadge
                      title='Shift + click'
                      className={styles.control}
                    />
                    {t('to remove activity')}
                  </div>
                  <div className={styles.tip}>
                    <TextBadge
                      title={`${shortcutsBaseTitles.CMD} + click`}
                      className={styles.control}
                    />
                    {t('to set maximum activity')}
                  </div>
                </>
              )
            }
          ]}
        >
          <Icon
            iconType={IconType.QuestionCircle}
            className={styles['question-mark']}
            data-testid='head-model-control-tips'
          />
        </Tooltip>
        <Tooltip
          tooltipId='sensor-tooltip'
          data={[
            {
              mainTooltip: (
                <div className={styles.tip}>
                  <TextBadge title='Click' className={styles.control} />
                  {enlargeHeadModel ? t('to minimize') : t('to maximize')}
                </div>
              )
            }
          ]}
        >
          <Icon
            iconType={enlargeHeadModel ? IconType.Minimize : IconType.Maximize}
            className={styles['maximize-icon']}
            data-testid={
              enlargeHeadModel ? 'minimize-head-model' : 'maximize-head-model'
            }
            onClick={() => setEnlargeHeadModel(!enlargeHeadModel)}
          />
        </Tooltip>
      </div>
    </div>
  );
};
