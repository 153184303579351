import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form/dist/index.ie11';
import { RegionTags, useCustomTranslation } from '@holberg/ui-kit';
import { extractSensorId } from 'components/HeadModel/Sensor/utils';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { EventPropertyCode } from 'entities/EventPropertyCode.entity';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { FindingPropertiesEventTypes } from 'enums/FindingPropertiesEventTypes.enum';
import { LocationType } from 'enums/LocationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHandlePropertiesAutoSave } from 'hooks/useHandlePropertiesAutoSave';
import { useSetRegions } from 'hooks/useSetRegions';
import { observer } from 'mobx-react-lite';
import { FindingPropertiesPublisher } from 'services/FindingPropertiesPublisher';
import { EventPropertyCodingsData } from 'stores/finding-properties';
import { SensorState } from 'stores/head-model';

import { groupRegions } from './utils';

import styles from './RegionPropertyType.module.scss';

interface Props {
  locationType: LocationType;
  propertyCodes: EventPropertyCode[];
  propertyType: EventPropertyType;
  panelId: EventPropertyPanel['eventPropertyPanelId'];
}

const RegionPropertyTypeInner: FC<
  Props & { selectedCodes: { [x: string]: boolean } }
> = observer(
  ({ locationType, propertyCodes, selectedCodes, propertyType, panelId }) => {
    const { t } = useCustomTranslation();

    const { eventHandler } = useHandlePropertiesAutoSave(panelId);
    const { setRegions } = useSetRegions(panelId);

    const { activeCodingId } = usePropertiesContext();
    const headModelStore = useStore(StoreType.HeadModel);
    const allSensorsMeta = headModelStore.getSensorsMeta(
      activeCodingId!,
      locationType
    );

    const { getValues, setValue } = useFormContext<{
      eventPropertyCodings: EventPropertyCodingsData;
    }>();

    const onRemoveTag = useCallback(
      (id: number) => {
        setValue(
          `eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}`,
          {
            ...selectedCodes,
            [id]: false
          }
        );

        eventHandler();
      },
      [propertyType.eventPropertyTypeId, selectedCodes, setValue, eventHandler]
    );

    const sensorsFields =
      getValues().eventPropertyCodings?.headModel?.[locationType] || {};

    const selectedSensorsRegions = Object.entries(sensorsFields)
      .filter(([, state]) => state !== SensorState.Empty)
      .map(
        ([sensorFieldName]) =>
          allSensorsMeta
            ?.get(extractSensorId(sensorFieldName))
            ?.regions.filter((region) => selectedCodes[region]) || []
      );

    const groupedRegions = groupRegions(selectedSensorsRegions);

    const regionTags = propertyCodes
      .filter((code) => selectedCodes[code.eventPropertyCodeId])
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((code) => ({
        id: code.eventPropertyCodeId,
        title: code.translatedName.eitherValue,
        isRemovable: groupedRegions.severalRegions.has(
          String(code.eventPropertyCodeId)
        )
      }));

    useEffect(() => {
      const selectObserver = {
        event: {
          panelId,
          eventType: FindingPropertiesEventTypes.SensorsSelect
        },
        update: () => {
          setRegions();
        }
      };
      const deselectObserver = {
        event: {
          panelId,
          eventType: FindingPropertiesEventTypes.SensorsDeselect
        },
        update: () => {
          setRegions();
        }
      };

      FindingPropertiesPublisher.subscribe(deselectObserver);
      FindingPropertiesPublisher.subscribe(selectObserver);

      return () => {
        FindingPropertiesPublisher.unsubscribe(selectObserver);
        FindingPropertiesPublisher.unsubscribe(deselectObserver);
      };
      // eslint-disable-next-line
    }, []);

    return (
      <div className={styles.container} data-testid='regions-property-type'>
        <RegionTags
          placeholder={t('Regions will appear here once you select sensors')}
          tags={regionTags}
          onRemoveTag={onRemoveTag}
        />
      </div>
    );
  }
);

export const RegionPropertyType: FC<Props> = (props) => (
  <Controller
    render={({ value }) => (
      <RegionPropertyTypeInner {...props} selectedCodes={value} />
    )}
    name={`eventPropertyCodings.categorical.${props.propertyType.eventPropertyTypeId}`}
  />
);
