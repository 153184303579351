import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form/dist/index.ie11';
import { useParams } from 'react-router-dom';
import { ErrorTheme, ErrorTitles, GeneralError } from '@holberg/ui-kit';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { Description } from 'entities/Description.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { buildFormShape } from 'utils/formHelpers';

import { ConclusionDetailsForm } from '../ConclusionDetailsForm';

import styles from './Conclusion.module.scss';

const descriptionPropertyTypes = Object.freeze([
  DescriptionPropertyTypes.ChangeSinceLastEEG,
  DescriptionPropertyTypes.DiagnosticSignificance,
  DescriptionPropertyTypes.Etiology
]);

interface Props {
  descriptionDetails: Description;
  descriptionPropertyCodings?: Record<number, CategoricalPropertyCoding[]>;
}

const ConclusionForm: React.FC<Props> = observer(
  ({ descriptionDetails, descriptionPropertyCodings = {} }) => {
    const descriptionCodings = useMemo(
      () => buildFormShape(descriptionPropertyCodings),
      [descriptionPropertyCodings]
    );

    const methods = useForm<{
      descriptionDetails: Description;
      descriptionCodings: Record<
        number,
        CategoricalPropertyCoding['formShape'][]
      >;
    }>({
      defaultValues: {
        descriptionDetails,
        descriptionCodings
      },
      mode: 'onTouched',
      reValidateMode: 'onChange',
      shouldUnregister: false
    });

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(() => {})}>
          <ConclusionDetailsForm descriptionDetails={descriptionDetails} />
        </form>
      </FormProvider>
    );
  }
);

export const Conclusion: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>();
  const descriptionsStore = useStore(StoreType.Descriptions);
  const findingsStore = useStore(StoreType.Findings);
  const descriptionPropertyTypesStore = useStore(
    StoreType.DescriptionPropertyTypes
  );
  const descriptionPropertyTypeCodesStore = useStore(
    StoreType.DescriptionPropertyTypeCodes
  );
  const messagesStore = useStore(StoreType.Messages);

  const activeDescription = descriptionsStore.descriptionById(parseInt(id))!;

  useEffect(() => {
    return () => {
      descriptionsStore.clearAllRequests();
      messagesStore.clearSystemMessages();
    };
  }, [descriptionsStore, messagesStore]);

  useEffect(() => {
    for (const propertyType of descriptionPropertyTypes) {
      if (propertyType === DescriptionPropertyTypes.DiagnosticSignificance) {
        descriptionPropertyTypeCodesStore.loadAllPropertyTypesCodes(
          {
            propertyType,
            ageConstraint: activeDescription.ageConstraints
          },
          2
        );
      }
      descriptionPropertyTypeCodesStore.loadPropertyTypesCodes({
        propertyType,
        ageConstraint: activeDescription.ageConstraints
      });
    }
  }, [descriptionPropertyTypeCodesStore, activeDescription.ageConstraints]);

  useEffect(() => {
    descriptionPropertyTypesStore.loadPropertyTypes(
      activeDescription.ageConstraints
    );
  }, [descriptionPropertyTypesStore, activeDescription.ageConstraints]);

  const error =
    descriptionsStore.descriptionDetailsError ||
    descriptionPropertyTypesStore.propertyTypesError ||
    descriptionPropertyTypeCodesStore.propertyTypeCodesExtraInfoError ||
    findingsStore.eventCodingsError;

  return (
    <>
      {error ? (
        <GeneralError theme={ErrorTheme.Secondary} title={ErrorTitles.Load} />
      ) : (
        activeDescription?.descriptionId && (
          <div className={styles['tab-content']} data-testid='content'>
            <ConclusionForm
              descriptionDetails={activeDescription}
              descriptionPropertyCodings={descriptionsStore.propertyCodings(
                activeDescription.descriptionId
              )}
            />
          </div>
        )
      )}
    </>
  );
});
