import React, { FC, useCallback, useState } from 'react';
import {
  Checkbox,
  ConfirmationModal,
  IconType,
  useCustomTranslation
} from '@holberg/ui-kit';
import { observer } from 'mobx-react-lite';

import { useConfirmationContext } from './FindingsConfirmationContext';

import styles from './FindingsConfirmationModal.module.scss';

interface Props {
  transparent?: boolean;
  onUpdateSettingsConfig: (userSetting: Object) => void;
}

export const FindingsConfirmationModal: FC<Props> = observer(
  ({ transparent, onUpdateSettingsConfig }) => {
    const [hideWarnings, setHideWarnings] = useState<boolean>(false);
    const { t } = useCustomTranslation();

    const confirmationContext = useConfirmationContext();

    const onSubmit = useCallback(() => {
      confirmationContext!.state.onSubmit!();
      confirmationContext!.onClose();
      if (hideWarnings) {
        onUpdateSettingsConfig(hideWarnings);
      }
      setHideWarnings(false);
    }, [confirmationContext, hideWarnings, onUpdateSettingsConfig]);

    const onClose = useCallback(() => {
      confirmationContext?.onClose();
      setHideWarnings(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationContext, hideWarnings]);

    const toggleHideWarnings = useCallback(() => {
      setHideWarnings((state) => !state);
    }, []);

    if (!confirmationContext) {
      return null;
    }

    const renderModalContent = () => {
      switch (confirmationContext?.state.modalContent) {
        default:
          return (
            <Checkbox
              checked={hideWarnings}
              label={t('Don’t show me this message again.')}
              onChange={toggleHideWarnings}
            />
          );
      }
    };

    return (
      <ConfirmationModal
        onCancel={onClose}
        onSubmit={onSubmit}
        cancelButtonTitle={
          confirmationContext?.state.cancelButtonTitle || t('Cancel')
        }
        submitButtonTitle={confirmationContext?.state.submitButtonTitle!}
        visible={confirmationContext?.state.isOpen}
        transparent={transparent}
        icon={IconType.Warning}
        handleVisible={onClose}
        title={confirmationContext?.state.title}
      >
        <div className={styles.content} data-testid='confirmation-modal'>
          {renderModalContent()}
        </div>
      </ConfirmationModal>
    );
  }
);
