import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import {
  FormInput,
  InputContainer,
  rebuildTooltips,
  useCustomTranslation
} from '@holberg/ui-kit';
import { FormTreeSelectRow } from 'components/FormTreeSelectRow';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { Description } from 'entities/Description.entity';
import { PropertyTypeCode } from 'entities/PropertyTypeCode.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { PropertyCodeTagType } from 'enums/PropertyCodeTagType.enum';
import { PropertyType } from 'enums/PropertyType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useCurrentSessionHasLock } from 'hooks/useCurrentSessionHasLock';
import { useHandleAutoSave } from 'hooks/useHandleAutoSave';
import { observer } from 'mobx-react-lite';
import { CommonPropertyType } from 'stores/property-type-codes';

import { FormRowSection } from '../FormRowSection';

import styles from './ConclusionDetailsForm.module.scss';

interface Props {
  descriptionDetails: Description;
}

export const ConclusionDetailsForm: React.FC<Props> = observer(
  ({ descriptionDetails }) => {
    const { t } = useCustomTranslation();

    const { register } = useFormContext<{
      descriptionDetails: Description;
      descriptionCodings: Record<
        number,
        CategoricalPropertyCoding['formShape'][]
      >;
    }>();

    const findingsStore = useStore(StoreType.Findings);
    const descriptionsStore = useStore(StoreType.Descriptions);
    const descriptionPropertyTypesStore = useStore(
      StoreType.DescriptionPropertyTypes
    );
    const descriptionPropertyTypeCodesStore = useStore(
      StoreType.DescriptionPropertyTypeCodes
    );

    const activeDescriptionId = descriptionDetails.descriptionId;
    const propertyTypes = descriptionPropertyTypesStore.getPropertyTypes(
      descriptionDetails.ageConstraints
    );

    const { currentSessionHasLock } = useCurrentSessionHasLock(
      descriptionDetails.patientId
    );

    const readOnly =
      descriptionsStore.isReadOnly(activeDescriptionId) ||
      !currentSessionHasLock;

    // Exceptional rule only for DS propertyType:
    // if (current option has Abnormal tag && there is no abnormal finding in eventTree) => disable this option
    const isOptionSelectable = useCallback(
      (propertyCodeId: PropertyTypeCode['propertyCodeId']) => {
        const hasAbnormalEventCodings =
          findingsStore.abnormalEventCodings(activeDescriptionId!).length > 0;

        if (hasAbnormalEventCodings) {
          return true;
        }

        const isAbnormalOption = descriptionPropertyTypeCodesStore
          .getPropertyTypeCodesTags(
            DescriptionPropertyTypes.DiagnosticSignificance
          )
          ?.get(propertyCodeId)
          ?.get(PropertyCodeTagType.Abnormal);

        return !isAbnormalOption;
      },
      [activeDescriptionId, descriptionPropertyTypeCodesStore, findingsStore]
    );

    useEffect(() => {
      rebuildTooltips();
    }, []);

    const { eventHandler } = useHandleAutoSave<
      {
        descriptionDetails: Description;
      },
      string,
      { propertyName: string }
    >({
      saveHandler: (value, config) => {
        descriptionsStore.updateDescriptionDetails(
          value,
          descriptionDetails.descriptionId,
          config.propertyName
        );
      }
    });

    const onDescriptionSelectSaveAction = useCallback<
      (
        propertyType: CommonPropertyType,
        newPropertyCodeId?: string,
        oldPropertyCodeId?: string
      ) => void
    >(
      (propertyType, newPropertyCodeId, oldPropertyCodeId) => {
        descriptionsStore.updatePropertyCodings({
          newPropertyCodeId,
          propertyTypeId: propertyType,
          oldPropertyCodeId,
          descriptionId: descriptionDetails.descriptionId
        });
      },
      [descriptionDetails.descriptionId, descriptionsStore]
    );

    const onDescriptionNoteSaveAction = useCallback<
      (
        propertyType: CommonPropertyType,
        freeText: string,
        currentPropertyCodeId: string
      ) => void
    >(
      (propertyType, freeText, currentPropertyCodeId) => {
        descriptionsStore.updatePropertyCodingsNote({
          currentPropertyCodeId,
          propertyTypeId: propertyType,
          freeText,
          descriptionId: descriptionDetails.descriptionId
        });
      },
      [descriptionDetails.descriptionId, descriptionsStore]
    );

    return (
      <>
        <InputContainer required label={t('Diagnostic significance')}>
          <FormRowSection
            readOnly={readOnly}
            includeCode={false}
            component={FormTreeSelectRow}
            isOptionSelectable={isOptionSelectable}
            formAccessorName='descriptionCodings'
            onNoteSaveAction={onDescriptionNoteSaveAction}
            onSelectSaveAction={onDescriptionSelectSaveAction}
            categoricalPropertyType={PropertyType.Description}
            propertyType={DescriptionPropertyTypes.DiagnosticSignificance}
            addButtonTitle={t('Add diagnostic')}
            isMultiSelect={
              propertyTypes.get(DescriptionPropertyTypes.DiagnosticSignificance)
                ?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('Change since last EEG')}>
          <FormRowSection
            readOnly={readOnly}
            formAccessorName='descriptionCodings'
            onNoteSaveAction={onDescriptionNoteSaveAction}
            onSelectSaveAction={onDescriptionSelectSaveAction}
            categoricalPropertyType={PropertyType.Description}
            propertyType={DescriptionPropertyTypes.ChangeSinceLastEEG}
            addButtonTitle={t('Add change since last EEG')}
            isMultiSelect={
              propertyTypes.get(DescriptionPropertyTypes.ChangeSinceLastEEG)
                ?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('Etiology')}>
          <FormRowSection
            readOnly={readOnly}
            includeCode={false}
            component={FormTreeSelectRow}
            formAccessorName='descriptionCodings'
            addButtonTitle={t('Add etiology')}
            onNoteSaveAction={onDescriptionNoteSaveAction}
            onSelectSaveAction={onDescriptionSelectSaveAction}
            categoricalPropertyType={PropertyType.Description}
            propertyType={DescriptionPropertyTypes.Etiology}
            isMultiSelect={
              propertyTypes.get(DescriptionPropertyTypes.Etiology)
                ?.isMultiSelect
            }
          />
        </InputContainer>
        <InputContainer label={t('EEG summary')}>
          <div className={styles['input-container']}>
            <FormInput
              multiline
              ref={register}
              readOnly={readOnly}
              data-testid='eeg-summary'
              onBlur={useCallback(() => {
                eventHandler('descriptionDetails.eegSummary', {
                  propertyName: 'eegSummary'
                })();
              }, [eventHandler])}
              name='descriptionDetails.eegSummary'
              placeholder={t('Enter value')}
            />
          </div>
        </InputContainer>
        <InputContainer label={t('Clinical comments')}>
          <div className={styles['input-container']}>
            <FormInput
              multiline
              ref={register}
              readOnly={readOnly}
              onBlur={useCallback(() => {
                eventHandler('descriptionDetails.clinicalComment', {
                  propertyName: 'clinicalComment'
                })();
              }, [eventHandler])}
              name='descriptionDetails.clinicalComment'
              placeholder={t('Enter value')}
            />
          </div>
        </InputContainer>
      </>
    );
  }
);
