import { deserialize, object, serializable } from 'serializr';

import { EventPropertyType } from './EventPropertyType.entity';

export class EventPropertyTypeOther {
  @serializable(object(EventPropertyType))
  propertyType: EventPropertyType = new EventPropertyType();

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyTypeOther, json);
  }
}
