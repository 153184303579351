import queryString from 'query-string';
import { ApiBase } from 'services/API/ApiBase';

class CustomApi extends ApiBase {
  getCustomEndpoint<T>(
    endpoint: string,
    queryObject: object = {},
    urlModifier: (url: string) => string = (url) => url
  ) {
    const { url: endpointUrl, query: endpointQuery } = queryString.parseUrl(
      endpoint
    );

    const url = urlModifier(endpointUrl);
    const query = queryString.stringify({ ...queryObject, ...endpointQuery });

    const fullEndpoint = query ? `${url}?${query}` : url;

    return this.client.get<T>(fullEndpoint);
  }
}

const instance = new CustomApi();

export { instance as CustomApi };
