/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import 'element-matches-polyfill';
import 'focus-visible';

import React from 'react';
import ReactDOM from 'react-dom';
import { TooltipIds, TooltipManager, TooltipTheme } from '@holberg/ui-kit';
import { I18nextProvider } from 'react-i18next';
import { Router, Route } from 'react-router-dom';
import { i18n } from 'services/I18n';
import { stores, StoresProvider } from 'stores';
import { QueryParamProvider } from 'use-query-params';
import { Helmet } from 'react-helmet';
import { Env } from 'services/Env';
import { history } from 'services/history';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

import '@holberg/ui-kit/dist/index.css';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <StoresProvider value={stores}>
        <Helmet>
          <title>{Env.APP_TITLE}</title>
        </Helmet>
        <TooltipManager />
        <TooltipManager theme={TooltipTheme.Warning} tooltipId={TooltipIds.DefaultWarning}/>
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App />
          </QueryParamProvider>
        </Router>
      </StoresProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
