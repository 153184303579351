import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Conclusion } from 'components/Conclusion';
import { FindingsContainer } from 'components/FindingsContainer';
import { PropertiesProviderContext } from 'components/PropertiesProviderContext';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { StudyDetails } from 'components/StudyDetails';
import { Routes } from 'enums/Routes.enum';
import { useHotKeysPushToPath } from 'hooks/useHotKeysPushToPath';

import styles from './StudyTabsSection.module.scss';

export const StudyTabsSection: React.FC = () => {
  useHotKeysPushToPath(1, Routes.StudyDetails);
  useHotKeysPushToPath(2, Routes.StudyFindings);
  useHotKeysPushToPath(3, Routes.StudyConclusion);

  return (
    <section className={styles['study-tabs-section']}>
      <div className={styles['study-tabs-wrap']}>
        <Switch>
          <ProtectedRoute path={Routes.StudyDetails}>
            <StudyDetails />
          </ProtectedRoute>
          <ProtectedRoute path={Routes.StudyFindings}>
            <PropertiesProviderContext>
              <FindingsContainer />
            </PropertiesProviderContext>
          </ProtectedRoute>
          <ProtectedRoute path={Routes.StudyConclusion}>
            <Conclusion />
          </ProtectedRoute>
          <Redirect to={Routes.StudyDetails} from={Routes.Study} />
        </Switch>
      </div>
    </section>
  );
};
