import { ApiError } from 'entities/ApiError.entity';
import { Description } from 'entities/Description.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { EventCodingHeadModel } from 'entities/EventCodingHeadModel.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { LocationType } from 'enums/LocationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { StudyApi } from 'services/API/Study/StudyApi';
import { BaseStore } from 'stores/BaseStore';
import { stores } from 'stores/index';

export enum SensorState {
  Empty,
  Normal,
  Max
}

export type SensorMeta = {
  left: boolean;
  right: boolean;
  midline: boolean;
  frontal: boolean;
  central: boolean;
  temporal: boolean;
  regions: string[];
};

export enum SensorPositionMarker {
  True = 'True',
  False = 'False'
}

export class HeadModelStore implements BaseStore {
  @observable
  headModels!: Map<EventCodingHeadModel['eventCodingId'], EventCodingHeadModel>;

  // HeadModels without labels for preview
  @observable
  simpleHeadModels!: Map<
    EventCodingHeadModel['eventCodingId'],
    EventCodingHeadModel
  >;

  @observable
  sensorsMeta!: Map<
    EventCodingHeadModel['eventCodingId'],
    Map<LocationType, Map<string, SensorMeta>>
  >;

  @observable
  headModelLoading!: boolean;

  @observable
  headModelError?: ApiError | UnknownError;

  @observable
  headModelsLoading!: boolean;

  @observable
  headModelsError?: ApiError | UnknownError;

  constructor() {
    makeObservable(this);
    this.reset();
  }

  @action reset() {
    this.headModels = new Map();
    this.simpleHeadModels = new Map();
    this.sensorsMeta = new Map();
    this.headModelLoading = false;
    this.headModelError = undefined;
    this.headModelsLoading = false;
    this.headModelsError = undefined;
  }

  @action clearErrors() {
    this.headModelError = undefined;
    this.headModelsError = undefined;
  }

  getSensorsMeta(
    eventCodingId: EventCodingHeadModel['eventCodingId'],
    locationType: LocationType
  ) {
    return this.sensorsMeta.get(eventCodingId)?.get(locationType);
  }

  @action
  setSensorsMeta(
    eventCodingId: EventCodingHeadModel['eventCodingId'],
    locationType: LocationType,
    metaData: Map<string, SensorMeta>
  ) {
    if (!this.sensorsMeta.has(eventCodingId)) {
      this.sensorsMeta.set(eventCodingId, new Map());
    }

    this.sensorsMeta.get(eventCodingId)!.set(locationType, metaData);
  }

  @action
  async loadHeadModel(
    descriptionId: Description['descriptionId'],
    eventCodingId: EventCoding['eventCodingId']
  ) {
    this.headModelLoading = true;
    this.headModelError = undefined;

    try {
      const { data } = await StudyApi.loadHeadModelByEventCodingId(
        descriptionId,
        eventCodingId
      );

      this.headModels.set(
        eventCodingId,
        EventCodingHeadModel.deserialize(data)
      );
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('head-model', error);
      this.headModelError = error;
    } finally {
      this.headModelLoading = false;
    }
  }

  @action
  async loadSimpleHeadModels(descriptionId: Description['descriptionId']) {
    this.headModelsLoading = true;
    this.headModelsError = undefined;

    try {
      const { data } = await StudyApi.loadHeadModels(descriptionId);

      const headModelsList = EventCodingHeadModel.deserializeAsList(data);

      runInAction(() => {
        headModelsList.map((headModel) =>
          this.simpleHeadModels.set(headModel.eventCodingId, headModel)
        );
      });
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      this.headModelsError = error;
      stores[StoreType.Messages].addMsgError('head-models', error);
    } finally {
      this.headModelsLoading = false;
    }
  }

  getHeadModels(
    eventCodingId: EventCoding['eventCodingId'],
    simpleList?: boolean
  ) {
    const headModels = simpleList
      ? this.simpleHeadModels.get(eventCodingId)
      : this.headModels.get(eventCodingId);

    return {
      onset: headModels?.headModels.find(
        (headModel) => headModel.locationTypeId === LocationType.Onset
      ),
      propagation: headModels?.headModels.find(
        (headModel) => headModel.locationTypeId === LocationType.Propagation
      )
    };
  }
}
