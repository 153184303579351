import { deserialize, serializable } from 'serializr';

export class EventCodingUpdateDTO {
  @serializable
  eventCodingId: number = 0;

  @serializable
  sortOrder?: number;

  @serializable
  parentEventCodingId?: number;

  @serializable
  freeText?: number;

  static deserialize(json: Object | string) {
    return deserialize(EventCodingUpdateDTO, json);
  }

  static deserializeAsList(
    list: EventCodingUpdateDTO[]
  ): EventCodingUpdateDTO[] {
    return list.map(EventCodingUpdateDTO.deserialize);
  }
}
