import { generatePath } from 'react-router-dom';
import {
  EventsTreeBlockItem,
  EventsTreeSubitem,
  EventsTreeSubitemTheme,
  EventsTreeSubitemType,
  ImagesDataType,
  StatusIndicatorStatus
} from '@holberg/ui-kit';
import { generateSettingsConfigKey } from 'components/Category/helpers';
import { sortFindings } from 'components/Category/useAggregateFindings';
import { groupFindingsBySortOrder } from 'components/EventsTree/helpers';
import { compareAsc } from 'date-fns';
import { debounce } from 'debounce';
import { ApiError } from 'entities/ApiError.entity';
import { Description } from 'entities/Description.entity';
import { DescriptionStatus } from 'entities/DescriptionStatus.entity';
import { EEGMarkerType } from 'entities/EEGMarkerType.entity';
import { Event } from 'entities/Event.entity';
import { EventCode } from 'entities/EventCode.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { EventCodingCreateDTO } from 'entities/EventCodingCreateDTO.entity';
import { EventCodingUpdateDTO } from 'entities/EventCodingUpdateDTO.entity';
import { GenericEventTree } from 'entities/GenericEventTree.entity';
import { Report } from 'entities/Report.entity';
import { Screenshot } from 'entities/Screenshot.entity';
import { ShoppingCart } from 'entities/ShoppingCart';
import { Study } from 'entities/Study.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { EventTreeSettingsType } from 'enums/EventTreeSettingsType.enum';
import { HeadModelTemplate } from 'enums/HeadModelTemplate.enum';
import { MarkerGroups } from 'enums/MarkerGroups.enum';
import {
  ReaderErrorMessage,
  ReaderErrorType
} from 'enums/ReaderErrorType.enum';
import {
  RealTimeUpdateReceiveMessages,
  RealTimeUpdateSendMessages
} from 'enums/RealTimeUpdateType.enum';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction
} from 'mobx';
import { ScoreApi } from 'services/API/Score/ScoreApi';
import { ScreenshotApi } from 'services/API/Screenshot/ScreenshotApi';
import { StudyApi } from 'services/API/Study/StudyApi';
import { ReportEventTreeBuilder } from 'services/EventsTree/ReportEventsTreeBuilder';
import { history } from 'services/history';
import {
  EventChangesAckStatusData,
  EventRTUData,
  RTUManager,
  StudyRTUData
} from 'services/RealTimeUpdatesManager';
import { BaseStore } from 'stores/BaseStore';
import { EventTreeState } from 'stores/findings/EventTreeState';
import { stores } from 'stores/index';
import { formatLocaleDateTime } from 'utils/dateHelpers';

import { getEventCodingsIdentifier } from './helpers';
import { SelectionState } from './SelectionState';

const MODULATORS_EVENT_CODE_ID = 440;
export interface EventCodesTreeItem {
  item: EventCode;
  isNested?: boolean;
  eventCoding?: EventCoding;
  children: EventCodesTreeItem[];
}

export class FindingsStore implements BaseStore {
  @observable
  readonly eventTreeState = new EventTreeState();

  @observable
  selectionState = new SelectionState();

  @observable
  findingsLoading!: boolean;

  @observable
  findingsError?: ApiError | UnknownError;

  @observable
  eventCodesLoading!: boolean;

  @observable
  eventCodesError?: ApiError | UnknownError;

  @observable
  eventCodes: ReturnType<typeof EventCode.deserializeAsMap> = new Map();

  @observable
  eventCodesByAgeConstraint: EventCode[] = [];

  @observable
  eventCodingsLoading!: boolean;

  @observable
  eventCodingsError?: ApiError | UnknownError;

  @observable
  descriptionStatuses: Map<
    Description['descriptionId'],
    DescriptionStatus
  > = new Map();

  @observable
  descriptionStatusesLoading!: boolean;

  @observable
  descriptionStatusesError?: ApiError | UnknownError;

  @observable
  eventCodings: Map<
    Description['descriptionId'],
    ReturnType<typeof EventCoding.deserializeAsMap>
  > = new Map();

  @observable
  eventsLoading!: boolean;

  @observable
  eventsError?: ApiError | UnknownError;

  @observable
  events: Map<
    Description['descriptionId'],
    ReturnType<typeof Event.deserializeAsMap>
  > = new Map();

  @observable
  unclassifiedEvents: Map<EEGMarkerType['markerTypeId'], Event[]> = new Map();

  @observable
  autoScoreMarkerTypes: EEGMarkerType[] = [];

  @observable
  markerTypesLoading!: boolean;

  @observable
  focusedEventCodingId?: EventCoding['eventCodingId'];

  @observable
  reportHeadModel?: string;

  @observable
  reportHeadModelLoading!: boolean;

  @observable
  reportHeadModelError?: ApiError | UnknownError;

  @observable
  shoppingCart!: Map<
    Description['descriptionId'],
    ReturnType<typeof ShoppingCart.deserializeAsMap>
  >;

  @observable
  shoppingCartLoading!: boolean;

  @observable
  shoppingCartError?: ApiError | UnknownError;

  @observable
  activeUnclassifiedEvent?: Event;

  @observable
  screenshots: ReturnType<typeof Screenshot.deserializeAsMap> = new Map();

  screenshotsCountInReport: number = 0;

  screenshotsInReport: Screenshot[] = [];

  @observable
  screenshotsLoading: boolean = false;

  @observable
  openEventScreenshot: Event['eventId'] | null = null;

  @observable
  eventsAwaitingAck: boolean = false;

  @observable
  activeDescriptionId: number = -1;

  constructor() {
    makeObservable(this);
    this.reset();

    reaction(
      () => JSON.stringify(this.events.get(this.activeDescriptionId)),
      () => this.checkAnyEventsAwaitingForAck()
    );

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.ActivateEvent,
        callback: (data) => this.activateEvent(data)
      },
      {
        message: RealTimeUpdateReceiveMessages.EventDeleted,
        callback: (data) => this.onEventDeletion(data)
      },
      {
        message: RealTimeUpdateReceiveMessages.EventUpdated,
        callback: (data) => this.applyEventCodingsRTUUpdates(data)
      },
      {
        message: RealTimeUpdateReceiveMessages.EventAdded,
        callback: (data) => {
          this.applyEventCodingsRTUUpdates(data);
          this.activateFinding(data);
        }
      }
    ]);

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.EventChangesAckStatus,
        callback: (data) => this.onEventChangeAck(data)
      }
    ]);

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.StudyUpdated,
        callback: (data) => this.refreshStudyData(data)
      }
    ]);

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.StudyScreenshotDeleted,
        callback: (data) => {
          if (data.isActionSuccessful) {
            this.screenshots.delete(data.screenshot.eventId);
          }
        }
      }
    ]);

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.StudyScreenshotsSynced,
        callback: (data) =>
          this.screenshots.set(
            data.screenshot.eventId,
            Screenshot.deserialize(data.screenshot)
          )
      }
    ]);
  }

  @action
  private refreshStudyData(data: StudyRTUData) {
    const { study, descriptionId } = data;

    stores[StoreType.Messages].removeError('activate-app-event');

    if (study && descriptionId) {
      this.loadEvents(descriptionId);
      this.loadEventCodings(descriptionId);
      this.loadShoppingCarts(descriptionId);
      this.reloadStudyDerivedData(descriptionId);
    }
  }

  @action
  reloadStudyDerivedData(descriptionId: Description['descriptionId']) {
    this.loadDescriptionStatus(descriptionId);
    this.loadReportHeadModel(descriptionId);
  }

  @action
  private setEvents(
    descriptionId: Description['descriptionId'],
    events: Event[] = []
  ) {
    const existingEvents =
      this.events.get(descriptionId) || new Map<number, Event>();

    this.events.set(
      descriptionId,
      new Map([...existingEvents, ...Event.deserializeAsMap(events)])
    );
    this.filterEventsByCategories(descriptionId);
  }

  @action
  private setEventCodings(
    descriptionId: Description['descriptionId'],
    eventCodings: EventCoding[] = []
  ) {
    const existingEventCodings =
      this.eventCodings.get(descriptionId) || new Map();

    this.eventCodings.set(
      descriptionId,
      new Map([
        ...existingEventCodings,
        ...EventCoding.deserializeAsMap(eventCodings)
      ])
    );
  }

  @action
  private applyEventCodingsRTUUpdates(data: EventRTUData) {
    if (data.descriptionId === null) return;
    this.setEvents(data.descriptionId, data?.findingsTreeChanges?.events);
    this.setEventCodings(
      data.descriptionId,
      data.findingsTreeChanges?.eventCodings
    );

    this.loadShoppingCarts(
      data.descriptionId,
      data.findingsTreeChanges?.eventCodings.map(
        (coding) => coding.eventCodingId
      )
    );
    this.reloadStudyDerivedData(data.descriptionId);
  }

  @action
  private onEventDeletion = (data: EventRTUData) => {
    if (data.descriptionId === null) return;

    const deletedEventFindingId = this.events
      .get(data.descriptionId)
      ?.get(data.eventId)?.eventCodingId;

    this.applyEventCodingsRTUUpdates(data);

    if (deletedEventFindingId) {
      this.deleteFindingsWithLastExample(
        [deletedEventFindingId],
        data.descriptionId
      );
    }
  };

  @action
  private activateFinding = (data: EventRTUData) => {
    const { studyId } = data;
    data.findingsTreeChanges?.eventCodings.forEach((coding) => {
      if (coding.eventCodingId && coding.eventCodeId) {
        const parentFolderId = this.eventCodes.get(coding.eventCodeId)
          ?.parentFolderId;
        this.eventTreeState.updateEventTreeSettingsConfig({
          studyId,
          entityId: parentFolderId!,
          settingKey: EventTreeSettingsType.CategoriesState,
          expanded: true
        });
        this.focusEventCodingById(coding.eventCodingId);
      }
    });
  };

  @action
  private activateEvent = async (data: EventRTUData) => {
    if (data.descriptionId === null) return;

    const { eventId, studyId, descriptionId } = data;

    history.push(
      generatePath(Routes.StudyFindings, { id: String(data.descriptionId) })
    );

    const loaders: Array<Promise<void>> = [];

    if (!this.events.get(descriptionId)) {
      loaders.push(this.loadEvents(descriptionId));
    }

    if (!this.eventCodings.get(descriptionId)) {
      loaders.push(this.loadEventCodings(descriptionId));
      loaders.push(this.loadShoppingCarts(descriptionId));
    }

    await Promise.all(loaders);

    this.updateStudyIdsRTUConfig(studyId);

    const events = [
      ...(this.events.get(descriptionId) || new Map<number, Event>()).values()
    ];
    const activeEvent = events.find(
      (event: Event) => event.eventId === eventId
    );

    if (activeEvent) {
      stores[StoreType.RealTimeUpdates].updateRTUConfig({
        activeEventId: eventId
      });

      const activeEventCoding = this.eventCodings
        .get(descriptionId)
        ?.get(activeEvent.eventCodingId);

      if (activeEventCoding) {
        this.expandActiveExample({
          descriptionId,
          activeEventCoding,
          events
        });
      } else {
        this.activeUnclassifiedEvent = activeEvent;
      }
    } else {
      RTUManager.sendMessage(RealTimeUpdateSendMessages.EventNotFound, {
        studyId,
        internalEventId: eventId
      });

      stores[StoreType.Messages].addMsgError('activate-eeg-event', {
        name: ReaderErrorMessage[ReaderErrorType.NotFoundEventApp],
        message: ReaderErrorMessage[ReaderErrorType.NotFoundEventApp],
        fullMessage: ReaderErrorMessage[ReaderErrorType.NotFoundEventApp]
      });
    }
  };

  @action
  onEventChangeAck(data: EventChangesAckStatusData) {
    data.ackStatuses.forEach((eventAckStatus) => {
      const eventToBeUpdated = this.events
        .get(this.activeDescriptionId)
        ?.get(eventAckStatus.eventId);
      if (eventToBeUpdated) {
        eventToBeUpdated.awaitingEventUpdateAckCount =
          eventAckStatus.awaitingEventUpdateAckCount;
        eventToBeUpdated.isSynced = eventAckStatus.isSynced;
      }
    });
  }

  @action
  private expandActiveExample({
    descriptionId,
    activeEventCoding,
    events
  }: {
    descriptionId: Description['descriptionId'];
    activeEventCoding: EventCoding;
    events: Event[];
  }) {
    const parentEventCoding =
      activeEventCoding.parentId &&
      this.eventCodings.get(descriptionId)?.get(activeEventCoding.parentId);

    const relatedEventCodings = parentEventCoding
      ? [activeEventCoding, parentEventCoding]
      : [activeEventCoding];

    this.updateEventTreeSettings(
      descriptionId,
      {
        eventCodings: relatedEventCodings,
        events
      },
      activeEventCoding
    );
  }

  @action
  private updateStudyIdsRTUConfig(studyId: Study['studyId']) {
    const prevActiveStudyIds =
      stores[StoreType.RealTimeUpdates].realTimeUpdatesConfig?.activeStudyIds ||
      [];

    const activeStudyIds = prevActiveStudyIds.some((id) => id === studyId)
      ? prevActiveStudyIds
      : [...prevActiveStudyIds, studyId];

    stores[StoreType.RealTimeUpdates].updateRTUConfig({
      activeStudyIds
    });
  }

  @action
  reset() {
    this.eventCodesLoading = false;
    this.eventCodesError = undefined;
    this.eventCodes = new Map();
    this.eventCodingsLoading = false;
    this.eventCodingsError = undefined;
    this.eventCodings = new Map();
    this.eventsLoading = false;
    this.eventsError = undefined;
    this.markerTypesLoading = false;
    this.events = new Map();
    this.findingsLoading = false;
    this.findingsError = undefined;
    this.descriptionStatusesLoading = false;
    this.descriptionStatusesError = undefined;
    this.reportHeadModelError = undefined;
    this.reportHeadModelLoading = false;
    this.shoppingCart = new Map();
    this.shoppingCartLoading = false;
    this.shoppingCartError = undefined;
    this.unclassifiedEvents = new Map();
    this.activeUnclassifiedEvent = undefined;
    this.screenshotsLoading = false;
    this.focusEventCodingById();
    this.selectionState.discardSelections();
    this.openEventScreenshot = null;
    this.screenshotsCountInReport = 0;
    this.screenshotsInReport = [];
    this.eventsAwaitingAck = false;
    this.activeDescriptionId = -1;
  }

  @computed
  get saveStatus(): StatusIndicatorStatus {
    if (this.findingsLoading) {
      return StatusIndicatorStatus.Loading;
    }

    if (!!this.findingsError) {
      return StatusIndicatorStatus.NonRetryError;
    }

    return StatusIndicatorStatus.Saved;
  }

  @action
  resetSelectionState() {
    this.selectionState.discardSelections();
  }

  private setFindingsLoading = debounce(
    (state: boolean) => (this.findingsLoading = state),
    250
  );

  abnormalEventCodings(
    descriptionId: Description['descriptionId']
  ): EventCoding[] {
    const eventCodingList = this.eventCodings.get(descriptionId) || new Map();

    return [...eventCodingList.values()].filter(
      (eventCoding) => eventCoding.isAbnormal
    );
  }

  isToBeDefined(eventCodeId: EventCode['eventCodeId']): boolean {
    const activeEventCode = this.eventCodes.get(eventCodeId);

    return !!activeEventCode && activeEventCode.isToBeDefinedNode;
  }

  getVisibleParentEventCode(
    eventCodeId?: EventCode['eventCodeId']
  ): EventCode['parentId'] {
    if (eventCodeId === undefined) {
      return null;
    }

    const activeEventCode = this.eventCodes.get(eventCodeId);

    if (!activeEventCode) {
      return null;
    }

    const parentEventCodeId = activeEventCode.isToBeDefinedNode
      ? activeEventCode.parentFolderId
      : activeEventCode.parentId || activeEventCode.parentFolderId;

    const parentEventCode = this.eventCodes.get(parentEventCodeId);

    return parentEventCode?.showInSelectionTreeView
      ? parentEventCode.eventCodeId
      : parentEventCode?.eventCodeId === activeEventCode.eventCodeId
      ? null
      : this.getVisibleParentEventCode(parentEventCode?.eventCodeId);
  }

  eventCodingsForEventCode(
    descriptionId: Description['descriptionId'] = 0,
    eventCodeId: EventCode['eventCodeId']
  ): EventCoding[] {
    const eventCodingsMap = this.eventCodings.get(descriptionId);
    if (!eventCodingsMap) {
      return [];
    }

    return [...eventCodingsMap.values()].filter(
      (coding) => coding.eventCodeId === eventCodeId && coding.isActive
    );
  }

  eventsForEventCoding(
    descriptionId: Description['descriptionId'],
    eventCodingId: EventCoding['eventCodingId'] = 0
  ): Event[] {
    const events = this.events.get(descriptionId)
      ? [...this.events.get(descriptionId)!.values()]
      : [];
    const isDescriptionCompleted = stores[
      StoreType.Descriptions
    ].descriptionById(descriptionId)?.isCompleted;
    return events
      .filter(
        (event) =>
          event.eventCodingId === eventCodingId &&
          (event.isActive || isDescriptionCompleted)
      )
      .sort((a, b) => compareAsc(a.startDatetime, b.startDatetime));
  }

  @action setEventCoding(
    descriptionId: Description['descriptionId'],
    eventCoding: EventCoding
  ) {
    this.eventCodings
      .get(descriptionId)
      ?.set(eventCoding.eventCodingId, EventCoding.deserialize(eventCoding));
  }

  @action
  private applyUpdates(
    { eventCodings, events }: GenericEventTree,
    descriptionId: Description['descriptionId']
  ) {
    if (!this.eventCodings.get(descriptionId)) {
      this.eventCodings.set(descriptionId, new Map());
    }

    if (!this.events.get(descriptionId)) {
      this.events.set(descriptionId, new Map());
    }

    eventCodings.forEach((eventCoding) =>
      this.setEventCoding(descriptionId, eventCoding)
    );
    events.forEach((eventItem) =>
      this.events
        .get(descriptionId)
        ?.set(eventItem.eventId, Event.deserialize(eventItem))
    );
    this.filterEventsByCategories(descriptionId);
  }

  @action
  focusEventCodingById(id?: EventCoding['eventCodingId']) {
    this.focusedEventCodingId = id;
  }

  @action
  async loadScreenshots(recordingIds: number[]) {
    this.screenshotsLoading = true;
    try {
      const { data } = await ScreenshotApi.getScreenshotsByRecordingIds(
        recordingIds
      );
      this.screenshots = Screenshot.deserializeAsMap(data);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('screenshot', error);
    } finally {
      this.screenshotsLoading = false;
    }
  }

  @action
  async captureScreenshot(recordingId: number, eventId: number) {
    this.screenshotsLoading = true;
    try {
      const { data } = await ScreenshotApi.createScreenshot(
        recordingId,
        eventId
      );
      this.screenshots.set(data.eventId, Screenshot.deserialize(data));
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('screenshot', error);
    } finally {
      this.screenshotsLoading = false;
    }
  }

  @action
  async deleteScreenshot(screenshotId: number) {
    this.screenshotsLoading = true;
    try {
      const { data } = await ScreenshotApi.deleteScreenshot(screenshotId);
      this.screenshots.delete(data.eventId);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('screenshot', error);
    } finally {
      this.screenshotsLoading = false;
    }
  }

  getScreenshot(eventId: number) {
    return this.screenshots.get(eventId);
  }

  getScreenshotHeaderData(eventId: number, descriptionId: number) {
    let findingLabel: string | undefined = '';
    let exampleDate: string | undefined = '';
    let exampleFreeText: string | undefined = '';
    let shoppingCartNodes: EventsTreeSubitem[] | undefined = [];
    const example = this.events.get(descriptionId)!.get(eventId);
    if (example) {
      const eventCodeId = this.eventCodings
        .get(descriptionId)!
        .get(example!.eventCodingId)?.eventCodeId;
      findingLabel = this.eventCodes.get(eventCodeId!)?.translatedLongName
        .eitherValue;
      shoppingCartNodes = this.shoppingCart
        .get(descriptionId)
        ?.get(example!.eventCodingId)?.shoppingCartNodes;
      exampleDate = formatLocaleDateTime(example!.startDatetime, '');
      exampleFreeText = example?.freeText;
    }
    return { findingLabel, shoppingCartNodes, exampleDate, exampleFreeText };
  }

  @action
  openScreenshotModal(eventId: number | null) {
    this.openEventScreenshot = eventId;
  }

  @action
  async loadEventCodes(ageConstraint = 'all') {
    this.eventCodesLoading = true;
    this.eventCodesError = undefined;

    try {
      const { data } = await ScoreApi.loadEventCodes('all');

      this.eventCodes = EventCode.deserializeAsMap(data);
      this.eventCodesByAgeConstraint = EventCode.deserializeAsList(data)
        .filter((eventCode: EventCode) =>
          ageConstraint.includes(eventCode.ageConstraintId.toString())
        )
        .sort((a: EventCode, b: EventCode) => a.sortOrder - b.sortOrder);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('event-codes', error);
      this.eventCodesError = error;
    } finally {
      this.eventCodesLoading = false;
    }
  }

  @action
  async loadEventCodings(descriptionId: Description['descriptionId']) {
    this.eventCodingsLoading = true;
    this.eventCodingsError = undefined;

    try {
      const { data } = await StudyApi.loadEventCodings(descriptionId);

      this.eventCodings.set(descriptionId, EventCoding.deserializeAsMap(data));
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('event-codings', error);
      this.eventCodingsError = error;
    } finally {
      this.eventCodingsLoading = false;
    }
  }

  @action
  async loadDescriptionStatus(descriptionId: Description['descriptionId']) {
    this.descriptionStatusesLoading = true;
    this.descriptionStatusesError = undefined;

    try {
      const { data } = await StudyApi.loadDescriptionStatus(descriptionId);

      this.descriptionStatuses.set(
        descriptionId,
        DescriptionStatus.deserialize(data)
      );
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('description-statuses', error);
      this.descriptionStatusesError = error;
    } finally {
      this.descriptionStatusesLoading = false;
    }
  }

  @action
  async updateEventCodings({
    data,
    newEventCodeId,
    descriptionId,
    identifier
  }: {
    descriptionId: number;
    data: EventCodingUpdateDTO[];
    identifier: string;
    newEventCodeId: number;
  }): Promise<EventCoding[]> {
    this.setFindingsLoading(true);
    this.findingsError = undefined;
    const updateDto = EventCodingUpdateDTO.deserializeAsList(data);

    try {
      const { data } = await StudyApi.updateEventCodings({
        dto: updateDto,
        descriptionId,
        newEventCodeId
      });

      await this.loadShoppingCarts(
        descriptionId,
        data.eventCodings.map((coding) => coding.eventCodingId)
      );
      this.reloadStudyDerivedData(descriptionId);

      if (!this.eventCodings.get(descriptionId)) {
        this.eventCodings.set(descriptionId, new Map());
      }

      data.eventCodings.forEach((eventCoding) =>
        this.eventCodings
          .get(descriptionId)
          ?.set(eventCoding.eventCodingId, EventCoding.deserialize(eventCoding))
      );
      if (data.eventCodings.length > 1 && data.events.length > 0) {
        this.setEvents(data.eventCodings[0].descriptionId, data.events);
      }
      stores[StoreType.Messages].removeError(identifier);
      return data.eventCodings;
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError(identifier, error);
      this.findingsError = error;
      return [];
    } finally {
      this.setFindingsLoading(false);
    }
  }

  @action
  async mergeEventCodings({
    descriptionId,
    destinationEventCodingId,
    sourceEventCodingIds
  }: {
    descriptionId: Description['descriptionId'];
    destinationEventCodingId: EventCoding['eventCodingId'];
    sourceEventCodingIds: Array<EventCoding['eventCodingId']>;
  }) {
    this.setFindingsLoading(true);
    this.findingsError = undefined;

    const identifier = `finding-move-${destinationEventCodingId}-${sourceEventCodingIds.join(
      ','
    )}`;

    try {
      const { data } = await StudyApi.mergeEventCodings({
        descriptionId,
        destinationEventCodingId,
        sourceEventCodingIds
      });

      await this.loadShoppingCarts(
        descriptionId,
        data.eventCodings.map((coding) => coding.eventCodingId)
      );
      this.reloadStudyDerivedData(descriptionId);

      this.applyUpdates(data, descriptionId);
      stores[StoreType.Messages].removeError(identifier);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError(identifier, error);
      this.findingsError = error;
    } finally {
      this.setFindingsLoading(false);
    }
  }

  @action
  async updateEventCodingsSortOrder({
    data,
    descriptionId,
    identifier = 'sort-order'
  }: {
    descriptionId: number;
    data: EventCodingUpdateDTO[];
    identifier?: string;
  }) {
    this.setFindingsLoading(true);
    this.findingsError = undefined;
    const updateDto = EventCodingUpdateDTO.deserializeAsList(data);

    try {
      const { data } = await StudyApi.updateEventCodingsSortOrder({
        dto: updateDto,
        descriptionId
      });

      this.reloadStudyDerivedData(descriptionId);

      if (!this.eventCodings.get(descriptionId)) {
        this.eventCodings.set(descriptionId, new Map());
      }

      data.forEach((eventCoding) =>
        this.eventCodings
          .get(descriptionId)
          ?.set(eventCoding.eventCodingId, EventCoding.deserialize(eventCoding))
      );
      stores[StoreType.Messages].removeError(identifier);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError(identifier, error);
      this.findingsError = error;
    } finally {
      this.setFindingsLoading(false);
    }
  }

  @action
  async deleteEventCodings(
    descriptionId: Description['descriptionId'],
    eventCodingIds: Array<EventCoding['eventCodingId']>
  ) {
    this.setFindingsLoading(true);
    this.findingsError = undefined;
    const identifier = `findings-delete-${eventCodingIds.sort().join('-')}`;
    try {
      const { data } = await StudyApi.deleteEventCodings(
        descriptionId,
        eventCodingIds
      );

      this.reloadStudyDerivedData(descriptionId);

      this.applyUpdates(data, descriptionId);
      data.eventCodings.forEach((eventCoding) =>
        this.eventCodings.get(descriptionId)?.delete(eventCoding.eventCodingId)
      );
      stores[StoreType.Messages].removeError(identifier);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError(identifier, error);
      this.findingsError = error;
    } finally {
      this.setFindingsLoading(false);
    }
  }

  private getEventCodesPath(
    destinationEventCodeId: EventCode['eventCodeId'],
    currentEventCode?: EventCode,
    path: Array<EventCode['eventCodeId']> = []
  ): Array<EventCode['eventCodeId']> {
    if (
      !currentEventCode ||
      currentEventCode.eventCodeId === destinationEventCodeId
    ) {
      return path;
    }

    return this.getEventCodesPath(
      destinationEventCodeId,
      currentEventCode.parentId === null
        ? undefined
        : this.eventCodes.get(currentEventCode.parentId),
      currentEventCode.showInFindingTreeView
        ? [...path, currentEventCode.eventCodeId]
        : path
    );
  }

  private updateEventTreeSettings(
    descriptionId: Description['descriptionId'],
    data: GenericEventTree,
    eventCoding: EventCoding
  ) {
    const rootEventCoding = data.eventCodings.find(
      (coding) => coding.parentId === null
    );

    this.focusEventCodingById(eventCoding.eventCodingId);

    const rootCategoryId =
      rootEventCoding &&
      this.eventCodes.get(rootEventCoding.eventCodeId)?.parentFolderId;
    const foldersPath =
      eventCoding.eventCodingId === rootEventCoding?.eventCodingId
        ? []
        : this.getEventCodesPath(
            rootEventCoding!.eventCodeId,
            this.eventCodes.get(eventCoding.eventCodeId)
          );

    if (rootCategoryId) {
      this.eventTreeState.updateEventTreeSettingsConfig({
        studyId: descriptionId,
        entityId: rootCategoryId,
        settingKey: EventTreeSettingsType.CategoriesState,
        expanded: true
      });
    }

    foldersPath.forEach((folderId) => {
      const settingsConfigKey = generateSettingsConfigKey(
        folderId,
        rootEventCoding?.eventCodingId
      );
      this.eventTreeState.updateEventTreeSettingsConfig({
        studyId: descriptionId,
        entityId: settingsConfigKey,
        settingKey: EventTreeSettingsType.CategoriesState,
        expanded: true
      });
    });
    data.eventCodings.forEach((eventCoding) => {
      this.eventTreeState.updateEventTreeSettingsConfig({
        studyId: descriptionId,
        entityId: eventCoding.eventCodingId,
        expanded: true,
        settingKey: EventTreeSettingsType.FindingsState
      });
    });
    this.eventTreeState.updateEventTreeSettingsConfig({
      studyId: descriptionId,
      entityId: eventCoding.eventCodingId,
      settingKey: EventTreeSettingsType.ExamplesState,
      expanded: true
    });
  }

  @action
  async loadAutoScoreCategories() {
    this.markerTypesLoading = true;
    try {
      const { data } = await StudyApi.loadEEGMarkerTypeGroup(
        MarkerGroups.AutoScore
      );
      this.autoScoreMarkerTypes = EEGMarkerType.deserializeAsList(data);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('marker-types', error);
    } finally {
      this.markerTypesLoading = false;
    }
  }

  @action
  async createEventCoding({
    data,
    studyId,
    descriptionId,
    identifier
  }: {
    studyId: Study['studyId'];
    descriptionId: number;
    data: EventCodingCreateDTO;
    identifier: string;
  }): Promise<EventCoding[]> {
    this.setFindingsLoading(true);
    this.findingsError = undefined;
    const dto = EventCodingCreateDTO.deserialize(data);

    try {
      const sourceEventCodingIds = this.getEventCodingIdsForEventIds(
        dto.eventIds,
        descriptionId
      );

      const { data: responseData } = await StudyApi.createEventCoding({
        dto,
        descriptionId
      });

      this.reloadStudyDerivedData(descriptionId);

      this.applyUpdates(responseData, descriptionId);
      this.deleteFindingsWithLastExample(sourceEventCodingIds, descriptionId);

      const lastEventCoding =
        responseData.eventCodings.length > 1
          ? responseData.eventCodings.find(
              (coding) => coding.parentId !== null
            ) || responseData.eventCodings[responseData.eventCodings.length - 1]
          : responseData.eventCodings[0];

      const eventCodings = [...responseData.eventCodings];
      if (data.parentEventCodingId) {
        const parentEventCoding = this.eventCodings
          .get(descriptionId)
          ?.get(data.parentEventCodingId)!;
        eventCodings.push(parentEventCoding);
      }

      this.updateEventTreeSettings(
        studyId,
        {
          eventCodings,
          events: [...responseData.events]
        },
        lastEventCoding
      );
      stores[StoreType.Messages].removeError(identifier);
      return responseData.eventCodings;
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError(identifier, error);
      this.findingsError = error;
      return [];
    } finally {
      this.setFindingsLoading(false);
    }
  }

  @action
  async deleteEvents(
    descriptionId: Description['descriptionId'],
    eventIds: Array<Event['eventId']>
  ) {
    this.setFindingsLoading(true);
    this.findingsError = undefined;
    const identifier = `examples-delete-${eventIds.sort().join('-')}`;
    try {
      const sourceEventCodingIds = this.getEventCodingIdsForEventIds(
        eventIds,
        descriptionId
      );
      const { data } = await StudyApi.deleteEvents(descriptionId, eventIds);

      this.reloadStudyDerivedData(descriptionId);

      if (!this.events.get(descriptionId)) {
        this.events.set(descriptionId, new Map());
      }

      const descriptionEventsMap = this.events.get(descriptionId);

      data.forEach((eventItem) =>
        descriptionEventsMap?.set(
          eventItem.eventId,
          Event.deserialize(eventItem)
        )
      );

      this.filterEventsByCategories(descriptionId);
      this.deleteFindingsWithLastExample(sourceEventCodingIds, descriptionId);
      stores[StoreType.Messages].removeError(identifier);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError(identifier, error);
      this.findingsError = error;
    } finally {
      this.setFindingsLoading(false);
    }
  }

  @action
  async deleteEEGMarker(
    descriptionId: Description['descriptionId'],
    eventIds: Array<Event['eventId']>
  ) {
    this.setFindingsLoading(true);
    this.findingsError = undefined;
    const identifier = `examples-delete-from-source-${eventIds
      .sort()
      .join('-')}`;
    try {
      const sourceEventCodingIds = this.getEventCodingIdsForEventIds(
        eventIds,
        descriptionId
      );
      const { data } = await StudyApi.deleteEEGMarkersFromhiSCORE(
        descriptionId,
        eventIds
      );

      data.forEach((eventId) => {
        this.events.get(descriptionId)?.delete(eventId);
      });

      this.reloadStudyDerivedData(descriptionId);
      this.filterEventsByCategories(descriptionId);
      this.deleteFindingsWithLastExample(sourceEventCodingIds, descriptionId);
      this.selectionState.discardSelections();
      stores[StoreType.Messages].removeError(identifier);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError(identifier, error);
      this.findingsError = error;
    } finally {
      this.setFindingsLoading(false);
    }
  }

  @action
  async updateEvents({
    descriptionId,
    eventIds,
    eventCodingId
  }: {
    descriptionId: Description['descriptionId'];
    eventIds: Array<Event['eventId']>;
    eventCodingId: EventCoding['eventCodingId'];
  }) {
    this.setFindingsLoading(true);
    try {
      const sourceEventCodingIds = this.getEventCodingIdsForEventIds(
        eventIds,
        descriptionId
      );
      const { data } = await StudyApi.updateEvents({
        descriptionId,
        eventIds,
        eventCodingId
      });

      this.reloadStudyDerivedData(descriptionId);

      data.forEach((eventItem) =>
        this.events
          .get(descriptionId)
          ?.set(eventItem.eventId, Event.deserialize(eventItem))
      );
      this.filterEventsByCategories(descriptionId);
      this.deleteFindingsWithLastExample(sourceEventCodingIds, descriptionId);
    } catch (err) {
      this.eventsError = ApiError.deserializeFromCatch(err);
    } finally {
      this.setFindingsLoading(false);
    }
  }

  private deleteFindingsWithLastExample(
    eventCodingIds: number[],
    descriptionId: number
  ) {
    if (eventCodingIds && eventCodingIds.length) {
      eventCodingIds.forEach((eventCodingId) => {
        const findingEventsCount = this.eventsForEventCoding(
          descriptionId,
          eventCodingId
        ).length;
        if (findingEventsCount < 1) {
          this.eventCodings.get(descriptionId)?.delete(eventCodingId);
        }
      });
    }
  }

  private getEventCodingIdsForEventIds(
    eventIds: number[],
    descriptionId: number
  ) {
    return eventIds
      .filter(
        (eventId) => this.events.get(descriptionId)?.get(eventId)?.eventCodingId
      )
      .map(
        (eventId) => this.events.get(descriptionId)!.get(eventId)!.eventCodingId
      );
  }

  @action
  async loadEvents(descriptionId: Description['descriptionId']) {
    this.eventsLoading = true;
    this.eventsError = undefined;
    try {
      const { data } = await StudyApi.loadEvents(descriptionId);
      this.events = this.events.set(
        descriptionId,
        Event.deserializeAsMap(data)
      );
      if (this.autoScoreMarkerTypes.length === 0) {
        await this.loadAutoScoreCategories();
      }
      this.filterEventsByCategories(descriptionId);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('events', error);
      this.eventsError = error;
    } finally {
      this.eventsLoading = false;
    }
  }

  filterEventsByCategories(descriptionId: number) {
    const autoScoreMarkerTypeIds = this.autoScoreMarkerTypes.map(
      (markerType) => markerType.markerTypeId
    );
    const unclassifiedEvents = new Map();
    if (!this.events.get(descriptionId)) return;
    for (const event of this.events.get(descriptionId)!.values()) {
      if (!event.eventCodingId && event.isActive) {
        if (autoScoreMarkerTypeIds.includes(event.markerTypeId)) {
          //Autoscore and graphoelements are seperated based on autoScore markerTypeIds
          unclassifiedEvents.get(event.markerTypeId)
            ? unclassifiedEvents.get(event.markerTypeId).push(event)
            : unclassifiedEvents.set(event.markerTypeId, [event]);
        } else {
          unclassifiedEvents.get(MarkerGroups.GraphoElements)
            ? unclassifiedEvents.get(MarkerGroups.GraphoElements).push(event)
            : unclassifiedEvents.set(MarkerGroups.GraphoElements, [event]);
        }
      }
    }

    for (const key of unclassifiedEvents.keys()) {
      unclassifiedEvents.set(
        key,
        unclassifiedEvents
          .get(key)
          .sort(
            (a: Event, b: Event) =>
              b.probability - a.probability ||
              compareAsc(a.startDatetime, b.startDatetime)
          )
      );
    }

    this.unclassifiedEvents = unclassifiedEvents;
  }

  @action
  getUnclassifiedCategories(descriptionId: Description['descriptionId']) {
    if (
      !this.autoScoreMarkerTypes.length ||
      !this.events.get(descriptionId)?.size ||
      !this.autoScoreMarkerTypes.find(
        (markerType) =>
          this.unclassifiedExamplesList(markerType.markerTypeId).length > 0
      )
    )
      return [];

    return [
      {
        id: MarkerGroups.GraphoElements,
        title: null,
        subMenu: [
          {
            id: MarkerGroups.GraphoElements,
            name: 'Graphoelements',
            eventsCount: this.unclassifiedExamplesList(
              MarkerGroups.GraphoElements
            ).length
          }
        ]
      },
      {
        id: MarkerGroups.AutoScore,
        title: 'autoSCORE',
        subMenu: this.autoScoreMarkerTypes
          .map((markerType) => ({
            id: markerType.markerTypeId,
            name: markerType.name.eitherValue,
            eventsCount: this.unclassifiedExamplesList(markerType.markerTypeId)
              .length
          }))
          .sort((a, b) => b.eventsCount - a.eventsCount)
      }
    ];
  }

  @action
  unclassifiedExamplesList(markerTypeId: Event['markerTypeId']): Event[] {
    return this.unclassifiedEvents.get(markerTypeId) || [];
  }

  @action
  checkAnyEventsAwaitingForAck() {
    if (!this.events.get(this.activeDescriptionId)) return;
    this.eventsAwaitingAck = [
      ...this.events.get(this.activeDescriptionId)!.values()
    ]
      .filter((event) => event.eventCodingId)
      .some((event) => event.awaitingEventUpdateAckCount > 0);
  }

  @computed
  get eventCodesList(): EventCode[] {
    const compare = (a: EventCode, b: EventCode) => a.sortOrder - b.sortOrder;
    return [...this.eventCodes.values()].sort(compare);
  }

  @computed
  get eventCodesRootCategories(): EventCode[] {
    return this.eventCodesCategories.filter(
      (node) => node.parentId === null && node.isFolder
    );
  }

  @computed
  get eventCodesFindings(): EventCode[] {
    return this.eventCodesList.filter((node) => !node.isFolder);
  }

  @computed
  get eventCodesCategories(): EventCode[] {
    return this.eventCodesList.filter((node) => node.isFolder);
  }

  makeTreeItem(item: EventCode, isNested?: boolean): EventCodesTreeItem {
    const children: EventCodesTreeItem[] = [];

    if (item.isFolder || item.isExpandableNodeFindingTreeView) {
      const childrenFindings = this.eventCodesFindings
        .filter((finding) => finding.parentFolderId === item.eventCodeId)
        .map((item) => this.makeTreeItem(item));

      children.push(...childrenFindings);

      const childrenFolders = this.eventCodesCategories
        .filter((category) => {
          return category.parentId === item.eventCodeId;
        })
        .map((item) => this.makeTreeItem(item, item.isDependentOnEventCoding));

      children.push(...childrenFolders);
    }

    if (!item.isFolder && item.nestedEventCodeId) {
      const subtreeRoot = this.eventCodes.get(item.nestedEventCodeId);
      if (subtreeRoot) {
        const subTree = this.makeTreeItem(subtreeRoot, true);
        children.push(subTree);
      }
    }

    return {
      item,
      isNested,
      children
    };
  }

  @computed
  get eventCodesTree(): EventCodesTreeItem[] {
    return this.eventCodesRootCategories.map((eventCode) => {
      return this.makeTreeItem(eventCode);
    });
  }

  private hasSameParent(
    eventCoding: EventCoding,
    selectedEventCoding: EventCoding
  ) {
    const eventCode = this.eventCodes.get(eventCoding.eventCodeId);
    const selectedEventCode = this.eventCodes.get(
      selectedEventCoding.eventCodeId
    );

    return (
      eventCode?.parentFolderId === selectedEventCode?.parentFolderId &&
      eventCoding?.parentId === selectedEventCoding?.parentId
    );
  }

  private getEventCodingSiblings(
    descriptionId: Description['descriptionId'],
    selectedEventCoding: EventCoding
  ): EventCodesTreeItem[] {
    const eventCodingsMap = this.eventCodings.get(descriptionId);

    if (!eventCodingsMap) {
      return [];
    }

    return [...eventCodingsMap.values()]
      .filter((eventCoding) =>
        this.hasSameParent(eventCoding, selectedEventCoding)
      )
      .map((eventCoding) => ({
        item: this.eventCodes.get(eventCoding.eventCodeId)!,
        eventCoding,
        children: []
      }))
      .sort(sortFindings);
  }

  private groupSiblings(
    descriptionId: Description['descriptionId'],
    selectedEventCoding: EventCoding
  ): EventCoding[][] {
    const siblingsList = this.getEventCodingSiblings(
      descriptionId,
      selectedEventCoding
    );

    return groupFindingsBySortOrder(siblingsList).map((group) =>
      group.map(({ eventCoding }) => eventCoding!)
    );
  }

  private updateEventCodingsOrder({
    descriptionId,
    eventCodingsLists,
    identifier
  }: {
    descriptionId: Description['descriptionId'];
    eventCodingsLists: EventCoding[][];
    identifier: string;
  }) {
    const data = eventCodingsLists
      .filter((group) => group.length)
      .map((group, index) =>
        group.map((item) => ({
          eventCodingId: item!.eventCodingId,
          sortOrder: index + 1
        }))
      )
      .flat();

    this.updateEventCodingsSortOrder({
      data,
      descriptionId,
      identifier
    });

    this.selectionState.discardFindingsSelections();
  }

  @action
  undoSimultaneous(
    descriptionId: Description['descriptionId'],
    eventCodingsList: EventCoding[]
  ): void {
    const groupedSiblings = this.groupSiblings(
      descriptionId,
      eventCodingsList[0]
    );

    eventCodingsList.forEach((item) => {
      const itemGroup = groupedSiblings[item.sortOrder! - 1];

      if (!itemGroup) {
        return;
      }
      const itemGroupIndex = itemGroup.findIndex((nestedItem) => {
        return nestedItem?.eventCodingId === item.eventCodingId;
      });

      itemGroup.splice(itemGroupIndex, 1);
      groupedSiblings.splice(item.sortOrder!, 0, [item]);
    });

    const actionIdentifier = getEventCodingsIdentifier(eventCodingsList);
    this.updateEventCodingsOrder({
      descriptionId,
      eventCodingsLists: groupedSiblings,
      identifier: `undo-simultaneous-${actionIdentifier}`
    });
  }

  @action
  makeSimultaneous(
    descriptionId: Description['descriptionId'],
    eventCodingsList: EventCoding[]
  ): void {
    const groupedSiblings = this.groupSiblings(
      descriptionId,
      eventCodingsList[0]
    );

    const groupIndexes = eventCodingsList.map((eventCoding) =>
      groupedSiblings.findIndex((group) =>
        group.find(
          (sibling) => sibling.eventCodingId === eventCoding.eventCodingId
        )
      )
    );
    const minSortOrder = Math.min(...groupIndexes);

    const simultaneousGroup: EventCoding[] = [];
    eventCodingsList.forEach((eventCoding, index) => {
      const eventCodingGroup = groupedSiblings[groupIndexes[index]];

      const eventCodingGroupIndex = eventCodingGroup.findIndex((item) => {
        return item.eventCodingId === eventCoding.eventCodingId;
      });

      eventCodingGroup.splice(eventCodingGroupIndex, 1);
      simultaneousGroup.push(eventCoding);
    });

    groupedSiblings.splice(minSortOrder, 0, simultaneousGroup);
    const actionIdentifier = getEventCodingsIdentifier(eventCodingsList);
    this.updateEventCodingsOrder({
      descriptionId,
      eventCodingsLists: groupedSiblings,
      identifier: `make-simultaneous-${actionIdentifier}`
    });
  }

  hasRequiredData(descriptionId?: Description['descriptionId']): boolean {
    if (!descriptionId) {
      return false;
    }

    const descriptionStatus = this.descriptionStatuses.get(descriptionId);

    return descriptionStatus ? !descriptionStatus?.hasErrors : false;
  }

  @action
  async loadReportHeadModel(descriptionId: Description['descriptionId']) {
    this.reportHeadModelLoading = true;
    this.reportHeadModelError = undefined;

    try {
      const { data } = await StudyApi.loadReportHeadModel(
        descriptionId,
        HeadModelTemplate.Report
      );

      this.reportHeadModel = data;
      stores[StoreType.Messages].removeError('report-head-model');
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('report-head-model', error);
      this.reportHeadModelError = error;
    } finally {
      this.reportHeadModelLoading = false;
    }
  }

  getActiveEventCodingsList(
    descriptionId: Description['descriptionId']
  ): EventCoding[] {
    const eventCodings: ReturnType<typeof EventCoding.deserializeAsMap> =
      this.eventCodings.get(descriptionId) || new Map();

    return [...eventCodings.values()].filter((item) => item.isActive);
  }

  hasActiveOnlyOneEventCoding(
    descriptionId: Description['descriptionId'],
    eventCodeId: EventCode['eventCodeId']
  ): boolean {
    return (
      !!this.eventCodes.get(eventCodeId)?.onlyOneEventCoding &&
      this.getActiveEventCodingsList(descriptionId).some(
        (eventCode) => eventCode.eventCodeId === eventCodeId
      )
    );
  }

  private getEventsForEventCodings(
    eventCodingsList: EventCoding[],
    descriptionId: Description['descriptionId']
  ) {
    return eventCodingsList.reduce((acc, eventCoding) => {
      acc.set(
        eventCoding.eventCodingId,
        this.eventsForEventCoding(descriptionId, eventCoding.eventCodingId)
      );

      return acc;
    }, new Map());
  }

  getScreenshotsUnderEventcoding(eventId: number, descriptionId: number) {
    const example = this.events.get(descriptionId)!.get(eventId);
    const eventsUnderFinding = this.eventsForEventCoding(
      descriptionId,
      example?.eventCodingId
    );
    const screenshots = [];
    for (const event of eventsUnderFinding) {
      const screenshot = this.screenshots?.get(event.eventId);
      if (screenshot) {
        screenshots.push(screenshot);
      }
    }
    return screenshots;
  }

  private attachScreenshotsToReportTree(
    reportEventsTree: EventsTreeBlockItem[],
    descriptionId: number
  ) {
    let count = this.screenshotsCountInReport;
    const screenshots: Screenshot[] = [];
    const attachScreenshotsData = (each: EventsTreeBlockItem) => {
      if (each.type === 'finding') {
        const events = this.eventsForEventCoding(descriptionId, each.id);
        const screenshotNums = [];
        for (const event of events) {
          const eventScreenshot = this.screenshots.get(event.eventId);
          if (eventScreenshot) {
            count++;
            screenshotNums.push(count);
            screenshots.push(eventScreenshot);
          }
        }
        if (screenshotNums.length) {
          const screenshotSubitem: EventsTreeSubitem = {
            text: `screenshot ${screenshotNums.join(', ')}`,
            theme: EventsTreeSubitemTheme.Italic,
            type: EventsTreeSubitemType.Screenshot
          };
          each.subItems = each.subItems
            ? [...each.subItems, screenshotSubitem]
            : [screenshotSubitem];
        }
      }

      if (each.children?.length) {
        each.children.forEach((child) => attachScreenshotsData(child));
      }
    };

    reportEventsTree.forEach((eventsTreeBlockItem: EventsTreeBlockItem) => {
      attachScreenshotsData(eventsTreeBlockItem);
    });

    this.screenshotsCountInReport = screenshots.length;
    this.screenshotsInReport = [...this.screenshotsInReport, ...screenshots];

    return { reportEventsTree: reportEventsTree, screenshots: screenshots };
  }

  private getReportTree(
    descriptionId: Description['descriptionId'],
    reportDetails: Report,
    eventCodesTree?: EventCodesTreeItem[]
  ) {
    const eventCodingsList = this.getActiveEventCodingsList(descriptionId);

    if (!eventCodesTree) {
      return [];
    }

    const builder = new ReportEventTreeBuilder(
      eventCodingsList,
      this.eventCodes,
      eventCodesTree,
      reportDetails,
      this.shoppingCart.get(descriptionId),
      this.getEventsForEventCodings(eventCodingsList, descriptionId)
    );

    return builder.buildFullTree();
  }

  getReportEventsTree(
    descriptionId: Description['descriptionId'],
    reportDetails: Report,
    addScreenshotsData: boolean
  ): EventsTreeBlockItem[] {
    const nonModulatorCodesTree = this.eventCodesTree.filter(
      ({ item }) => item.eventCodeId !== MODULATORS_EVENT_CODE_ID
    );

    if (addScreenshotsData) {
      this.screenshotsCountInReport = 0;
      this.screenshotsInReport = [];
      const { reportEventsTree } = this.attachScreenshotsToReportTree(
        this.getReportTree(descriptionId, reportDetails, nonModulatorCodesTree),
        descriptionId
      );
      return reportEventsTree;
    } else {
      return this.getReportTree(
        descriptionId,
        reportDetails,
        nonModulatorCodesTree
      );
    }
  }

  getReportModulatorsTree(
    descriptionId: Description['descriptionId'],
    reportDetails: Report,
    addScreenshotsData: boolean
  ): EventsTreeBlockItem[] {
    const modulatorsFolder = this.eventCodesTree.find(
      ({ item }) => item.eventCodeId === MODULATORS_EVENT_CODE_ID
    );
    if (addScreenshotsData) {
      const { reportEventsTree } = this.attachScreenshotsToReportTree(
        this.getReportTree(
          descriptionId,
          reportDetails,
          modulatorsFolder?.children
        ),
        descriptionId
      );
      return reportEventsTree;
    }
    return this.getReportTree(
      descriptionId,
      reportDetails,
      modulatorsFolder?.children
    );
  }

  getPdfScreenshots(): ImagesDataType {
    return {
      title: 'Screenshots',
      values: this.screenshotsInReport.map((screenshot, index) => ({
        label: `Screenshot ${index + 1}`,
        image: screenshot.screenshot
      }))
    };
  }

  @action
  async loadShoppingCarts(
    descriptionId: Description['descriptionId'],
    eventCodings?: Array<EventCoding['eventCodingId']>
  ) {
    this.shoppingCartLoading = true;
    this.shoppingCartError = undefined;

    try {
      const { data } = await StudyApi.loadShoppingCart(
        descriptionId,
        eventCodings
      );

      if (
        !this.shoppingCart.has(descriptionId) ||
        !eventCodings ||
        !eventCodings.length
      ) {
        this.shoppingCart.set(
          descriptionId,
          ShoppingCart.deserializeAsMap(data)
        );
      } else {
        runInAction(() => {
          const shoppingCartsMap = ShoppingCart.deserializeAsMap(data);

          eventCodings.forEach((eventCodingId) => {
            const shoppingCartsByDescription = this.shoppingCart.get(
              descriptionId
            )!;

            const shoppingCart = shoppingCartsMap.get(eventCodingId);

            if (shoppingCart) {
              shoppingCartsByDescription.set(eventCodingId, shoppingCart);
            } else {
              shoppingCartsByDescription.delete(eventCodingId);
            }
          });
        });
      }

      stores[StoreType.Messages].removeError('shopping-cart');
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('shopping-cart', error);
      this.shoppingCartError = error;
    } finally {
      this.shoppingCartLoading = false;
    }
  }
}
