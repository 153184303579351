import { Event } from 'entities/Event.entity';
import { EventCode } from 'entities/EventCode.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { action, computed, makeObservable, observable } from 'mobx';

type Finding = {
  item: EventCoding;
  parentFolderId: EventCode['parentFolderId'];
  isSimultaneous: boolean;
  isToBeDefined: boolean;
  isLinked: boolean;
};

interface FindingState {
  selected: boolean;
  checkboxHidden: boolean;
  checkboxDisabled: boolean;
  actionsAvailable: boolean;
  moveToAvailable: boolean;
}

interface ExampleState {
  selected: boolean;
  checkboxHidden: boolean;
  checkboxDisabled: boolean;
  actionsAvailable: boolean;
}

interface CategoryState {
  actionsAvailable: boolean;
  moveToAvailable: boolean;
}

interface ActionBarState {
  addUnlinkedFindingAvailable: boolean;
  unclassifyAvailable: boolean;
  addFromSelectionAvailable: boolean;
  undoSimultaneousAvailable: boolean;
  simultaneousAvailable: boolean;
}

export class SelectionState {
  @observable
  private examples: Map<Event['eventId'], Event> = new Map();

  @observable
  private findings: Map<EventCoding['eventCodingId'], Finding> = new Map();

  @observable
  draggedExample?: Event;

  @observable
  draggedFinding?: Finding;

  constructor() {
    makeObservable(this);
  }

  @action
  toggleExample(example: Event) {
    this.examples.get(example.eventId)
      ? this.examples.delete(example.eventId)
      : this.examples.set(example.eventId, example);
  }

  @action
  setDraggedExample(example: Event) {
    this.draggedExample = example;
  }

  @action
  setDraggedFinding(
    finding: EventCoding,
    parentFolderId: EventCode['parentFolderId'],
    isSimultaneous: boolean = false,
    isToBeDefined: boolean = false,
    isLinked: boolean = false
  ) {
    this.draggedFinding = {
      item: finding,
      parentFolderId,
      isSimultaneous,
      isToBeDefined,
      isLinked
    };
  }

  @action
  selectMultipleExamples(examples: Array<Event>) {
    for (const example of examples) {
      this.examples.set(example.eventId, example);
    }
  }

  @action
  toggleFinding(
    finding: EventCoding,
    parentFolderId: EventCode['parentFolderId'],
    isSimultaneous: boolean = false,
    isToBeDefined: boolean = false,
    isLinked: boolean = false
  ) {
    if (this.findings.get(finding.eventCodingId)) {
      this.findings.delete(finding.eventCodingId);
    } else {
      this.findings.set(finding.eventCodingId, {
        item: finding,
        parentFolderId,
        isSimultaneous,
        isToBeDefined,
        isLinked
      });
    }
  }

  @action
  discardSelections() {
    this.discardExamplesSelections();
    this.discardFindingsSelections();
  }

  @action
  discardExamplesSelections() {
    this.examples.clear();
    this.draggedExample = undefined;
  }

  @action
  discardFindingsSelections() {
    this.findings.clear();
    this.draggedFinding = undefined;
  }

  getExampleById(id: Event['eventId']): Event | undefined {
    return this.examples.get(id);
  }

  @computed
  get getSelectedExamplesIds(): number[] {
    return [...this.examples.keys()];
  }

  getFindingById(id: EventCoding['eventCodingId']): Finding | undefined {
    return this.findings.get(id);
  }

  @computed
  get selectedFindings() {
    return [...this.findings.values()];
  }

  @computed
  get selectedExamples() {
    return [...this.examples.values()];
  }

  getExampleState(example: Event): ExampleState {
    return {
      selected: !!this.getExampleById(example.eventId),
      checkboxHidden:
        !!example.eventCodingId && !!this.getFindingById(example.eventCodingId),
      checkboxDisabled: !!this.findings.size,
      actionsAvailable: !(this.examples.size || this.findings.size)
    };
  }

  @computed
  get selectedFindingsIds() {
    return Array.from(this.findings.keys());
  }

  @computed
  get selectedClassifiedExamplesIds() {
    return Array.from(this.examples.values())
      .filter((example) => example.eventCodingId !== null)
      .map((example) => example.eventId);
  }

  @computed
  get selectionSize() {
    return this.examples.size + this.findings.size;
  }

  private getChildrenFindingSelected = (finding: EventCoding) =>
    !!this.findings.size &&
    [...this.findings.values()].some(
      (selectedFinding) =>
        selectedFinding.item.parentId === finding.eventCodingId
    );

  private getOnlyChildrenFindingsSelected = (finding: EventCoding) =>
    this.getChildrenFindingSelected(finding) &&
    [...this.findings.values()].every(
      (selectedFinding) =>
        selectedFinding.item.parentId === finding.eventCodingId
    );

  private getOnlyChildrenExamplesSelected = (finding: EventCoding) =>
    !!this.examples.size &&
    [...this.examples.values()].every(
      (selectedExample) =>
        selectedExample.eventCodingId === finding.eventCodingId
    );

  private getParentFindingSelected = (
    parentId?: EventCoding['eventCodingId']
  ) => !!(parentId && this.getFindingById(parentId));

  getFindingState(finding: EventCoding): FindingState {
    const isSelected = !!this.getFindingById(finding.eventCodingId);

    return {
      selected: isSelected,
      checkboxHidden:
        this.getParentFindingSelected(finding.parentId) ||
        this.getChildrenFindingSelected(finding),
      checkboxDisabled: !!this.examples.size,
      actionsAvailable: !(this.examples.size || this.findings.size),
      moveToAvailable:
        !isSelected &&
        !!this.selectionSize &&
        !this.getOnlyChildrenExamplesSelected(finding) &&
        !this.getOnlyChildrenFindingsSelected(finding)
    };
  }

  getCategoryState(
    eventCode: EventCode,
    parentFindingId?: EventCoding['eventCodingId']
  ): CategoryState {
    return {
      actionsAvailable:
        eventCode.isSelectable && !(this.examples.size || this.findings.size),
      moveToAvailable:
        eventCode.isSelectable &&
        !this.getParentFindingSelected(parentFindingId) &&
        (!!this.examples.size ||
          (!!this.selectionSize &&
            ![...this.findings.values()].every((selectedFinding) => {
              if (parentFindingId) {
                return (
                  selectedFinding.item.parentId === parentFindingId &&
                  this.isInSameFolder(selectedFinding, eventCode.eventCodeId)
                );
              } else {
                return this.isInSameFolder(
                  selectedFinding,
                  eventCode.eventCodeId
                );
              }
            })))
    };
  }

  @computed
  get hasOnlyUnclassifiedExamples(): boolean {
    return [...this.examples.values()].every(
      (example) => example.eventCodingId === null
    );
  }

  private isInSameFolder = (
    selectedFinding: Finding,
    eventCodeId: EventCode['eventCodeId']
  ) =>
    selectedFinding.item.eventCodeId === eventCodeId ||
    selectedFinding.parentFolderId === eventCodeId;

  private isInFolder = (
    finding: Finding,
    parentId: EventCoding['parentId'],
    parentFolderId: Finding['parentFolderId']
  ) =>
    finding.item.parentId === parentId &&
    finding.parentFolderId === parentFolderId;

  @computed
  private get hasNotSimultaneousFinding() {
    return [...this.findings.values()].some(
      (finding) => !finding.isSimultaneous
    );
  }

  @computed
  private get isAllFromSameNestedFolder() {
    const findings = [...this.findings.values()];
    const firstFinding = findings[0];

    return findings.every((finding) => {
      return (
        finding.item.parentId !== null &&
        this.isInFolder(
          finding,
          firstFinding.item.parentId,
          firstFinding.parentFolderId
        )
      );
    });
  }

  @computed
  private get hasOnlyOneGroup() {
    return [...this.findings.values()]
      .filter((finding) => finding.isSimultaneous)
      .every(
        (finding, _, simultaneousFindings) =>
          simultaneousFindings[0].item.sortOrder === finding.item.sortOrder
      );
  }

  @computed
  get actionBarState(): ActionBarState {
    const findings = [...this.findings.values()];
    const firstFinding = findings[0];

    return {
      addUnlinkedFindingAvailable: this.selectionSize === 0,
      unclassifyAvailable:
        this.findings.size > 1 ||
        (this.examples.size > 1 && !this.hasOnlyUnclassifiedExamples),
      addFromSelectionAvailable: this.examples.size > 1,
      undoSimultaneousAvailable:
        this.findings.size > 1 &&
        findings.every((finding) => {
          return (
            finding.isSimultaneous &&
            this.isInFolder(
              finding,
              firstFinding.item.parentId,
              firstFinding.parentFolderId
            )
          );
        }),
      simultaneousAvailable:
        this.findings.size > 1 &&
        this.hasNotSimultaneousFinding &&
        this.isAllFromSameNestedFolder &&
        this.hasOnlyOneGroup
    };
  }
}
