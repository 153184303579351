import { AxiosResponse } from 'axios';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { FilterCriteria } from 'entities/FilterCriteria.entity';
import { GenericListPagination } from 'entities/GenericListPagination.entity';
import { GenericMetaInformation } from 'entities/GenericMetaInformation.entity';
import { PatientCreateDTO } from 'entities/PatientCreateDTO.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { PatientUpdateDTO } from 'entities/PatientUpdateDTO.entity';
import { PropertyCodingCreateDTO } from 'entities/PropertyCodingCreateDTO.entity';
import { PropertyCodingUpdateDTO } from 'entities/PropertyCodingUpdateDTO.entity';
import { SortCriteria } from 'entities/SortCriteria.entity';
import queryString from 'query-string';
import { ApiBase } from 'services/API/ApiBase';
import { CommonPropertyType } from 'stores/property-type-codes';

import { GenericListApi } from '../GenericListApi';

class PatientApi extends ApiBase implements GenericListApi {
  loadGenericListMetadata(): Promise<AxiosResponse<GenericMetaInformation>> {
    return this.client.get<GenericMetaInformation>('/patientList/metaInfo');
  }

  loadGenericList(
    pageNumber: string = '1',
    criteria?: {
      sortCriteria?: SortCriteria[];
      filterCriteria?: FilterCriteria;
    }
  ): Promise<AxiosResponse<GenericListPagination>> {
    const query = queryString.stringify({
      PageNumber: pageNumber
    });

    return this.client.post<GenericListPagination>(`/patientList?${query}`, {
      sortCriteria: criteria?.sortCriteria,
      filterCriteria: criteria?.filterCriteria
    });
  }

  createPatient(patient: PatientCreateDTO) {
    return this.client.post<PatientDetails>('/patients', patient);
  }

  loadPatientDetails(patientId: PatientDetails['patientId']) {
    return this.client.get<PatientDetails>(`/patients/${patientId}`);
  }

  updatePatientDetails(patientId: number, dto: PatientUpdateDTO) {
    return this.client.put<PatientDetails>(`/patients/${patientId}`, dto);
  }

  loadCategoricalPropertyCodings(patientId: number) {
    return this.client.get<CategoricalPropertyCoding[]>(
      `/patients/${patientId}/PropertyCodings`
    );
  }

  createCategoricalPropertyCoding(
    patientId: number,
    dto: PropertyCodingCreateDTO,
    descriptionId?: number
  ) {
    const query = queryString.stringify({
      descriptionId
    });

    return this.client.post<CategoricalPropertyCoding>(
      `/patients/${patientId}/PropertyCodings?${query}`,
      dto
    );
  }

  updateCategoricalPropertyCoding({
    patientId,
    dto,
    propertyTypeId,
    currentPropertyCodeId,
    descriptionId
  }: {
    patientId: number;
    dto: PropertyCodingUpdateDTO;
    propertyTypeId: CommonPropertyType;
    currentPropertyCodeId?: string;
    descriptionId?: number;
  }) {
    const query = queryString.stringify({
      descriptionId,
      typeId: propertyTypeId,
      codeId: currentPropertyCodeId
    });

    return this.client.put<CategoricalPropertyCoding>(
      `/patients/${patientId}/PropertyCodings?${query}`,
      dto
    );
  }

  deleteCategoricalPropertyCoding(
    patientId: number,
    propertyTypeId: CommonPropertyType,
    propertyCodeId?: string
  ) {
    const query = queryString.stringify({
      typeId: propertyTypeId,
      codeId: propertyCodeId
    });

    return this.client.delete<CategoricalPropertyCoding>(
      `/patients/${patientId}/PropertyCodings?${query}`
    );
  }
}

const instance = new PatientApi('/patient/api/v1');

export { instance as PatientApi };
