import { Event } from 'entities/Event.entity';
import { EventCoding } from 'entities/EventCoding.entity';

export const getEventCodingsIdentifier = (
  eventCodings: EventCoding[]
): string => {
  return eventCodings
    .map(({ eventCodingId }) => eventCodingId)
    .sort()
    .join('-');
};

export const getEventsIdentifier = (events: Event[]): string => {
  return events
    .map(({ eventId }) => eventId)
    .sort()
    .join('-');
};
