import React, { FC } from 'react';
import { Icon } from '@holberg/ui-kit';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { PropertyPanelIconsId } from 'enums/PropertyPanelIconsId.enum';

import styles from './NoteLabel.module.scss';

interface Props {
  text: string;
  iconId: EventPropertyType['iconId'];
}

export const NoteLabel: FC<Props> = ({ text, iconId }) => (
  <span className={styles.label}>
    {PropertyPanelIconsId[iconId] && (
      <Icon iconType={PropertyPanelIconsId[iconId]} className={styles.icon} />
    )}
    <span className={styles.text}>{text}</span>
  </span>
);
