import { makeObservable, observable } from 'mobx';
import { deserialize, serializable } from 'serializr';

import { GenericColumnMetaInformation } from './GenericColumnMetaInformation.entity';
import { GenericListItem } from './GenericListItem.entity';

export class TableConfig {
  @observable
  @serializable
  propertyName: keyof GenericListItem = 'patientId'; // TODO: add different types

  @observable
  @serializable
  order: number = 0;

  @observable
  @serializable
  isVisible: boolean = false;

  @observable
  @serializable
  isFrozen: boolean = false;

  @observable
  @serializable
  width: number = 0;

  constructor() {
    makeObservable(this);
  }

  static deserialize(json: Object | string) {
    return deserialize(TableConfig, json);
  }

  static deserializeAsMap(
    list: (TableConfig | GenericColumnMetaInformation)[]
  ): Record<keyof GenericListItem, TableConfig> {
    return list.reduce((acc, patient) => {
      return {
        ...acc,
        [patient.propertyName]: TableConfig.deserialize(patient)
      };
    }, {} as Record<keyof GenericListItem, TableConfig>);
  }
}
