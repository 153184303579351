import React from 'react';
import { Cell } from 'react-table';
import { StatusBadge } from '@holberg/ui-kit';

import {
  StudyBadgeStatus,
  studyBadgeTheme,
  studyBadgeTitles
} from './CustomTableCell.enum';

interface Props {
  cell: Cell;
  propertyName: string | number;
}

export const CustomTableCell: React.FC<Props> = ({
  cell: { value },
  propertyName
}) => {
  return propertyName === 'studyStatus' ? (
    <StatusBadge
      theme={studyBadgeTheme[StudyBadgeStatus[value]]}
      title={studyBadgeTitles[StudyBadgeStatus[value]]}
    />
  ) : (
    <>{value}</>
  );
};

export const CustomCellAccessor = (
  propertyName: string | number,
  cell?: Cell
) => {
  if (cell) {
    return <CustomTableCell cell={cell} propertyName={propertyName} />;
  }

  return null;
};
