import React from 'react';
import { useParams } from 'react-router-dom';
import { NeonatalInfoForm } from 'components/NeonatalInfoForm';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHandleAutoSave } from 'hooks/useHandleAutoSave';

interface Props {
  readOnly: boolean;
}

export const StudyNeonatalContainer: React.FC<Props> = ({ readOnly }) => {
  const { id: descriptionId } = useParams<{ id: string }>();

  const descriptionDetailsStore = useStore(StoreType.Descriptions);
  const patientDetailsStore = useStore(StoreType.PatientDetails);

  const descriptionDetails = descriptionDetailsStore.descriptionById(
    parseInt(descriptionId)
  )!;
  const patientId = String(descriptionDetails?.patientId);

  const { eventHandler } = useHandleAutoSave<
    {
      patientDetails: PatientDetails;
    },
    string,
    { propertyName: string }
  >({
    saveHandler: (value, config) => {
      patientDetailsStore.updatePatientDetails(
        value,
        patientId,
        config.propertyName
      );
    }
  });

  return (
    <NeonatalInfoForm
      onPropertyBlur={(accessor, propertyName) => {
        eventHandler(accessor, {
          propertyName
        })();
      }}
      readOnly={readOnly}
      descriptionId={parseInt(descriptionId)}
    />
  );
};
