import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconType,
  rebuildTooltips,
  TextBadge,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { groupExamples } from 'components/Category/helpers';
import {
  ConfirmationTitle,
  useConfirmationContext
} from 'components/FindingsConfirmationModal';
import { FindingsWarning } from 'components/FindingsWarning';
import { useNameSelectionContext } from 'components/NameSelectionModal/NameSelectionContext';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { Description } from 'entities/Description.entity';
import { EventCode } from 'entities/EventCode.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { Study } from 'entities/Study.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { EventTreeSettingsType } from 'enums/EventTreeSettingsType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHasFocus } from 'hooks/useHasFocus';
import { observer } from 'mobx-react-lite';
import { createShortcutCombination } from 'services/keyboardShortcuts/helpers';
import {
  getEventCodingsIdentifier,
  getEventsIdentifier
} from 'stores/findings/helpers';

import findingsStyles from '../Findings/Findings.module.scss';
import styles from './CategoryHeader.module.scss';

export interface CommonHeaderProps {
  arrow: ReactNode;
  label?: string;
  hasChildren?: boolean;
  isActive?: boolean;
}

interface Props extends CommonHeaderProps {
  onToggleFolderByHotKey?: () => void;
  eventCode: EventCode;
  readOnly: boolean;
  parentCodingId?: EventCoding['eventCodingId'];
  activeDescriptionId: Description['descriptionId'];
  studyId: Study['studyId'];
  childrenHasWarning: boolean | undefined;
}

export const CategoryHeader: FC<Props> = observer(
  ({
    arrow,
    hasChildren,
    readOnly,
    eventCode,
    parentCodingId,
    activeDescriptionId,
    studyId,
    onToggleFolderByHotKey,
    isActive,
    childrenHasWarning
  }) => {
    const { t } = useCustomTranslation();
    const { hasFocus, onFocus, onBlur } = useHasFocus();
    const { onFindingClick } = usePropertiesContext();

    const findingsStore = useStore(StoreType.Findings);

    const confirmationContext = useConfirmationContext();
    const nameSelectionContext = useNameSelectionContext();

    const descriptionStatus = findingsStore.descriptionStatuses.get(
      activeDescriptionId
    );

    const codeStatus = descriptionStatus?.getEventCodeStatus({
      eventCodeId: eventCode.eventCodeId!,
      parentEventCodingId: parentCodingId
    });

    const label = eventCode.translatedName.eitherValue;

    const categoryState = findingsStore.selectionState.getCategoryState(
      eventCode,
      parentCodingId
    );
    const hasClassifiedFinding = findingsStore.selectionState.selectedFindings.some(
      (finding) => !finding.isToBeDefined
    );

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: [DraggableTypes.example, DraggableTypes.finding],
      canDrop: () => categoryState.actionsAvailable,
      drop: (_item, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        if (monitor.getItemType() === DraggableTypes.example) {
          onExamplesMoveTo();
        } else if (monitor.getItemType() === DraggableTypes.finding) {
          onFindingsMoveTo();
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }));

    useEffect(() => {
      if (!isActive && isOver) {
        findingsStore.eventTreeState.updateEventTreeSettingsConfig({
          studyId: studyId,
          entityId: eventCode.eventCodeId,
          settingKey: EventTreeSettingsType.CategoriesState,
          expanded: true
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOver]);

    useEffect(() => {
      rebuildTooltips();
    }, [
      categoryState.actionsAvailable,
      categoryState.moveToAvailable,
      hasClassifiedFinding
    ]);

    useHotkeys(
      createShortcutCombination({ key: 'z' }),
      (event) => {
        event.preventDefault();

        hasFocus && onToggleFolderByHotKey?.();
      },
      {},
      [hasFocus, onToggleFolderByHotKey]
    );

    const onExamplesMoveTo = async () => {
      if (activeDescriptionId) {
        const groupedExamples = groupExamples(
          findingsStore.selectionState.draggedExample
            ? [findingsStore.selectionState.draggedExample]
            : findingsStore.selectionState.selectedExamples
        );

        await Promise.all(
          [...groupedExamples.values()].map((group) =>
            findingsStore.createEventCoding({
              data: {
                eventIds: group.map((example) => example.eventId),
                eventCodeId: eventCode.eventCodeId,
                parentEventCodingId: parentCodingId
              },
              studyId,
              descriptionId: activeDescriptionId,
              identifier: `examples-moveto-${getEventsIdentifier(group)}`
            })
          )
        );
        findingsStore.selectionState.discardSelections();
      }
    };

    const updateFindings = () => {
      if (activeDescriptionId) {
        findingsStore.updateEventCodings({
          data: findingsStore.selectionState.draggedFinding //when only single finding is moved by dragging
            ? [
                {
                  eventCodingId:
                    findingsStore.selectionState.draggedFinding.item
                      .eventCodingId,
                  parentEventCodingId: parentCodingId
                }
              ]
            : findingsStore.selectionState.selectedFindingsIds.map(
                (findingId) => ({
                  eventCodingId: findingId,
                  parentEventCodingId: parentCodingId
                })
              ),
          descriptionId: activeDescriptionId,
          newEventCodeId: eventCode.eventCodeId,
          identifier: `findings-moveto-${getEventCodingsIdentifier(
            findingsStore.selectionState.draggedFinding
              ? [findingsStore.selectionState.draggedFinding.item]
              : findingsStore.selectionState.selectedFindings.map(
                  ({ item }) => item
                )
          )}`
        });
        findingsStore.selectionState.discardSelections();
      }
    };

    const onFindingsMoveTo = () => {
      const hasClassifiedSelection = findingsStore.selectionState.draggedFinding
        ? !findingsStore.selectionState.draggedFinding?.isToBeDefined
        : hasClassifiedFinding;
      if (hasClassifiedSelection) {
        confirmationContext?.onOpen({
          title: t(ConfirmationTitle.Move),
          submitButtonTitle: t('Yes, move'),
          onSubmit: updateFindings
        });
      } else {
        updateFindings();
      }
    };

    const onMoveTo = () => {
      if (findingsStore.selectionState.selectedExamples.length) {
        onExamplesMoveTo();
      } else {
        onFindingsMoveTo();
      }
    };

    const createEventCoding = useCallback(
      async (eventCodeId: number) => {
        const eventCodings = await findingsStore.createEventCoding({
          data: {
            eventCodeId,
            eventIds: [],
            parentEventCodingId: parentCodingId
          },
          studyId,
          descriptionId: activeDescriptionId,
          identifier: `category-classify-${eventCode.eventCodeId}`
        });
        const activeEventCoding = eventCodings.find(
          (eventCoding) => eventCoding.eventCodeId === eventCodeId
        );
        if (!findingsStore.isToBeDefined(eventCodeId)) {
          onFindingClick(activeEventCoding);
        }
      },
      [
        activeDescriptionId,
        eventCode.eventCodeId,
        findingsStore,
        onFindingClick,
        parentCodingId,
        studyId
      ]
    );

    const onAddFinding = useCallback(() => {
      if (activeDescriptionId) {
        nameSelectionContext?.onOpen({
          title: t('Classify'),
          activeEventCodeId: eventCode.eventCodeId,
          onSelect: (eventCodeId) => {
            if (
              findingsStore.hasActiveOnlyOneEventCoding(
                activeDescriptionId,
                eventCodeId
              )
            ) {
              confirmationContext?.onOpen({
                title: t(ConfirmationTitle.Add_Second_OnlyOneEventCoding),
                submitButtonTitle: t('Yes, sure'),
                onSubmit: () => createEventCoding(eventCodeId)
              });
            } else {
              createEventCoding(eventCodeId);
            }

            nameSelectionContext.onClose();
          }
        });
      }
    }, [
      activeDescriptionId,
      confirmationContext,
      createEventCoding,
      eventCode.eventCodeId,
      findingsStore,
      nameSelectionContext,
      t
    ]);

    return (
      <div
        ref={drop}
        className={cn(
          styles['category-header'],
          canDrop && isOver ? findingsStyles['on-dropover'] : ''
        )}
        tabIndex={0}
        data-testid='category-header'
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <div className={styles.container}>
          <span data-testid='arrow-button' className={styles.arrow}>
            {arrow}
          </span>
          <span className={cn(styles.label, !hasChildren && styles.disabled)}>
            {label}
          </span>
          <FindingsWarning
            status={codeStatus}
            parentFolderWarning={childrenHasWarning}
            isActive={isActive}
          />
        </div>
        <div className={styles.container}>
          {categoryState.actionsAvailable && !readOnly && (
            <Tooltip
              data={[
                {
                  mainTooltip: (
                    <span>
                      {t('Add unlinked finding')}
                      <TextBadge title='A' />
                    </span>
                  )
                }
              ]}
            >
              <Button
                data-testid='add-finding'
                size={ButtonSize.Small}
                theme={ButtonTheme.SecondaryTransparent}
                className={styles['add-button']}
                icon={IconType.Plus}
                onClick={onAddFinding}
                tabIndex={-1}
              />
            </Tooltip>
          )}
          {categoryState.moveToAvailable &&
            !readOnly &&
            (hasClassifiedFinding ? (
              <Tooltip
                contentClassName={styles['move-tooltip']}
                offset={{ bottom: 5, left: 75 }}
                data={[
                  {
                    mainTooltip: (
                      <span className={styles['tooltip-content']}>
                        {t('Move to')}
                        <TextBadge title='M' />
                      </span>
                    )
                  },
                  {
                    mainTooltip: t('Moving finding will remove its properties')
                  }
                ]}
              >
                <Button
                  size={ButtonSize.Small}
                  icon={IconType.Subdirectory}
                  onClick={onMoveTo}
                  title={t('Move to')}
                  theme={ButtonTheme.Highlighted}
                  className={styles['move-button']}
                  tabIndex={-1}
                  data-testid='move-to-finding'
                />
              </Tooltip>
            ) : (
              <Button
                size={ButtonSize.Small}
                icon={IconType.Subdirectory}
                onClick={onMoveTo}
                title={t('Move to')}
                theme={ButtonTheme.Highlighted}
                className={styles['move-button']}
                data-testid='move-to-finding'
                tabIndex={-1}
              />
            ))}
        </div>
      </div>
    );
  }
);
