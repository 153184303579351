import { deserialize, object, serializable } from 'serializr';

import { GenericListItem } from './GenericListItem.entity';

class PaginationMetadata {
  @serializable
  pageNumber: number = 0;

  @serializable
  nextPage?: string;

  @serializable
  previousPage?: string;

  @serializable
  totalRecords: number = 0;

  @serializable
  pageSize: number = 0;
}

export class GenericListPagination {
  data: GenericListItem[] = [];

  @serializable(object(PaginationMetadata))
  pagedMetaData = new PaginationMetadata();

  get pagedMetadata() {
    return this.pagedMetaData;
  }

  static deserialize(json: Object | string) {
    return deserialize(GenericListPagination, json);
  }
}
