import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { useParams } from 'react-router-dom';
import {
  DatePicker,
  FormInput,
  InputContainer,
  useCustomTranslation
} from '@holberg/ui-kit';
import { Description } from 'entities/Description.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { PatientPropertyTypes } from 'enums/PatientPropertyType.enum';
import { PropertyType } from 'enums/PropertyType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHandleAutoSave } from 'hooks/useHandleAutoSave';
import { observer } from 'mobx-react-lite';
import { CommonPropertyType } from 'stores/property-type-codes';
import { getPadTimeFormat } from 'utils/dateHelpers';

import { FormRowSection } from '../FormRowSection';
import { FormTreeSelectRow } from '../FormTreeSelectRow';

import styles from './StudyConditionsForm.module.scss';

interface Props {
  readOnly: boolean;
}

export const StudyConditionsForm: React.FC<Props> = observer(({ readOnly }) => {
  const { id: descriptionId } = useParams<{ id: string }>();

  const patientDetailsStore = useStore(StoreType.PatientDetails);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const patientPropertyTypesStore = useStore(StoreType.PatientPropertyTypes);
  const descriptionPropertyTypesStore = useStore(
    StoreType.DescriptionPropertyTypes
  );

  const descriptionDetails = descriptionsStore.descriptionById(
    Number(descriptionId)
  )!;
  const patientId = String(descriptionDetails?.patientId);
  const descriptionPropertyTypes = descriptionPropertyTypesStore.getPropertyTypes(
    descriptionDetails.ageConstraints
  );
  const patientPropertyTypes = patientPropertyTypesStore.getPropertyTypes(
    descriptionDetails.ageConstraints
  );

  const { t } = useCustomTranslation();
  const { register, errors } = useFormContext<{
    descriptionDetails: Description;
  }>();

  const onPatientSelectSaveAction = useCallback<
    (
      propertyType: CommonPropertyType,
      newPropertyCodeId?: string,
      oldPropertyCodeId?: string
    ) => void
  >(
    (propertyType, newPropertyCodeId, oldPropertyCodeId) => {
      patientDetailsStore.updatePropertyCodings({
        patientId,
        newPropertyCodeId,
        propertyTypeId: propertyType,
        oldPropertyCodeId,
        descriptionId: descriptionDetails.descriptionId
      });
    },
    [patientDetailsStore, patientId, descriptionDetails.descriptionId]
  );

  const onPatientNoteSaveAction = useCallback<
    (
      propertyType: CommonPropertyType,
      freeText: string,
      currentPropertyCodeId: string
    ) => void
  >(
    (propertyType, freeText, currentPropertyCodeId) => {
      patientDetailsStore.updatePropertyCodingsNote({
        patientId,
        currentPropertyCodeId,
        propertyTypeId: propertyType,
        freeText,
        descriptionId: descriptionDetails.descriptionId
      });
    },
    [patientDetailsStore, patientId, descriptionDetails.descriptionId]
  );

  const { eventHandler } = useHandleAutoSave<
    {
      descriptionDetails: Description;
    },
    string,
    { propertyName: string }
  >({
    saveHandler: (value, config) => {
      descriptionsStore.updateDescriptionDetails(
        value,
        Number(descriptionId),
        config.propertyName
      );
    }
  });

  const onDescriptionSelectSaveAction = useCallback<
    (
      propertyType: CommonPropertyType,
      newPropertyCodeId?: string,
      oldPropertyCodeId?: string
    ) => void
  >(
    (propertyType, newPropertyCodeId, oldPropertyCodeId) => {
      descriptionsStore.updatePropertyCodings({
        newPropertyCodeId,
        propertyTypeId: propertyType,
        oldPropertyCodeId,
        descriptionId: descriptionDetails.descriptionId
      });
    },
    [descriptionDetails.descriptionId, descriptionsStore]
  );

  const onDescriptionNoteSaveAction = useCallback<
    (
      propertyType: CommonPropertyType,
      freeText: string,
      currentPropertyCodeId: string
    ) => void
  >(
    (propertyType, freeText, currentPropertyCodeId) => {
      descriptionsStore.updatePropertyCodingsNote({
        currentPropertyCodeId,
        propertyTypeId: propertyType,
        freeText,
        descriptionId: descriptionDetails.descriptionId
      });
    },
    [descriptionDetails.descriptionId, descriptionsStore]
  );

  return (
    <>
      <InputContainer label={t('EEG type')}>
        <FormRowSection
          readOnly={readOnly}
          hasNoteField={false}
          formAccessorName='studyCodings'
          onSelectSaveAction={onDescriptionSelectSaveAction}
          categoricalPropertyType={PropertyType.Description}
          propertyType={DescriptionPropertyTypes.EEGType}
          addButtonTitle={t('Add EEG type')}
          isMultiSelect={
            descriptionPropertyTypes.get(DescriptionPropertyTypes.EEGType)
              ?.isMultiSelect
          }
        />
      </InputContainer>
      <InputContainer label={t('Alertness')}>
        <FormRowSection
          readOnly={readOnly}
          formAccessorName='studyCodings'
          onSelectSaveAction={onDescriptionSelectSaveAction}
          onNoteSaveAction={onDescriptionNoteSaveAction}
          categoricalPropertyType={PropertyType.Description}
          propertyType={DescriptionPropertyTypes.Alertness}
          addButtonTitle={t('Add alertness')}
          isMultiSelect={
            descriptionPropertyTypes.get(DescriptionPropertyTypes.Alertness)
              ?.isMultiSelect
          }
        />
      </InputContainer>
      <InputContainer label={t('Medication during the study')}>
        <FormRowSection
          readOnly={readOnly}
          component={FormTreeSelectRow}
          formAccessorName='patientCodings'
          onSelectSaveAction={onPatientSelectSaveAction}
          onNoteSaveAction={onPatientNoteSaveAction}
          propertyType={PatientPropertyTypes.MedicationAdministered}
          addButtonTitle={t('Add medication')}
          isMultiSelect={
            patientPropertyTypes.get(
              PatientPropertyTypes.MedicationAdministered
            )?.isMultiSelect
          }
        />
      </InputContainer>
      <InputContainer label={t('Medication withdrawal')}>
        <FormRowSection
          readOnly={readOnly}
          component={FormTreeSelectRow}
          formAccessorName='patientCodings'
          onSelectSaveAction={onPatientSelectSaveAction}
          onNoteSaveAction={onPatientNoteSaveAction}
          addButtonTitle={t('Add medication')}
          propertyType={PatientPropertyTypes.MedicationWithdrawal}
          isMultiSelect={
            patientPropertyTypes.get(PatientPropertyTypes.MedicationWithdrawal)
              ?.isMultiSelect
          }
        />
      </InputContainer>

      <InputContainer label={t('Latest meal')}>
        <div className={styles['input-container']}>
          <DatePicker
            withTime
            error={
              errors.descriptionDetails?.formattedLatestMeal
                ? t('Invalid date')
                : undefined
            }
            readOnly={readOnly}
            defaultValue={
              descriptionDetails?.formattedLatestMeal
                ? getPadTimeFormat(
                    new Date(descriptionDetails.formattedLatestMeal),
                    true
                  )
                : ''
            }
            getValue={(value) => {
              if (
                descriptionDetails?.formattedLatestMeal
                  ? descriptionDetails.formattedLatestMeal
                  : '' !== value
              ) {
                const date = new Date(value);

                descriptionsStore.updateDescriptionDetails(
                  getPadTimeFormat(date, true),
                  Number(descriptionId),
                  'latestMeal'
                );
              }
            }}
          />
        </div>
      </InputContainer>
      <InputContainer label={t('Priority')}>
        <FormRowSection
          readOnly={readOnly}
          hasNoteField={false}
          formAccessorName='studyCodings'
          onSelectSaveAction={onDescriptionSelectSaveAction}
          categoricalPropertyType={PropertyType.Description}
          propertyType={DescriptionPropertyTypes.Priority}
          addButtonTitle={t('Add priority')}
          isMultiSelect={
            descriptionPropertyTypes.get(DescriptionPropertyTypes.Priority)
              ?.isMultiSelect
          }
        />
      </InputContainer>
      <InputContainer label={t('Working off-hours')}>
        <FormRowSection
          readOnly={readOnly}
          hasNoteField={false}
          formAccessorName='studyCodings'
          onSelectSaveAction={onDescriptionSelectSaveAction}
          categoricalPropertyType={PropertyType.Description}
          propertyType={DescriptionPropertyTypes.WorkingOrOffHours}
          addButtonTitle={t('Add working or off hours')}
          isMultiSelect={
            descriptionPropertyTypes.get(
              DescriptionPropertyTypes.WorkingOrOffHours
            )?.isMultiSelect
          }
        />
      </InputContainer>
      <InputContainer label={t('Level of care')}>
        <FormRowSection
          readOnly={readOnly}
          hasNoteField={false}
          formAccessorName='studyCodings'
          onSelectSaveAction={onDescriptionSelectSaveAction}
          categoricalPropertyType={PropertyType.Description}
          propertyType={DescriptionPropertyTypes.LevelOfCare}
          addButtonTitle={t('Add level of care')}
          isMultiSelect={
            descriptionPropertyTypes.get(DescriptionPropertyTypes.LevelOfCare)
              ?.isMultiSelect
          }
        />
      </InputContainer>
      <InputContainer label={t('Study notes')}>
        <div className={styles['input-container']}>
          <FormInput
            multiline
            ref={register}
            readOnly={readOnly}
            onBlur={eventHandler('descriptionDetails.recordingNotes', {
              propertyName: 'recordingNotes'
            })}
            name='descriptionDetails.recordingNotes'
            placeholder={t('Enter value')}
          />
        </div>
      </InputContainer>
    </>
  );
});
