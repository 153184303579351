import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class EventPropertyCode {
  @serializable
  eventPropertyCodeId: number = 0;

  @serializable
  eventPropertyTypeId: number = 0;

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  translatedLongName: InformationModel = new InformationModel();

  @serializable
  isExclusiveInMultiSelect: boolean = false;

  @serializable
  sortOrder: number = 0;

  @serializable
  isNotScoredProperty: boolean = false;

  @serializable(object(InformationModel))
  definition?: InformationModel = new InformationModel();

  @serializable
  linkedEventPropertyTypeId?: number;

  @serializable
  isActive?: boolean;

  @serializable
  ageConstraintId?: number;

  @serializable
  selectAllSensors: boolean = false;

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCode, json);
  }
}
