import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class CategoricalPropertyType {
  @serializable
  propertyTypeId: number = 0;

  @serializable
  name: string = '';

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  @serializable
  ageConstraintId: number = 0;

  @serializable
  sortOrder: number = 0;

  @serializable
  isMultiselect: boolean = true;

  @serializable
  dataType: string = '';

  @serializable
  suggestActiveEntriesAsNewEntriesForNewStudy: boolean = true;

  @serializable
  propertyGroupId: number = 0;

  @serializable
  propertyType: number = 0;

  static deserialize(json: Object | string) {
    return deserialize(CategoricalPropertyType, json);
  }
}
