import React, { useEffect, useRef, useState } from 'react';
import { OptionsType } from 'react-select';
import {
  DatePicker,
  ErrorTitles,
  FormInput,
  Select,
  TreeItem,
  TreeSelect,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { format } from 'date-fns';
import { debounce } from 'debounce';
import { Condition } from 'entities/Condition.entity';
import { GenericColumnMetaInformation } from 'entities/GenericColumnMetaInformation.entity';
import { GenericListItem } from 'entities/GenericListItem.entity';
import { HDataType } from 'enums/HDataType.enum';
import { PropertiesWithoutCodes } from 'enums/PropertiesWithoutCodes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { usePropertyTypesReload } from 'hooks/usePropertyTypesReload';
import { useSelectSearchInput } from 'hooks/useSelectSearchInput';
import { observer } from 'mobx-react-lite';
import {
  TREE_DROPDOWN_MIN_WIDTH,
  TREE_DROPDOWN_RIGHT_INDENTATION
} from 'utils/constants';

import { FilterOption } from '../FiltersSettings/FiltersSettings';

import styles from './FilterRowSection.module.scss';

interface Props {
  rowIndex: number;
  condition: Condition;
  onChangeColumnValue: (rowIndex: number, value: string | number) => void;
  columnMetaInfo: Record<keyof GenericListItem, GenericColumnMetaInformation>;
}

export const FilterRowSection: React.FC<Props> = observer(
  ({ rowIndex, condition, onChangeColumnValue, columnMetaInfo = {} }) => {
    const { t } = useCustomTranslation();
    const columnMeta = columnMetaInfo[condition.columnName];
    const propertyType = columnMeta?.propertyName;
    const endPoint = columnMeta?.endPoint || '';

    const [childIdsLoading, setChildIdsLoading] = useState<
      Array<string | number>
    >([]);

    const [isUnknownSelect, setIsUnknownSelect] = useState<boolean>(false);

    const includeCodes = !Object.values(PropertiesWithoutCodes).includes(
      String(columnMeta?.propertyName)
    );

    const filterValueInput = useRef<HTMLDivElement | null>(null);
    const patientPropertyTypeCodesStore = useStore(
      StoreType.PatientPropertyTypeCodes
    );

    const stateAccessor = { propertyType };

    const { isPropertyTypeRetrying, handleReload } = usePropertyTypesReload(
      StoreType.PatientPropertyTypeCodes,
      stateAccessor
    );

    const {
      onInputChange,
      inputHasValue,
      setInputHasValue,
      highlightedItemId,
      setHighlightedItemId
    } = useSelectSearchInput(
      debounce((newValue) =>
        patientPropertyTypeCodesStore.searchPropertyTypesCodes({
          endpoint: endPoint,
          query: newValue,
          propertyType
        })
      )
    );

    useEffect(() => {
      if (columnMeta?.endPoint) {
        patientPropertyTypeCodesStore.loadPropertyTypesCodes({
          endpoint: columnMeta.endPoint,
          propertyType: columnMeta.propertyName
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnMetaInfo, condition.columnName]);

    const propertyTypeError = patientPropertyTypeCodesStore.getPropertyTypesCodesError(
      stateAccessor
    );

    useEffect(() => {
      if (propertyTypeError && columnMeta?.isCategorical) {
        setIsUnknownSelect(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyTypeError]);

    if (
      patientPropertyTypeCodesStore.isTreeStructure(
        patientPropertyTypeCodesStore.getTreeData({
          propertyType
        })
      ) &&
      endPoint
    ) {
      const treeData = inputHasValue
        ? patientPropertyTypeCodesStore.getSearchTreeData(
            stateAccessor,
            undefined,
            includeCodes
          )
        : patientPropertyTypeCodesStore.getTreeData(
            stateAccessor,
            undefined,
            includeCodes
          );

      const loadPropertyTypesCodesByParentId = async (
        item: TreeItem,
        expanded: boolean
      ) => {
        if (expanded) {
          setChildIdsLoading([...childIdsLoading, item.id]);
          await patientPropertyTypeCodesStore.loadPropertyTypesCodesByParentId({
            endpoint: endPoint,
            parentId: item.id,
            propertyType
          });
          setChildIdsLoading(childIdsLoading.filter((id) => id !== item.id));
        }
      };

      return (
        <div ref={filterValueInput} className={styles['filter-component']}>
          <TreeSelect<{ label: string; value: string }>
            isClearable
            styles={{
              menu: () => {
                const inputContainerStyles = filterValueInput.current?.getBoundingClientRect();
                return {
                  position: 'fixed',
                  background: 'white',
                  top: inputContainerStyles!.bottom + 5,
                  minWidth:
                    inputContainerStyles!.width + TREE_DROPDOWN_MIN_WIDTH,
                  maxWidth:
                    inputContainerStyles!.width + TREE_DROPDOWN_MIN_WIDTH,
                  left: 'initial',
                  right:
                    window.innerWidth -
                    inputContainerStyles!.right +
                    TREE_DROPDOWN_RIGHT_INDENTATION
                };
              }
            }}
            isFormComponent={false}
            isLoading={patientPropertyTypeCodesStore.propertyTypeCodesLoading}
            childIdsLoading={childIdsLoading}
            treeClassName={cn(styles.tree)}
            defaultMenuIsOpen={isUnknownSelect}
            openMenuOnFocus
            onChange={(option) => {
              if (option && !Array.isArray(option)) {
                onChangeColumnValue(
                  rowIndex,
                  (option as {
                    label: string;
                    value: string;
                  }).value
                );
              }
            }}
            onItemStateChange={loadPropertyTypesCodesByParentId}
            highlightedItemId={highlightedItemId}
            onActionLabelClick={async (item: TreeItem) => {
              await patientPropertyTypeCodesStore.setShowInTree(
                {
                  endpoint: endPoint,
                  propertyType
                },
                item
              );
              setHighlightedItemId(parseInt(item.id as string));
              setInputHasValue(false);
            }}
            onInputChange={onInputChange}
            items={propertyTypeError ? [] : treeData}
            placeholder={t('Select value')}
            errorMessage={propertyTypeError && ErrorTitles.Load}
            onMenuOpen={() => handleReload(inputHasValue)}
          />
        </div>
      );
    }

    if (columnMeta?.isCategorical) {
      const options = patientPropertyTypeCodesStore
        .getPropertyTypesCodes(stateAccessor)
        .map(({ propertyTypeOption }) => propertyTypeOption)
        .sort((a, b) =>
          a.label.toString().localeCompare(b.label.toString())
        ) as OptionsType<FilterOption>;

      const selectedOption = options.find(
        (option) => String((option as FilterOption).value) === condition.value
      );

      return (
        <div ref={filterValueInput} className={styles['filter-component']}>
          <Select
            isClearable
            options={options}
            value={selectedOption}
            errorMessage={propertyTypeError && ErrorTitles.Load}
            placeholder={t('Select value')}
            isLoading={isPropertyTypeRetrying}
            openMenuOnFocus
            styles={{
              menuList: (base) => {
                const inputContainerStyles = filterValueInput.current?.getBoundingClientRect();
                return {
                  ...base,
                  maxHeight: '40vh',
                  position: 'fixed',
                  background: 'white',
                  top: inputContainerStyles!.bottom + 5,
                  minWidth: inputContainerStyles!.width + 30,
                  maxWidth: inputContainerStyles!.width + 30,
                  left: 'initial'
                };
              }
            }}
            onChange={(option) => {
              onChangeColumnValue(rowIndex, (option as FilterOption)?.value);
            }}
            onMenuOpen={handleReload}
          />
        </div>
      );
    }

    switch (condition.dataType) {
      case HDataType.Integer:
      case HDataType.Decimal:
        return (
          <div className={styles['filter-component']}>
            <FormInput
              type='number'
              value={condition.value}
              placeholder={t('Enter value')}
              onChange={({ target }) => {
                onChangeColumnValue(rowIndex, target.value);
              }}
            />
          </div>
        );
      case HDataType.DateTime:
        return (
          <>
            <div className={styles['filter-component']}>
              <FormInput disabled placeholder={t('Exact date')} />
            </div>
            <div className={styles['filter-component']}>
              <DatePicker
                getValue={(value) => {
                  onChangeColumnValue(
                    rowIndex,
                    value ? format(new Date(value), 'yyyy-MM-dd HH:mm') : ''
                  );
                }}
                withTime
              />
            </div>
          </>
        );
      case HDataType.Date:
        return (
          <>
            <div className={styles['filter-component']}>
              <FormInput disabled placeholder={t('Exact date')} />
            </div>
            <div className={styles['filter-component']}>
              <DatePicker
                getValue={(value) => {
                  onChangeColumnValue(
                    rowIndex,
                    new Date(value).toLocaleString('fr-CA', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })
                  );
                }}
              />
            </div>
          </>
        );
      default:
        return (
          <div className={styles['filter-component']}>
            <FormInput
              value={condition?.value}
              placeholder={t('Enter value')}
              disabled={!condition.columnName}
              onChange={({ target }) => {
                onChangeColumnValue(rowIndex, target.value);
              }}
            />
          </div>
        );
    }
  }
);
