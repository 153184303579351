import { StatusIndicatorStatus } from '@holberg/ui-kit';
import { ApiError } from 'entities/ApiError.entity';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { Description } from 'entities/Description.entity';
import { Study } from 'entities/Study.entity';
import { StudyPropertyCodingCreateDTO } from 'entities/StudyPropertyCodingCreateDTO.entity';
import { StudyPropertyCodingUpdateDTO } from 'entities/StudyPropertyCodingUpdateDTO.entity';
import { StudyUpdateDTO } from 'entities/StudyUpdateDTO.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { UserSettingsConfig } from 'entities/UserSettingsConfig';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { RealTimeUpdateReceiveMessages } from 'enums/RealTimeUpdateType.enum';
import { SaveOperationType } from 'enums/SaveOperationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, computed, makeObservable, observable } from 'mobx';
import { ApiLoadingState } from 'services/API/ApiLoadingState';
import { ApiRetryManager } from 'services/API/ApiRetryManager';
import { StudyApi } from 'services/API/Study/StudyApi';
import { AsyncStorage } from 'services/AsyncStorage';
import { RTUManager, StudyRTUData } from 'services/RealTimeUpdatesManager';
import { stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';
import { CommonPropertyType } from 'stores/property-type-codes';

import { CategoricalPropertyCodingState } from '../categorical-property-coding/CategoricalPropertyCodingState';

const requiredPropertyTypes = Object.freeze([
  DescriptionPropertyTypes.IndicationForEEG
]);

export class StudyDetailsStore implements BaseStore {
  @observable
  readonly categoricalPropertyCodingState = new CategoricalPropertyCodingState();

  @observable
  studyDetails!: Map<number, ReturnType<typeof Study.deserialize>>;

  @observable
  studyDetailsLoading!: boolean;

  @observable
  studyDetailsError?: ApiError | UnknownError;

  @observable
  userSettingsConfig!: ReturnType<typeof UserSettingsConfig.deserialize>;

  @observable
  readonly retryManager: ApiRetryManager = new ApiRetryManager();

  @observable
  readonly apiLoadingState: ApiLoadingState = new ApiLoadingState();

  @observable
  studyStatuses: Map<Study['studyId'], boolean> = new Map<
    Study['studyId'],
    boolean
  >();

  constructor() {
    makeObservable(this);
    this.reset();

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.StudyUpdated,
        callback: (data) => this.updateStudy(data)
      }
    ]);

    // RTUManager.addObservers([
    //   {
    //     message: RealTimeUpdateReceiveMessages.StudyActiveState,
    //     callback: (data) => {
    //       this.updateStudyStatus(data.studyId, data.isEditable);
    //     }
    //   }
    // ]);
  }

  private setStudyDetails(data: Study) {
    this.studyDetails.set(data.studyId, data);

    this.categoricalPropertyCodingState.addCategoricalPropertyCodings(
      data.studyId,
      data.propertyCodings
    );
  }

  propertyCodings(
    id: string
  ): ReturnType<typeof CategoricalPropertyCoding.deserializeAsMap> {
    return this.categoricalPropertyCodingState.categoricalPropertyCodingsById(
      id
    );
  }

  isStudyEditable(id: Study['studyId']): boolean {
    return this.studyStatuses.get(id) || false;
  }

  studyById(id: string): Study | undefined {
    return this.studyDetails.get(parseInt(id));
  }

  eegTypeById(id: number): string | undefined {
    const [eegTypeCodings] =
      this.propertyCodings(String(id))[DescriptionPropertyTypes.EEGType] || [];

    return eegTypeCodings?.propertyCode.translatedName.eitherValue;
  }

  @action reset() {
    this.studyDetails = new Map();
    this.studyDetailsLoading = false;
    this.studyDetailsError = undefined;
    this.studyStatuses = new Map();
    this.userSettingsConfig = new UserSettingsConfig();
    this.categoricalPropertyCodingState.reset();
    this.apiLoadingState.reset();
    this.retryManager.stopAll();
  }

  @computed
  get hasGiveUpSaving(): boolean {
    return this.retryManager.hasGiveUp;
  }

  @computed
  get saveStatus(): StatusIndicatorStatus {
    if (this.apiLoadingState.isAnyLoading || this.retryManager.isRetrying) {
      return StatusIndicatorStatus.Loading;
    }

    if (this.retryManager.errors.length) {
      return StatusIndicatorStatus.Error;
    }

    return StatusIndicatorStatus.Saved;
  }

  @action
  retryAllRequests() {
    this.retryManager.retryAll();
  }

  @action
  clearAllRequests() {
    this.apiLoadingState.reset();
    this.retryManager.stopAll();
  }

  hasRequiredData(id: Description['descriptionId']): boolean {
    const propertyCodings = this.propertyCodings(String(id));

    return requiredPropertyTypes.every(
      (propertyType) => propertyCodings[propertyType]?.length
    );
  }

  @action
  updateStudyStatus(id: Study['studyId'], editable: boolean) {
    this.studyStatuses.set(id, editable);
  }

  @action
  private updateStudy(data: StudyRTUData) {
    if (data.study) {
      this.setStudyDetails(Study.deserialize(data.study));
    }
  }

  @action
  async getUserSettingsConfig() {
    try {
      const storageState = await AsyncStorage.getUserSettingsConfig();

      this.userSettingsConfig =
        storageState || UserSettingsConfig.deserialize({});
    } catch (e) {}
  }

  @action
  async updateUserSettingsConfig(config: UserSettingsConfig) {
    try {
      const reportConfig = UserSettingsConfig.deserialize({
        ...this.userSettingsConfig,
        ...config
      });

      await AsyncStorage.updateUserSettingsConfig(reportConfig);

      this.userSettingsConfig = reportConfig;
    } catch (e) {}
  }

  @action
  async updateStudyDetails(
    value: string | null,
    studyId: string,
    propertyName: string
  ) {
    const saveIdentifier = `${SaveOperationType.SAVE}_${studyId}_${propertyName}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = StudyUpdateDTO.deserialize({
      [propertyName]: value
    });

    try {
      const { data } = await StudyApi.updateStudyDetails(studyId, updateDto);

      const updatedStudy = Study.deserialize(data);
      this.studyDetails.set(updatedStudy.studyId, updatedStudy);

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState(e);
      retryState?.addEventListener('success', () =>
        stores[StoreType.Messages].removeError(saveIdentifier)
      );
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );
      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async loadStudyDetails(studyId: Study['studyId']) {
    this.studyDetailsLoading = true;
    try {
      const { data } = await StudyApi.loadStudyDetails(studyId);

      this.setStudyDetails(Study.deserialize(data));
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.studyDetailsLoading = false;
    }
  }

  @action
  async createPropertyCodings(
    propertyCodeId: string,
    propertyTypeId: CommonPropertyType,
    studyId: string
  ) {
    const saveIdentifier = `${SaveOperationType.SAVE}_${studyId}_${propertyTypeId}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const createDto = StudyPropertyCodingCreateDTO.deserialize({
      propertyCodeId: parseInt(propertyCodeId),
      propertyTypeId: propertyTypeId
    });

    try {
      const { data } = await StudyApi.createDescriptionPropertyCoding(
        parseInt(studyId),
        createDto
      );

      this.categoricalPropertyCodingState.addCategoricalPropertyCoding({
        entityId: parseInt(studyId),
        propertyTypeId,
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState<CategoricalPropertyCoding>(
        e
      );

      retryState?.addEventListener('success', () => {
        if (retryState.result) {
          this.categoricalPropertyCodingState.addCategoricalPropertyCoding({
            entityId: parseInt(studyId),
            propertyTypeId,
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }

        stores[StoreType.Messages].removeError(saveIdentifier);
      });
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async updatePropertyCodings({
    newPropertyCodeId,
    propertyTypeId,
    oldPropertyCodeId,
    studyId
  }: {
    newPropertyCodeId?: string;
    propertyTypeId: CommonPropertyType;
    oldPropertyCodeId?: string;
    studyId: string;
  }) {
    if (!oldPropertyCodeId && !!newPropertyCodeId) {
      this.createPropertyCodings(newPropertyCodeId, propertyTypeId, studyId);
      return;
    }

    if (!!oldPropertyCodeId && !newPropertyCodeId) {
      this.deletePropertyCodings(studyId, oldPropertyCodeId, propertyTypeId);
      return;
    }

    if (!(oldPropertyCodeId && newPropertyCodeId)) {
      return;
    }

    const saveIdentifier = `${SaveOperationType.UPDATE}_${studyId}_${propertyTypeId}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = StudyPropertyCodingUpdateDTO.deserialize({
      propertyCodeId: parseInt(newPropertyCodeId)
    });

    try {
      const { data } = await StudyApi.updateDescriptionPropertyCoding({
        descriptionId: parseInt(studyId),
        dto: updateDto,
        propertyTypeId,
        currentPropertyCodeId: oldPropertyCodeId
      });

      this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
        entityId: parseInt(studyId),
        propertyTypeId,
        propertyCodeId: parseInt(oldPropertyCodeId),
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState<CategoricalPropertyCoding>(
        e
      );
      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        if (retryState.result) {
          this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
            entityId: parseInt(studyId),
            propertyTypeId,
            propertyCodeId: parseInt(oldPropertyCodeId),
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }
      });
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async deletePropertyCodings(
    studyId: string,
    propertyCodeId: string,
    propertyTypeId: CommonPropertyType
  ) {
    const saveIdentifier = `${SaveOperationType.DELETE}_${studyId}_${propertyTypeId}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    try {
      await StudyApi.deleteDescriptionPropertyCoding({
        descriptionId: parseInt(studyId),
        propertyTypeId,
        propertyCodeId
      });

      this.categoricalPropertyCodingState.deleteCategoricalPropertyCoding({
        entityId: parseInt(studyId),
        propertyTypeId,
        propertyCodeId: parseInt(propertyCodeId)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState(e);
      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        this.categoricalPropertyCodingState.deleteCategoricalPropertyCoding({
          entityId: parseInt(studyId),
          propertyTypeId,
          propertyCodeId: parseInt(propertyCodeId)
        });
      });
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async updatePropertyCodingsNote({
    currentPropertyCodeId,
    propertyTypeId,
    studyId,
    freeText
  }: {
    currentPropertyCodeId: string;
    propertyTypeId: CommonPropertyType;
    freeText: string;
    studyId: string;
  }) {
    const saveIdentifier = `${SaveOperationType.UPDATE}_${studyId}_${propertyTypeId}_freeText`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = StudyPropertyCodingUpdateDTO.deserialize({ freeText });

    try {
      const { data } = await StudyApi.updateDescriptionPropertyCoding({
        descriptionId: parseInt(studyId),
        dto: updateDto,
        propertyTypeId,
        currentPropertyCodeId
      });

      this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
        entityId: parseInt(studyId),
        propertyTypeId,
        propertyCodeId: parseInt(currentPropertyCodeId),
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState<CategoricalPropertyCoding>(
        e
      );
      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        if (retryState.result) {
          this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
            entityId: parseInt(studyId),
            propertyTypeId,
            propertyCodeId: parseInt(currentPropertyCodeId),
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }
      });
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }
}
