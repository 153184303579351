import React from 'react';
import {
  Icon,
  IconType,
  Tooltip,
  TooltipIds,
  useCustomTranslation
} from '@holberg/ui-kit';
import { EventStatus } from 'entities/DescriptionStatus.entity';

import styles from './FindingsWarning.module.scss';

interface Props {
  status?: EventStatus;
  isActive?: boolean;
  parentFolderWarning?: boolean | undefined;
}

export const FindingsWarning: React.FC<Props> = ({
  status,
  isActive = false,
  parentFolderWarning = false
}) => {
  const { t } = useCustomTranslation();
  const fullMessage = status?.getFullMessage(isActive);

  return (
    <div className={styles['findings-warning']}>
      <Tooltip
        tooltipId={TooltipIds.DefaultWarning}
        contentClassName={styles['tooltip-content']}
        data={[
          {
            mainTooltip: t('You have an action to complete'),
            subTooltip: fullMessage
          }
        ]}
      >
        <div>
          {((status?.hasErrors && !isActive) ||
            fullMessage ||
            (parentFolderWarning && !isActive)) && (
            <Icon
              data-testid='findings-warning'
              iconType={IconType.WarningFilled}
              className={styles.warning}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};
