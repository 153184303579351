import { action, makeObservable, observable } from 'mobx';

type Request<TData, TServiceResponse, TError> = {
  data: TData;
  onSuccess: (result: Awaited<TServiceResponse>) => void;
  onFail?: (error: TError) => void;
};

export class RequestsQueueManager<
  TData,
  TServiceResponse extends Promise<any>,
  TError = Error
> {
  @observable
  private isPending: boolean = false;
  private requestQueue: Request<TData, TServiceResponse, TError>[] = [];
  private currentRequestIndex: number = 0;

  constructor(
    private service: (data: TData) => TServiceResponse,
    private mergeRequests: (
      requestsQueue: Request<TData, TServiceResponse, TError>[],
      newRequest: Request<TData, TServiceResponse, TError>
    ) => Request<TData, TServiceResponse, TError>[]
  ) {
    makeObservable(this);
  }

  @action
  private async runRequests() {
    for (
      ;
      this.currentRequestIndex < this.requestQueue.length;
      this.currentRequestIndex++
    ) {
      try {
        const request = this.requestQueue[this.currentRequestIndex];
        const result = await this.service(request.data);
        request.onSuccess(result);
      } catch (e) {
        this.requestQueue[this.currentRequestIndex].onFail?.(e);
        break;
      }
    }

    this.requestQueue = [];
    this.isPending = false;
    this.currentRequestIndex = 0;
  }

  private updateLatestRequest(
    request: Request<TData, TServiceResponse, TError>
  ) {
    this.requestQueue = [
      ...this.requestQueue.slice(0, this.currentRequestIndex + 1),
      ...this.mergeRequests(
        this.requestQueue.slice(this.currentRequestIndex + 1),
        request
      )
    ];
  }

  @action
  callAction(request: Request<TData, TServiceResponse, TError>) {
    if (this.currentRequestIndex < this.requestQueue.length - 1) {
      this.updateLatestRequest(request);
    } else if (
      !this.requestQueue.length ||
      this.currentRequestIndex >= this.requestQueue.length - 1
    ) {
      this.requestQueue.push(request);
    }
    if (!this.isPending) {
      this.isPending = true;
      this.runRequests();
    }
  }
}
