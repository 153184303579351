import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { TreeItem, useCustomTranslation } from '@holberg/ui-kit';
import { EventCode } from 'entities/EventCode.entity';

const buildTree = (
  items: TreeItem[],
  id: string | number | null = null,
  pathIds: Array<string | number> = []
): TreeItem[] =>
  items.reduce((acc: TreeItem[], treeItem) => {
    if (treeItem.parentId === id) {
      acc.push({
        ...treeItem,
        children: buildTree(items, treeItem.id, pathIds),
        expanded: pathIds.includes(treeItem.id)
      });
    }

    return acc;
  }, []);

const buildPath = (
  items: TreeItem[],
  parentId?: string | number | null,
  acc: Array<string | number> = []
): Array<string | number> => {
  if (parentId || parentId === 0) {
    const parent = items.find((item) => item.id === parentId);
    if (parent?.hasChildren) {
      acc.push(parentId);
    }

    buildPath(items, parent?.parentId, acc);
  }
  return acc;
};

export const useEventCodesTree = (
  eventCodes: EventCode[],
  defaultActiveCode?: EventCode['eventCodeId'],
  disabledEventCodeIds?: Array<EventCode['eventCodeId']>
) => {
  const { t } = useCustomTranslation();

  const makeTreeItem = useCallback(
    (eventCode: EventCode): TreeItem => ({
      label: '',
      subLabel: eventCode.translatedName.primaryValue || '',
      id: eventCode.eventCodeId,
      hasChildren: eventCode.isExpandableNodeSelectionTreeView,
      children: [],
      isSelectable:
        eventCode.isSelectable &&
        !disabledEventCodeIds?.find((id) => id === eventCode.eventCodeId),
      actionLabel: t('Show in Tree'),
      parentId: eventCode.parentId,
      isHidden: !eventCode.showInSelectionTreeView
    }),
    [t, disabledEventCodeIds]
  );

  const treeItems = useMemo(
    () =>
      eventCodes.filter((eventCode) => eventCode.isActive).map(makeTreeItem),
    [eventCodes, makeTreeItem]
  );

  const defaultTree = useMemo(() => {
    if (!defaultActiveCode) {
      return buildTree(treeItems);
    }

    const pathIds = buildPath(treeItems, defaultActiveCode);
    return buildTree(treeItems, null, pathIds);
    // eslint-disable-next-line
  }, []);
  const [treeData, setTreeData] = useState(defaultTree);

  const [searchValue, setSearchValue] = useState<string>('');

  const [highlightedItemId, setHighlightedItemId] = useState<
    number | string | undefined
  >(defaultActiveCode || treeItems.find((item) => !item.isHidden)?.id);

  const handleSearch = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = target.value;
      const treeData = newSearchValue
        ? treeItems
            .filter(
              (item: TreeItem) =>
                !item.isHidden &&
                (item.subLabel as string)
                  .toLowerCase()
                  .indexOf(newSearchValue.toLowerCase()) !== -1
            )
            .slice(0, 20)
        : buildTree(treeItems);

      setHighlightedItemId(undefined);
      setTreeData(treeData);
      setSearchValue(newSearchValue);
    },
    [treeItems]
  );

  const handleShowInTree = useCallback(
    (item: TreeItem) => {
      setHighlightedItemId(item.id);
      const pathIds = buildPath(treeItems, item.parentId as number | null);
      setTreeData(buildTree(treeItems, null, pathIds));
    },
    [treeItems]
  );

  const reset = useCallback(() => {
    setSearchValue('');
    setTreeData(defaultTree);
  }, [defaultTree]);

  return {
    treeData,
    searchValue,
    highlightedItemId,
    handleSearch,
    handleShowInTree,
    reset
  };
};
