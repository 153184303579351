import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Modal,
  NavTab,
  NavTabTheme,
  useCustomTranslation,
  UserHeading
} from '@holberg/ui-kit';
import label from 'assets/label.png';
import logo from 'assets/logo.png';
import cn from 'classnames';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import styles from './NavigationHeader.module.scss';

interface Props {
  className?: string;
}

export const NavigationHeader: React.FC<Props> = observer(({ className }) => {
  const authStore = useStore(StoreType.Auth);
  const siteStore = useStore(StoreType.Site);
  const [showLabel, setShowLabel] = useState(false);

  const { t } = useCustomTranslation();

  useEffect(() => {
    if (!authStore.me.email) {
      authStore.loadMe();
      siteStore.loadSite();
    }
  }, [authStore, siteStore]);

  return (
    <>
      <Modal handleVisible={() => setShowLabel(false)} visible={showLabel}>
        <img src={label} alt='hiSCORE label' data-testid='hiSCORE-label' />
      </Modal>
      <header className={cn(styles['navigation-header'], className)}>
        <img
          src={logo}
          alt='holberg logo'
          data-testid='logo'
          onClick={() => setShowLabel(true)}
        />
        <div className={styles.tabs} data-testid='navigation'>
          <NavTab
            label={t('Patients')}
            component={NavLink}
            className={styles.tab}
            activeClassName={NavTab.activeStyle}
            to={Routes.Patients}
            exact
            theme={NavTabTheme.Underlined}
          />
        </div>
        {!authStore.meLoading && (
          <div className={styles.controls}>
            <UserHeading
              className={styles['user-heading']}
              name={authStore.me.fullName}
            />
          </div>
        )}
      </header>
    </>
  );
});
