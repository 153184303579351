import React, {
  FC,
  PointerEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { processNodes, Transform } from 'react-html-parser';
import { DomNode, LoadingOverlay } from '@holberg/ui-kit';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { FindingPropertiesEventTypes } from 'enums/FindingPropertiesEventTypes.enum';
import { LocationType } from 'enums/LocationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useHandlePropertiesAutoSave } from 'hooks/useHandlePropertiesAutoSave';
import { observer } from 'mobx-react-lite';
import { FindingPropertiesPublisher } from 'services/FindingPropertiesPublisher';
import { isMacOS } from 'services/keyboardShortcuts/shortcutsBaseKeys';
import { EventPropertyCodingsData } from 'stores/finding-properties';
import { SensorCodings } from 'stores/finding-properties/helpers';
import { convertSensorsForm } from 'stores/finding-properties/helpers';
import {
  SensorMeta,
  SensorPositionMarker,
  SensorState
} from 'stores/head-model';

import {
  createSvgRect,
  getFontSize,
  getId,
  getIntersections,
  getScaledOffset,
  transformSvgRect
} from './utils';

import styles from './SVG.module.scss';

interface Props {
  node: DomNode;
  transform: Transform;
  sensors: RefObject<SVGGElement>;
  locationType: LocationType;
  panelId: EventPropertyPanel['eventPropertyPanelId'];
}

export const SVG: FC<Props> = observer(
  ({ node, transform, sensors, locationType, panelId }) => {
    const svgRef = useRef<SVGSVGElement>(null);

    const [isLoading, setLoading] = useState<boolean>(true);
    const [pointerId, setPointerId] = useState<number>();
    const { setValue, getValues } = useFormContext<{
      eventPropertyCodings: EventPropertyCodingsData;
    }>();
    const findingProperties = useStore(StoreType.FindingProperties);
    const headModelStore = useStore(StoreType.HeadModel);
    const { activeCodingId } = usePropertiesContext();

    const { eventHandler } = useHandlePropertiesAutoSave(panelId);

    const adjustFontSize = useCallback(() => {
      if (svgRef.current) {
        const pathElements = Array.from(
          svgRef.current.querySelectorAll('.sensorbox .sensoredge')
        ) as SVGGeometryElement[];

        const fontSize = getFontSize(svgRef.current, pathElements);

        pathElements.forEach((pathElement) => {
          const textElement = pathElement.nextElementSibling as SVGTextElement;

          if (textElement) {
            const { xOffset, yOffset } = getScaledOffset(textElement, fontSize);

            textElement.setAttribute('x', String(xOffset));
            textElement.setAttribute('y', String(yOffset));
            textElement.style.fontSize = String(fontSize);
          }
        });
      }
    }, []);

    const parseMetaData = useCallback(() => {
      if (svgRef.current && activeCodingId) {
        const sensorElements = Array.from(
          svgRef.current.querySelectorAll('.sensorbox')
        ) as SVGGeometryElement[];

        const sensorsMetaData = sensorElements.reduce<Map<string, SensorMeta>>(
          (metaMap, sensor) => {
            if (!sensor) {
              return metaMap;
            }

            return metaMap.set(sensor.getAttribute('data-id')!, {
              left:
                sensor.getAttribute('data-position-left') ===
                SensorPositionMarker.True,
              right:
                sensor.getAttribute('data-position-right') ===
                SensorPositionMarker.True,
              midline:
                sensor.getAttribute('data-position-midline') ===
                SensorPositionMarker.True,
              frontal:
                sensor.getAttribute('data-position-frontal') ===
                SensorPositionMarker.True,
              central:
                sensor.getAttribute('data-position-central') ===
                SensorPositionMarker.True,
              temporal:
                sensor.getAttribute('data-position-temporal') ===
                SensorPositionMarker.True,
              regions: sensor.getAttribute('data-regionids')?.split(',') || []
            });
          },
          new Map()
        );
        headModelStore.setSensorsMeta(
          activeCodingId,
          locationType,
          sensorsMetaData
        );
      }
      // eslint-disable-next-line
    }, [activeCodingId, locationType]);

    const parseSensors = useCallback(() => {
      if (!activeCodingId) {
        return;
      }

      const sensors: SensorCodings[] = convertSensorsForm(
        getValues().eventPropertyCodings?.headModel
      );

      findingProperties.setSensors(activeCodingId, panelId, sensors);
      // eslint-disable-next-line
    }, [activeCodingId, panelId]);

    useEffect(() => {
      adjustFontSize();
      parseMetaData();
      parseSensors();

      setLoading(false);
      // eslint-disable-next-line
    }, []);

    const { viewbox, height, ...props } = node.attribs;

    const getState = useCallback<(e: PointerEvent) => SensorState | undefined>(
      (event: PointerEvent) => {
        const controlKey = isMacOS ? event.metaKey : event.ctrlKey;

        switch (true) {
          case event.shiftKey && controlKey:
            return;
          case event.shiftKey:
            return SensorState.Empty;
          case controlKey:
            return SensorState.Max;
          default:
            return SensorState.Normal;
        }
      },
      []
    );

    const getSelectedElements = useCallback(
      (event: PointerEvent) => {
        const dragAreaInClientCoordinate = createSvgRect(
          svgRef.current!,
          event.clientX,
          event.clientY,
          event.clientX + 1,
          event.clientY + 1
        );
        const dragAreaInSvgCoordinate = transformSvgRect(
          svgRef.current!,
          svgRef.current!.getScreenCTM()!.inverse(),
          dragAreaInClientCoordinate
        );
        const dragAreaInInitialSvgCoordinate = transformSvgRect(
          svgRef.current!,
          svgRef.current!.getCTM(),
          dragAreaInSvgCoordinate
        );
        const referenceElement = sensors.current || null;

        return getIntersections(
          svgRef.current!,
          referenceElement,
          dragAreaInSvgCoordinate,
          dragAreaInInitialSvgCoordinate
        ).map(getId);
      },
      [sensors]
    );

    const onDrag = useCallback(
      (event: PointerEvent) => {
        event.preventDefault();
        const state = getState(event);

        if (state === undefined) return;

        const newSelectedElements = getSelectedElements(event);
        newSelectedElements.forEach((sensorId) =>
          setValue(
            `eventPropertyCodings.headModel.${locationType}.${sensorId}`,
            state
          )
        );

        if (state === SensorState.Empty) {
          FindingPropertiesPublisher.publish({
            panelId,
            eventType: FindingPropertiesEventTypes.SensorsDeselect
          });
        } else {
          FindingPropertiesPublisher.publish({
            panelId,
            eventType: FindingPropertiesEventTypes.SensorsSelect
          });
        }

        eventHandler();
      },
      [
        getState,
        setValue,
        locationType,
        getSelectedElements,
        eventHandler,
        panelId
      ]
    );

    const onPointerMove = useCallback(
      (event: PointerEvent) => {
        if (event.pointerId === pointerId) {
          onDrag(event);
        }
      },
      [pointerId, onDrag]
    );

    const onPointerDown = useCallback(
      (event: PointerEvent) => {
        if (event.isPrimary !== false && pointerId === undefined) {
          setPointerId(event.pointerId || 0);
          onDrag(event);
        }
      },
      [pointerId, onDrag]
    );

    const onPointerUp = useCallback(
      (event: PointerEvent) => {
        if (event.pointerId === pointerId) {
          setPointerId(undefined);
        }
      },
      [pointerId]
    );

    useEffect(() => {
      const observer = {
        event: {
          panelId,
          eventType: FindingPropertiesEventTypes.DiffuseSelect
        },
        update: () => {
          const headModel = getValues().eventPropertyCodings.headModel?.[
            locationType
          ];

          if (!headModel) {
            return;
          }

          Object.entries(headModel).forEach(([sensorName, sensorState]) => {
            if (sensorState === SensorState.Empty) {
              setValue(
                `eventPropertyCodings.headModel.${locationType}.${sensorName}`,
                SensorState.Normal
              );
            }
          });

          FindingPropertiesPublisher.publish({
            panelId,
            eventType: FindingPropertiesEventTypes.SensorsSelect
          });
        }
      };

      FindingPropertiesPublisher.subscribe(observer);

      return () => {
        FindingPropertiesPublisher.unsubscribe(observer);
      };
      // eslint-disable-next-line
    }, []);

    return (
      <LoadingOverlay loading={isLoading}>
        <svg
          ref={svgRef}
          viewBox={viewbox}
          {...props}
          width='100%'
          className={styles.container}
          onPointerDown={onPointerDown}
          onPointerMove={onPointerMove}
          onPointerUp={onPointerUp}
          onPointerCancel={onPointerUp}
        >
          {processNodes(node.children, transform)}
        </svg>
      </LoadingOverlay>
    );
  }
);
