import React, { useCallback, useMemo, useRef } from 'react';
import { components, OptionProps, ValueType } from 'react-select';
import {
  Button,
  ButtonTheme,
  IconType,
  Select,
  SelectOption,
  useCustomTranslation
} from '@holberg/ui-kit';
import { Condition } from 'entities/Condition.entity';
import { GenericColumnMetaInformation } from 'entities/GenericColumnMetaInformation.entity';
import { GenericListItem } from 'entities/GenericListItem.entity';
import { TableConfig } from 'entities/TableConfig.entity';

import { FilterRowSection } from '../FilterRowSection';
import { FilterOption } from '../FiltersSettings/FiltersSettings';

import styles from './FilterRow.module.scss';

const Option: React.FC<OptionProps<FilterOption, false>> = (props) => (
  <components.Option {...props}>
    <SelectOption
      label={props.label}
      icon={!props.data.isVisible ? IconType.Hidden : undefined}
    />
  </components.Option>
);

interface Props {
  rowIndex: number;
  condition: Condition;
  filterConditions: Condition[];
  onRemoveCondition: (rowIndex: number) => void;
  value?: ValueType<{ label: string; value: string }, false>;
  tableConfig: Record<keyof GenericListItem, TableConfig>;
  columnMetaInfo: Record<keyof GenericListItem, GenericColumnMetaInformation>;
  onChangeColumnName: (rowIndex: number, column: FilterOption) => void;
  onChangeColumnValue: (rowIndex: number, value: string | number) => void;
}

export const FilterRow: React.FC<Props> = ({
  rowIndex,
  tableConfig,
  onChangeColumnName,
  onChangeColumnValue,
  filterConditions,
  condition,
  onRemoveCondition,
  columnMetaInfo = {}
}) => {
  const { t } = useCustomTranslation();

  const handleRemoveCondition = useCallback(() => onRemoveCondition(rowIndex), [
    onRemoveCondition,
    rowIndex
  ]);

  const filterSelect = useRef<HTMLDivElement | null>(null);
  const filterOptions: FilterOption[] = useMemo(
    () =>
      Object.values(columnMetaInfo)
        .filter(({ isSearchable, propertyName }) => {
          const isPropertySelected = filterConditions.some(
            ({ columnName }) => columnName === propertyName
          );
          return isSearchable && !isPropertySelected;
        })
        .map(({ dataType, propertyName, name }) => ({
          dataType,
          value: propertyName.toString(),
          label: name.eitherValue.toString(),
          isVisible: tableConfig[propertyName].isVisible
        }))
        .sort((a, b) => a.label.toString().localeCompare(b.label.toString())),
    [columnMetaInfo, filterConditions, tableConfig]
  );

  const selectedProperty = condition.columnName
    ? ({
        value: condition.columnName,
        label: columnMetaInfo[condition.columnName]?.name.eitherValue
      } as ValueType<FilterOption, false>)
    : undefined;

  return (
    <div className={styles['filter-row']}>
      <div className={styles['inputs-container']}>
        <div ref={filterSelect} className={styles.select}>
          <Select<FilterOption>
            isClearable
            value={selectedProperty}
            inputId='select'
            options={filterOptions}
            components={{ Option }}
            styles={{
              menuList: (base) => {
                const inputContainerStyles = filterSelect.current?.getBoundingClientRect();
                return {
                  ...base,
                  maxHeight: '40vh',
                  position: 'fixed',
                  background: 'white',
                  top: inputContainerStyles!.bottom + 5,
                  minWidth: inputContainerStyles!.width + 30,
                  maxWidth: inputContainerStyles!.width + 30,
                  left: 'initial'
                };
              }
            }}
            onChange={(option) =>
              onChangeColumnName(rowIndex, option as FilterOption)
            }
            placeholder={t('Select column')}
          />
        </div>
        <FilterRowSection
          rowIndex={rowIndex}
          condition={condition}
          columnMetaInfo={columnMetaInfo}
          onChangeColumnValue={onChangeColumnValue}
        />
      </div>
      <Button
        title=''
        icon={IconType.Close}
        theme={ButtonTheme.SecondaryTransparent}
        className={styles['remove-button']}
        iconClassName={styles['remove-icon']}
        onClick={handleRemoveCondition}
      />
    </div>
  );
};
