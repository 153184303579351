import { useCallback, useState } from 'react';
import { InputActionMeta } from 'react-select';

export const useSelectSearchInput = (
  onValuePresent: (value: string) => void
) => {
  const [inputHasValue, setInputHasValue] = useState(false);
  const [highlightedItemId, setHighlightedItemId] = useState<
    number | undefined
  >(undefined);
  const [isInputChanged, setIsInputChanged] = useState(false);

  const onInputChange = useCallback<
    (newValue: string, actionMeta: InputActionMeta) => void
  >(
    (newValue, { action }) => {
      if (
        action === 'input-blur' ||
        action === 'set-value' ||
        action === 'menu-close' ||
        (action === 'input-change' && !newValue)
      ) {
        setInputHasValue(false);
        setIsInputChanged(false);
        setHighlightedItemId(undefined);
      } else {
        setIsInputChanged(true);
        setInputHasValue(true);
        setHighlightedItemId(undefined);
        onValuePresent(newValue);
      }
    },
    [onValuePresent]
  );

  return {
    inputHasValue,
    setInputHasValue,
    onInputChange,
    highlightedItemId,
    setHighlightedItemId,
    isInputChanged
  };
};
