import { computed, makeObservable } from 'mobx';
import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class PropertyTypeCode {
  @serializable
  propertyCodeId: number = 0;

  @serializable
  code: string = '';

  @serializable
  rootCode: string = '';

  @serializable
  rootCodeId: number = 0;

  @serializable
  isExclusiveInMultiSelect: boolean = false;

  @serializable
  parentCode: string = '';

  @serializable
  ageConstraintId: number = 0;

  @serializable
  parentId: number | null = null;

  @serializable
  isSelectable: boolean = false;

  @serializable
  isNotScoredProperty: boolean = false;

  @serializable
  hasChildren: boolean = false;

  @serializable
  isActive: boolean = false;

  @serializable
  name: string = '';

  @serializable(object(InformationModel))
  translatedName: InformationModel = new InformationModel();

  constructor() {
    makeObservable(this);
  }

  @computed
  get propertyTypeOption() {
    return {
      value: String(this.propertyCodeId),
      label: this.translatedName.eitherValue,
      isMultiSelect: !this.isExclusiveInMultiSelect
    };
  }

  static deserialize(json: Object | string) {
    return deserialize(PropertyTypeCode, json);
  }

  static deserializeAsList(list: PropertyTypeCode[]): PropertyTypeCode[] {
    return list.map(PropertyTypeCode.deserialize);
  }

  static deserializeAsMap(
    list: PropertyTypeCode[]
  ): Map<PropertyTypeCode['propertyCodeId'], PropertyTypeCode> {
    return list.reduce((acc, patient) => {
      acc.set(patient.propertyCodeId, PropertyTypeCode.deserialize(patient));

      return acc;
    }, new Map());
  }
}
