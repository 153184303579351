import React, { ComponentType, FC } from 'react';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { useGetPanelById } from 'hooks/useGetPanelById';
import { useRequiredPropertyTypeSelected } from 'hooks/useRequiredPropertyTypeSelected';
import { useSensorSelected } from 'hooks/useSensorSelected';
import { observer } from 'mobx-react-lite';

export const withRequiredProperty = <
  P extends {
    disabled?: boolean;
    panelId: EventPropertyPanel['eventPropertyPanelId'];
  }
>(
  getPropertyType: (props: P) => EventPropertyType
) => (WrappedComponent: ComponentType<P>) => {
  const WatchedSensorsComponent: FC<P> = observer((props) => {
    const panel = useGetPanelById(props.panelId)!;

    const isEnabled = useSensorSelected(panel);

    return <WrappedComponent {...props} disabled={!isEnabled} />;
  });

  const WatchedRequiredPropertyComponent: FC<P> = observer((props) => {
    const propertyType = getPropertyType(props);
    const panel = useGetPanelById(props.panelId)!;

    const isEnabled = useRequiredPropertyTypeSelected(
      propertyType.requiredEventPropertyTypeInCodingId,
      panel
    );

    return <WrappedComponent {...props} disabled={!isEnabled} />;
  });

  const WatchedComponent: FC<P> = observer((props) => {
    const propertyType = getPropertyType(props);
    const panel = useGetPanelById(props.panelId)!;

    return panel.headModelPropertyType?.propertyType.eventPropertyTypeId ===
      propertyType.requiredEventPropertyTypeInCodingId ? (
      <WatchedSensorsComponent {...props} />
    ) : (
      <WatchedRequiredPropertyComponent {...props} />
    );
  });

  const Wrapped: FC<P> = (props) => {
    const propertyType = getPropertyType(props);

    return propertyType.requiredEventPropertyTypeInCodingId === null ? (
      <WrappedComponent {...props} />
    ) : (
      <WatchedComponent {...props} />
    );
  };

  return Wrapped;
};
