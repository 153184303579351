import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource
} from 'axios';
import { StoreType } from 'enums/StoreType.enum';
import { action, computed, makeObservable, observable } from 'mobx';
import { operation, RetryOperation } from 'retry';
import { stores } from 'stores';

type EventType = 'success' | 'retry' | 'giveUp';
const noOp = () => {};

export class ApiRetryState<T> {
  private static createOperation() {
    return operation({
      retries: 3,
      factor: 1.7,
      maxTimeout: 60 * 1000,
      forever: stores[StoreType.Messages].hasConnectionIssues
    });
  }

  constructor(
    private readonly client: AxiosInstance,
    private readonly config: AxiosRequestConfig
  ) {
    makeObservable<
      ApiRetryState<T>,
      | '_result'
      | '_hasSucceeded'
      | '_isRetrying'
      | '_hasGiveUp'
      | 'operation'
      | 'setCancelToken'
      | 'processRequest'
    >(this);
    this.operation = ApiRetryState.createOperation();
  }

  private cancelTokenSource?: CancelTokenSource;

  @observable
  private _result?: AxiosResponse<T>;

  @computed
  get result() {
    return this._result;
  }

  @observable
  private _hasSucceeded: boolean = false;

  @computed
  get hasSucceeded(): boolean {
    return this._hasSucceeded;
  }

  @observable
  private _isRetrying: boolean = false;

  @computed
  get isRetrying(): boolean {
    return this._isRetrying;
  }

  @observable
  private _hasGiveUp: boolean = false;

  @computed
  get hasGiveUp(): boolean {
    return this._hasGiveUp;
  }

  @computed
  get errors() {
    return this.operation.errors() as AxiosError<T>[];
  }

  @computed
  get mainError() {
    return this.operation.mainError() as AxiosError<T>;
  }

  private onSuccess: () => void = noOp;
  private onRetry: () => void = noOp;
  private onGiveUp: () => void = noOp;

  @observable
  private operation: RetryOperation;

  private setCancelToken() {
    this.cancelTokenSource = axios.CancelToken.source();
    this.config.cancelToken = this.cancelTokenSource?.token;
  }

  private async processRequest() {
    this._result = await this.client.request<T>(this.config);

    this._isRetrying = false;
    this._hasSucceeded = true;
    this.onSuccess();
  }

  addEventListener(eventType: EventType, event: () => void) {
    if (eventType === 'success') {
      this.onSuccess = event;
    }

    if (eventType === 'retry') {
      this.onRetry = event;
    }

    if (eventType === 'giveUp') {
      this.onGiveUp = event;
    }
  }

  removeEventListener(eventType: EventType) {
    if (eventType === 'success') {
      this.onSuccess = noOp;
    }

    if (eventType === 'retry') {
      this.onRetry = noOp;
    }

    if (eventType === 'giveUp') {
      this.onGiveUp = noOp;
    }
  }

  @action
  start() {
    this.setCancelToken();

    this.operation.attempt(async () => {
      try {
        if (stores[StoreType.Messages].hasConnectionIssues)
          throw new Error('Connection issue');

        this._isRetrying = true;
        await this.processRequest();
      } catch (e) {
        this._isRetrying = false;

        if (this.operation.retry(e)) {
          this.onRetry();
        } else {
          this._hasGiveUp = true;
          this.onGiveUp();
        }
      }
    });
  }

  @action async retryOnce() {
    this.setCancelToken();
    this._isRetrying = true;

    try {
      await this.processRequest();
    } catch (e) {
      this._isRetrying = false;
    }
  }

  @action
  restart() {
    if (!this._hasSucceeded) {
      this._hasGiveUp = false;
      this.cancelTokenSource?.cancel();
      this.operation = ApiRetryState.createOperation();
      this.start();
    }
  }

  @action
  abort() {
    this.cancelTokenSource?.cancel();
    this.operation.stop();
    this._isRetrying = false;
  }
}

export class ApiRetryManager {
  @observable
  private statesMap: Map<string, ApiRetryState<unknown>> = new Map();

  constructor() {
    makeObservable<ApiRetryManager, 'statesMap' | 'statesList'>(this);
  }

  @computed
  private get statesList(): ApiRetryState<unknown>[] {
    return Array.from(this.statesMap.values());
  }

  @computed
  get length(): number {
    return this.statesList.length;
  }

  @action
  addRetryState(identifier: string, state: ApiRetryState<unknown>) {
    this.statesMap.set(identifier, state);
  }

  @action
  cancelRetryFor(identifier: string) {
    const state = this.statesMap.get(identifier);
    state?.abort();
    this.statesMap.delete(identifier);
  }

  @action
  retryAll() {
    this.statesList.forEach((state) => state.restart());
  }

  @action stopAll() {
    this.statesList.forEach((state) => state.abort());
    this.statesMap = new Map();
  }

  @computed
  get isRetrying(): boolean {
    return this.statesList
      .filter((state) => !state.hasSucceeded)
      .some((state) => state.isRetrying);
  }

  @computed
  get errors() {
    return this.statesList
      .filter((state) => !state.hasSucceeded)
      .map((state) => state.mainError);
  }

  @computed
  get hasGiveUp(): boolean {
    return (
      this.statesList
        .filter((state) => !state.hasSucceeded)
        .filter((state) => state.hasGiveUp).length > 0
    );
  }
}
