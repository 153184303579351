import { StoreType } from './StoreType.enum';

export enum PropertyType {
  Patient = 0,
  Study,
  Description
}

export const propertyTypeUrl = Object.freeze<Record<PropertyType, string>>({
  [PropertyType.Patient]: 'patientPropertyTypes',
  [PropertyType.Study]: 'studyPropertyTypes',
  [PropertyType.Description]: 'descriptionPropertyTypes'
});

export const propertyTypeStoreAccessor: Record<
  PropertyType,
  | StoreType.PatientPropertyTypeCodes
  | StoreType.StudyPropertyTypeCodes
  | StoreType.DescriptionPropertyTypeCodes
> = {
  [PropertyType.Patient]: StoreType.PatientPropertyTypeCodes,
  [PropertyType.Study]: StoreType.StudyPropertyTypeCodes,
  [PropertyType.Description]: StoreType.DescriptionPropertyTypeCodes
};
