import { deserialize, list, object, serializable } from 'serializr';

import { EventPropertyCodingsCategorical } from './EventPropertyCodingsCategorical.entity';
import { EventPropertyCodingsDecimal } from './EventPropertyCodingsDecimal.entity';
import { EventPropertyCodingsInteger } from './EventPropertyCodingsInteger.entity';
import { EventPropertyCodingString } from './EventPropertyCodingString.entity';

export class EventPropertyCoding {
  @serializable(list(object(EventPropertyCodingsCategorical)))
  categorical: EventPropertyCodingsCategorical[] = [];

  @serializable(list(object(EventPropertyCodingString)))
  string: EventPropertyCodingString[] = [];

  @serializable(list(object(EventPropertyCodingsInteger)))
  integer: EventPropertyCodingsInteger[] = [];

  @serializable(list(object(EventPropertyCodingsDecimal)))
  decimal: EventPropertyCodingsDecimal[] = [];

  static deserialize(json: Object | string) {
    return deserialize(EventPropertyCoding, json);
  }
}
