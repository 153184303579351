import React, { CSSProperties, useEffect, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Findings } from 'components/Findings';
import { PropertiesContainer } from 'components/PropertiesContainer';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { dividerStyles } from 'components/ResizeContainer/ResizeContainer';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useWindowWidth } from 'hooks/useWindowWidth';
import {
  FINDINGS_PROPERTIES_MIN_WIDTH,
  STUDY_TABS_CONTAINER_MIN_WIDTH,
  STUDY_TABS_CONTAINER_MIN_WIDTH_NO_EXAMPLES
} from 'utils/constants';

import styles from './FindingsContainer.module.scss';

const propertiesStyles = Object.freeze({
  minWidth: FINDINGS_PROPERTIES_MIN_WIDTH,
  overflowX: 'hidden' as CSSProperties['overflowX']
});

const MIN_WIDTH_WITH_PROPERTIES_AND_EXAMPLES =
  STUDY_TABS_CONTAINER_MIN_WIDTH + FINDINGS_PROPERTIES_MIN_WIDTH;

const MIN_WIDTH_WITH_PROPERTIES_ONLY =
  STUDY_TABS_CONTAINER_MIN_WIDTH_NO_EXAMPLES + FINDINGS_PROPERTIES_MIN_WIDTH;

export const FindingsContainer: React.FC = () => {
  const [showEventsTree, setShowEventsTree] = useState(true);
  const { windowWidth } = useWindowWidth();
  const { activeCodingId, onFindingClick } = usePropertiesContext();
  const studyDetailsStore = useStore(StoreType.StudyDetails);

  const hasActiveCoding = activeCodingId !== undefined;

  useEffect(() => {
    if (
      windowWidth <= MIN_WIDTH_WITH_PROPERTIES_AND_EXAMPLES &&
      hasActiveCoding
    ) {
      studyDetailsStore.updateUserSettingsConfig({
        unclassifiedExamplesCollapsed: true
      });
    }

    if (windowWidth <= MIN_WIDTH_WITH_PROPERTIES_ONLY && hasActiveCoding) {
      setShowEventsTree(false);
      return;
    }

    if (windowWidth <= STUDY_TABS_CONTAINER_MIN_WIDTH && !hasActiveCoding) {
      studyDetailsStore.updateUserSettingsConfig({
        unclassifiedExamplesCollapsed: true
      });
    }

    setShowEventsTree(true);
  }, [windowWidth, hasActiveCoding, studyDetailsStore]);

  return (
    <ReflexContainer
      data-testid='findings-resize-container'
      orientation='vertical'
      windowResizeAware
      style={{
        display: showEventsTree ? 'flex' : 'block'
      }}
    >
      <ReflexElement
        minSize={STUDY_TABS_CONTAINER_MIN_WIDTH}
        data-testid='events-tree-container'
        className={!showEventsTree ? styles.hidden : ''}
        style={{ overflow: 'hidden' }}
      >
        <Findings />
      </ReflexElement>

      {activeCodingId && studyDetailsStore.userSettingsConfig?.reportCollapsed && (
        <ReflexSplitter
          style={{
            ...dividerStyles,
            backgroundColor: styles.White,
            borderLeft: `1px solid ${styles.N200}`
          }}
          data-testid='findings-splitter'
        />
      )}

      {activeCodingId && studyDetailsStore.userSettingsConfig?.reportCollapsed && (
        <ReflexElement
          style={propertiesStyles}
          data-testid='properties-container'
          flex={studyDetailsStore.userSettingsConfig.propertiesSize}
          minSize={FINDINGS_PROPERTIES_MIN_WIDTH}
          onStopResize={({ component }) => {
            studyDetailsStore.updateUserSettingsConfig({
              propertiesSize: component.props.flex
            });
          }}
          propagateDimensions={true}
        >
          <PropertiesContainer
            onCloseProperties={
              !showEventsTree ? () => onFindingClick() : undefined
            }
          />
        </ReflexElement>
      )}
    </ReflexContainer>
  );
};
