import React, { CSSProperties, useCallback, useEffect, useMemo } from 'react';
import {
  Controller,
  useFormContext,
  useWatch
} from 'react-hook-form/dist/index.ie11';
import {
  ErrorTitles,
  Select,
  SelectOptionType,
  useCustomTranslation
} from '@holberg/ui-kit';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { PropertyTypeCode } from 'entities/PropertyTypeCode.entity';
import {
  PropertyType,
  propertyTypeStoreAccessor
} from 'enums/PropertyType.enum';
import { useStore } from 'hooks/store';
import { useHandleAutoSave } from 'hooks/useHandleAutoSave';
import { usePropertyTypesReload } from 'hooks/usePropertyTypesReload';
import { observer } from 'mobx-react-lite';
import { CommonPropertyType } from 'stores/property-type-codes';
import { PropertyTypeCodesRules } from 'stores/property-type-codes/PropertyTypeCodesRules';

export interface FormSelectProps {
  categoricalPropertyType: PropertyType;
  ageConstraints?: string;
  readOnly?: boolean;
  hasNoteField: boolean;
  isOptionSelectable?: (
    propertyCodeId: PropertyTypeCode['propertyCodeId']
  ) => boolean;
  propertyType: CommonPropertyType;
  selectStyles?: CSSProperties;
  removeRow?: () => void;
  selectedPropertyTypeCodes: Array<CategoricalPropertyCoding['formShape']>;
  fieldArrayItemAccessor: string;
  defaultValue?: CategoricalPropertyCoding['formShape'];
  placeholder?: string;
  onSelectSaveAction?: (
    newPropertyCodeId: string,
    oldPropertyCodeId?: string
  ) => void;
  includeCode?: boolean;
}

export const FormSelectRow: React.FC<FormSelectProps> = observer(
  ({
    fieldArrayItemAccessor,
    categoricalPropertyType,
    ageConstraints = 'all',
    hasNoteField,
    removeRow,
    defaultValue,
    selectedPropertyTypeCodes,
    readOnly,
    propertyType,
    onSelectSaveAction = () => {}
  }) => {
    const { t } = useCustomTranslation();
    const { control, setValue } = useFormContext<{
      patientDetails: PatientDetails;
      patientCodings: Record<number, CategoricalPropertyCoding['formShape'][]>;
    }>();

    const storeAccessor = propertyTypeStoreAccessor[categoricalPropertyType];
    const propertyTypesStore = useStore(storeAccessor);

    const stateAccessor = useMemo(
      () => ({
        ageConstraint: ageConstraints,
        propertyType
      }),
      [ageConstraints, propertyType]
    );
    const { isPropertyTypeRetrying, handleReload } = usePropertyTypesReload(
      storeAccessor,
      stateAccessor
    );

    const propertyTypesError = propertyTypesStore.getPropertyTypesCodesError(
      stateAccessor
    );

    const options = useMemo(
      () =>
        propertyTypesStore
          .getPropertyTypesCodes(stateAccessor)
          .map(({ propertyTypeOption }) => ({
            isDisabled: !PropertyTypeCodesRules.notPreviouslySelected(
              selectedPropertyTypeCodes,
              { propertyCodeId: Number(propertyTypeOption.value) }
            ),
            ...propertyTypeOption
          })),
      [propertyTypesStore, selectedPropertyTypeCodes, stateAccessor]
    );

    const freeTextAccessor = useMemo(
      () => `${fieldArrayItemAccessor}.freeText`,
      [fieldArrayItemAccessor]
    );

    const selectAccessor = useMemo(() => `${fieldArrayItemAccessor}.value`, [
      fieldArrayItemAccessor
    ]);

    const freeTextValue = useWatch({
      control,
      name: freeTextAccessor
    });

    const selectValue = useWatch<SelectOptionType | null>({
      control,
      name: selectAccessor
    });

    const isClearable = !!selectValue ? !hasNoteField || !freeTextValue : false;

    const handleBlur = useCallback(
      (onBlur: () => void, value?: string) => {
        onBlur();

        if (!value && removeRow) {
          removeRow();
        }
      },
      [removeRow]
    );

    const { eventHandler } = useHandleAutoSave<
      {
        patientDetails: PatientDetails;
        patientCodings: Record<number, CategoricalPropertyCoding[]>;
      },
      SelectOptionType,
      { currentValue?: Partial<SelectOptionType> | null }
    >({
      saveHandler: (value, config) => {
        const newPropertyCodeId = value?.value;
        const oldPropertyCodeId = config.currentValue?.value;

        onSelectSaveAction(newPropertyCodeId, oldPropertyCodeId);
      }
    });

    const changeHandler = eventHandler(selectAccessor, {
      currentValue: selectValue
    });

    const deleteHandler = eventHandler(undefined, {
      currentValue: selectValue
    });

    useEffect(() => {
      setValue(selectAccessor, defaultValue?.value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Controller
        control={control}
        name={selectAccessor}
        defaultValue={defaultValue?.value}
        render={({ onChange, onBlur, ...rest }) => {
          return (
            <Select
              {...rest}
              readOnly={readOnly}
              data-testid={selectAccessor}
              readOnlyValue={rest.value?.label}
              isLoading={isPropertyTypeRetrying}
              openMenuOnFocus
              onChange={(event, action) => {
                onChange(event, action);
                if (action.action === 'clear') {
                  removeRow && removeRow();
                  deleteHandler();
                } else {
                  changeHandler();
                }
              }}
              onMenuOpen={handleReload}
              errorMessage={propertyTypesError && ErrorTitles.Load}
              options={options}
              isClearable={isClearable}
              placeholder={t('Enter value here')}
              styles={{
                menuList: () => ({
                  maxHeight: 250,
                  maxWidth: '100%'
                })
              }}
              onBlur={() => {
                handleBlur(onBlur, rest.value?.value);
              }}
            />
          );
        }}
      />
    );
  }
);
