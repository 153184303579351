import { deserialize, serializable } from 'serializr';

export class AuthToken {
  @serializable
  authHeader: string = '';

  @serializable
  accessToken: string = '';

  @serializable
  expiresIn: number = 0;

  @serializable
  scope: string = '';

  @serializable
  tokenType: string = '';

  @serializable
  errorDescription: string = '';

  @serializable
  temporarySession: boolean = false;

  static deserialize(json: Object | string) {
    return deserialize(AuthToken, json);
  }
}
