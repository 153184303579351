import React, { FC, useCallback } from 'react';
import {
  IconType,
  Input,
  Modal,
  Tree,
  TreeItem,
  useCustomTranslation
} from '@holberg/ui-kit';
import { GeneralError } from '@holberg/ui-kit';
import { EventCode } from 'entities/EventCode.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useEventCodesTree } from 'hooks/useEventCodesTree';
import { observer } from 'mobx-react-lite';

import styles from './NameSelectionModal.module.scss';

interface ContentProps {
  onClose: () => void;
  onSelect: (eventCodeId: EventCode['eventCodeId']) => void;
  defaultActiveCodeId?: EventCode['eventCodeId'];
  disabledEventCodeIds?: Array<EventCode['eventCodeId']>;
}

interface Props extends ContentProps {
  visible: boolean;
  title: string;
  contentHidden?: boolean;
}

const NameSelectionContent: FC<ContentProps> = observer(
  ({ onSelect, defaultActiveCodeId, disabledEventCodeIds }) => {
    const { t } = useCustomTranslation();
    const findingsStore = useStore(StoreType.Findings);

    const {
      treeData,
      searchValue,
      highlightedItemId,
      handleSearch,
      handleShowInTree
    } = useEventCodesTree(
      findingsStore.eventCodesByAgeConstraint,
      defaultActiveCodeId,
      disabledEventCodeIds
    );

    const handleSelect = useCallback(
      ({ id }: TreeItem) => {
        onSelect(Number(id));
      },
      [onSelect]
    );

    return (
      <div
        role='dialog'
        data-testid='name-selection-content'
        className={styles.wrap}
      >
        <Input
          className={styles.search}
          inputClassName={styles['search-input']}
          cancelable
          value={searchValue}
          icon={IconType.Search}
          iconClassName={styles['search-icon']}
          placeholder={t('Start typing to search')}
          onChange={handleSearch}
        />

        <div className={styles['tree-wrap']}>
          {!treeData.length ? (
            <GeneralError title={t('No results')} icon={IconType.Empty} />
          ) : (
            <Tree
              isPlainList={!!searchValue && !highlightedItemId}
              onActionLabelClick={handleShowInTree}
              rowIndentation={60}
              className={styles.tree}
              actionLabelClassName={styles['action-label']}
              data={treeData}
              highlightedItemId={highlightedItemId}
              onClick={handleSelect}
              iconType={IconType.ChevronRight}
            />
          )}
        </div>
      </div>
    );
  }
);

export const NameSelectionModal: FC<Props> = (props) => (
  <Modal
    className={styles.modal}
    contentClassName={styles.content}
    visible={props.visible}
    handleVisible={props.onClose}
    title={props.title}
    contentHidden={props.contentHidden}
  >
    <NameSelectionContent {...props} />
  </Modal>
);
