import { TreeItem } from '@holberg/ui-kit';
import { ApiError } from 'entities/ApiError.entity';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PropertyCodeExtraInfo } from 'entities/PropertyCodeExtraInfo.entity';
import { PropertyTypeCode } from 'entities/PropertyTypeCode.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { PatientPropertyTypes } from 'enums/PatientPropertyType.enum';
import { PropertyCodeTagType } from 'enums/PropertyCodeTagType.enum';
import { PropertyType, propertyTypeUrl } from 'enums/PropertyType.enum';
import { StudyPropertyTypes } from 'enums/StudyPropertyType.enum';
import { action, makeObservable, observable } from 'mobx';
import { CustomApi } from 'services/API/Custom/CustomApi';
import { ScoreApi } from 'services/API/Score/ScoreApi';
import { BaseStore } from 'stores/BaseStore';
import { PropertyTypeCodesState } from 'stores/property-type-codes/PropertyTypeCodesState';
import { XOR } from 'utils/types';

export type CommonPropertyType =
  | PatientPropertyTypes
  | StudyPropertyTypes
  | DescriptionPropertyTypes
  | string;

export type StateAccessor = {
  propertyType: CommonPropertyType;
} & XOR<
  {
    ageConstraint: string;
  },
  { endpoint?: string }
>;

export class PropertyTypeCodesStore implements BaseStore {
  constructor(propertyType: PropertyType) {
    makeObservable(this);
    this.propertyTypeUrl = propertyTypeUrl[propertyType];
    this.reset();
  }

  private readonly propertyTypeUrl: string;

  @observable propertyTypeCodes!: Map<
    string,
    Map<CommonPropertyType, PropertyTypeCodesState>
  >;
  @observable searchResultsIds!: Map<CommonPropertyType, number[]>;
  @observable propertyTypeCodesLoading!: boolean;

  @observable propertyTypeCodesExtraInfo: Map<
    CommonPropertyType,
    Map<
      PropertyCodeExtraInfo['propertyCodeId'],
      Map<PropertyCodeTagType, boolean>
    >
  > = new Map<
    CommonPropertyType,
    Map<
      PropertyCodeExtraInfo['propertyCodeId'],
      Map<PropertyCodeTagType, boolean>
    >
  >();

  @observable
  propertyTypeCodesExtraInfoLoading: boolean = false;
  @observable
  propertyTypeCodesExtraInfoError?: ApiError | UnknownError;

  @action reset() {
    this.propertyTypeCodes = new Map();
    this.searchResultsIds = new Map();
    this.propertyTypeCodesLoading = false;
    this.propertyTypeCodesExtraInfo = new Map();
    this.propertyTypeCodesExtraInfoLoading = false;
  }

  getPropertyTypesCodesRetrying({
    ageConstraint = 'all',
    propertyType
  }: StateAccessor): boolean | undefined {
    return this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)
      ?.isRetrying;
  }

  getPropertyTypesCodesError({
    ageConstraint = 'all',
    propertyType
  }: StateAccessor): ApiError | UnknownError | undefined {
    return this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)
      ?.dataError;
  }

  getPropertyTypesCodes({
    ageConstraint = 'all',
    propertyType
  }: StateAccessor): PropertyTypeCode[] {
    return (
      this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)?.values || []
    );
  }

  getPropertyTypeCodesTags(
    propertyTypeId: CommonPropertyType
  ): Map<
    PropertyCodeExtraInfo['propertyCodeId'],
    Map<PropertyCodeTagType, boolean>
  > {
    return this.propertyTypeCodesExtraInfo.get(propertyTypeId) || new Map();
  }

  getPreviewPathsId({
    ageConstraint = 'all',
    propertyType
  }: StateAccessor): number[] {
    return (
      this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)
        ?.previewPathIds || []
    );
  }

  getTreeData(
    { ageConstraint = 'all', propertyType }: StateAccessor,
    selectedItems: Array<CategoricalPropertyCoding['formShape']> = [],
    includeCode?: boolean,
    isSelectableRule?: (
      propertyCodeId: PropertyTypeCode['propertyCodeId']
    ) => boolean
  ): TreeItem[] {
    return (
      this.propertyTypeCodes
        .get(ageConstraint)
        ?.get(propertyType)
        ?.getTreeData(selectedItems, includeCode, isSelectableRule) || []
    );
  }

  getSearchTreeData(
    { ageConstraint = 'all', propertyType }: StateAccessor,
    selectedItems: Array<CategoricalPropertyCoding['formShape']> = [],
    includeCode?: boolean,
    isSelectableRule?: (
      propertyCodeId: PropertyTypeCode['propertyCodeId']
    ) => boolean
  ): TreeItem[] {
    return (
      this.propertyTypeCodes
        .get(ageConstraint)
        ?.get(propertyType)
        ?.getSearchTreeData(selectedItems, includeCode, isSelectableRule) || []
    );
  }

  @action
  async loadPropertyTypesCodes({
    ageConstraint = 'all',
    propertyType,
    endpoint
  }: StateAccessor) {
    this.propertyTypeCodesLoading = true;
    this.clearError({ ageConstraint, propertyType });

    if (!this.propertyTypeCodes.get(ageConstraint)) {
      this.propertyTypeCodes.set(ageConstraint, new Map());
    }

    if (this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)) {
      this.propertyTypeCodesLoading = false;
      return;
    }

    try {
      const apiCall = endpoint
        ? CustomApi.getCustomEndpoint<PropertyTypeCode[]>(endpoint)
        : ScoreApi.loadPropertyTypesCodes(propertyType, this.propertyTypeUrl, {
            ageConstraint
          });

      const { data } = await apiCall;

      const propertyCodingState = new PropertyTypeCodesState(
        propertyType,
        PropertyTypeCode.deserializeAsMap(data)
      );

      this.propertyTypeCodes
        .get(ageConstraint)
        ?.set(propertyType, propertyCodingState);
    } catch (e) {
      const propertyCodingState = new PropertyTypeCodesState(
        propertyType,
        new Map(),
        ApiError.deserializeFromCatch(e),
        endpoint ? CustomApi.createRetryState(e) : ScoreApi.createRetryState(e)
      );

      this.propertyTypeCodes
        .get(ageConstraint)
        ?.set(propertyType, propertyCodingState);
    } finally {
      this.propertyTypeCodesLoading = false;
    }
  }

  @action
  async loadPropertyTypeCodesExtraInfo(propertyTypeId: CommonPropertyType) {
    this.propertyTypeCodesExtraInfoLoading = true;
    this.propertyTypeCodesExtraInfoError = undefined;

    try {
      const { data } = await ScoreApi.loadPropertyTypeCodesExtraInfo(
        propertyTypeId,
        this.propertyTypeUrl
      );

      this.propertyTypeCodesExtraInfo.set(
        propertyTypeId,
        PropertyCodeExtraInfo.deserializeAsMap(data)
      );
    } catch (e) {
      this.propertyTypeCodesExtraInfoError = ApiError.deserializeFromCatch(e);
    } finally {
      this.propertyTypeCodesExtraInfoLoading = false;
    }
  }

  @action
  private async loadNestedTypesCodes(
    stateAccessor: StateAccessor,
    parentId: PropertyTypeCode['parentId'],
    levelsLeft: number,
    path: Array<PropertyTypeCode['parentId']> = []
  ): Promise<any> {
    if (!levelsLeft) {
      return [parentId];
    }

    const nestedCodes = this.propertyTypeCodes
      .get(stateAccessor.ageConstraint || 'all')
      ?.get(stateAccessor.propertyType)
      ?.values.filter((code) => code.parentId === parentId);

    const nestedPath = await Promise.all(
      nestedCodes?.map(async (code) => {
        if (code.hasChildren) {
          await this.loadPropertyTypesCodesByParentId({
            ...stateAccessor,
            parentId: code.propertyCodeId
          });

          return await this.loadNestedTypesCodes(
            stateAccessor,
            code.propertyCodeId,
            levelsLeft - 1,
            path
          );
        }

        return [];
      }) || []
    );

    const flattenNestedPath = nestedPath.filter((path) => !!path).flat();

    return parentId ? [parentId, ...flattenNestedPath] : [...flattenNestedPath];
  }

  @action
  async loadAllPropertyTypesCodes(
    stateAccessor: StateAccessor,
    nestingLevel = 1
  ) {
    await this.loadPropertyTypesCodes(stateAccessor);
    const path = await this.loadNestedTypesCodes(
      stateAccessor,
      null,
      nestingLevel
    );

    this.propertyTypeCodes
      .get(stateAccessor.ageConstraint || 'all')
      ?.get(stateAccessor.propertyType)
      ?.setPreviewPathIds(path);
  }

  @action reloadPropertyTypesCodes(
    { ageConstraint = 'all', propertyType }: StateAccessor,
    isSearch?: boolean
  ) {
    this.propertyTypeCodes
      .get(ageConstraint)
      ?.get(propertyType)
      ?.retryOnce(isSearch);
  }

  @action
  async loadPropertyTypesCodesByParentId({
    ageConstraint = 'all',
    propertyType,
    parentId,
    endpoint
  }: StateAccessor & { parentId: string | number }) {
    this.propertyTypeCodesLoading = true;

    try {
      const apiCall = endpoint
        ? CustomApi.getCustomEndpoint<PropertyTypeCode[]>(endpoint, {
            parentId
          })
        : ScoreApi.loadPropertyTypesCodes(propertyType, this.propertyTypeUrl, {
            parentId,
            ageConstraint
          });

      const { data } = await apiCall;

      if (!this.propertyTypeCodes.get(ageConstraint)) {
        this.propertyTypeCodes.set(ageConstraint, new Map());
      }

      if (!this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)) {
        this.propertyTypeCodes
          .get(ageConstraint)
          ?.set(propertyType, new PropertyTypeCodesState(propertyType));
      }

      this.propertyTypeCodes
        .get(ageConstraint)
        ?.get(propertyType)
        ?.addValues(data);
    } catch {
    } finally {
      this.propertyTypeCodesLoading = false;
    }
  }

  @action
  async searchPropertyTypesCodes({
    ageConstraint = 'all',
    propertyType,
    query,
    endpoint
  }: StateAccessor & { query: string }) {
    this.propertyTypeCodesLoading = true;
    this.clearError({ ageConstraint, propertyType });

    if (!this.propertyTypeCodes.get(ageConstraint)) {
      this.propertyTypeCodes.set(ageConstraint, new Map());
    }

    if (!this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)) {
      this.propertyTypeCodes
        .get(ageConstraint)
        ?.set(propertyType, new PropertyTypeCodesState(propertyType));
    }

    try {
      const apiCall = endpoint
        ? CustomApi.getCustomEndpoint<PropertyTypeCode[]>(endpoint, {
            query
          })
        : ScoreApi.loadPropertyTypesCodes(propertyType, this.propertyTypeUrl, {
            query,
            ageConstraint
          });

      const { data } = await apiCall;

      this.propertyTypeCodes
        .get(ageConstraint)
        ?.get(propertyType)
        ?.addSearchResults(data);
    } catch (e) {
      this.propertyTypeCodes
        .get(ageConstraint)
        ?.get(propertyType)
        ?.setError(ApiError.deserializeFromCatch(e));
      this.resetSearchResults({ propertyType, ageConstraint });
    } finally {
      this.propertyTypeCodesLoading = false;
    }
  }

  @action
  async setShowInTree(
    { ageConstraint = 'all', propertyType, endpoint }: StateAccessor,
    item: TreeItem
  ) {
    const { id } = item;
    this.propertyTypeCodesLoading = true;

    if (!this.propertyTypeCodes.get(ageConstraint)) {
      this.propertyTypeCodes.set(ageConstraint, new Map());
    }

    if (!this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)) {
      this.propertyTypeCodes
        .get(ageConstraint)
        ?.set(propertyType, new PropertyTypeCodesState(propertyType));
    }

    try {
      const apiCall = endpoint
        ? CustomApi.getCustomEndpoint<PropertyTypeCode[]>(
            endpoint,
            {},
            (url) => `${url}/${id}`
          )
        : ScoreApi.loadPropertyTypesCodesPath(
            propertyType,
            this.propertyTypeUrl,
            id,
            {
              ageConstraint
            }
          );

      const { data } = await apiCall;

      this.propertyTypeCodes
        .get(ageConstraint)
        ?.get(propertyType)
        ?.setShowInTree(data, item);
    } catch (e) {
      this.propertyTypeCodes
        .get(ageConstraint)
        ?.get(propertyType)
        ?.setError(ApiError.deserializeFromCatch(e));
    } finally {
      this.propertyTypeCodesLoading = false;
    }
  }

  @action
  resetSearchResults({ ageConstraint = 'all', propertyType }: StateAccessor) {
    this.propertyTypeCodes
      .get(ageConstraint)
      ?.get(propertyType)
      ?.resetSearchResults();
  }

  @action
  resetPreviewPathsId({ ageConstraint = 'all', propertyType }: StateAccessor) {
    this.propertyTypeCodes
      .get(ageConstraint)
      ?.get(propertyType)
      ?.resetPreviewPathsId();
  }

  @action
  clearError({ ageConstraint = 'all', propertyType }: StateAccessor) {
    this.propertyTypeCodes.get(ageConstraint)?.get(propertyType)?.clearError();
  }

  isTreeStructure(items: TreeItem[]): boolean {
    return items.filter((item) => item.hasChildren).filter(Boolean).length > 0;
  }
}
