import { EventsTreeSubitem } from '@holberg/ui-kit';
import { HDataType } from 'enums/HDataType.enum';
import { deserialize, list, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export abstract class ShoppingCartComponent {
  abstract getNodes(index?: number): EventsTreeSubitem[];
}

export class ShoppingCartValue extends ShoppingCartComponent {
  @serializable
  valueDataType: HDataType = HDataType.String;

  @serializable
  valueTemplate: string | null = null;

  @serializable(list(object(InformationModel)))
  values: InformationModel<string | boolean, string | boolean>[] = [];

  getNodes() {
    if (!this.values.length) {
      return [];
    }

    return [
      {
        text: this.values.reduce(
          (parsedValue, value, index) =>
            parsedValue.replace(`{${index}}`, value.eitherValue),
          this.valueTemplate || '{0}'
        )
      }
    ];
  }

  static deserialize(json: Object | string) {
    return deserialize(ShoppingCartValue, json);
  }

  static deserializeAsList(list: ShoppingCartValue[]): ShoppingCartValue[] {
    return list.map(ShoppingCartValue.deserialize);
  }
}
