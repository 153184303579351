import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ErrorTheme,
  GeneralError,
  IconType,
  LoadingOverlay,
  useCustomTranslation
} from '@holberg/ui-kit';
import { ErrorScreen } from 'components/ErrorScreen';
import {
  ReaderErrorMessage,
  ReaderErrorType
} from 'enums/ReaderErrorType.enum';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';

import styles from './ExternalLoginPage.module.scss';

/**
 * The <code>authorizeFromExternal</code> method from <code>authStore</code> is called with token from URL<br/>
 * Then wait for <code>authStore.isAuthenticated</code> to be true and redirect to the Root URL
 */
export const ExternalLoginPage: React.FC = observer(() => {
  const { t } = useCustomTranslation();
  const { push, location } = useHistory();
  const hash = queryString.parse(location.hash);

  const token = (hash.token as string) || undefined;
  const authStore = useStore(StoreType.Auth);
  const hasError = authStore.authError || !token;

  const authorize = useCallback(() => {
    authStore.authorizeFromExternal(token);
    authStore.setLoadingStudyFromReaderStatus(true);
  }, [authStore, token]);

  useEffect(() => {
    authorize();
  }, [authorize]);

  useEffect(() => {
    if (authStore.isAuthenticated) {
      push(Routes.Root);
    }
  }, [authStore.isAuthenticated, push]);

  //return this error screen with message when license is expired
  if (
    authStore.licenseStatus.clientName &&
    !authStore.licenseStatus.isLicensed &&
    hasError
  ) {
    return (
      <ErrorScreen
        errorText={t(ReaderErrorMessage[ReaderErrorType.LicenseExpired])}
      />
    );
  }

  return (
    <LoadingOverlay loading={authStore.authLoading}>
      <div className={styles['external-login-page']}>
        {hasError && (
          <GeneralError
            icon={IconType.Error}
            theme={ErrorTheme.Secondary}
            title={ReaderErrorMessage[ReaderErrorType.ReaderAuthFailed]}
          />
        )}
      </div>
    </LoadingOverlay>
  );
});
