import { deserialize, object, serializable } from 'serializr';

import { InformationModel } from './InformationModel.entity';

export class EEGMarkerType {
  @serializable
  markerTypeId: number = 0;

  @serializable(object(InformationModel))
  name: InformationModel = new InformationModel();

  static deserialize(json: Object | string) {
    return deserialize(EEGMarkerType, json);
  }

  static deserializeAsList(list: EEGMarkerType[]): EEGMarkerType[] {
    return list.map(EEGMarkerType.deserialize);
  }
}
