import { IconType } from '@holberg/ui-kit';

enum PropertyPanelIcons {
  Amplitude = 1,
  Duration = 2,
  EventCount = 3,
  Frequency = 4,
  Interval = 5,
  Note = 7,
  Time = 8
}

export const PropertyPanelIconsId = Object.freeze({
  [PropertyPanelIcons.Amplitude]: IconType.Amplitude,
  [PropertyPanelIcons.Duration]: IconType.Duration,
  [PropertyPanelIcons.EventCount]: IconType.EventCount,
  [PropertyPanelIcons.Frequency]: IconType.Frequency,
  [PropertyPanelIcons.Interval]: IconType.Interval,
  [PropertyPanelIcons.Note]: IconType.Note,
  [PropertyPanelIcons.Time]: IconType.Time
});
